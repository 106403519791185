import { Component, OnInit,ChangeDetectorRef,ViewChild } from '@angular/core';
import { FormGroup, FormControl, Validators, FormBuilder, ValidatorFn, AbstractControl } from '@angular/forms';
import { MatDialog, MatSnackBar } from '@angular/material';
import { VendorsupervisorregisterService } from 'src/app/Services/vendorsupervisor/vendorsupervisorregister.service';
import { VendorSupervisor } from 'src/app/Models/vendorsupervisor/vendor-supervisor.model';
import { LoginService } from 'src/app/Services/loginmanagement/login.service';
import { Router } from '@angular/router';
import { IRole } from 'src/app/Models/usermanagement/role.model';
import { Observable } from 'rxjs';
import { IUserType } from 'src/app/Models/usermanagement/usertype.model';
import { catchError } from 'rxjs/operators';
import { UniqueUsernameValidator } from 'src/app/shared/unique-username-validator.directive';
import { ConfigurationService } from 'src/app/Services/Utilities/configuration.service';
import { Constants } from 'src/app/Constants/constants';
import { IState } from 'src/app/Models/common/state.model';
import { Icity } from 'src/app/Models/common/city.model';
import { VendorsupervisoreditService } from 'src/app/Services/vendorsupervisor/vendorsupervisoredit.service';
import { VSSCreateService } from 'src/app/Services/vendorsupervisor/VSSCreate.service';
@Component({
  selector: 'app-vendorsupervisor-registration',
  templateUrl: './vendorsupervisor-registration.component.html',
  styleUrls: ['./vendorsupervisor-registration.component.scss']
})
export class VendorsupervisorRegistrationComponent implements OnInit {

 
  public submitted: boolean;
  public ShowLoader: boolean;
  
  public mobileExists: boolean;
  public emailExists: boolean;
  public alternateemailExists: boolean;
  public roles: IRole[];
  public State: IState[];
  public City: Icity[];
  public intialEmailId : string;
 
  @ViewChild('form', {static: false}) form;

  constructor(private SEService: VSSCreateService,private router: Router,
    private userService: VendorsupervisorregisterService,
    private userEditService: VendorsupervisoreditService,
    private userLoginService: LoginService,
    private fb: FormBuilder,
    public snackBar: MatSnackBar,
    private configurationService: ConfigurationService,
    private cdr:ChangeDetectorRef) {
      this.createForm();

    this.submitted = false;
    

    this.mobileExists = false;
    this.emailExists = false;

     }

     resetForm() {
      //this.form.reset();
     // this.form.control.controls['lastName','username','mobile'].reset();
     this.form.control.controls['lastName'].reset();
     this.form.control.controls['username'].reset();
     this.form.control.controls['mobile'].reset();
     this.form.control.controls['firstName'].reset();
     this.form.control.controls['Address'].reset();
     this.form.control.controls['Aemail'].reset();
     this.form.control.controls['email'].reset();
     this.form.control.controls['Amobile'].reset();
     this.form.control.controls['Organization'].reset();
     this.form.control.controls['State'].reset();
     this.form.control.controls['City'].reset();
     this.form.control.controls['PIN'].reset();
     var ExistVendorID = this.userLoginService.GetFirstTimeLoginUserInput();
    this.VendorSupervisorRegForm.get('VendorID').setValue(ExistVendorID); 
   
      // clear validators and dont remove them
      this.VendorSupervisorRegForm.clearValidators();
  
      this.VendorSupervisorRegForm.markAsUntouched();
      this.VendorSupervisorRegForm.markAsPristine();
      // let control: AbstractControl = null;
      // Object.keys(this.createUserForm.controls).forEach((name) => {
      //   control = this.createUserForm.controls[name];
      //   control.setErrors(null);
      // });
  
      this.mobileExists = false;
      this.emailExists = false;
     
      
    }

     public account_validation_messages = {
      'VendorID': [
        { type: 'required', message: 'Vendor ID is required' }
      ],
      'username': [
        { type: 'required', message: 'Username is required' },
        { type: 'minlength', message: 'Username must be at least 5 characters long' },
        { type: 'maxlength', message: 'Username cannot be more than 100 characters long' },
        { type: 'pattern', message: 'Your username must contain only numbers and alphabets' },
        { type: 'usernameTaken', message: 'Username is already in use.' }
      ],
      'firstname': [
        { type: 'required', message: 'First name is required' },
        { type: 'maxlength', message: 'First name cannot be more than 250 characters long' },
        { type: 'pattern', message: 'Your first name must contain only  alphabets' }
      ],
    
      'lastname': [
        { type: 'required', message: 'Last name is required' },
        { type: 'maxlength', message: 'Last name cannot be more than 250 characters long' },
        { type: 'pattern', message: 'Your last name must contain only numbers and alphabets' }
      ],
      'mobile': [
        { type: 'required', message: 'Mobile is required' },
        { type: 'minlength', message: 'Mobile must be of 10 digits' },
        { type: 'pattern', message: 'Mobile is invalid' }
      ],
      'Amobile': [
       // { type: 'required', message: 'Mobile is required' },
        { type: 'minlength', message: 'Mobile must be of 10 digits' },
        { type: 'pattern', message: 'Mobile is invalid' }
      ],
      'email': [
        { type: 'required', message: 'Email is required' },
        { type: 'maxlength', message: 'Email cannot be more than 100 characters long' },
        { type: 'pattern', message: 'Email is invalid' }
      ],
      'Aemail': [
       // { type: 'required', message: 'Email is required' },
        { type: 'maxlength', message: 'Email cannot be more than 100 characters long' },
        { type: 'pattern', message: 'Email is invalid' }
      ],
      'Address': [
        //{ type: 'required', message: 'Address is required' },
        { type: 'maxlength', message: 'Address cannot be more than 100 characters long' },
      
   
      ],
      'PIN': [
        { type: 'required', message: 'PIN is required' },
        // { type: 'pattern', message: 'PIN is invalid' }
        { type: 'pattern', message: 'PIN should be numeric' },
        { type: 'minlength', message: 'PIN should be 6 digits long' },
      ],
      'State': [
        { type: 'required', message: 'State is required' },
        // { type: 'pattern', message: 'PIN is invalid' }
      ],
      'City': [
        { type: 'required', message: 'City is required' },
        // { type: 'pattern', message: 'PIN is invalid' }
      ],
      'Organization':[
        { type: 'required', message: 'Organization is Required'},
      ]
  
    };

    validationMessages = {
      'VendorID': {
        'required': 'Vendor ID is required'
      },
      'username': {
        'required': 'Username is required.',
        'minlength': 'Username must be at least 5 characters long.',
        'maxlength': 'Username cannot be more than 100 characters long.',
        'pattern': 'Your username must contain only numbers and alphabets.',
        'usernameTaken': 'Username is already in use.'
      },
      'firstName': {
        'required': 'First name is required.',
        'maxlength': 'First name cannot be more than 250 characters long.',
        'pattern': 'Your first name must contain only  alphabets.'
      },
      'lastName': {
        'required': 'Last name is required.',
        'maxlength': 'Last name cannot be more than 250 characters long.',
        'pattern': 'Your last name must contain only numbers and alphabets.'
      },
      'mobile': {
        'required': 'Mobile is required.',
        'minlength': 'Mobile must be of 10 digits.',
        'pattern': 'Mobile is invalid.'
      },
      'Amobile': {
        'minlength': 'Mobile must be of 10 digits.',
        'pattern': 'Mobile is invalid.'
      },
      'email': {
        'required': 'Email Id is required.',
        'maxlength': 'Email Id cannot be more than 100 characters long.',
        'pattern': 'Email Id is invalid.'
      },
      'Aemail': {
        'maxlength': 'Email Id cannot be more than 100 characters long.',
        'pattern': 'Email Id is invalid.'
      },
      'Organization': {
        'required': 'Organization is Required.'
      },
      'Address': {
        'maxlength': 'Address cannot be more than 100 characters long.'
      },
      'PIN': {
        'required': 'PIN Code is required.',
        'pattern': 'PIN Code should be numeric.',
        'minlength': 'PIN Code should be 6 digits long.'
      },
      'State': {
        'required': 'State is required.'
      },
      'City': {
        'required': 'City is required.'
      }
    };
  
    formErrors = {
      'VendorID': '',
      'username': '',
      'firstName': '',
      'lastName': '',
      'mobile': '',
      'Amobile': '',
      'email': '',
      'Aemail': '',
      'Organization': '',
      'Address': '',
      'PIN': '',
      'State': '',
      'City': ''
    };

  public VendorSupervisorRegForm: FormGroup;
  ngOnInit() {
    this.ShowLoader =true;
    var ExistVendorID = this.userLoginService.GetFirstTimeLoginUserInput();
    var VendorMobile = this.userLoginService.GetFirstTimeLoginUserMobile();
    this.VendorSupervisorRegForm.get('VendorID').setValue(ExistVendorID); 
    this.VendorSupervisorRegForm.get('mobile').setValue(VendorMobile); 

    // this.userService.getState()
    // .pipe(catchError((err) => this.handleError(err)))
    // .subscribe((data: any) => {
    //   this.State = data.results;  
    //   this.ShowLoader =false;      
    // });
  
    this.userEditService.getVSByVendorID(ExistVendorID)
        .subscribe((data: any) => {
          if (data != null)
          {
             this.SetVSIdDetails(data.results);
          }
        },
        (err: any) => {
         this.openSnackBar(err.error.message, 'error');
        });
  
        this.VendorSupervisorRegForm.valueChanges.subscribe((data) => {
          this.logValidationErrors(this.VendorSupervisorRegForm);
        });

  }



  FillCityDD(Slectedstate:any)
{
  this.userService.getCity(Slectedstate.name)
  .pipe(catchError((err) => this.handleError(err)))
  .subscribe((data: any) => {
    this.City = data.results;  
    this.ShowLoader =false;      
  });
}


public SetVSIdDetails(VSID: VendorSupervisor) {
if (VSID != null)
{
    this.VendorSupervisorRegForm.patchValue({
      username: VSID[0].vendorID ,
      VendorID: VSID[0].vendorID ,
      firstName: VSID[0].firstName,
      lastName: VSID[0].lastName,
     // mobile: VSID[0].mobile,
      email: VSID[0].email
    });
    this.intialEmailId = VSID[0].email;
    this.ShowLoader =false; 
  }
  }
  createForm() {
    this.VendorSupervisorRegForm = this.fb.group({
      username: new FormControl('', Validators.compose([
        Validators.required,
        Validators.maxLength(100)
        
      ]),
        //UniqueUsernameValidator(this.VendorSupervisorRegForm) // async validator
      ),
      firstName: new FormControl('', Validators.compose([
        Validators.required,
        Validators.maxLength(250),
        //Validators.pattern('^[A-Za-z]+(?:[A-Za-z]+)*$')
        //Validators.pattern('/^[a-zA-Z]+$/')
        Validators.pattern('^[a-zA-Z0-9 ]*$')
      ])),
     
      lastName: new FormControl('', Validators.compose([
        Validators.required,
        Validators.maxLength(250),
       // Validators.pattern('^[A-Za-z]+(?:[A-Za-z]+)*$')
        Validators.pattern('^[a-zA-Z0-9 ]*$')
      ])),
      mobile: new FormControl('', Validators.compose([
        Validators.required,
        Validators.minLength(10),
        Validators.pattern('^[6-9][0-9]{9}$')
      ])),
      Amobile: new FormControl('', Validators.compose([
        Validators.minLength(10),
        Validators.pattern('^[6-9][0-9]{9}$')
      ])),
      email: new FormControl('', Validators.compose([
        Validators.required,
        Validators.maxLength(100),
        Validators.pattern('^([a-zA-Z0-9_\.\-])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})+$')
      ])),

      Aemail: new FormControl('', Validators.compose([
        
        Validators.maxLength(100),
        Validators.pattern('^([a-zA-Z0-9_\.\-])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})+$')
      ])),
      Organization: new FormControl('', Validators.compose([
        Validators.required,
        Validators.maxLength(100),
        //Validators.pattern('^([a-zA-Z0-9_\.\-])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})+$')
      ])),
      
      Address: new FormControl('', Validators.compose([
       
        Validators.maxLength(100),
        //Validators.pattern('^([a-zA-Z0-9_\.\-])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})+$')
      ])),
      PIN: new FormControl('', Validators.compose([
        Validators.required,
        Validators.maxLength(6),
        Validators.minLength(6),
        Validators.pattern('^[0-9]*$')
      ])),
      // State: new FormControl('', Validators.compose([
      //   Validators.required,
      //  ])),
      // City: new FormControl('', Validators.compose([
      //   Validators.required,
      //  ])),
      VendorID: new FormControl('', Validators.compose([
        Validators.required
        
      ])),

     // reference: new FormControl({ disabled: true, value: '' },[Validators.required]),
     
    });
  }

  checkEmail() {

    if (this.VendorSupervisorRegForm.get('email').valid) {
      const email = this.VendorSupervisorRegForm.get('email').value;
      const searchType = 'email';
      if ( this.intialEmailId == email)
      {
         this.emailExists = false;
         return;
      } 

      this.SEService.searchUserVS(searchType, email).subscribe(
        (data: any) => {
          if (data.flag == 'S') {
            this.emailExists = true;
          } else {
            this.emailExists = false;
          }
        },
        (err: any) => { 
          this.openSnackBar(err.error.message, '')
        },
      );
    }
  }

  checkAlternateEmail() {
    
    if (this.VendorSupervisorRegForm.get('Aemail').valid) {
      const email = this.VendorSupervisorRegForm.get('Aemail').value;
      if (email == "")
      {
        return;
      }

      const searchType = 'email';

      this.SEService.searchUserVS(searchType, email).subscribe(
        (data: any) => {
          if (data.flag == 'S') {
            this.alternateemailExists = true;
          } else {
            this.alternateemailExists = false;
          }
        },
        (err: any) => { 
          this.openSnackBar(err.error.message, '')
        },
      );
    }
  }

  onSubmit() {
    if (this.VendorSupervisorRegForm.valid) {
      this.ShowLoader =true;
      const userDetails = Object.assign({}, this.VendorSupervisorRegForm.value);
      const newUser = new VendorSupervisor();
      newUser.username = userDetails.VendorID;
      newUser.VendorID = userDetails.VendorID;
      newUser.firstName = userDetails.firstName;
      newUser.lastName = userDetails.lastName;
      newUser.email = userDetails.email;
      newUser.mobile = userDetails.mobile;
      newUser.Aemail = userDetails.Aemail;
      newUser.Amobile = userDetails.Amobile;
      newUser.State = null;
      newUser.City = null;
      newUser.PIN = userDetails.PIN;
      newUser.Address = userDetails.Address;
      newUser.Organization = userDetails.Organization;
      newUser.level =0;
      this.userService.CreateVS(newUser)
        .subscribe(
          (data: any) => {
            if (data.flag === Constants.SUCCESS_FLAG) {
              this.openSnackBar(data.message, '');
              this.router.navigate(['/login']);
              this.ShowLoader =false;
            }
            else
            {
              this.openSnackBar(data.message, '');
              this.ShowLoader =false;
            }
          },
          (err: any) => {
            if (err.error != null)
            {
              this.openSnackBar(err.error.message, '')
            }
            else
            {
              this.openSnackBar(err,'');
            }
            this.ShowLoader =false;
          });
    }
    else
    {
      this.ValidateAllFields(this.VendorSupervisorRegForm);
      this.logValidationErrors(this.VendorSupervisorRegForm);
    }

   
  }

  openSnackBar(message: string, action: string) {
    this.snackBar.open(message, action, {
      duration: 5000,
      verticalPosition: 'top'
    });
  }

  logValidationErrors(group: FormGroup = this.VendorSupervisorRegForm): void {
    Object.keys(group.controls).forEach((key: string) => {
      const abstractControl = group.get(key);
      if (abstractControl instanceof FormGroup) {
        this.logValidationErrors(abstractControl)
      } else {
        this.formErrors[key] = '';
        if (abstractControl && !abstractControl.valid && (abstractControl.touched || abstractControl.dirty)) {
          const messages = this.validationMessages[key];

          for (const errorKey in abstractControl.errors) {
            if (errorKey) {
              this.formErrors[key] += messages[errorKey] + '';
            }
          }
        }
      }
    });
  }

  ValidateAllFields(formGroup: FormGroup) {
    Object.keys(formGroup.controls).forEach(field => {
      const control = formGroup.get(field);
      if (control instanceof FormControl) {
        control.markAsTouched({ onlySelf: true });
      } else if (control instanceof FormGroup) {
        this.ValidateAllFields(control);
      }
    });
  }


  private handleError(error: any) {
    // this.errorReceived = true;
    return Observable.throw(error);
  }
  Back(): void
  {
    this.router.navigate(['/login']);
  }
  
}
