import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpRequest, HttpEventType, HttpResponse, HttpEvent, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from '../../../environments/environment';
//import { Ticket } from 'src/app/Models/vendorsupervisor/ticket.model';
import { ViewTicket } from 'src/app/Models/ViewTicket/ViewTicket.model';
//import { flattenStyles } from '@angular/platform-browser/src/dom/dom_renderer';
import { HttpVerbService } from 'src/app/Services/httpverb/httpverb.service';
import { DropdownItems } from '../../Models/DropdownItemsGroup/dropdownitems.model';
import { debounceTime } from 'rxjs/internal/operators/debounceTime';
import { VendorSupervisor } from 'src/app/Models/vendorsupervisor/vendor-supervisor.model';



@Injectable({
  providedIn: 'root'
})
export class VendorsupervisoreditService {
  baseUrl: string;
  VSID: string;

  constructor(private http: HttpClient, private service: HttpVerbService) { 
  this.baseUrl = environment.restUrl + '/api/v1/';
  }

  public setVSID(VSID: string) {
    this.VSID = VSID;
  }

  public getVSId() {
    return this.VSID;
  }
  //+ '?VSID=' + VSID
  public getVS(): Observable<any> {
    return this.http.get<any>(this.baseUrl + 'getVSUser', {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        //'No-Auth': 'True' //need to remove during deployment
      })
    }).pipe(
      map((response: any) => {
      return response;
    }
    ));
  }

  public getVSByVendorID(VSID: string): Observable<any> {
    return this.http.post<any>(this.baseUrl + 'getVSUserbyVSID', VSID, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'No-Auth': 'True' //need to remove during deployment
      })
    }).pipe(
      map((response: any) => {
      return response;
    }
    ));
  }

  updateUser(userData: VendorSupervisor): Observable<any> {
    return this.http.post<any>(this.baseUrl + 'EditVSusers', userData, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    });
  }
}
