/**
 * This class is to have string literal for application constants. 
 */
export class applicationconstant {
    public static readonly SuccessFlag = 'S';
    public static readonly FailureFlag = 'F';
    public static readonly ExceptionErrorMsg = 'Something went wrong';
    public static readonly SEDocument = 'SERVICE_ENGINEER_DOCUMENT';
    public static readonly EFSRDocument = 'EFSR_DOCUMENT';
    public static readonly EFSRForm = 'EFSR_REPORT';
    public static readonly ActiveFlag = 'A';
    public static readonly InActiveFlag = 'I';
    public static readonly NotVerifiedFlag = 'NV';
    public static readonly Ticket_Search_Status = 'STATUS';

    public static readonly Ticket_Search_Open = 'OPEN';
    public static readonly Ticket_Search_Closed = 'CLOSED';
    public static readonly Ticket_Search_OnHold = 'ON HOLD';
    public static readonly Ticket_Search_InProgress = 'IN PROGRESS';
    
    public static readonly Incident_Assigned_Status = 'ASSIGNED';
    public static readonly Incident_Open_Status = 'OPEN';
    public static readonly Incident_WIP_Status = 'WORK IN PROGRESS';
    public static readonly Incident_EFSRWIP_Status = 'EFSR ON HOLD';
    public static readonly Incident_ROApproval_Status = 'RO APPROVAL PENDING';
    public static readonly Incident_WorkApproved_Status = 'EFSR APPROVED BY RO';
    public static readonly Incident_WorkRejected_Status = 'EFSR REJECTED BY RO'
    public static readonly Incident_Closed_Status = 'CLOSED';

    // New Status

    public static readonly INCIDENT_STATUS_OPEN = 'OPEN';

    public static readonly INCIDENT_STATUS_ASSIGNED = "ASSIGNED";

    public static readonly INCIDENT_STATUS_WORKINPROGRESS = "WORK_IN_PROGRESS";

    public static readonly INCIDENT_STATUS_EFSR_ON_HOLD = "EFSR_ON_HOLD";

    public static readonly INCIDENT_STATUS_RO_APPROVAL_PENDING = "RO_APPROVAL_PENDING";

    public static readonly INCIDENT_STATUS_EFSR_APPROVED_BY_RO = "EFSR_APPROVED_BY_RO";

    public static readonly INCIDENT_STATUS_EFSR_REJECTED_BY_RO = "EFSR_REJECTED_BY_RO";



    public static readonly Vehicle_DOC_CommercialPermitCopy = 'Commercial Permit copy';
    public static readonly Vehicle_DOC_Registration_Certificate_Copy = 'Registration Certificate Copy';
    public static readonly Customer_Search_Status = 'STATUS_ID';
    public static readonly Customer_Search_Active = 'Active';
    public static readonly Customer_Search_Inactive = 'Blacklist';
    public static readonly Payment_Method_Wallet = 'WALLET';
    public static readonly Request_For_Mobile_Verification = 'MobileVerification';
    public static readonly Request_For_Email_Verification = 'EmailVerification';
    public static readonly RESERVED_FOR_ROUTING = 'RESERVED_FOR_ROUTING';
    public static readonly CONFIRM_FOR_DELIVERY = 'Confirmed For Delivery';
}
