import { Injectable } from '@angular/core';
import { ISearchPara } from 'src/app/Models/Reports/workingincidenthistory.model';
import { Observable, throwError } from 'rxjs';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { map, catchError, tap } from 'rxjs/operators';
import { HttpVerbService } from 'src/app/Services/httpverb/httpverb.service';
import {ISearchUser} from 'src/app/Models/Reports/workingincidenthistory.model';
import * as FileSaver from 'file-saver'; 
import * as XLSX from 'xlsx'; 
import { environment } from 'src/environments/environment';


@Injectable({
    providedIn: 'root'
  })
  export class userlistService {
  
    baseUrl: string;
    constructor(private http: HttpClient, private service: HttpVerbService) {
      this.baseUrl = environment.restUrl + '/api/v1/';
    }
    fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8'; 
    fileExtension = '.xlsx'; 

    public exportExcel(jsonData: any[], fileName: string): void { 
       const ws: XLSX.WorkSheet = XLSX.utils.json_to_sheet(jsonData); 
      const wb: XLSX.WorkBook = { Sheets: { 'data': ws }, SheetNames: ['data'] }; 
      const excelBuffer: any = XLSX.write(wb, { bookType: 'xlsx', type: 'array' }); 
      this.saveExcelFile(excelBuffer, fileName); 
      } 
       
      private saveExcelFile(buffer: any, fileName: string): void { 
      const data: Blob = new Blob([buffer], {type: this.fileType}); 
      FileSaver.saveAs(data, fileName + this.fileExtension); 
      } 
  
  searchUser(userData: ISearchUser): Observable<any> {
    return this.http.post<any>(this.baseUrl + 'SearchUserListReport', userData, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    }).pipe(
      map((response: any) => {
      return response;
    }
    ));
  }


  searchROVendorData(userData: ISearchUser): Observable<any> {
    return this.http.post<any>(this.baseUrl + 'SearchROVendorMappListReport', userData, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    }).pipe(
      map((response: any) => {
      return response;
    }
    ));
  }

  }  