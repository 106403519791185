import { Component, OnInit, ViewChild, Input, Output, EventEmitter } from '@angular/core';
import { documentList } from 'src/app/Models/ServiceEngineer/documentList';
import { HttpEventType, HttpResponse } from '@angular/common/http';
import { FormGroup, FormBuilder, FormControl, Validators, PatternValidator } from '@angular/forms';
import { MatSnackBar } from '@angular/material';
import { ReturnResponse } from 'src/app/Models/ServiceEngineer/returnResponse.model';
import { Router } from '@angular/router';
import { VendorSupervisorService } from 'src/app/Services/vendorsupervisor/vendorsupervisor.service';
import { efsrService } from 'src/app/Services/EFSR/efsrcreate.service';
import { applicationconstant } from 'src/app/Models/DropdownItemsGroup/applicationconstant.model';
import { RemoveFile } from 'src/app/Models/common/removeFile.model';
import { TicketsAssginedSE } from 'src/app/Services/ServiceEngineer/TicketsAssignedSE.service';
//import{IName} from 'src/app/Models/TicketsSE/engineername.model';
//import { BindingFlags } from '@angular/compiler/src/core';
//import { EFSR, efsrDoc } from 'src/app/Models/EFSR/efsr.model';
//import { catchError } from 'rxjs/operators';
//import { patchefsrDraft, EFSRApproveReject } from 'src/app/Models/EFSR/efsrcreate.model';
import { UserService } from 'src/app/Services/ServiceEngineer/SECreate.service';
import { IName } from 'src/app/Models/TicketsSE/engineername.model';
import { Observable } from 'rxjs';
import { SECreate } from 'src/app/Models/ServiceEngineer/SECreate.model';
import { Constants } from 'src/app/Constants/constants';




@Component({
  selector: 'app-upload-docs',
  templateUrl: './upload-docs.component.html',
  styleUrls: ['./upload-docs.component.scss']
})
export class UploadDocsComponent implements OnInit {
  
  documents: documentList[]=[];
  file:File;
  userData:SECreate;
  //efsrDoc: efsrDoc[]=[];
  isDocRequired: boolean = false;
 //public efsr:EFSRApproveReject[];
  isAlldocumentsUploaded: boolean = false;
  //public ShowLoader: boolean;
  uploadStatus: string = '';
  percentDone: number = 0;
  @ViewChild('documentSelected',{static:false}) selectedDoc: any;
  @Input() isEditForm: boolean;
  @Input() seId: number;
  @Input() userId:number;
  //@Input() createdBy:number;
 //@Input() enggName:IName[];
  @Output() valueupdate = new EventEmitter();
  //@Output() submitTheResult: EventEmitter<any> = new EventEmitter();
  // public imagePath;
  // imgURL: any;
  //approvedFlag:number;
  efsrId:number;
  //engId:number;
  alreadyLoggedIn: boolean = false;
  documetListValidation: boolean = false;
  documentFileValidation: boolean = false;
  requestFromEfsr : boolean = false;
  requestFromROEfsr : boolean = false;
  documentForm: FormGroup;
  //isDocumentSectionVisiable: boolean = true;
  //isFormSectionVisible: boolean = false;

  document_validation_messages = {
    'documentfile': [
      { type: 'required', message: 'Please select document' }
    ],
    'documetList': [
      { type: 'required', message: 'Plesae select document type' }
    ],
    'documentNumber': [
      { type: 'required', message: 'Please enter document number' }
    ]

  }

  constructor(private vehicleService: VendorSupervisorService, private efsrservice: efsrService,private fb: FormBuilder, private snackbar: MatSnackBar, private router: Router,private EngineerService: TicketsAssginedSE,private SEservice:UserService)
   {
    this.documentForm = this.fb.group({
      documetList: new FormControl('0', Validators.compose([
        Validators.required
      ])),
      documentfile: new FormControl('', Validators.compose([
        Validators.required
      ])),
      documentNumber: new FormControl('', Validators.compose([
        Validators.required,
        Validators.pattern("^[0-9]{10}$")
      ])),
      createdby:new FormControl('', Validators.compose([
        Validators.required,
        Validators.pattern("^[0-9]{10}$")
      ]))
    })
   }

  ngOnInit() {
    this.requestFromEfsr = this.efsrservice.getRequestFromEfsr();
    this.requestFromROEfsr = this.efsrservice.getRequestFromROEfsr();
    this.efsrId= this.EngineerService.getEFSRId();
    //this.ShowLoader=false;

    //this.createdBy=this.documentForm.get('createdby').value;
    // this.SEservice.getNames().
    // pipe(catchError((err) => this.handleError(err)))
    // .subscribe((data:any)=>{

    //   this.engId= Number(data.results);
    
        
    //   });

    //this.approvedFlag=1;
    //this.efsrId=this.efsr.filter((data1) => data1.efsrId==this.efsrId);
    
      //this.approvedFlag=Number(this.efsr.filter((item) => item.rejectFlag));
  
//     var flag=this.efsr.filter((data1) => data1.rejectFlag===1);
//     if(flag!=null)
// {

//   this.approvedFlag=flag[0].rejectFlag;
// }    
    //      this.approvedFlag = data1;
    //       this.attachmentFileName=data1.results;
    //    });;

    if (this.requestFromEfsr || this.requestFromROEfsr)
    {
      this.getRequiredDocumentsForEfsr();
    }
    //  else if (this.requestFromROEfsr && this.createdBy==187 ){

    //    this.getRequiredDocumentsForEfsrForm();
    //  }
    else{
      this.getRequiredDocuments();
      
    }
    
  }

  getRequiredDocuments() {
    this.vehicleService.getRequiredFiles().subscribe((data: any) => {
      this.documents = data.results;
      if (this.isEditForm) {
        this.patchFiles();
      }
      });
  }

  getRequiredDocumentsForEfsr() {
    this.efsrservice.getRequiredFiles().subscribe((data: any) => {
      this.documents = data.results;
      if (this.isEditForm) {
        this.patchFilesEFSR();
      }
      });
  }
  // getRequiredDocumentsForEfsrForm() {
  //   this.efsrservice.getRequiredFilesEFSR().subscribe((data: any) => {
  //     this.efsrDoc = data.results;
  //     if (this.isEditForm) {
  //       this.patchFilesEFSRForm();
  //     }
  //     });
  // }
  checkNumberRequired() {
    this.documentForm.get('documetList').markAsPristine();
    if (this.documentForm.get('documetList').value == applicationconstant.Vehicle_DOC_Registration_Certificate_Copy) {
    }
    else if (this.documentForm.get('documetList').value == applicationconstant.Vehicle_DOC_CommercialPermitCopy) {
    }

    this.documentForm.get('documentfile').reset();
    this.documentForm.get('documentNumber').reset();
  }

  UploadDocuments(event) {
    if (this.selectedDoc.nativeElement.value == "0") {
      this.documetListValidation = true;
      this.documentForm.get('documentfile').reset();
      this.documentFileValidation = false;
      return;
    }
    if (event.target.files.length == 0) {
      this.documentFileValidation = true;
      this.documents.filter((value) => value.documentId == this.selectedDoc.nativeElement.value)[0].file = undefined;
    }
    else {
      this.documentFileValidation = false;
    }
    if (this.documentFileValidation == false) {
      const files = event.target.files[0];
      this.documents.filter((value) => value.documentId == this.selectedDoc.nativeElement.value)[0].file = files;
    }
  }
  
  private handleError(error: any) {
    // this.errorReceived = true;
    return Observable.throw(error);
  }
  onSubmit() {
    const doc = this.documentForm.get('documetList').value;
    //const crt=this.documentForm.get('createdby').value;
    if (this.documents.filter((val) => val.documentId == doc && val.isUploaded == true).length > 0) {
      this.openSnackBar(doc + ' is already uploaded ! You may remove old file and upload again.', '');
      this.documentForm.get('documentfile').reset();
      return;
    }

    if (this.documentForm.get('documetList').value == "0") {
      this.documetListValidation = true;
      return;
    }
    else {
      this.documetListValidation = false;
    }
    if (this.documents.filter((val) => val.documentId == doc).map(x => x.file)[0] == undefined) {
      this.documentFileValidation = true;
      return;
    }

    const file = this.documents.filter((value) => value.documentId == this.selectedDoc.nativeElement.value)[0].file;
    const fileType = this.documentForm.get('documetList').value;
    const docNum = this.documentForm.get('documentNumber').value;
     //const crt= this.documentForm.get('createdby').value;
    if (this.requestFromEfsr)
    {
      this.efsrservice.uploadFile(file, fileType, docNum, this.seId)
      .subscribe(event => {
        if (event.type === HttpEventType.UploadProgress) {
          this.percentDone = Math.round(100 * event.loaded / event.total);
          this.uploadStatus = `File is ${this.percentDone}% uploaded.`;
          this.percentDone = 0;
        } else if (event instanceof HttpResponse) {
          this.openSnackBar(event.body.message, '');

          this.uploadStatus = 'File is completely uploaded!';
          if (event.body.flag == "S") {
            this.documents.filter((value) => value.documentId == this.selectedDoc.nativeElement.value)[0].isUploaded = true;
            this.documents.filter((value) => value.documentId == this.selectedDoc.nativeElement.value)[0].documentType = fileType;
            this.documents.filter((value) => value.documentId == this.selectedDoc.nativeElement.value)[0].documentName = event.body.fileName;
            //this.documents.filter((value) => value.documentId == this.selectedDoc.nativeElement.value)[0].createdBy = event.body.createdBy;
          
          }
          if (event.body.returnId == 1) {
            this.isAlldocumentsUploaded = true;
          }
          else {
            this.isAlldocumentsUploaded = false;
          }
          this.resetform();
        }
      });
    }
    else{
      this.vehicleService.uploadFile(file, fileType, docNum, this.seId)
        .subscribe(event => {
          if (event.type === HttpEventType.UploadProgress) {
            this.percentDone = Math.round(100 * event.loaded / event.total);
            this.uploadStatus = `File is ${this.percentDone}% uploaded.`;
            this.percentDone = 0;
          } else if (event instanceof HttpResponse) {
            this.openSnackBar(event.body.message, '');

            this.uploadStatus = 'File is completely uploaded!';
            if (event.body.flag == "S") {
              this.documents.filter((value) => value.documentId == this.selectedDoc.nativeElement.value)[0].isUploaded = true;
              this.documents.filter((value) => value.documentId == this.selectedDoc.nativeElement.value)[0].documentType = fileType;
              this.documents.filter((value) => value.documentId == this.selectedDoc.nativeElement.value)[0].documentName = event.body.fileName;
              //this.documents.filter((value) => value.documentId == this.selectedDoc.nativeElement.value)[0].createdBy = event.body.createdBy;
            }
            if (event.body.returnId == 1) {
              this.isAlldocumentsUploaded = true;
            }
            else {
              this.isAlldocumentsUploaded = false;
            }
            this.resetform();
          }
        });
     }
  }

  patchFiles() {
    this.vehicleService.getSEAllDocs(applicationconstant.SEDocument, 0, this.seId).subscribe(
        (data: any) => {
          if (data.flag == "S") {
          data.results.forEach(x => {     
            if(x.isSuccess==true){       
            this.documents.filter((val) => val.documentId == x.fileType)[0].documentName = x.fileName;
            this.documents.filter((val) => val.documentId == x.fileType)[0].file = x.fileStream;
            this.documents.filter((val) => val.documentId == x.fileType)[0].isUploaded = true;
            //this.documents.filter((val) => val.documentId == x.fileType)[0].createdBy = x.createdBy;
            }
          });
        }
          if (data.returnId == 1) {
            this.isAlldocumentsUploaded = true;
            //this.openSnackBar('Submitted Successfully.Redirecting to Homepage..','');
            
          }
          else {
            this.isAlldocumentsUploaded = false;
          }
        }
      )
  }

  patchFilesEFSR() {
    this.vehicleService.getEFSRAllDocs(applicationconstant.EFSRDocument, 0, this.seId).subscribe(
        (data: any) => {
          if (data.flag == "S") {
          data.results.forEach(x => {     
            if(x.isSuccess==true){       
            this.documents.filter((val) => val.documentId == x.fileType)[0].documentName = x.fileName;
            this.documents.filter((val) => val.documentId == x.fileType)[0].file = x.fileStream;
            this.documents.filter((val) => val.documentId == x.fileType)[0].isUploaded = true;
            //this.documents.filter((val) => val.documentId == x.fileType)[0].createdBy = x.createdBy;
            }
          });
        }
          if (data.returnId == 1) {
            this.isAlldocumentsUploaded = true;
            //this.openSnackBar('Submitted Successfully.Redirecting to Homepage..','');
          }
          else {
            this.isAlldocumentsUploaded = false;
          }
        }
      )
  }


// patchFilesEFSRForm() {
//     this.vehicleService.getEFSRReportsList(applicationconstant.EFSRForm, 0, this.seId).subscribe(
//         (data: any) => {
//           if (data.flag == "S") {
//           data.results.forEach(x => {     
//             if(x.isSuccess==true){       
//             this.efsrDoc.filter((val) => val.id == x.fileType)[0].docName = x.fileName;
//             this.efsrDoc.filter((val) => val.id == x.fileType)[0].file = x.fileStream;
//             this.efsrDoc.filter((val) => val.id == x.fileType)[0].docPath = x.filePath;
            
//            // this.documents.filter((val) => val.documentId == x.fileType)[0].isUploaded = undefined;
//             this.efsrDoc.filter((val) => val.id == x.fileType)[0].createdBy = x.createdBy;
//             }
//           });
//         }
//           if (data.returnId == 1) {
//             this.isAlldocumentsUploaded = true;
//             //this.openSnackBar('Submitted Successfully.Redirecting to Homepage..','');
//           }
//           else {
//             this.isAlldocumentsUploaded = false;
//           }
//         }
//       )
//   }
  removeFile(docType: string) {
    var removeFile = new RemoveFile();
      const docId = this.seId;
      if (this.requestFromEfsr)
      {
        removeFile.docId = docId;
        removeFile.docType = docType;
        this.efsrservice.removeFile(removeFile).subscribe(
          (data: ReturnResponse) => {
            this.openSnackBar(data.message, '');
            this.documents.filter((val) => val.documentId == docType)[0].file = null;
            this.documents.filter((val) => val.documentId == docType)[0].isUploaded = false;
            if (data.returnId == 1) {
              this.isAlldocumentsUploaded = true;
            }
            else {
              this.isAlldocumentsUploaded = false;
            }
            this.resetform();
          }
        )
      }
      else
      {
        this.vehicleService.removeFile(docType, docId).subscribe(
          (data: ReturnResponse) => {
            this.openSnackBar(data.message, '');
            this.documents.filter((val) => val.documentId == docType)[0].file = null;
            this.documents.filter((val) => val.documentId == docType)[0].isUploaded = false;
            if (data.returnId == 1) {
              this.isAlldocumentsUploaded = true;
            }
            else {
              this.isAlldocumentsUploaded = false;
            }
            this.resetform();
          }
        )
      }
  }

  SubmitValues() {
    this.valueupdate.emit(false);
    this.openSnackBar('Submitted Successfully.Redirecting to Homepage..','');
    //this.ShowLoader=true;
    this.router.navigate(['dashboard']);
  }

  
  public backToList()
  {
    if (this.requestFromROEfsr)
    {
      this.efsrservice.setRequestFromROEfsr(false);
      this.openSnackBar('Documents Verified Successfully.Going Back to Previous Page..','');
      this.valueupdate.emit(false);
    //this.ShowLoader=false;
    //this.ShowLoader=true;
      //this.efsrservice.setIsDocumentSectionVisibleFromROEfsr(false);
      //this.efsrservice.setIsFormSectionVisibleFromROEfsr(true);
      //this.isFormSectionVisible = true;
      //this.isDocumentSectionVisiable = false;
    }
    else if(this.requestFromEfsr)
    {
      this.efsrservice.setRequestFromEfsr(false);
      this.openSnackBar('Submitted Successfully.Redirecting to Homepage..','');
      this.router.navigate(['dashboard']);
    }
    else
    {
      //this.efsrservice.setRequestFromEfsr(false);
    
       var seProf=new SECreate();
       //const RoFormData: any = Object.assign({}, seProf);
       seProf.seId=this.seId;
      // seProf.userId=this.userId;
       //seProf.userId=this.userId;
      this.SEservice.submitSEDoc(seProf.seId).subscribe(
         (data: any) => {
        //   //this.submitted = false;
        //   //this.openSnackBar(data.message, '');
         if (data.flag === Constants.SUCCESS_FLAG) {
            //this.openSnackBar(data.message, '');
            this.openSnackBar(data.message, '');
            //this.openSnackBar('Submitted Successfully!','');
              this.router.navigate(['dashboard']);
            
      //       //this.form.resetForm();
           }
        },
         (err: any) => {
      //     //this.submitted = false;
           this.openSnackBar(err.error.message, '');
         },
         () => { }
       );
      
      //this.ShowLoader=true;
    }
    
  }

  ngOnDestroy() {
    this.requestFromEfsr = false;
    this.requestFromROEfsr = false;
  }

  public UpdateEFSRStatus()
  {
    this.efsrservice.UpdateEfsrStatus(this.seId)
    .subscribe(event => {
      // if (event.type === HttpEventType.UploadProgress) {
      //   this.percentDone = Math.round(100 * event.loaded / event.total);
      //   this.uploadStatus = `File is ${this.percentDone}% uploaded.`;
      //   this.percentDone = 0;
      // } else if (event instanceof HttpResponse) {
      //   this.openSnackBar(event.body.message, '');

      //   this.uploadStatus = 'File is completely uploaded!';
      //   if (event.body.flag == "S") {
      //     this.documents.filter((value) => value.documentId == this.selectedDoc.nativeElement.value)[0].isUploaded = true;
      //     this.documents.filter((value) => value.documentId == this.selectedDoc.nativeElement.value)[0].documentType = fileType;
      //     this.documents.filter((value) => value.documentId == this.selectedDoc.nativeElement.value)[0].documentName = event.body.fileName;
      //   }
      //   if (event.body.returnId == 1) {
      //     this.isAlldocumentsUploaded = true;
      //   }
      //   else {
      //     this.isAlldocumentsUploaded = false;
      //   }
      //   this.resetform();
      // }
    });
    this.efsrservice.setRequestFromEfsr(false);
    this.router.navigate(['dashboard']);
  }

  preview(documentType: string, documentName: string) {
    if (this.requestFromEfsr || this.requestFromROEfsr  )
    {
      var removeFile = new RemoveFile();
      removeFile.docId = this.seId;
      removeFile.docType = documentType;
      this.efsrservice.downloadDoc(removeFile).subscribe(result => {
        if (window.navigator.msSaveOrOpenBlob) {
          window.navigator.msSaveOrOpenBlob(result, documentName);
        } else {
          var url = window.URL.createObjectURL(result);
  
          let link = document.createElement('a');
          link.setAttribute('type', 'hidden');
          link.href = url;
          link.download = documentName;
          document.body.appendChild(link);
          link.click();
          link.remove();
        }
        this.resetform();
      }    
      );
    }
    else{
      this.vehicleService.downloadDoc(this.seId, documentType).subscribe(result => {
        if (window.navigator.msSaveOrOpenBlob) {
          window.navigator.msSaveOrOpenBlob(result, documentName);
        } else {
          var url = window.URL.createObjectURL(result);
  
          let link = document.createElement('a');
          link.setAttribute('type', 'hidden');
          link.href = url;
          link.download = documentName;
          document.body.appendChild(link);
          link.click();
          link.remove();
        }
        this.resetform();
      }    
      );
    }

  }

  public ClosePopUp(): void {
    this.alreadyLoggedIn = false;
  }

  public openSnackBar(message: string, action: string) {
    this.snackbar.open(message, action, {
      duration: 5000,
      verticalPosition: 'top'
    });
  }

  resetform(){
    this.documentForm.get('documentfile').reset();
    this.documentForm.get('documetList').patchValue('0');
    this.documentFileValidation=false;
    this.documetListValidation=false;
  }


}
