/**
 * This class is to have string literal for Dropdown Group 
 */
export class DropdownItemsGroup {
    public static readonly ROLE = 'ROLE';
    public static readonly ASSET_TYPE = 'ASSET_TYPE';
    public static readonly FUEL_TYPE = 'FUEL_TYPE';
    public static readonly SERVICE_MODE = 'SERVICE_MODE';
    public static readonly ASSET_STATUS = 'ASSET_STATUS';
    public static readonly DESIGNATION = 'DESIGNATION';    
    public static readonly INDUSTRY_TYPE = 'INDUSTRY_TYPE';
    public static readonly ASSET_PLACEMENT = 'ASSET_PLACEMENT';
    public static readonly PRODUCT_PROMOTER_STATUS = 'PRODUCT_PROMOTER_STATUS';
    public static readonly RO_STATUS = 'RO_STATUS';
    public static readonly RO_PP_MAP_STATUS = 'RO_PP_MAP_STATUS';
    public static readonly CLASSIFICATION = 'CLASSIFICATION'; 
    public static readonly ASSET_SEARCH_PARAM = 'ASSET_SEARCH_PARAM';
    public static readonly ASSET_EXISTS = 'ASSET_EXISTS';
    public static readonly ACCESSIBILITY_FOR_MDU = 'ACCESSIBILITY_FOR_MDU';
    public static readonly VEHICLE_TYPE = 'VEHICLE_TYPE';
    public static readonly VEHICLE_STATUS = 'VEHICLE_STATUS';
    public static readonly TICKET_SEARCH_PARAM = 'TICKET_SEARCH_PARAM';
    public static readonly VEHICLE_OWNERSHIP = 'VEHICLE_OWNERSHIP';
    public static readonly PRODUCT_CATEGORY = 'FUEL';
    public static readonly CUSTOMER_SEARCH_PARAM = 'CUSTOMER_SEARCH_PARAM';
    public static readonly MYORDER_SEARCH_PARAM = 'MYORDER_SEARCH_PARAM';
    public static readonly DRIVER_TRIP_SEARCH_PENDING_PARAM = 'DRIVER_TRIP_SEARCH_PENDING_PARAM';
    public static readonly DRIVER_TRIP_SEARCH_SUBMITTED_PARAM = 'DRIVER_TRIP_SEARCH_SUBMITTED_PARAM';
    public static readonly DRIVER_DELIVERY_STATUS = 'DRIVER_DELIVERY_STATUS';
    public static readonly UNDELIVERY_REASONS = 'UNDELIVERY_REASONS';
    public static readonly VEHICLE_MAKE = 'VEHICLE_MAKE';
    public static readonly PAYMENT_METHOD = 'PAYMENT_METHOD';
    public static readonly UNVERIFIED_ASSET_SEARCH_PARAM = 'UNVERIFIED_ASSET_SEARCH_PARAM';

}
