import { Directive } from '@angular/core';
import { AsyncValidator, AbstractControl, ValidationErrors, NG_ASYNC_VALIDATORS, AsyncValidatorFn } from '@angular/forms';
import { Observable } from 'rxjs';
import { UserService } from 'src/app/Services/usermanagement/user.service';
import { map } from 'rxjs/operators';

/**
 * Unique username validator
 *
 * @export
 * @param {UserService} userService
 * @returns {AsyncValidatorFn}
 */
export function UniqueUsernameValidator(userService: UserService): AsyncValidatorFn {
    return (control: AbstractControl): Promise<ValidationErrors | null> | Observable<ValidationErrors | null> => {

        // Set timeout, sufficient enough for checking username already taken by other user
        const q = new Promise((resolve, reject) => {
            setTimeout(() => {
                userService.isUsernameTaken(control.value).subscribe(
                    (data: any) => {
                        if (data.result === false) {
                            resolve(null);
                        } else {
                            resolve({
                                usernameTaken: {
                                    valid: true
                                }
                            });
                        }
                    },
                    (error) => {
                        resolve({
                            usernameTaken: {
                                valid: false
                            }
                        });
                    });
            }, 3000);
        });

        return q;
    };
}

@Directive({
    selector: '[appUniqueUsername]',
    providers: [{ provide: NG_ASYNC_VALIDATORS, useExisting: UniqueUsernameValidatorDirective, multi: true }, UserService]
})
export class UniqueUsernameValidatorDirective implements AsyncValidator {
    constructor(private userService: UserService) { }

    validate(control: AbstractControl): Promise<ValidationErrors | null> | Observable<ValidationErrors | null> {
        return UniqueUsernameValidator(this.userService)(control);
    }

}
