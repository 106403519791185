import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpRequest, HttpEventType, HttpResponse, HttpEvent, HttpParams } from '@angular/common/http';
//import { efsrcreate,hpsmandsedetails,efsrd } from 'src/app/Models/EFSR/efsrcreate.model';
import { efsrcreate, hpsmandsedetails,efsrDraft, EFSRApproveReject, patchefsrDraft } from 'src/app/Models/EFSR/efsrcreate.model';
import { Observable, throwError } from 'rxjs';
import { map, catchError, tap } from 'rxjs/operators';
import { HttpVerbService } from 'src/app/Services/httpverb/httpverb.service';
import { environment } from 'src/environments/environment';
import { Ivendor, Iservicing, Idevice, Irokeypersonlist } from 'src/app/Models/common/vendor.model';
import { RemoveFile } from 'src/app/Models/common/removeFile.model';
import { FileDownload } from 'src/app/Models/common/fileDownload.model';

@Injectable({
  providedIn: 'root'
})
export class efsrService {
  baseUrl: string;
  requestFromEfsr: boolean = false;
  requestFromROEfsr: boolean = false;
  //requestFromviewSEEfsr:boolean=false;
  //requestFromviewROEfsr:boolean=false;
  IsFormSectionVisibleFromROEfsr : boolean = false;
  //IsFormSectionVisibleFromviewROEfsr:boolean=false;
 // IsFormSectionVisibleFromviewSEEfsr:boolean=false;
  //IsDocumentSectionVisibleFromviewSEEfsr: boolean = false;
  //IsDocumentSectionVisibleFromviewROEfsr: boolean = false;
  IsDocumentSectionVisibleFromROEfsr: boolean = false;
  
  
  constructor(private http: HttpClient, private service: HttpVerbService) {
    this.baseUrl = environment.restUrl + '/api/v1/';
  }
  getRODetails(roId: number): Observable<efsrcreate> {
    // encoding the search term into base64 format
    const url = this.baseUrl + 'getRODetails?ROCode=' + roId;
    //const url ='http://10.21.75.41/romasterapi/api/romaster?roid='+'1000';
    return this.http.get<efsrcreate>(url);
  }
  // getSEandHPSMDetails(): Observable<hpsmandsedetails> {
  //   getSEandHPSMDetails(ticketId : number): Observable<efsrcreate> {
  //   // encoding the search term into base64 format
  //   const url = this.baseUrl + 'GetHPSMandSEdetails?TKTID=' + ticketId;
  //   return this.http.get<efsrcreate>(url);
  // }
  getSEandHPSMDetails(ticketId : number): Observable<efsrcreate> {
    // encoding the search term into base64 format
    const url = this.baseUrl + 'GetHPSMandSEdetails?TKTID=' + ticketId;
    return this.http.post<efsrcreate>( this.baseUrl + 'GetHPSMandSEdetails', ticketId);
  }

  getSEandHPSMDetailsforRO(ticketId : number): Observable<efsrcreate> {
    // encoding the search term into base64 format
    // url = this.baseUrl + 'GetHPSMandSEdetails?TKTID=' + ticketId;
    return this.http.post<efsrcreate>( this.baseUrl + 'getHPSMandSEdetailsforRO', ticketId);
  }

  getTypeOfVendor(): Observable<Ivendor[]> {
    const url = this.baseUrl + 'typeofvendors';
    return this.service.get(url).pipe(map((response: any) => {
      return response;
    }));
  }
  getROKeyPersonList(): Observable<Irokeypersonlist[]> {
    const url = this.baseUrl + 'getROKeyPersonList';
    return this.service.get(url).pipe(map((response: any) => {
      return response;
    }));
  }
  getTypeOfService(): Observable<Iservicing[]> {
    const url = this.baseUrl + 'typeofServicing';
    return this.service.get(url).pipe(map((response: any) => {
      return response;
    }));
  }
  getTypeOfDevice(): Observable<Idevice[]> {
    const url = this.baseUrl + 'typeofDevice';
    return this.service.get(url).pipe(map((response: any) => {
      return response;
    }));
  }
  getTypeOfChcekList(): Observable<any[]> {
    const url = this.baseUrl + 'typeofchecklist';
    return this.service.get(url).pipe(map((response: any) => {
      return response;
    }));
  }

  public setRequestFromEfsr(request: boolean) {
    this.requestFromEfsr = request;
  }

  public setRequestFromROEfsr(request: boolean) {
    this.requestFromROEfsr = request;
  }
 // public setRequestFromviewSEEfsr(request: boolean) {
   // this.requestFromviewSEEfsr = request;
 // }

  //public setRequestFromviewROEfsr(request: boolean) {
   // this.requestFromviewROEfsr = request;
  //}

 // public setIsFormSectionVisibleFromROEfsr(request: boolean) {
 //   this.IsFormSectionVisibleFromROEfsr = request;
 // }

 // public setIsFormSectionVisibleFromviewSEEfsr(request: boolean) {
  //  this.IsFormSectionVisibleFromviewSEEfsr = request;
 // }
 // public setIsFormSectionVisibleFromviewROEfsr(request: boolean) {
  //  this.IsFormSectionVisibleFromviewROEfsr = request;
  //}



  public getIsFormSectionVisibleFromROEfsr() {
    return this.IsFormSectionVisibleFromROEfsr;
    
  }



  public getIsDocumentSectionVisibleFromROEfsr() {
    return this.IsDocumentSectionVisibleFromROEfsr;
  }

  


  public getRequestFromROEfsr() {
    return this.requestFromROEfsr;
  }

  public getRequestFromEfsr() {
    return this.requestFromEfsr;
  }

  
  //Start EFSR
  public getRequiredFiles(): Observable<any> {
    return this.http.get(this.baseUrl + "getEFSRDocumentsList", {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    }).pipe(map((res: any) => {
      return res;
    }))
  }

  public getRequiredFilest(TRACKING_ID: number): Observable<any> {
    return this.http.get(this.baseUrl + "getEFSRDocumentsList?TRACKING_ID=" + TRACKING_ID, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    }).pipe(map((res: any) => {
      return res;
    }))
  }

  // public getRequiredFilesEFSR(): Observable<any> {
  //   return this.http.get(this.baseUrl + "getEFSRReportsList", {
  //     headers: new HttpHeaders({
  //       'Content-Type': 'application/json'
  //     })
  //   }).pipe(map((res: any) => {
  //     return res;
  //   }))
  // }

  createefsr(newUser: efsrDraft): Observable<any> {
    return this.http.post<any>(this.baseUrl + 'createefsr', newUser, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    });
  }

  getSEAllDocs(docGroup: string, docid: number, trackingId: number): Observable<any> {
    return this.http.get(this.baseUrl + "getEFSRDocs?doc_Group=" + docGroup + "&docId=" + docid + "&TRACKING_ID=" + trackingId, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    }).pipe(map((res: any) => {
      return res;
    }))
  }

public getEFSRAllReports(docGroup:string,  trackingId:number): Observable<any> {
    return this.http.get(this.baseUrl + "getEFSRReportsList?doc_Group="+docGroup +"&efsrId="+trackingId, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    }).pipe(map((res: any) => {
      return res;
    }))
  }

  removeFile(removeFile: RemoveFile): Observable<any> {
    return this.http.post(this.baseUrl + "RemoveEFSRDoc",removeFile, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    }).pipe(map((res: any) => {
      return res;
    }))
  }

  uploadFile(file: File, fileType: string, docNum: string, driverId: number): Observable<HttpEvent<any>> {
    let formData = new FormData();
    formData.append('upload', file);
    formData.append('TrackingId', String(driverId));
    formData.append('FileType', fileType);
    formData.append('UniqueFileNumber', docNum);
    let params = new HttpParams();
    const options = {
      params: params,
      reportProgress: true,
    };
    const req = new HttpRequest('POST', this.baseUrl + "UploadEFSRDoc", formData, options);
    return this.http.request(req);
  }

  public downloadDoc(removeFile: RemoveFile): Observable<any> {

    // let url = this.baseUrl + "DownloadEFSRDoc"+ removeFile;
    // return this.http.get(url, { responseType: "blob" });
    
    const headers = new HttpHeaders().set('Content-Type', 'application/json');
    return this.http.post<any>(this.baseUrl + 'DownloadEFSRDoc', removeFile,
      { headers, responseType: 'blob' as 'json'});
  }

  public downloadEFSR(removeFile: RemoveFile): Observable<any> {

    // let url = this.baseUrl + "DownloadEFSRDoc"+ removeFile;
    // return this.http.get(url, { responseType: "blob" });
    
    const headers = new HttpHeaders().set('Content-Type', 'application/json');
    return this.http.post<any>(this.baseUrl + 'DownloadEFSRReports', removeFile,
      { headers, responseType: 'blob'as 'json'});
  }

  getEFSRDetails(efsrId:number): Observable<any> {
    // encoding the search term into base64 format
    // const url = this.baseUrl + 'getEFSRDetails?efsrId=' + efsrId;
    // return this.http.get<any>(url);

    return this.http.post<any>(this.baseUrl + 'getEFSRDetails', efsrId, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    });
  }

//   downloadFile(fileDetails:FileDownload,efsrDEtails:patchefsrDraft): Observable<any>{
//     const headers = new HttpHeaders().set('Content-Type', 'application/json');
//     return this.http.post<any>(this.baseUrl + 'DownloadFile', fileDetails,
//       { headers, responseType: 'blob' as 'json'});
//    //let fileExtension = fileDetails.fileType;
//   }
//     //let input = fileDetails.fileName;
// //     let efsrId= efsrDEtails.efsrId;
//     let fileGroup=fileDetails.fileGroupName;
//     let docId=fileDetails.docId;
//     let fileExtension= fileDetails.fileType;
//    // const headers = new HttpHeaders().set('Content-Type', fileExtension);
//     return this.http.post(this.baseUrl +"downloadFile?fileName="+efsrId+"_"+fileGroup,{
//     responseType: 'blob'as 'pdf',
//   observe: 'response' 
// })
  
//    .pipe(map(
  
//      (res:any) => {
//        return new Blob([res.blob()], { type: fileExtension  })
//             //var blob = new Blob([res.blob()], {type: fileExtension} )
  
//            // return blob;            
  
//      }));
  
//  }
 //downloadFile(efsrId:number): Observable<any> {
    // encoding the search term into base64 format
    //const url = this.baseUrl + '?efsrId=' + efsrId;
    //return this.http.get<any>(url);
 // }

  approveRejectefsr(efsrdetails: EFSRApproveReject): Observable<any> {
    return this.http.post<any>(this.baseUrl + 'approveEfsr', efsrdetails, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    });
  }

  UpdateEfsrStatus(efsrId: number): Observable<any> {
    return this.http.post<any>(this.baseUrl + 'updateEfsrStatus', efsrId, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    });
  }

  getEFSRDetailsForReport(efsrId:number): Observable<any> {
    // encoding the search term into base64 format
    const url = this.baseUrl + 'getEFSRDetailsForReport?efsrId=' + efsrId;
    return this.http.get<any>(url);
  }

}

