import { Component, OnInit,ChangeDetectorRef, ViewChild } from '@angular/core';
import { FormGroup, FormControl, Validators, FormBuilder, ValidatorFn, AbstractControl } from '@angular/forms';
import { MatDialog, MatSnackBar } from '@angular/material';
//import { UserService } from 'src/app/Services/ServiceEngineer/SECreate.service';
import { VSSCreateService } from 'src/app/Services/vendorsupervisor/VSSCreate.service';
//import { SECreate } from 'src/app/Models/ServiceEngineer/SECreate.model';
import { VSSCreate } from 'src/app/Models/vendorsupervisor/VSS-Create.model';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { UniqueUsernameValidator } from 'src/app/shared/unique-username-validator.directive';
import { ConfigurationService } from 'src/app/Services/Utilities/configuration.service';
import { Constants } from 'src/app/Constants/constants';
import { IState } from 'src/app/Models/common/state.model';
import { Icity } from 'src/app/Models/common/city.model';
import { VendorSupervisor, VendorSubSupervisor } from 'src/app/Models/vendorsupervisor/vendor-supervisor.model';
import { Router } from '@angular/router';
import { MatOption } from '@angular/material';
import { applicationconstant } from 'src/app/Models/DropdownItemsGroup/applicationconstant.model';
import { VendorService } from 'src/app/Services/master/vendor.service';

@Component({
  selector: 'app-vendorsubsupervisor',
  templateUrl: './vendorsubsupervisor.component.html',
  styleUrls: ['./vendorsubsupervisor.component.scss']
})
export class VendorsubsupervisorComponent implements OnInit {
  public submitted: boolean;
  public selectedReference: string;
  isUserTypeSelected:boolean=false;
  channelPartners = []; 
  public state: IState[];
  public selectedState: string[]= [];
  public selectedCity: string[]= [];
  public city: Icity[];
  public mobileExists: boolean;
  public alternatemobileExists: boolean;
  public emailExists: boolean;
  public alternateemailExists: boolean;
  public userExists: boolean;
  public pincodeExists: boolean;
  isDocumentSectionVisiable: boolean = false;
  isFormSectionVisible: boolean = true;
  seId: number = 0;
  levelToCreate: number = 0;
  isEditForm: boolean = false;

  startdate : Date;
  username:string;
  @ViewChild('form', {static: false}) form;
  @ViewChild('referenceid', {static: false}) referenceElement: any;
 

  userTypesDisabledMap: Map<string, boolean>;
  stateDisabledMap: Map<string, boolean>;
  cityDisabledMap: Map<string, boolean>;
  ShowLoader: boolean;

  omit_special_char(event)
{   
   var k;  
   k = event.keyCode;  //         k = event.keyCode;  (Both can be used)
   return((k > 64 && k < 91) || (k > 96 && k < 123) || k == 8 || k == 32 || (k >= 48 && k <= 57)||(k >= 96 && k <= 105)); 
   
}

validationMessages = {
  'VendorID': {
    'required': 'Vendor ID is required'
  },
  'username': {
    'required': 'Username is required.',
    'minlength': 'Username must be at least 5 characters long.',
    'maxlength': 'Username cannot be more than 100 characters long.',
    'pattern': 'Your username must contain only numbers and alphabets.',
    'usernameTaken': 'Username is already in use.'
  },
  'firstName': {
    'required': 'First name is required.',
    'maxlength': 'First name cannot be more than 250 characters long.',
    'pattern': 'Your first name must contain only  alphabets.'
  },
  'lastName': {
    'required': 'Last name is required.',
    'maxlength': 'Last name cannot be more than 250 characters long.',
    'pattern': 'Your last name must contain only numbers and alphabets.'
  },
  'mobile': {
    'required': 'Mobile is required.',
    'minlength': 'Mobile must be of 10 digits.',
    'pattern': 'Mobile is invalid.'
  },
  'Amobile': {
    'minlength': 'Mobile must be of 10 digits.',
    'pattern': 'Mobile is invalid.'
  },
  'email': {
    'required': 'Email is required.',
    'maxlength': 'Email cannot be more than 100 characters long.',
    'pattern': 'Email is invalid.'
  },
  'Aemail': {
    'maxlength': 'Email cannot be more than 100 characters long.',
    'pattern': 'Email is invalid.'
  },
  'Organization': {
    'required': 'Organization is Required.'
  },
  'Address': {
    'maxlength': 'Address cannot be more than 100 characters long.'
  },
  'PIN': {
    'required': 'PIN is required.',
    'pattern': 'PIN should be numeric.',
    'minlength': 'PIN should be 6 digits long.'
  },
  'State': {
    'required': 'State is required.'
  },
  'StatefirstLvl': {
    'required': 'State is required.'
  },
  'City': {
    'required': 'City is required.'
  }
};

formErrors = {
  'VendorID': '',
  'username': '',
  'firstName': '',
  'lastName': '',
  'mobile': '',
  'Amobile': '',
  'email': '',
  'Aemail': '',
  'Organization': '',
  'Address': '',
  'PIN': '',
  'State': '',
  'StatefirstLvl': '',
  'City': ''
};

  public account_validation_messages = {
    'username': [
      { type: 'required', message: 'UserID is required' },
      { type: 'minlength', message: 'UserID must be of 4 digits' },
    ],
    'firstname': [
      { type: 'required', message: 'First name is required' },
      { type: 'maxlength', message: 'First name cannot be more than 250 characters long' },
      { type: 'pattern', message: 'Your first name must contain only alphabets' }
    ],
    'middlename': [
      { type: 'maxlength', message: 'Middle name cannot be more than 250 characters long' },
      { type: 'pattern', message: 'Your first name must contain only alphabets' }
    ],
    'lastname': [
      { type: 'required', message: 'Last name is required' },
      { type: 'maxlength', message: 'Last name cannot be more than 250 characters long' },
      { type: 'pattern', message: 'Your last name must contain only alphabets' }
    ],
    'mobile': [
      { type: 'required', message: 'Mobile is required' },
      { type: 'minlength', message: 'Mobile must be of 10 digits' },
      { type: 'pattern', message: 'Mobile is invalid' }
    ],
    'email': [
      { type: 'required', message: 'Email is required' },
      { type: 'maxlength', message: 'Email cannot be more than 100 characters long' },
      { type: 'pattern', message: 'Email is invalid' }
    ],
    'channelPartner': [
      { type: 'required', message: 'Channel Partner is required' }
    ],
     'reference': [
       { type:'required',message:'Please select reference value'}
     ],
    'DOB': {
      'required': 'DOB is required.',
      'matDatepickerMin': 'DOB should be past date.'
    }, 
    'state': [
      { type: 'required', message: 'state is required' },
    ],
    city: [
      { type: 'required', message: 'city is required' },
    ],
    'pincode': [
      { type: 'required', message: 'pincode is required' },
      { type: 'minlength', message: 'pincode must be of 6 digits' },
      { type: 'pattern', message: 'pincode is invalid' }
    ]
  };

  constructor(private SEService: VSSCreateService,
    private fb: FormBuilder,
    public snackBar: MatSnackBar,
    private router: Router,
    private configurationService: ConfigurationService,
    private VSSService: VendorService,
    private cdr:ChangeDetectorRef) {
    this.createForm();

    this.submitted = false;

    this.mobileExists = false;
    this.emailExists = false;
    this.alternatemobileExists = false;
    this.alternateemailExists = false;
    this.pincodeExists = false;
    this.userExists = false;

     this.stateDisabledMap = new Map();
     this.cityDisabledMap = new Map();
  }


  createForm() {
    this.VSSRegisterForm = this.fb.group({
      username: new FormControl('', Validators.compose([
        Validators.required,
        Validators.maxLength(100)
        
      ]),
      ),
      firstName: new FormControl('', Validators.compose([
        Validators.required,
        Validators.maxLength(250),
        //Validators.pattern('^[A-Za-z]+(?:[A-Za-z]+)*$')
        //Validators.pattern('/^[a-zA-Z]+$/')
        Validators.pattern('^[A-Za-z]+(?:[A-Za-z]+)*$')
      ])),
     
      lastName: new FormControl('', Validators.compose([
        Validators.required,
        Validators.maxLength(250),
       // Validators.pattern('^[A-Za-z]+(?:[A-Za-z]+)*$')
        Validators.pattern('^[a-zA-Z0-9 ]*$')
      ])),
      mobile: new FormControl('', Validators.compose([
        Validators.required,
        Validators.minLength(10),
        Validators.pattern('^[6-9][0-9]{9}$')
      ])),
      Amobile: new FormControl('', Validators.compose([
        Validators.minLength(10),
        Validators.pattern('^[6-9][0-9]{9}$')
      ])),
      email: new FormControl('', Validators.compose([
        Validators.required,
        Validators.maxLength(100),
        Validators.pattern('^([a-zA-Z0-9_\.\-])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})+$')
      ])),

      Aemail: new FormControl('', Validators.compose([
        
        Validators.maxLength(100),
        Validators.pattern('^([a-zA-Z0-9_\.\-])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})+$')
      ])),
      Organization: new FormControl('', Validators.compose([
        Validators.required,
        Validators.maxLength(100),
        //Validators.pattern('^([a-zA-Z0-9_\.\-])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})+$')
      ])),
      
      Address: new FormControl('', Validators.compose([
       
        Validators.maxLength(100),
        //Validators.pattern('^([a-zA-Z0-9_\.\-])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})+$')
      ])),
      PIN: new FormControl('', Validators.compose([
        Validators.required,
        Validators.maxLength(6),
        Validators.minLength(6),
        Validators.pattern('^[0-9]*$')
      ])),
      State: new FormControl('', Validators.compose([
       ])),
       StatefirstLvl: new FormControl('', Validators.compose([
       ])),
      City: new FormControl('', Validators.compose([
       ])),
      

     // reference: new FormControl({ disabled: true, value: '' },[Validators.required]),
     
    });
  }

  // createForm() {
  //   this.VSSRegisterForm = this.fb.group({
  //     username: new FormControl('', Validators.compose([
  //       Validators.required,
        
  //     ]),
  //     ),
  //     firstName: new FormControl('', Validators.compose([
  //       Validators.required,
  //       Validators.maxLength(250),
  //       Validators.pattern('^[A-Za-z]+(?:[A-Za-z]+)*$') 
  //     ])),
  //     middleName: new FormControl('', Validators.compose([
  //       Validators.maxLength(250),
  //       Validators.pattern('^[A-Za-z]+(?:[A-Za-z]+)*$') 
  //     ])),
  //     lastName: new FormControl('', Validators.compose([
  //       Validators.required,
  //       Validators.maxLength(250),
  //       Validators.pattern('^[A-Za-z]+(?:[A-Za-z]+)*$') 
  //     ])),
  //     mobile: new FormControl('', Validators.compose([
  //       Validators.required,
  //       Validators.minLength(10),
  //       Validators.pattern('^[6-9][0-9]{9}$')
  //     ])),
  //     pincode: new FormControl('', Validators.compose([
  //       Validators.required,
  //       Validators.minLength(6),
  //       Validators.pattern('^[1-9][0-9]{5}$')
  //     ])),
  //     email: new FormControl('', Validators.compose([
  //       Validators.required,
  //       Validators.maxLength(100),
  //       Validators.pattern('^([a-zA-Z0-9_\.\-])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})+$')
  //     ])),
  //     state: new FormControl('', Validators.compose([
  //       Validators.required,
  //     ])),
  //     city: new FormControl('', Validators.compose([
  //       Validators.required,
  //     ])),
  //     DOB: new FormControl('', Validators.compose([
  //       Validators.required,
  //       Validators.maxLength(250),
  //     ])),
  //     channelPartner: new FormControl(null),
  //   });
  // }
  
  public VSSRegisterForm: FormGroup;
   ngOnInit() {
    this.startdate=new Date();
    this.startdate.setDate(this.startdate.getDate() - 7665);
    // this.SEService.getVSSStates()
    //   .pipe(catchError((err) => this.handleError(err)))
    //   .subscribe((data: any) => {
    //     this.state = data.results;        
    //   });
      var NewSEID = this.SEService.getUniqueUserName()
      .pipe(catchError((err) => this.handleError(err)))
      .subscribe((data: any) => {
         NewSEID  = data.uniqueId; 
         this.levelToCreate = data.level; 
         if (this.levelToCreate == 1)
         {
          this.SEService.getStates()
          .pipe(catchError((err) => this.handleError(err)))
          .subscribe((data: any) => {
            this.state = data.results;        
          });
           this.VSSRegisterForm.controls['StatefirstLvl'].setValidators([Validators.required]);
           this.VSSRegisterForm.controls["StatefirstLvl"].updateValueAndValidity();
           this.VSSRegisterForm.controls['State'].clearValidators();
           this.VSSRegisterForm.controls["State"].updateValueAndValidity();
           this.VSSRegisterForm.controls['City'].clearValidators();
           this.VSSRegisterForm.controls["City"].updateValueAndValidity();
         }
         else
         {
          this.SEService.getVSSStates()
          .pipe(catchError((err) => this.handleError(err)))
          .subscribe((data: any) => {
            this.state = data.results;        
          });
           this.VSSRegisterForm.controls['State'].setValidators([Validators.required]);
           this.VSSRegisterForm.controls["State"].updateValueAndValidity();
           this.VSSRegisterForm.controls['StatefirstLvl'].clearValidators();
           this.VSSRegisterForm.controls["StatefirstLvl"].updateValueAndValidity();
           this.VSSRegisterForm.controls['City'].setValidators([Validators.required]);
           this.VSSRegisterForm.controls["City"].updateValueAndValidity();
         }
         this.username = String(NewSEID);  
         this.VSSRegisterForm.get('username').setValue(NewSEID);   
      });

     

      this.VSSRegisterForm.valueChanges.subscribe((data) => {
        this.logValidationErrors(this.VSSRegisterForm);
      });
  }
FillCityDD(Slectedstate:any)
{
  if (this.selectedState.length > 0) {
    var index_of_selected_option = this.selectedState.indexOf(Slectedstate.name);
    if (index_of_selected_option != -1) {
      this.selectedState.splice(index_of_selected_option, 1)
    }
    else {
      this.selectedState = this.selectedState.concat(Slectedstate.name);
    }
  }
  else {
    this.selectedState = this.selectedState.concat(Slectedstate.name);
  }

   if (this.selectedState.length ==1)
   {
     this.VSSRegisterForm.controls['City'].setValidators([Validators.required]);
     this.VSSRegisterForm.controls["City"].updateValueAndValidity();
   }
   else
   {
    this.VSSRegisterForm.controls['City'].clearValidators();
    this.VSSRegisterForm.controls["City"].updateValueAndValidity();
   }

  if (this.levelToCreate == 2)
  {
      this.SEService.getVSSCity(this.selectedState)
      .pipe(catchError((err) => this.handleError(err)))
      .subscribe((data: any) => {
        this.city = data.results;        
      });
  }
  else
  {
    this.SEService.getVSSCities()
    .pipe(catchError((err) => this.handleError(err)))
    .subscribe((data: any) => {
      this.city = data.results;        
    });

  }

}

selectedStates(state :any): void {
  if (this.selectedState.length > 0) {
    var index_of_selected_option = this.selectedState.indexOf(state.name);
    if (index_of_selected_option != -1) {
      this.selectedState.splice(index_of_selected_option, 1)
    }
    else {
      this.selectedState = this.selectedState.concat(state.name);
    }
  }
  else {
    this.selectedState = this.selectedState.concat(state.name);
  }
}

selectedCities(city :any): void {
  if (this.selectedCity.length > 0) {
    var index_of_selected_option = this.selectedCity.indexOf(city.name);
    if (index_of_selected_option != -1) {
      this.selectedCity.splice(index_of_selected_option, 1)
    }
    else {
      this.selectedCity = this.selectedCity.concat(city.name);
    }
  }
  else {
    this.selectedCity = this.selectedCity.concat(city.name);
  }
}



selectAll(ev){
   
  if(ev._selected){
    this.city.forEach(x => {
      this.selectedCity = this.selectedCity.concat(x.name);
    });
    // this.VSSRegisterForm.controls.userType
    // .patchValue([...this.VSSRegisterForm.map(item => item.key), 0])
    this.VSSRegisterForm.controls['City'].setValue(this.selectedCity);
    ev._selected=true;
  }
  if(ev._selected==false){
    this.selectedCity = [];
    this.VSSRegisterForm.controls['City'].setValue([]);
  }
  
}

  // onSubmit(form: any) {
  //   this.submitted = true;

  //   // stop here if form is invalid
  //   if (!this.VSSRegisterForm.valid) {
  //     this.submitted=false;
  //     return;
  //   }

  //   const userDetails = Object.assign({}, this.VSSRegisterForm.value);
  //   const newUser = new VSSCreate();
  //   newUser.username = userDetails.username;
  //   newUser.firstName = userDetails.firstName;
  //   newUser.lastName = userDetails.lastName;
  //   newUser.email = userDetails.email;
  //   newUser.mobile = userDetails.mobile;
  //   newUser.middleName = userDetails.middleName;
  //   newUser.DOB=new Date(Date.UTC((userDetails.DOB.getFullYear()),userDetails.DOB.getMonth(),userDetails.DOB.getDate()));
  //   // newUser.address=userDetails.address;
  //   newUser.state=userDetails.state;
  //   newUser.city=userDetails.city;
  //   newUser.pincode=userDetails.pincode;
  //   this.SEService.createVSSUser(newUser)
  //     .subscribe(
  //       (data: any) => {
  //         this.submitted = false;
  //         this.openSnackBar(data.message, '');
  //         if (data.flag === Constants.SUCCESS_FLAG) {
  //           this.openSnackBar(data.message, '');
  //           this.form.resetForm();
  //           this.isFormSectionVisible = false;
  //           this.seId = data.returnId;
  //           this.isDocumentSectionVisiable = true;
  //         }
  //       },
  //       (err: any) => {
  //         this.submitted = false;
  //         this.openSnackBar(err.error.message, '');
  //       },
  //       () => { }
  //     );
  // }


   public backToList()
        {
            this.router.navigate(['dashboard']);
        }

  onSubmit() {
    if(this.mobileExists || this.emailExists || this.alternateemailExists || this.alternatemobileExists)
    {
      this.submitted=false;
      return;
    }
    
    if (this.VSSRegisterForm.valid) {
      this.ShowLoader =true;
      const userDetails = Object.assign({}, this.VSSRegisterForm.value);
      const newUser = new VendorSubSupervisor();
      newUser.username = userDetails.username;
      newUser.VendorID = userDetails.username;
      newUser.firstName = userDetails.firstName;
      newUser.lastName = userDetails.lastName;
      newUser.email = userDetails.email;
      newUser.mobile = userDetails.mobile;
      newUser.Aemail = userDetails.Aemail;
      newUser.Amobile = userDetails.Amobile;
      newUser.State = this.selectedState;
      newUser.City = this.selectedCity;
      newUser.PIN = userDetails.PIN;
      newUser.Address = userDetails.Address;
      newUser.Organization = userDetails.Organization;
      newUser.level = this.levelToCreate;
      this.SEService.createVSSUser(newUser)
        .subscribe(
          (data: any) => {
            if (data.flag === Constants.SUCCESS_FLAG) {
              this.openSnackBar(data.message, '');
              this.router.navigate(['dashboard']);
              //this.ShowLoader =false;
            }
            else
            {
              this.openSnackBar(data.message, '');
              //this.ShowLoader =false;
            }
          },
          (err: any) => {
            if (err.error != null)
            {
              this.openSnackBar(err.error.message, '')
            }
            else
            {
              this.openSnackBar(err,'');
            }
            this.ShowLoader =false;
          });
    }
    else
    {
      this.ValidateAllFields(this.VSSRegisterForm);
      this.logValidationErrors(this.VSSRegisterForm);
    }

   
  }

  resetForm() {
    this.form.reset();
    this.VSSRegisterForm.clearValidators();

    this.VSSRegisterForm.markAsUntouched();
    this.VSSRegisterForm.markAsPristine();

    this.mobileExists = false;
    this.emailExists = false;
    this.alternateemailExists = false;
    this.alternatemobileExists = false;
    this.pincodeExists = false;
    this.userExists = false;
  }

  checkMobile() {
    if (this.VSSRegisterForm.get('mobile').valid) {
      const mobile = this.VSSRegisterForm.get('mobile').value;

      const searchType = 'mobile';

      this.VSSService.CheckMobileEmail(searchType, mobile).subscribe(
        (data: any) => {
          if (data.flag == applicationconstant.SuccessFlag) {
            this.mobileExists = false;
          } else {
            this.mobileExists = true;
          }
        },
        (err: any) => { },
      );
    }
  }

  checkUserID() {
    if (this.VSSRegisterForm.get('username').valid) {
      const useridsel = 'SE'+this.VSSRegisterForm.get('username').value;

      const searchType = 'username';

      this.SEService.searchUser(searchType, useridsel).subscribe(
        (data: any) => {
          if (data.result !== null) {
            this.userExists = true;
          } else {
            this.userExists = false;
          }
        },
        (err: any) => { },
      );
    }
  }
 
  checkEmail() {
    if (this.VSSRegisterForm.get('email').valid) {
      const email = this.VSSRegisterForm.get('email').value;

      const searchType = 'email';

      this.VSSService.CheckMobileEmail(searchType, email).subscribe(
        (data: any) => {
          if (data.flag == applicationconstant.SuccessFlag) {
            this.emailExists = false;
          } else {
            this.emailExists = true;
          }
        },
        (err: any) => { },
      );
    }
  }
  
  checkAMobile() {
    if (this.VSSRegisterForm.get('Amobile').valid) {
      const mobile = this.VSSRegisterForm.get('Amobile').value;

      const searchType = 'mobile';

      this.VSSService.CheckMobileEmail(searchType, mobile).subscribe(
        (data: any) => {
          if (data.flag == applicationconstant.SuccessFlag) {
            this.alternatemobileExists = false;
          } else {
            this.alternatemobileExists = true;
          }
        },
        (err: any) => { },
      );
    }
  }

  checkAlternateEmail() {
    if (this.VSSRegisterForm.get('Aemail').valid) {
      const email = this.VSSRegisterForm.get('Aemail').value;

      const searchType = 'email';

      this.VSSService.CheckMobileEmail(searchType, email).subscribe(
        (data: any) => {
          if (data.flag == applicationconstant.SuccessFlag) {
            this.alternateemailExists = false;
          } else {
            this.alternateemailExists = true;
          }
        },
        (err: any) => { },
      );
    }
  }



  openSnackBar(message: string, action: string) {
    this.snackBar.open(message, action, {
      duration: 5000,
      verticalPosition: 'top'
    });
  }

  private handleError(error: any) {
    return Observable.throw(error);
  }

  logValidationErrors(group: FormGroup = this.VSSRegisterForm): void {
    Object.keys(group.controls).forEach((key: string) => {
      const abstractControl = group.get(key);
      if (abstractControl instanceof FormGroup) {
        this.logValidationErrors(abstractControl)
      } else {
        this.formErrors[key] = '';
        if (abstractControl && !abstractControl.valid && (abstractControl.touched || abstractControl.dirty)) {
          const messages = this.validationMessages[key];

          for (const errorKey in abstractControl.errors) {
            if (errorKey) {
              this.formErrors[key] += messages[errorKey] + '';
            }
          }
        }
      }
    });
  }

  ValidateAllFields(formGroup: FormGroup) {
    Object.keys(formGroup.controls).forEach(field => {
      const control = formGroup.get(field);
      if (control instanceof FormControl) {
        control.markAsTouched({ onlySelf: true });
      } else if (control instanceof FormGroup) {
        this.ValidateAllFields(control);
      }
    });
  }

}
