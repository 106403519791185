import { NgModule } from '@angular/core';

import {
  MatButtonModule,
  MatSliderModule,
  MatInputModule,
  MatCardModule,
  MatSelectModule,
  MatDatepickerModule, MatExpansionModule, MatProgressSpinnerModule,
  MatNativeDateModule, MatTooltipModule, MatChipsModule, MatSlideToggleModule,
  MatSidenavModule, MatIconModule, MatListModule, MatToolbarModule, MatTabsModule, MatCheckboxModule, MatAutocompleteModule,
  MatGridListModule
} from '@angular/material';
// import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatRadioModule } from '@angular/material/radio';

import { MatMenuModule } from '@angular/material/menu';
import { MatTableModule } from '@angular/material/table';
import { MatPaginatorModule } from '@angular/material';
import { MatDialogModule } from '@angular/material/dialog';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatSortModule } from '@angular/material/sort';
import { MatBadgeModule } from '@angular/material/badge';


@NgModule({
  declarations: [],
  imports: [
    MatButtonModule,
    MatBadgeModule,
    MatInputModule,
    MatCardModule,
    MatSelectModule,
    // BrowserAnimationsModule,
    MatDatepickerModule, MatExpansionModule,
    MatNativeDateModule, MatTooltipModule, MatProgressSpinnerModule,
    MatSliderModule, MatTabsModule, MatChipsModule, MatSlideToggleModule,
    MatSidenavModule, MatIconModule, MatListModule, MatToolbarModule, MatRadioModule,
    MatMenuModule, MatTableModule, MatPaginatorModule, MatSortModule, MatCheckboxModule, MatSnackBarModule, MatDialogModule,
    MatGridListModule
  ],
  exports: [
    MatButtonModule,
    MatBadgeModule,
    MatInputModule,
    MatCardModule,
    MatSelectModule,
    // BrowserAnimationsModule,
    MatDatepickerModule, MatExpansionModule,
    MatNativeDateModule, MatTooltipModule, MatProgressSpinnerModule,
    MatSliderModule, MatTabsModule, MatChipsModule, MatSlideToggleModule,
    MatSidenavModule, MatIconModule, MatListModule, MatToolbarModule, MatRadioModule, MatMenuModule,
    MatTableModule, MatPaginatorModule, MatSortModule, MatCheckboxModule, MatSnackBarModule, MatDialogModule,
    MatAutocompleteModule,
    MatGridListModule
  ]
})
export class MaterialModule { }
