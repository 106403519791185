import { Component, OnInit,ChangeDetectorRef, ViewChild } from '@angular/core';
import { FormGroup, FormControl, Validators, FormBuilder, ValidatorFn, AbstractControl } from '@angular/forms';
import { MatDialog, MatSnackBar } from '@angular/material';
import { UserService } from 'src/app/Services/ServiceEngineer/SECreate.service';
import { SECreate } from 'src/app/Models/ServiceEngineer/SECreate.model';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { UniqueUsernameValidator } from 'src/app/shared/unique-username-validator.directive';
import { ConfigurationService } from 'src/app/Services/Utilities/configuration.service';
import { Constants } from 'src/app/Constants/constants';
import { IState } from 'src/app/Models/common/state.model';
import { Icity } from 'src/app/Models/common/city.model';
import { VSSCreateService } from 'src/app/Services/vendorsupervisor/VSSCreate.service';
import { applicationconstant } from 'src/app/Models/DropdownItemsGroup/applicationconstant.model';
import { VendorService } from 'src/app/Services/master/vendor.service';

@Component({
  selector: 'app-se-create',
  templateUrl: './se-create.component.html',
  styleUrls: ['./se-create.component.scss']
})
export class SECreateComponent implements OnInit {

  /**
   * Create User From Group
   *
   * @type {FormGroup}
   * @memberof SECreateComponent
   */
 

  /**
   * submitted variable to track if the form values
   * are being submitted
   *
   * @memberof SECreateComponent
   */
  public submitted: boolean;

  /**
   * Collection of user types to be filled in dropdown
   *
   * @type {IUserType[]}
   * @memberof SECreateComponent
   */
  // public userTypes: IUserType[];
  //  public referenceTypes: IUserType[];
   public selectedReference: string;
  isUserTypeSelected:boolean=false;
  channelPartners = []; // TODO
  

  /**
   * Collection of roles to be filled in roles dropdown
   *
   * @type {IRole[]}
   * @memberof SECreateComponent
   */
   public state: IState[];
   public city: Icity[];

  public mobileExists: boolean;
  public emailExists: boolean;
  public userExists: boolean;
 // public addressExists: boolean;
  public pincodeExists: boolean;
  isDocumentSectionVisiable: boolean = false;
  isFormSectionVisible: boolean = true;
  seId: number = 0;
  isEditForm: boolean = false;

  startdate : Date;
  username:string;
  /**
   * it will help reset both the form data and submit status
   * https://stackoverflow.com/questions/49788215/angular-material-reseting-reactiveform-shows-validation-error
   * 
   * @memberof SECreateComponent
   */
  @ViewChild('form', {static: false}) form;
  @ViewChild('referenceid', {static: false}) referenceElement: any;

  userTypesDisabledMap: Map<string, boolean>;
  stateDisabledMap: Map<string, boolean>;
  cityDisabledMap: Map<string, boolean>;

  omit_special_char(event)
{   
   var k;  
   k = event.keyCode;  //         k = event.keyCode;  (Both can be used)
   return((k > 64 && k < 91) || (k > 96 && k < 123) || k == 8 || k == 32 || (k >= 48 && k <= 57)||(k >= 96 && k <= 105)); 
   
}

  /**
   * Validation messages for user creation form
   *
   * @memberof SECreateComponent
   */
  public account_validation_messages = {
    'username': [
      { type: 'required', message: 'UserID is required' },
      { type: 'minlength', message: 'UserID must be of 4 digits' },
     // { type: 'pattern', message: 'UserID  must contain only numbers' }
    ],
    'firstname': [
      { type: 'required', message: 'First name is required' },
      { type: 'maxlength', message: 'First name cannot be more than 250 characters long' },
      { type: 'pattern', message: 'Your first name must contain only alphabets' }
    ],
    'middlename': [
      //{ type: 'required', message: 'Middle name is required' },
      { type: 'maxlength', message: 'Middle name cannot be more than 250 characters long' },
      { type: 'pattern', message: 'Your first name must contain only alphabets' }
    ],
    'lastname': [
      { type: 'required', message: 'Last name is required' },
      { type: 'maxlength', message: 'Last name cannot be more than 250 characters long' },
      { type: 'pattern', message: 'Your last name must contain only alphabets' }
    ],
    'mobile': [
      { type: 'required', message: 'Mobile is required' },
      { type: 'minlength', message: 'Mobile must be of 10 digits' },
      { type: 'pattern', message: 'Mobile is invalid' }
    ],
    'email': [
      { type: 'required', message: 'Email is required' },
      { type: 'maxlength', message: 'Email cannot be more than 100 characters long' },
      { type: 'pattern', message: 'Email is invalid' }
    ],
    // 'usertype': [
    //   { type: 'required', message: 'User type is required' }
    // ],
    'channelPartner': [
      { type: 'required', message: 'Channel Partner is required' }
    ],
    // 'role': [
    //   { type: 'required', message: 'Role is required' }
    // ],
     'reference': [
       { type:'required',message:'Please select reference value'}
     ],
    'DOB': {
      'required': 'DOB is required.',
      'matDatepickerMin': 'DOB should be past date.'
    }, 
    // 'address': [
    //   { type: 'required', message: 'address is required' },
    //   { type: 'maxlength', message: 'address cannot be more than 250 characters long' },
    //   { type: 'pattern', message: 'Your address must contain only numbers and alphabets' }
    // ],
    'state': [
      { type: 'required', message: 'state is required' },
      //{ type: 'maxlength', message: 'state cannot be more than 250 characters long' },
      //{ type: 'pattern', message: 'Your state must contain only numbers and alphabets' }
    ],
    city: [
      { type: 'required', message: 'city is required' },
      //{ type: 'maxlength', message: 'city cannot be more than 250 characters long' },
      //{ type: 'pattern', message: 'Your city must contain only numbers and alphabets' }
    ],
    'pincode': [
      { type: 'required', message: 'pincode is required' },
      { type: 'minlength', message: 'pincode must be of 6 digits' },
      { type: 'pattern', message: 'pincode is invalid' }
    ]
  };

  /**
   * Creates an instance of UserCreateComponent.
   * @param {UserService} userService
   * @param {FormBuilder} fb
   * @param {MatSnackBar} snackBar
   * @memberof SECreateComponent
   */
  constructor(private SEService: UserService,
    private fb: FormBuilder,
    public snackBar: MatSnackBar,
    private VSSService: VSSCreateService,
    private configurationService: ConfigurationService,
    private SEVService: VendorService,
    private cdr:ChangeDetectorRef) {
    this.createForm();

    this.submitted = false;

    this.mobileExists = false;
    this.emailExists = false;
    //this.addressExists = false;
    this.pincodeExists = false;
    this.userExists = false;

    // this.userTypesDisabledMap = new Map();
     this.stateDisabledMap = new Map();
     this.cityDisabledMap = new Map();
  }

  createForm() {
    this.createUserForm = this.fb.group({
      username: new FormControl('', Validators.compose([
        Validators.required,
       // Validators.minLength(4),
       // Validators.pattern('^[0-9]{1,4}(\.[0-9][0-9])?$')
        
      ]),
        //UniqueUsernameValidator(this.SEService) // async validator
      ),
      firstName: new FormControl('', Validators.compose([
        Validators.required,
        Validators.maxLength(250),
        Validators.pattern('^[A-Za-z]+(?:[A-Za-z]+)*$') 
      ])),
      middleName: new FormControl('', Validators.compose([
        //Validators.required,
        Validators.maxLength(250),
        Validators.pattern('^[A-Za-z]+(?:[A-Za-z]+)*$') 
      ])),
      lastName: new FormControl('', Validators.compose([
        Validators.required,
        Validators.maxLength(250),
        Validators.pattern('^[A-Za-z]+(?:[A-Za-z]+)*$') 
      ])),
      mobile: new FormControl('', Validators.compose([
        Validators.required,
        Validators.minLength(10),
        Validators.pattern('^[6-9][0-9]{9}$')
      ])),
      pincode: new FormControl('', Validators.compose([
        Validators.required,
        Validators.minLength(6),
        Validators.pattern('^[1-9][0-9]{5}$')
      ])),
      email: new FormControl('', Validators.compose([
        Validators.required,
        Validators.maxLength(100),
        Validators.pattern('^([a-zA-Z0-9_\.\-])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})+$')
      ])),
      // address: new FormControl('', Validators.compose([
      //   Validators.required,
      //   Validators.maxLength(250),
      //   Validators.pattern('^[A-Za-z0-9]+(?:[A-Za-z0-9]+)*$')
      // ])),
      state: new FormControl('', Validators.compose([
        Validators.required,
        // Validators.maxLength(250),
        // Validators.pattern('^[A-Za-z0-9]+(?:[A-Za-z0-9]+)*$')
      ])),
      city: new FormControl('', Validators.compose([
        Validators.required,
        // Validators.maxLength(250),
        // Validators.pattern('^[A-Za-z0-9]+(?:[A-Za-z0-9]+)*$')
      ])),
      DOB: new FormControl('', Validators.compose([
        Validators.required,
        Validators.maxLength(250),
        // Validators.pattern('^[A-Za-z0-9]+(?:[A-Za-z0-9]+)*$')
      ])),
      channelPartner: new FormControl(null),
    });
  }
  /**
   * Initializes the component and subscribes to the dropdown services
   * for usertypes and roles
   *
   * @memberof SECreateComponent
   */ 
  public createUserForm: FormGroup;
   ngOnInit() {
   
    // this.userService.getUserTypes()
    //   .pipe(catchError((err) => this.handleError(err)))
    //   .subscribe((response: any) => {
    //     this.userTypes = response.results;
    //   });
    
this.startdate=new Date();
 this.startdate.setDate(this.startdate.getDate() - 7665);
    // this.SEService.getStates()
    //   .pipe(catchError((err) => this.handleError(err)))
    //   .subscribe((data: any) => {
    //     this.state = data.results;        
    //   });
      this.VSSService.getVSSStates()
          .pipe(catchError((err) => this.handleError(err)))
          .subscribe((data: any) => {
            this.state = data.results;        
          });
      var NewSEID = this.SEService.getUniqueUserName()
      .pipe(catchError((err) => this.handleError(err)))
      .subscribe((data: any) => {
         NewSEID  = data;   
         this.username = String(NewSEID);  
         this.createUserForm.get('username').setValue(NewSEID);   
      });
   
      //
  }
FillCityDD(Slectedstate:any)
{
  const currentUser = JSON.parse(localStorage.getItem('currentUser'));
  const loginUserId = currentUser.Username;
  var strfourthChar = loginUserId.substring(4,3);
   if (!isNaN(Number(strfourthChar)))
  {
     this.SEService.getCity(Slectedstate.name)
  .pipe(catchError((err) => this.handleError(err)))
  .subscribe((data: any) => {
    this.city = data.results;        
  });
  }
  else
  {
  this.VSSService.getVSSCities()
    .pipe(catchError((err) => this.handleError(err)))
    .subscribe((data: any) => {
      this.city = data.results;        
    });
  }
}

  /**
   * Build user creation form using form builder
   *
   * @memberof SECreateComponent
   */
 

  onSubmit(form: any) {
    this.submitted = true;

    if(this.mobileExists || this.emailExists)
    {
      this.submitted=false;
      return;
    }

    // stop here if form is invalid
    if (!this.createUserForm.valid) {
      this.submitted=false;
      return;
    }

    const userDetails = Object.assign({}, this.createUserForm.value);
    const newUser = new SECreate();
    newUser.username = userDetails.username;
    newUser.firstName = userDetails.firstName;
    newUser.lastName = userDetails.lastName;
    newUser.email = userDetails.email;
    newUser.mobile = userDetails.mobile;
    newUser.middleName = userDetails.middleName;
    newUser.DOB=new Date(Date.UTC((userDetails.DOB.getFullYear()),userDetails.DOB.getMonth(),userDetails.DOB.getDate()));
    // newUser.address=userDetails.address;
    newUser.state=userDetails.state;
    newUser.city=userDetails.city;
    newUser.pincode=userDetails.pincode;
    this.SEService.createUser(newUser)
      .subscribe(
        (data: any) => {
          this.submitted = false;
          this.openSnackBar(data.message, '');
          if (data.flag === Constants.SUCCESS_FLAG) {
            this.openSnackBar(data.message, '');
            this.form.resetForm();
            this.isFormSectionVisible = false;
            this.seId = data.returnId;
            this.isDocumentSectionVisiable = true;
          }
        },
        (err: any) => {
          this.submitted = false;
          this.openSnackBar(err.error.message, '');
        },
        () => { }
      );
  }

  /**
   * Reset form on clicking reset button.
   * Only resetting the does not reset form group validators
   * https://stackoverflow.com/questions/48216330/angular-5-formgroup-reset-doesnt-reset-validators
   *
   * @memberof SECreateComponent
   */
  resetForm() {
    this.form.reset();
    // clear validators and dont remove them
    this.createUserForm.clearValidators();

    this.createUserForm.markAsUntouched();
    this.createUserForm.markAsPristine();
    // let control: AbstractControl = null;
    // Object.keys(this.createUserForm.controls).forEach((name) => {
    //   control = this.createUserForm.controls[name];
    //   control.setErrors(null);
    // });

    this.mobileExists = false;
    this.emailExists = false;
    //this.addressExists = false;
    this.pincodeExists = false;
    this.userExists = false;
    // this.isUserTypeSelected=false;
  }

  /**
   * Check if the mobile is already taken, set mobile taken flag to true if so
   *
   * @memberof SECreateComponent
   */
  checkMobile() {
    if (this.createUserForm.get('mobile').valid) {
      const mobile = this.createUserForm.get('mobile').value;

      const searchType = 'mobile';

      this.SEVService.CheckMobileEmail(searchType, mobile).subscribe(
        (data: any) => {
          if (data.flag == applicationconstant.SuccessFlag) {
            this.mobileExists = false;
          } else {
            this.mobileExists = true;
          }
        },
        (err: any) => { },
      );
    }
  }

  /**
   *  /**
   * Check if the mobile is already taken, set mobile taken flag to true if so
   *
   * @memberof SECreateComponent
   */
  checkUserID() {
    if (this.createUserForm.get('username').valid) {
      const useridsel = 'SE'+this.createUserForm.get('username').value;

      const searchType = 'username';

      this.SEService.searchUser(searchType, useridsel).subscribe(
        (data: any) => {
          if (data.result !== null) {
            this.userExists = true;
          } else {
            this.userExists = false;
          }
        },
        (err: any) => { },
      );
    }
  }
  //  * *Check if the email is already taken, set email taken flag to true if so
  //  *
  //  * @memberof SECreateComponent
  //  */
  checkEmail() {
    if (this.createUserForm.get('email').valid) {
      const email = String(this.createUserForm.get('email').value).toLocaleUpperCase() ;

      const searchType = 'email';

      this.SEVService.CheckMobileEmail(searchType, email).subscribe(
        (data: any) => {
          if (data.flag == applicationconstant.SuccessFlag) {
            this.emailExists = false;
          }
           else
          {
            this.emailExists = true;
          }
        },
        (err: any) => { },
      );
    }
  }

  openSnackBar(message: string, action: string) {
    this.snackBar.open(message, action, {
      duration: 5000,
      verticalPosition: 'top'
    });
  }

  private handleError(error: any) {
    // this.errorReceived = true;
    return Observable.throw(error);
  }

}
