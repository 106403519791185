import { Component, OnInit,ChangeDetectorRef, ViewChild } from '@angular/core';
import { FormGroup, FormControl, Validators, FormBuilder, ValidatorFn, AbstractControl } from '@angular/forms';
import { MatDialog, MatSnackBar } from '@angular/material';
import {vendordetails} from 'src/app/Models/master/vendor.model';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { UniqueUsernameValidator } from 'src/app/shared/unique-username-validator.directive';
import { ConfigurationService } from 'src/app/Services/Utilities/configuration.service';
import { Constants } from 'src/app/Constants/constants';
import { IState } from 'src/app/Models/common/state.model';
import { Icity } from 'src/app/Models/common/city.model';
import {VendorService} from 'src/app/Services/master/vendor.service';
import { UserService } from 'src/app/Services/usermanagement/user.service';
import { applicationconstant } from 'src/app/Models/DropdownItemsGroup/applicationconstant.model';

@Component({
  selector: 'app-addvendor',
  templateUrl: './addvendor.component.html',
  styleUrls: ['./addvendor.component.scss']
})
export class AddvendorComponent implements OnInit {







  /**
   * Create User From Group
   *
   * @type {FormGroup}
   * @memberof AddvendorComponent
   */
 

  /**
   * submitted variable to track if the form values
   * are being submitted
   *
   * @memberof AddvendorComponent
   */
  public submitted: boolean;

  /**
   * Collection of user types to be filled in dropdown
   *
   * @type {IUserType[]}
   * @memberof AddvendorComponent
   */
  // public userTypes: IUserType[];
  //  public referenceTypes: IUserType[];
   public selectedReference: string;
  isUserTypeSelected:boolean=false;
  channelPartners = []; // TODO
  

  /**
   * Collection of roles to be filled in roles dropdown
   *
   * @type {IRole[]}
   * @memberof AddvendorComponent
   */
   public state: IState[];
   public city: Icity[];

  public mobileExists: boolean;
  public emailExists: boolean;
  public userExists: boolean;
  public addressExists: boolean;
  public pincodeExists: boolean;
  isDocumentSectionVisiable: boolean = false;
  isFormSectionVisible: boolean = true;
  isEditForm: boolean = false;

  startdate : Date;
  username:string;
  /**
   * it will help reset both the form data and submit status
   * https://stackoverflow.com/questions/49788215/angular-material-reseting-reactiveform-shows-validation-error
   * 
   * @memberof SECreateComponent
   */
  // @ViewChild('form', {static: false}) form;
  // @ViewChild('referenceid', {static: false}) referenceElement: any;

  // userTypesDisabledMap: Map<string, boolean>;
  // stateDisabledMap: Map<string, boolean>;
  // cityDisabledMap: Map<string, boolean>;

  omit_special_char(event)
{   
   var k;  
   k = event.keyCode;  //         k = event.keyCode;  (Both can be used)
   return((k > 64 && k < 91) || (k > 96 && k < 123) || k == 8 || k == 32 || (k >= 48 && k <= 57)||(k >= 96 && k <= 105)); 
   
}

  /**
   * Validation messages for user creation form
   *
   * @memberof SECreateComponent
   */

  validationMessages = {
    'username': {
      'required': 'UserID is required.',
      'maxlength': 'UserID cannot be greater than 8 digits',
      'pattern': 'UserID must contain only numeric values'
    },
    'firstName': {
      'required': 'First name is required.',
      'maxlength': 'First name cannot be more than 20 characters long',
      'pattern': 'Your first name must contain only alphabets'
    },
    'middleName': {
      //'required': 'First name is required.',
      'maxlength': 'Middle name cannot be more than 20 characters long',
      'pattern': 'Your middl name must contain only alphabets'
    },
    'lastName': {
      'required': 'Last name is required.',
      'maxlength': 'Last name cannot be more than 20 characters long',
      'pattern': 'Your last name must contain only alphabets'
    },
    'mobile': {
      'required': 'Mobile is required.',
      'minlength': 'Mobile must be of 10 digits',
      'pattern': 'Mobile is invalid'
    },
    'email': {
      'required': 'Email is required.',
      'maxlength': 'Email cannot be more than 100 characters long',
      'pattern': 'Email is invalid'
    },
    'DOB': {
      'required': 'DOB is required.',
      'matDatepickerMin': 'DOB should be past date.',
     }, 
     'Organization': {
      'required': 'Organization is Required.'
     }, 
    'address': {
      'required': 'address is required',
      'maxlength': 'address cannot be more than 100 characters long'
      //{ type: 'pattern', message: 'Your address must contain only numbers and alphabets' 
      },

    'state': {
      'required':'state is required'
       },

      'city': {
        'required': 'city is required' 
      },
      'pincode': {
        'required': 'pincode is required',
        'minlength': 'pincode must be of 6 digits',
        'pattern': 'pincode is invalid'
      }  
  };

  formErrors = {
    'username': '',
    'firstName': '',
    'middleName': '',
    'lastName': '',
    'mobile': '',
    'email': '',
    'DOB': '',
    'address': '',
    'state': '',
    'city': '',
    'pincode': '',
    'Organization': ''
  };

  public account_validation_messages = {
    'username': [
      { type: 'required', message: 'UserID is required' },
      { type: 'maxlength', message: 'UserID cannot be greater than 8 digits' },      
      { type: 'pattern', message: 'UserID must contain only numeric values' }
    ],
    'firstname': [
      { type: 'required', message: 'First name is required' },
      { type: 'maxlength', message: 'First name cannot be more than 20 characters long' },
      { type: 'pattern', message: 'Your first name must contain only alphabets' }
    ],
    'middlename': [
      //{ type: 'required', message: 'Middle name is required' },
      { type: 'maxlength', message: 'Middle name cannot be more than 20 characters long' },
      { type: 'pattern', message: 'Your first name must contain only alphabets' }
    ],
    'lastname': [
      { type: 'required', message: 'Last name is required' },
      { type: 'maxlength', message: 'Last name cannot be more than 20 characters long' },
      { type: 'pattern', message: 'Your last name must contain only alphabets' }
    ],
    'mobile': [
      { type: 'required', message: 'Mobile is required' },
      { type: 'minlength', message: 'Mobile must be of 10 digits' },
      { type: 'pattern', message: 'Mobile is invalid' }
    ],
    'email': [
      { type: 'required', message: 'Email is required' },
      { type: 'maxlength', message: 'Email cannot be more than 100 characters long' },
      { type: 'pattern', message: 'Email is invalid' }
    ],
    'DOB': {
      'required': 'DOB is required.',
      'matDatepickerMin': 'DOB should be past date.'
    }, 
    'address': [
      //{ type: 'required', message: 'address is required' },
      { type: 'maxlength', message: 'address cannot be more than 100 characters long' },
      //{ type: 'pattern', message: 'Your address must contain only numbers and alphabets' 
    ],
    'state': [
      { type: 'required', message: 'state is required' },
    ],
    city: [
      { type: 'required', message: 'city is required' },
    ],
    'pincode': [
      { type: 'required', message: 'pincode is required' },
      { type: 'minlength', message: 'pincode must be of 6 digits' },
      { type: 'pattern', message: 'pincode is invalid' }
    ]
  };








  /**
   * Creates an instance of UserCreateComponent.
   * @param {UserService} userService
   * @param {FormBuilder} fb
   * @param {MatSnackBar} snackBar
   * @memberof addvednorComponent
   */
  constructor(private VSService: VendorService,
    private userService: UserService,
    private fb: FormBuilder,
    public snackBar: MatSnackBar,
    private VSSService: VendorService,
    private configurationService: ConfigurationService,
    private cdr:ChangeDetectorRef) {
    //this.createForm();

    this.submitted = false;

    this.mobileExists = false;
    this.emailExists = false;
    this.addressExists = false;
    this.pincodeExists = false;
    this.userExists = false;

    //  this.stateDisabledMap = new Map();
    //  this.cityDisabledMap = new Map();
  }

  createForm() {
    this.createUserForm = this.fb.group({
      username: new FormControl('', Validators.compose([
        Validators.maxLength(8),
        Validators.required,
        //this.userIdValidator(this.submitted),
        Validators.pattern('^[0-9]+(?:[0-9]+)*$')         
      ]),
      //UniqueUsernameValidator(this.userService) // async validator
      ),
      firstName: new FormControl('', Validators.compose([
        Validators.required,
        Validators.maxLength(20),
        Validators.pattern('^[A-Za-z]+(?:[A-Za-z]+)*$') 
      ])),
      middleName: new FormControl('', Validators.compose([
        Validators.maxLength(20),
        Validators.pattern('^[A-Za-z]+(?:[A-Za-z]+)*$') 
      ])),
      lastName: new FormControl('', Validators.compose([
        Validators.required,
        Validators.maxLength(20),
        Validators.pattern('^[A-Za-z]+(?:[A-Za-z]+)*$') 
      ])),
      mobile: new FormControl('', Validators.compose([
        Validators.required,
        Validators.minLength(10),
        Validators.pattern('^[6-9][0-9]{9}$')
      ])),
      pincode: new FormControl('', Validators.compose([
        Validators.required,
        Validators.minLength(6),
        Validators.pattern('^[1-9][0-9]{5}$')
      ])),
      email: new FormControl('', Validators.compose([
        Validators.required,
        Validators.maxLength(100),
        Validators.pattern('^([a-zA-Z0-9_\.\-])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})+$')
      ])),
      address: new FormControl('', Validators.compose([
        // Validators.required,
         //Validators.maxLength(250),
        // Validators.pattern('^[A-Za-z0-9]+(?:[A-Za-z0-9]+)*$')
        Validators.maxLength(100),
      ])),
      state: new FormControl('', Validators.compose([
        Validators.required,
      ])),
      city: new FormControl('', Validators.compose([
        Validators.required,
      ])),
      DOB: new FormControl('', Validators.compose([
        Validators.required,
        Validators.maxLength(250),
      ])),
      Organization: new FormControl('', Validators.compose([
        Validators.required,
        Validators.maxLength(100),
      ])),
    });
  }
  /**
   * Initializes the component and subscribes to the dropdown services
   * for usertypes and roles
   *
   * @memberof addvendorComponent
   */ 
  public createUserForm: FormGroup;
   ngOnInit() { 
    this.createForm ();  
    this.startdate=new Date();
    this.startdate.setDate(this.startdate.getDate() - 7665);
    this.VSSService.getStates()
          .pipe(catchError((err) => this.handleError(err)))
          .subscribe((data: any) => {
            this.state = data.results;        
          });

    this.createUserForm.valueChanges.subscribe((data) => {
      this.logValidationErrors(this.createUserForm);
          });     
  }

  logValidationErrors(group: FormGroup = this.createUserForm): void {
    Object.keys(group.controls).forEach((key: string) => {
      const abstractControl = group.get(key);
      if (abstractControl instanceof FormGroup) {
        this.logValidationErrors(abstractControl)
      } else {
        this.formErrors[key] = '';
        if (abstractControl && !abstractControl.valid && (abstractControl.touched || abstractControl.dirty)) {
          const messages = this.validationMessages[key];

          for (const errorKey in abstractControl.errors) {
            if (errorKey) {
              this.formErrors[key] += messages[errorKey] + '';
            }
          }
        }
      }
    });
  }

  ValidateAllFields(formGroup: FormGroup) {
    Object.keys(formGroup.controls).forEach(field => {
      const control = formGroup.get(field);
      if (control instanceof FormControl) {
        control.markAsTouched({ onlySelf: true });
      } else if (control instanceof FormGroup) {
        this.ValidateAllFields(control);
      }
    });
  }


FillCityDD(Slectedstate:any)
{
  const currentUser = JSON.parse(localStorage.getItem('currentUser'));
  const loginUserId = currentUser.Username;
  var strfourthChar = loginUserId.substring(4,3);
  
  this.VSSService.getCity(Slectedstate.name)
    .pipe(catchError((err) => this.handleError(err)))
    .subscribe((data: any) => {
      this.city = data.results;        
    });
  
}

  /**
   * Build user creation form using form builder
   *
   * @memberof SECreateComponent
   */
 

  onSubmit() {
    // this.submitted = true;

    
    if(this.mobileExists || this.emailExists)
    {
      this.submitted=false;
      return;
    }
    
    if (this.createUserForm.valid)
    {
       //this.checkUserID();
    if (this.createUserForm.get('username').valid) {
      const useridsel = this.createUserForm.get('username').value;

      const searchType = 'username';

      this.VSService.searchUser(searchType, useridsel).subscribe(
        (data: any) => {
          if (data.result !== null) {
            this.userExists = true;
            this.submitted = false;
            return;
          } else {
            this.userExists = false;
            this.submitted = true;
            const userDetails = Object.assign({}, this.createUserForm.value);
            const newUser = new vendordetails();
            newUser.username = userDetails.username;
            newUser.firstName = userDetails.firstName;
            newUser.lastName = userDetails.lastName;
            newUser.email = userDetails.email;
            newUser.mobile = userDetails.mobile;
            newUser.middlename = userDetails.middleName;
            newUser.organization = userDetails.Organization;
            newUser.DOB=new Date(Date.UTC((userDetails.DOB.getFullYear()),userDetails.DOB.getMonth(),userDetails.DOB.getDate()));
            newUser.address=userDetails.address;
            newUser.state=userDetails.state;
            newUser.city=userDetails.city;
            newUser.pincode=userDetails.pincode;
            this.VSService.createUser(newUser)
              .subscribe(
                (data: any) => {
                  this.submitted = false;
                  this.openSnackBar(data.message, '');
                  if (data.flag === Constants.SUCCESS_FLAG) {
                    this.openSnackBar(data.message, '');
                    this.resetForm();
                  }
                },
                (err: any) => {
                  this.submitted = false;
                  this.openSnackBar(err.error.message, '');
                },
                () => { }
              );
            //this.createUserForm.get('username').setValidators = false;
          }
        },
        (err: any) => { },
      );
      } 
     }
    else
    {
      this.ValidateAllFields(this.createUserForm);
      this.logValidationErrors(this.createUserForm);
    }   

  }

  /**
   * Reset form on clicking reset button.
   * Only resetting the does not reset form group validators
   * https://stackoverflow.com/questions/48216330/angular-5-formgroup-reset-doesnt-reset-validators
   *
   * @memberof SECreateComponent
   */
  // resetForm() {
  //   this.createUserForm.reset();
  //   this.createUserForm.clearValidators();

  //   this.createUserForm.markAsUntouched();
  //   this.createUserForm.markAsPristine();
  //   this.mobileExists = false;
  //   this.emailExists = false;
  //   //this.addressExists = false;
  //   this.pincodeExists = false;
  //   this.userExists = false;
  // }

  
  resetForm() {
    this.createUserForm.reset();
    for (const i in this.createUserForm.controls) {
      if (this.createUserForm.controls[i]) {
        this.createUserForm.controls[i].setErrors(null);
      }
    }
    this.mobileExists = false;
    this.emailExists = false;
    //this.addressExists = false;
    this.pincodeExists = false;
    this.userExists = false;
   
    }

  /**
   * Check if the mobile is already taken, set mobile taken flag to true if so
   *
   * @memberof SECreateComponent
   */
  checkMobile() {
    if (this.createUserForm.get('mobile').valid) {
      const mobile = this.createUserForm.get('mobile').value;

      const searchType = 'mobile';

      this.VSService.CheckMobileEmail(searchType, mobile).subscribe(
        (data: any) => {
          if (data.flag == applicationconstant.SuccessFlag) {
            this.mobileExists = false;
          } else {
            this.mobileExists = true;
          }
        },
        (err: any) => { },
      );
    }
  }

  /**
   *  /**
   * Check if the mobile is already taken, set mobile taken flag to true if so
   *
   * @memberof SECreateComponent
   */
  checkUserID() {
    if (this.createUserForm.get('username').valid) {
      const useridsel = this.createUserForm.get('username').value;

      const searchType = 'username';

      this.VSService.searchUser(searchType, useridsel).subscribe(
        (data: any) => {
          if (data.result !== null) {
            this.userExists = true;
            this.submitted = false;
          } else {
            this.userExists = false;
            this.submitted = true;
            //this.createUserForm.get('username').setValidators = false;
          }
        },
        (err: any) => { },
      );
    }
  }
  //  * *Check if the email is already taken, set email taken flag to true if so
  //  *
  //  * @memberof SECreateComponent
  //  */
  checkEmail() {
    if (this.createUserForm.get('email').valid) {
      const email = String(this.createUserForm.get('email').value).toLocaleUpperCase() ;

      const searchType = 'email';

      this.VSService.CheckMobileEmail(searchType, email).subscribe(
        (data: any) => {
          if (data.flag == applicationconstant.SuccessFlag) {
            this.emailExists = false;
            //this.addressExists = true;
          } else {
            this.emailExists = true;
            //this.addressExists = false;
          }
        },
        (err: any) => { },
      );
    }
  }

  openSnackBar(message: string, action: string) {
    this.snackBar.open(message, action, {
      duration: 5000,
      verticalPosition: 'top'
    });
  }

  private handleError(error: any) {
    // this.errorReceived = true;
    return Observable.throw(error);
  }

  userIdValidator(isExists:boolean): ValidatorFn {
    return (control: AbstractControl): { [key: string]: boolean } | null => {
        if (isExists ) {
            return { 'userIdExists': true };
        }
        return null;
    };
}

}
