import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, FormBuilder, Validators, FormGroupDirective, NgForm } from '@angular/forms'
import { MatDialog, MatSnackBar } from '@angular/material';
import { Router } from '@angular/router';
import { applicationconstant } from 'src/app/Models/DropdownItemsGroup/applicationconstant.model';
import { Ticket } from 'src/app/Models/vendorsupervisor/ticket.model';
import { Incident } from 'src/app/Models/vendorsupervisor/incident.model';
import { DropdownItems } from '../../Models/DropdownItemsGroup/dropdownitems.model';
import { DropdownItemsGroup } from '../../Models/DropdownItemsGroup/dropdownitemsgroup.model';
import { VendorSupervisorService } from 'src/app/Services/vendorsupervisor/vendorsupervisor.service';
import { TicketsAssginedSE } from 'src/app/Services/ServiceEngineer/TicketsAssignedSE.service';
import {MatSortModule} from '@angular/material/sort';
import {Sort} from '@angular/material/sort';
import { stringify } from '@angular/compiler/src/util';
import { SearchDTO } from 'src/app/Models/common/search.model';
import { RejectComponent } from './reject/reject.component';


@Component({
  selector: 'app-vendorsupervisor',
  templateUrl: './vendorsupervisor.component.html',
  styleUrls: ['./vendorsupervisor.component.scss']
})
export class VendorsupervisorComponent implements OnInit {

   /**
   * Form group for password reset
   *
   * @type {FormGroup}
   * @memberof VendorsupervisorComponent
   */
  vendorSearchForm: FormGroup;
  //ticketList: Ticket[];
  incidentList: Incident[];
  searched = false;
  ticketSearchType: DropdownItems[];
  ticketValues:string;
  interactionId: string;
  assignedIncidentStatus: string;
  openIncidentStatus: string;
  workinprogressIncidentStatus: string;
  efsrWIPIncidentStatus: string;
  roapprovalIncidentStatus: string;
  workapprovedIncidentStatus: string;
  workrejectedIncidentStatus: string;
  closedIncidentStatus: string;

// new status

  incident_status_open: string;
  incident_status_assigned: string;
  incident_status_workInProgress: string;
  incident_status_efsrOnHold: string;
  incident_status_roApprovalPending: string;
  incident_status_efsrApprovedByRO: string;
  incident_status_efsrRejectedByRO: string;


  ticketId:number;
  
  //onholdTicketStatus: string;
  

  searchTermStatus: string;


  //isDocumentSectionVisiable: boolean = false;
  //isFormSectionVisible: boolean = true;
  //isEditForm: boolean = true;
  //VehicleId: number = 0;
  
  
/**
   * Validation messages
   *
   * @memberof VendorsupervisorComponent
   */
validationMessages = {
    'searchType': {
      'required': 'Search type is required'
    },
    'searchTerm': {
      'required': 'Search Term is required'
    },
  };
  formErrors = {
    'searchType': '',
    'searchTerm': ''
  };

   /**
   * Creates an instance of ForgotpasswordComponent.
   * @param {FormBuilder} fb
   * @memberof VendorsupervisorComponent
   */
  constructor(private fb: FormBuilder, public snackBar: MatSnackBar, private router: Router, private VendorService: VendorSupervisorService,private dialog:MatDialog, private EngineerService: TicketsAssginedSE)
   {
      this.getStatusList();
      this.getDropdownItems();

      this.vendorSearchForm = this.fb.group({
      searchType: new FormControl(null, Validators.required),
      searchTerm: new FormControl(null, Validators.required),
     });
   }

  ngOnInit() {

    
    this.vendorSearchForm.valueChanges.subscribe((data) => {
      this.logValidationErrors(this.vendorSearchForm);
    });

    this.getTicketList('','');

    this.ticketId=this.VendorService.getTicketId();
    this.interactionId=this.VendorService.getTicketInteractionId();
    //this.toggleListAndCardTab('CARD');
  }

  public getStatusList() {
    // this.VendorService.getStatusList()
    //   .subscribe(items => {
    //     if (items.flag == applicationconstant.SuccessFlag) {
    //       for (let item of items.results) {
    //         if (item.mstKey == applicationconstant.ActiveFlag) {
    //           this.closedTicketStatus = item.id;
    //         }
    //         if (item.mstKey == applicationconstant.InActiveFlag) {
    //           this.openTicketStatus = item.id;
    //         }
    //         if (item.mstKey == applicationconstant.NotVerifiedFlag) {
    //           this.onholdTicketStatus = item.id;
    //         }
    //         if (item.mstKey == applicationconstant.NotVerifiedFlag) {
    //           this.onholdTicketStatus = item.id;
    //         }
    //       }
    //     }
    //   });

    this.assignedIncidentStatus = applicationconstant.Incident_Assigned_Status;
    this.openIncidentStatus = applicationconstant.Incident_Open_Status;
    this.workinprogressIncidentStatus = applicationconstant.Incident_WIP_Status;
    this.efsrWIPIncidentStatus = applicationconstant.Incident_EFSRWIP_Status;
    this.roapprovalIncidentStatus = applicationconstant.Incident_ROApproval_Status;
    this.workapprovedIncidentStatus = applicationconstant.Incident_WorkApproved_Status;
    this.workrejectedIncidentStatus = applicationconstant.Incident_WorkRejected_Status;
    this.closedIncidentStatus = applicationconstant.Incident_Closed_Status;
    //this.incidentId= Incident.incidentId;

    // New status
    this.incident_status_open = applicationconstant.INCIDENT_STATUS_OPEN;
    this.incident_status_assigned = applicationconstant.INCIDENT_STATUS_ASSIGNED;
    this.incident_status_workInProgress = applicationconstant.INCIDENT_STATUS_WORKINPROGRESS;
    this.incident_status_efsrOnHold = applicationconstant.INCIDENT_STATUS_EFSR_ON_HOLD;
    this.incident_status_roApprovalPending = applicationconstant.INCIDENT_STATUS_RO_APPROVAL_PENDING;
    this.incident_status_efsrApprovedByRO = applicationconstant.INCIDENT_STATUS_EFSR_APPROVED_BY_RO;
    this.incident_status_efsrRejectedByRO = applicationconstant.INCIDENT_STATUS_EFSR_REJECTED_BY_RO;

    //this.closedTicketStatus = applicationconstant.Ticket_Search_Closed;
    //this.openTicketStatus = applicationconstant.Ticket_Search_Open;
    //this.onholdTicketStatus = applicationconstant.Ticket_Search_OnHold;
    //this.inprogressTicketStatus = applicationconstant.Ticket_Search_InProgress;

  }

  onSearchTicket() {
    if(this.vendorSearchForm.valid) {
      const searchFormData: any = Object.assign({}, this.vendorSearchForm.value);
      const searchType = searchFormData.searchType;
      const searchTerm = searchFormData.searchTerm;
      if (searchType == applicationconstant.Ticket_Search_Status && searchTerm.trim().toUpperCase() == applicationconstant.Incident_Assigned_Status.toUpperCase()) {

        this.searchTermStatus = this.assignedIncidentStatus;
      }
      if (searchType == applicationconstant.Ticket_Search_Status && searchTerm.trim().toUpperCase() == applicationconstant.Incident_Open_Status.toUpperCase()) {

        this.searchTermStatus = this.openIncidentStatus;
      }
      if (searchType == applicationconstant.Ticket_Search_Status && searchTerm.trim().toUpperCase() == applicationconstant.Incident_WIP_Status.toUpperCase()) {

        this.searchTermStatus = this.workinprogressIncidentStatus;
      }
      if (searchType == applicationconstant.Ticket_Search_Status && searchTerm.trim().toUpperCase() == applicationconstant.Incident_EFSRWIP_Status.toUpperCase()) {

        this.searchTermStatus = this.efsrWIPIncidentStatus;
      }
      if (searchType == applicationconstant.Ticket_Search_Status && searchTerm.trim().toUpperCase() == applicationconstant.Incident_ROApproval_Status.toUpperCase()) {

        this.searchTermStatus = this.roapprovalIncidentStatus;
      }
      if (searchType == applicationconstant.Ticket_Search_Status && searchTerm.trim().toUpperCase() == applicationconstant.Incident_WorkApproved_Status.toUpperCase()) {

        this.searchTermStatus = this.workapprovedIncidentStatus;
      }
      if (searchType == applicationconstant.Ticket_Search_Status && searchTerm.trim().toUpperCase() == applicationconstant.Incident_WorkRejected_Status.toUpperCase()) {

        this.searchTermStatus = this.workrejectedIncidentStatus;
      }
      if (searchType == applicationconstant.Ticket_Search_Status && searchTerm.trim().toUpperCase() == applicationconstant.Incident_Closed_Status.toUpperCase()) {

        this.searchTermStatus = this.closedIncidentStatus;
      }
      if (searchType == this.interactionId && searchTerm.trim()==this.interactionId) 
      {

        this.searchTermStatus = this.interactionId;
      }

      if (searchType == applicationconstant.Ticket_Search_Status && (
        searchTerm.trim().toUpperCase() == applicationconstant.Incident_Assigned_Status.toUpperCase() ||
        searchTerm.trim().toUpperCase() == applicationconstant.Incident_Open_Status.toUpperCase() ||
        searchTerm.replace(/\s/g, "").toUpperCase() == applicationconstant.Incident_WIP_Status.toUpperCase() ||
        searchTerm.replace(/\s/g, "").toUpperCase() == applicationconstant.Incident_EFSRWIP_Status.toUpperCase() ||
        searchTerm.replace(/\s/g, "").toUpperCase() == applicationconstant.Incident_ROApproval_Status.toUpperCase() ||
        searchTerm.replace(/\s/g, "").toUpperCase() == applicationconstant.Incident_WorkApproved_Status.toUpperCase() ||
        searchTerm.replace(/\s/g, "").toUpperCase() == applicationconstant.Incident_WorkRejected_Status.toUpperCase() ||
        searchTerm.trim().toUpperCase() == applicationconstant.Incident_Closed_Status.toUpperCase() 
      )) {
        this.getTicketList(searchType, this.searchTermStatus.toLocaleString());
      }
      if (searchType == this.interactionId && (
        searchTerm.trim() == this.interactionId))
        {
        this.getTicketList(searchType, this.searchTermStatus.toLocaleString());
      }
   else{
        this.getTicketList(searchType, searchTerm);
      }
    }
    else {
      this.ValidateAllFields(this.vendorSearchForm);
      this.logValidationErrors(this.vendorSearchForm);
    }

  }
  ResetSearch()
  {
    this.vendorSearchForm.reset();
    this.ticketValues=this.ticketSearchType.map(x=>x.mstValue).join(' / ');
    this.getTicketList('','');
  }

  onSearchChange(value: any) {
    this.ticketValues=this.ticketSearchType.filter(x=>x.mstKey==value).map(x=>x.mstValue).toString();
  }

  public getTicketList(searchParam: string, searchValue: string) {
    var search = new SearchDTO();
    search.searchParam =searchParam;
    search.searchValue = searchValue;
    this.VendorService.getTicketList(search)
      .subscribe((data) => {
        if (data.flag == applicationconstant.SuccessFlag) {
          this.incidentList = data.results;
        }
        else {
          this.openSnackBar(data.message, '');
          this.incidentList = [];
        }
      }),
      (err: any) => {
        this.openSnackBar(err.error.message, 'error');
      }
  }

  public ViewDetails(ticketId: number) {
    this.VendorService.setTicketId(ticketId);
    this.VendorService.setRequestFromVendor(true);
    this.router.navigate(['supervisor/viewticket']);
  }

  public VIEWEFSR(ticketId: number,ticketInteractionId: string){

    this.VendorService.setRequestFromRO(true);
    this.VendorService.setRequestFromVendor(true);
    this.VendorService.setTicketId(ticketId);
      this.VendorService.setTicketInteractionId(ticketInteractionId);
      this.EngineerService.checkEFSRForRO(ticketInteractionId)
      .subscribe((data) => {
        if (data.flag == applicationconstant.SuccessFlag) {
      this.EngineerService.setEFSRId(data.returnId);
      this.EngineerService.setIncidentId(ticketInteractionId);
        
           // this.VendorService.setTicketId(ticketId);
       // this.EngineerService.setIncidentId(ticketInteractionId);
         this.router.navigate(['supervisor/view-efsr']);
          
       }
        else{
         // this.EngineerService.setEFSRExists(false);
          this.openSnackBar(data.message, 'error');
        }
      }),
     (err: any) => {
       this.openSnackBar(err.error.message, 'error');
     }
  
      }

      ViewSEDetails(ticketId: number) {
        this.VendorService.setTicketId(ticketId);
        this.router.navigate(['SE/view-se']);
      }
    
   public RejectTicket(ticketId:number, interactionId:string){
        this.VendorService.setTicketId(ticketId);
        this.EngineerService.setIncidentId(interactionId);
        if(ticketId!=null){
    
          const dialogRef = this.dialog.open(RejectComponent, {
                width: '500px',
                data: {ticketId : ticketId,interactionId:interactionId} //sEEMAIL:this.sEEMAIL}
              });
              dialogRef.afterClosed().subscribe(result => {
                if (result) {
                  this.router.navigate(['supervisor/ticketsearch']);
                  this.getTicketList('','');
                }
                
              });


      }
    }

  public AssignTicket(ticketId: number,ticketInteractionId: string) {
    this.VendorService.setTicketId(ticketId);
    this.VendorService.setTicketInteractionId(ticketInteractionId);
    this.router.navigate(['ServiceEngineer/assign-ticketto-se']);
  }

  public getDropdownItems() {
    const params =
      `${DropdownItemsGroup.TICKET_SEARCH_PARAM}`;
    this.VendorService.getDropdownItems(JSON.stringify(params))
      .subscribe(items => {
        if (items.flag == applicationconstant.SuccessFlag) {
          this.setDropdownItemsAsPerGroup(items.results);
        }
        else {
          this.openSnackBar(items.message, '')
        }
      },
        (err: any) => this.openSnackBar(err.error.message, '')
      );
   }

  private setDropdownItemsAsPerGroup(itemsGrop: any) {
    if (itemsGrop.length > 0) {
      this.ticketSearchType = [];
      for (let item of itemsGrop) {
        if (item.mstGroup == DropdownItemsGroup.TICKET_SEARCH_PARAM) {
          this.ticketSearchType.push(item);
        }
      }
    }
    this.ticketValues=this.ticketSearchType.map(x=>x.mstValue).join(' / '); 
  }


  logValidationErrors(group: FormGroup = this.vendorSearchForm): void {
    Object.keys(group.controls).forEach((key: string) => {
      const abstractControl = group.get(key);
      if (abstractControl instanceof FormGroup) {
        this.logValidationErrors(abstractControl)
      } else {
        this.formErrors[key] = '';
        if (abstractControl && !abstractControl.valid && (abstractControl.touched || abstractControl.dirty)) {
          const messages = this.validationMessages[key];

          for (const errorKey in abstractControl.errors) {
            if (errorKey) {
              this.formErrors[key] += messages[errorKey] + '';
            }
          }
        }
      }
    });
  }

  ValidateAllFields(formGroup: FormGroup) {
    Object.keys(formGroup.controls).forEach(field => {
      const control = formGroup.get(field);
      if (control instanceof FormControl) {
        control.markAsTouched({ onlySelf: true });
      } else if (control instanceof FormGroup) {
        this.ValidateAllFields(control);
      }
    });
  }

  // sortData(sort: Sort) {
  //   const data = this.desserts.slice();
  //   if (!sort.active || sort.direction === '') {
  //     this.sortedData = data;
  //     return;
  //   }

  //   this.sortedData = data.sort((a, b) => {
  //     const isAsc = sort.direction === 'asc';
  //     switch (sort.active) {
  //       case 'name': return compare(a.name, b.name, isAsc);
  //       case 'calories': return compare(a.calories, b.calories, isAsc);
  //       case 'fat': return compare(a.fat, b.fat, isAsc);
  //       case 'carbs': return compare(a.carbs, b.carbs, isAsc);
  //       case 'protein': return compare(a.protein, b.protein, isAsc);
  //       default: return 0;
  //     }
  //   });
  //  }
  // }

  //  compare(a: number | string, b: number | string, isAsc: boolean) {
  //   return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
  // }
     /**
   * Snack bar
   *
   * @param {string} message
   * @param {string} action
   * @memberof ForgotpasswordComponent
   */
  openSnackBar(message: string, action: string) {
    this.snackBar.open(message, action, {
      duration: 5000,
      verticalPosition: 'top'
    });
  }

//   public toggleListAndCardTab(selDisplayType: string) {
//  if (selDisplayType == 'CARD') {
//     $('#listPending').hide();
//     $('#listSubmitted').hide();
//     $('#cardPending').show();
//     $('#cardSubmitted').show();
//   }
//  else if (selDisplayType == 'LIST') {
//    $('#listPending').show();
//    $('#listSubmitted').show();
//     $('#cardPending').hide();
//    $('#cardSubmitted').hide();
//    }
// }

}
