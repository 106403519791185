import { Observable, throwError } from 'rxjs';
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { map, catchError, tap } from 'rxjs/operators';
import { HttpVerbService } from 'src/app/Services/httpverb/httpverb.service';
import { AssignTicket } from 'src/app/Models/TicketsSE/AssignTicket.model';

import { environment } from 'src/environments/environment';
import{ IName } from 'src/app/Models/TicketsSE/engineername.model'
import { SETicketOTP } from 'src/app/Models/ServiceEngineer/SETicketOTP.model';
import { SearchDTO } from 'src/app/Models/common/search.model';

@Injectable({
    providedIn: 'root'
  })
  
  export class TicketsAssginedSE {
  
    baseUrl: string;
    ticketId: number;
    efsrId: number;
    incidentId: string;
    roId: number;
    efsrExists : boolean;
  
    constructor(private http: HttpClient, private service: HttpVerbService) {
      this.baseUrl = environment.restUrl + '/api/v1/';
    }
 /**
   * Get all active States
   *
   * @returns {Observable<IState[]>}
   * @memberof UserService
   */
  
    getNames(): Observable<IName[]> {
      const url = this.baseUrl + 'getNames';
      return this.service.get(url).pipe(map((response: any) => {
        return response;
      }));
    }
    /**
   * Create new user with the details provide in the form
   *
   * @param {User} newUser
   * @returns {Observable<any>}
   * @memberof UserService
   */
  //createDetail(newAssign: AssignTicket): Observable<any> {
  //  return this.http.post<any>(this.baseUrl + 'createDetail', newAssign, {
    //  headers: new HttpHeaders({
       // 'Content-Type': 'application/json'
     // })
   // });
 // }


   // public setTicketId(TicketId: number) {
     // this.ticketId = TicketId;
   // }
  
   // public getTicketId() {
   //   return this.ticketId;
   // }
   // public getTicket(ticketId: number): Observable<any> {
     // return this.http.get<any>(this.baseUrl + 'getTicket' + '?ticketid=' + ticketId, {
      //  headers: new HttpHeaders({
          //'Content-Type': 'application/json',
         // 'No-Auth': 'True' //need to remove during deployment
       // })
     // }).pipe(
      //  map((response: any) => {
      //  return response;
     // }
     // ));
   // }
 

   public getIncidentList(Search: SearchDTO): Observable<any> {
    return this.http.post<any>(this.baseUrl + 'IncidentList/searchincident',Search, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'//,
        //'No-Auth':  'True' //need to remove during deployment
      })
    }).pipe(map((response: any) => {
      return response;
    }
    ));
  }

  
  updateefsrnotrequired(incidentid: string,SEEFSRRemark:string,roKeyPersonName:number): Observable<any> {
     const url = this.baseUrl + 'UpdateNotRequiredEFSR?incidentid=' + incidentid+'&SEEFSRRemark='+SEEFSRRemark+'&roKeyPersonName='+roKeyPersonName; 
     
     return this.http.get<any>(url, {
       headers: new HttpHeaders({
         'Content-Type': 'application/json'//,
         //'No-Auth':  'True' //need to remove during deployment
       })
     }).pipe(map((response: any) => {
       return response;
     }
     ));
   }

  checkEFSRExists(incidentid: string): Observable<any> {
     const url = this.baseUrl + 'CheckEFSRExists?incidentid=' + incidentid; 
     
     return this.http.get<any>(url, {
       headers: new HttpHeaders({
         'Content-Type': 'application/json'//,
         //'No-Auth':  'True' //need to remove during deployment
       })
     }).pipe(map((response: any) => {
       return response;
     }
     ));
   }


  checkEFSRForRO(incidentid: string): Observable<any> {
    const url = this.baseUrl + 'checkEFSRForRO?incidentid=' + incidentid; 
    
    return this.http.get<any>(url, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'//,
        //'No-Auth':  'True' //need to remove during deployment
      })
    }).pipe(map((response: any) => {
      return response;
    }
    ));
  }

  public setEFSRId(efsrId: number) {
    this.efsrId = efsrId;
  }

  public getEFSRId() {
    return this.efsrId;
  }

  public getEFSRExists() {
    return this.efsrExists;
  }

  public setEFSRExists(efsr: boolean) {
    this.efsrExists= efsr;
  }

  public setIncidentId(incidentId: string) {
    this.incidentId = incidentId;
  }

  public geIncidentId() {
    return this.incidentId;
  }

  public setROId(roId: number) {
    this.roId = roId;
  }

  public getROId() {
    return this.roId;
  }
  public gethistoryticket(IncidentID: number): Observable<any> {
    return this.http.post<any>(this.baseUrl + 'IncidentList/GetHistoryTicket' , IncidentID, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
       // 'No-Auth': 'True' //need to remove during deployment
      })
    }).pipe(
      map((response: any) => {
      return response;
    }
    ));
  }

  SendOTPToValidateSE(ticketId: number): Observable<any[]> {
    return this.http.post<any[]>(this.baseUrl + 'sendOTPToValidateSE', ticketId, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'No-Auth': 'True'
      })
    });
  }

  VerifyOTPForEFSR(OTP: SETicketOTP): Observable<any> {
    return this.http.post<any>(this.baseUrl + 'verifyOTPforEFSR', OTP, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    });
  }

  

    
    }
