import { Component, OnInit, ViewChild, OnDestroy } from '@angular/core';
    import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
    import { MatDialog, MatSnackBar, MatDialogConfig } from '@angular/material';
    import { Observable } from 'rxjs';
    import { catchError } from 'rxjs/operators';
    import {VendorService} from 'src/app/Services/master/vendor.service';
    import {vendordetails, Isubcategory,Iareaname,Isubareaname,Ivendor,Iro} from 'src/app/Models/master/vendor.model';
    import { documentList } from 'src/app/Models/ServiceEngineer/documentList';
    import { ConfigurationService } from 'src/app/Services/Utilities/configuration.service';
    import { Constants } from 'src/app/Constants/constants';
    import { IState } from 'src/app/Models/common/state.model';
    import { Icity } from 'src/app/Models/common/city.model';
    import {romappingdetails} from 'src/app/Models/master/romappingdetails.model';
    import { Router, NavigationEnd } from '@angular/router';
    import {paraDetails } from 'src/app/Models/master/paraDetails.model';
    import {rovendorpara} from 'src/app/Models/Reports/rovendorpara.model';
    import {rovendorparaedit} from 'src/app/Models/Reports/rovendorparaedit.model';
    
    import { applicationconstant } from 'src/app/Models/DropdownItemsGroup/applicationconstant.model';
    
    /**
     * Edit user page
     *
     * @export
     * @class UserEditComponent
     * @implements {OnInit}
     */
    
    @Component({
      selector: 'app-editrocatmapp',
      templateUrl: './editrocatmapp.component.html',
      styleUrls: ['./editrocatmapp.component.scss']
    })
    export class EditrocatmappComponent implements OnInit,OnDestroy {
    
      /**
       * User Search form
       *
       * @type {FormGroup}
       * @memberof ROComponent
       */
      rocategorymappingForm: FormGroup;
      rocategorymappingFormNew:FormGroup;
      navigationSubscription;
    
      //public category: Icategory[];
      public subcategory: Isubcategory[];
      public areaname: Iareaname[];
      public subareaname: Isubareaname[];
      public vendor: Ivendor[];
      public ro: Iro[];
      isFormSectionVisible: boolean = true;
      isFormDataSectionVisible: boolean = false;
      //userData: romappingdetails;
      public submitted: boolean;
      public editID: number;
    
      public subcategory1: Isubcategory[];
      public areaname1: Iareaname[];
      public subareaname1: Isubareaname[];
      public vendor1: Ivendor[];
      public ro1: Iro[];
      userData: paraDetails;
      IncidentList: rovendorpara[];
      /**
       * User details validation messages
       *
       * @memberof UserEditComponent
       */
      public account_validation_messages = {
        // 'category': [
        //   { type: 'required', message: 'Category is required' },
        // ],
        'subcategory': [
          { type: 'required', message: 'SubCategory is required' },
        ],
        'ro': [
          { type: 'required', message: 'RO is required' },
        ],
        'areaname': [
          { type: 'required', message: 'Area Name is required' },
        ],
        'subareaname': [
          { type: 'required', message: 'Sub Area Name is required' },
        ],
        'vendor': [
          { type: 'required', message: 'Vendor is required' },
        ],
      };
      public account_validation_messages1 = {
        // 'category': [
        //   { type: 'required', message: 'Category is required' },
        // ],
        'subcategory1': [
          { type: 'required', message: 'SubCategory is required' },
        ],
        'ro1': [
          { type: 'required', message: 'RO is required' },
        ],
        'areaname1': [
          { type: 'required', message: 'Area Name is required' },
        ],
      //  'subareaname1': [
      //  { type: 'required', message: 'Sub Area Name is required' },
      //  ],
        'vendor1': [
          { type: 'required', message: 'Vendor is required' },
        ],
      };
     
     
      /**
       * Creates an instance of UserEditComponent.
       * @param {FormBuilder} fb
       * @param {MatSnackBar} snackBar
       * @param {UserService} userService
       * @memberof UserEditComponent
       */
      constructor(private fb: FormBuilder,
        public snackBar: MatSnackBar,
        public dialog: MatDialog,
        private vendService: VendorService,
        private router: Router,
        private configurationService: ConfigurationService) {
          this.submitted = false;
        this.rocategorymappingForm = this.fb.group({
          //category: new FormControl(null, Validators.required),
          ro: new FormControl(null, Validators.required),
          areaname: new FormControl(null, Validators.required),
          subareaname: new FormControl(null, Validators.required),
          vendor: new FormControl(null, Validators.required),
          subcategory: new FormControl(null, Validators.compose([
            Validators.required,
            Validators.maxLength(100),
          ]))
        });
    
        this.rocategorymappingFormNew = this.fb.group({
          //category: new FormControl(null, Validators.required),
          ro1: new FormControl(null, Validators.required),
          areaname1: new FormControl(null, Validators.required),
          subareaname1: new FormControl(null),
          vendor1: new FormControl(null, Validators.required),
          subcategory1: new FormControl(null, Validators.compose([
            Validators.required,
            Validators.maxLength(100),
          ]))
        });

      }
    
      
      /**
       * Initialize component and subcribe to value to fill in
       * dropdowns of usertypes and roles
       *
       * @memberof UserEditComponent
       */
      ngOnInit() {
        // this.vendService.getCategoryROMap()
        //   .pipe(catchError((err) => this.handleError(err)))
        //   .subscribe((data: any) => {
        //     this.category = data.results;        
        //   });
          this.vendService.getSubCategoryROMap()
          .pipe(catchError((err) => this.handleError(err)))
          .subscribe((data: any) => {
            this.subcategory = data.results;   
            //this.subareaname = [];    
            this.rocategorymappingForm.get('subareaname').patchValue('');
            this.rocategorymappingForm.get('subareaname').setErrors(null); 
          });
      }
      getAreaName(Slectedstate:any){
        this.vendService.getAreaNameforEdit(Slectedstate.name)
          .pipe(catchError((err) => this.handleError(err)))
          .subscribe((data: any) => {
            this.areaname = data.results;        
          });
        }

        getSubAreaName(Slectedarea:any){
          const Details = Object.assign({}, this.rocategorymappingForm.value);
          this.vendService.getSubAreaNameforEdit(Details.subcategory.name,Slectedarea.name)
            .pipe(catchError((err) => this.handleError(err)))
            .subscribe((data: any) => {
              this.subareaname = data.results;        
            });
          }

          getRO(subareaname:any){
            const Details = Object.assign({}, this.rocategorymappingForm.value);
          this.vendService.getRODetailsforEdit(Details.subcategory.name,Details.areaname.name,subareaname.name)
            .pipe(catchError((err) => this.handleError(err)))
            .subscribe((data: any) => {
              this.ro = data.results;        
            });
          }

          getROArea(areaname:any){
            const Details = Object.assign({}, this.rocategorymappingForm.value);
          this.vendService.getRODetailsAreaforEdit(Details.subcategory.name,Details.areaname.name)
            .pipe(catchError((err) => this.handleError(err)))
            .subscribe((data: any) => {
              this.ro = data.results;        
            });
          }

          getVendor(ro:any){
            const Details = Object.assign({}, this.rocategorymappingForm.value);
            this.vendService.getvendorforEdit(Details.subcategory.name,Details.areaname.name,Details.subareaname.name?Details.subareaname.name:null,ro.name)
              .pipe(catchError((err) => this.handleError(err)))
              .subscribe((data: any) => {
                this.vendor = data.results;        
              });
            }
      /**
       * Submit the form details and pass data to web api
       *
       * @returns
       * @memberof UserEditComponent
       */
      onSearch() {
        
        const userDetails = Object.assign({}, this.rocategorymappingForm.value);
        // const userData = new romappingdetails();
        // userData.subcategory = userDetails.subcategory.name;
        // userData.areaname = userDetails.areaname.name;
        // userData.subareaname = userDetails.subareaname.name;
        // userData.roname = userDetails.ro.name;
        // userData.vendorname = userDetails.vendor.name;
        this.isFormDataSectionVisible= true;
        this.vendService.getSubCategoryROMap()
        .pipe(catchError((err) => this.handleError(err)))
        .subscribe((data: any) => {
          this.subcategory1 = data.results;           
        });
  
        this.vendService.getRO()
        .pipe(catchError((err) => this.handleError(err)))
        .subscribe((data: any) => {
          this.ro1 = data.results;        
        });
  
        this.vendService.getAreaName()
        .pipe(catchError((err) => this.handleError(err)))
        .subscribe((data: any) => {
          this.areaname1 = data.results;        
        });
  
        this.vendService.getSubAreaName()
        .pipe(catchError((err) => this.handleError(err)))
        .subscribe((data: any) => {
          this.subareaname1 = data.results;        
        });
  
        this.vendService.getVendorForMapping()
        .pipe(catchError((err) => this.handleError(err)))
        .subscribe((data: any) => {
          this.vendor1 = data.results;        
        });
        //this.setEditFormValues();
        this. getDetailsofEdit();
      }

      getDetailsofEdit()
      {
        const UpdateDetails = Object.assign({}, this.rocategorymappingForm.value);

        const userData=new rovendorpara();
        userData.subcategory=UpdateDetails.subcategory.name;
        userData.areaname=UpdateDetails.areaname.name;
        userData.subareaname=UpdateDetails.subareaname.name;
        userData.rocode=UpdateDetails.ro.name;
        userData.vendor=UpdateDetails.vendor.name;

      this.vendService.searchROVendorData(userData).subscribe(
        (data: any) => {
          
          if (data.flag == applicationconstant.SuccessFlag) {
            this.IncidentList = data.results;
            this.editID=this.IncidentList[0]["id"];

            this.setEditFormValues(this.IncidentList);

            this.openSnackBar('Data found and displayed','');
          }
          else {
            this.openSnackBar('Data not found for selected criteria','');
            this.IncidentList = [];
          }
        },
        (err: any) => this.openSnackBar(err.error.message, ''),
        () => { }
      );
      }
  
      setEditFormValues(user: rovendorpara[]) {
        this.rocategorymappingFormNew.patchValue({
          subcategory1: user[0].subcategory,
          areaname1: user[0].areaname,
          subareaname1: user[0].subareaname,
          vendor1: user[0].vendor,
          ro1: user[0].rocode,
        });
      }

      onSubmit(){
        const UpdateDetails = Object.assign({}, this.rocategorymappingFormNew.value);

        const userData=new rovendorparaedit();
        userData.subcategory1=UpdateDetails.subcategory1;
        userData.areaname1=UpdateDetails.areaname1;
        userData.subareaname1=UpdateDetails.subareaname1;
        userData.ro1=UpdateDetails.ro1;
        userData.vendor1=UpdateDetails.vendor1;
        userData.id1=this.editID;

        this.vendService.updateROVendorMapping(userData
          ).subscribe(
          (data: any) => {
            this.openSnackBar(data.message, '');
            if(data.flag=="F"){
             // this.showSEUserDetails=true;
              
            }
            else
            {
            // this.showSEUserDetails = false;
            // this.searchSEUserForm.get('searchTerm').patchValue('');
            // this.searchSEUserForm.get('searchTerm').setErrors(null);
            // this.searchSEUserForm.get('searchType').patchValue('null');
            this.resetForm();
          }
          },
          (err: any) => this.openSnackBar(err.error.message, ''),
          () => { }
        );

      }
      /**
       * Reset edit user from, set all controls errors to null
       *
       * @memberof UserEditComponent
       */
      resetForm() {
        this.rocategorymappingForm.reset();
    
        for (const i in this.rocategorymappingForm.controls) {
          if (this.rocategorymappingForm.controls[i]) {
            this.rocategorymappingForm.controls[i].setErrors(null);
          }
        }

        this.rocategorymappingFormNew.reset();
    
        for (const i in this.rocategorymappingFormNew.controls) {
          if (this.rocategorymappingFormNew.controls[i]) {
            this.rocategorymappingFormNew.controls[i].setErrors(null);
          }
        }
      }
    
      cancelEdit() {
        this.resetForm();
      }
      
      openSnackBar(message: string, action: string) {
        this.snackBar.open(message, action, {
          duration: 5000,
          verticalPosition: 'top'
        });
      }
    
      private handleError(error: any) {
        // this.errorReceived = true;
        return Observable.throw(error);
      }
    
      ngOnDestroy() {
        // avoid memory leaks here by cleaning up after ourselves. If we  
        // don't then we will continue to run our initialiseInvites()   
        // method on every navigationEnd event.
        if (this.navigationSubscription) {  
           this.navigationSubscription.unsubscribe();
        }
      }
    
    }
    
    
    