import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class RecaptchaService {

  baseUrl: string;

  constructor(private http: HttpClient) {
    // this.baseUrl = `${environment.restUrl}/`;
    this.baseUrl = environment.restUrl + '/api/v1/';
  }

  public isRecaptchaEnabled(): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json', 'No-Auth': 'True' })
    };
    const url = `${this.baseUrl}recaptcha/enforce`;
    return this.http.get<any>(url, httpOptions);
  }

  public verifyRecaptcha(token: string): Observable<any> {
    const url = `${this.baseUrl}verifyrecaptcha?response=${token}`;
    return this.http.get(url);
  }
}
