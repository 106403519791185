import { Component, OnInit,ChangeDetectorRef,ViewChild } from '@angular/core';
import { FormGroup, FormControl, Validators, FormBuilder, ValidatorFn, AbstractControl } from '@angular/forms';
import { MatDialog, MatSnackBar } from '@angular/material';
import { VendorsupervisoreditService } from 'src/app/Services/vendorsupervisor/vendorsupervisoredit.service';
import { VendorSupervisor } from 'src/app/Models/vendorsupervisor/vendor-supervisor.model';
import { LoginService } from 'src/app/Services/loginmanagement/login.service';
import { Router } from '@angular/router';
import { IRole } from 'src/app/Models/usermanagement/role.model';
import { Observable } from 'rxjs';
import { IUserType } from 'src/app/Models/usermanagement/usertype.model';
import { catchError } from 'rxjs/operators';
import { UniqueUsernameValidator } from 'src/app/shared/unique-username-validator.directive';
import { ConfigurationService } from 'src/app/Services/Utilities/configuration.service';
import { Constants } from 'src/app/Constants/constants';
import { IState } from 'src/app/Models/common/state.model';
import { Icity } from 'src/app/Models/common/city.model';
import{ VendorSupervisorService } from 'src/app/Services/vendorsupervisor/vendorsupervisor.service'
import { VendorsupervisorregisterService } from 'src/app/Services/vendorsupervisor/vendorsupervisorregister.service';
import { UserService } from 'src/app/Services/usermanagement/user.service';
import { VSSCreateService } from 'src/app/Services/vendorsupervisor/VSSCreate.service';
import { applicationconstant } from 'src/app/Models/DropdownItemsGroup/applicationconstant.model';
import { VendorService } from 'src/app/Services/master/vendor.service';
@Component({
  selector: 'app-vs-edit',
  templateUrl: './vs-edit.component.html',
  styleUrls: ['./vs-edit.component.scss']
})
export class VSEditComponent implements OnInit {

  public State: IState[];
  public City: Icity[];
  
  public submitted: boolean;
  public mobileExists: boolean;
  public emailExists: boolean;
  public alternatemobileExists: boolean;
  public alternateemailExists: boolean;  
  public userDetailsSubmitted = false;
  public levelOfVS = 0;
  public selectedState: string[]= [];
  public selectedCity: string[]= [];
  citylistSelected:string[];
  statelistSelected:string[];
  
  userSoftDeleted: boolean;
  showUserDetails = false;
  userData: VendorSupervisor;
  @ViewChild('form', {static: false}) form;

  constructor(private router: Router,private userService: VendorsupervisoreditService,
    private userLoginService: LoginService,
    private vendorservice:  VendorsupervisorregisterService,
    private UserVSService  : UserService,
    private SEService: VSSCreateService,
    private VSSService: VendorService,
  private fb: FormBuilder,
  public snackBar: MatSnackBar,
  private configurationService: ConfigurationService,
  private cdr:ChangeDetectorRef) {
    this.EditForm();

  this.submitted = false;
 

  this.mobileExists = false;
  this.emailExists = false;
  this.alternatemobileExists = false;
  this.alternateemailExists = false; 

   }
   public VendorSupervisorEditForm: FormGroup;
   VendorSupID : string;
   ShowLoader : boolean;
   public account_validation_messages = {
    'VendorID': [
      { type: 'required', message: 'Vendor ID is required' }
    ],
    'username': [
      { type: 'required', message: 'Username is required' },
      { type: 'minlength', message: 'Username must be at least 5 characters long' },
      { type: 'maxlength', message: 'Username cannot be more than 100 characters long' },
      { type: 'pattern', message: 'Your username must contain only numbers and alphabets' },
      { type: 'usernameTaken', message: 'Username is already in use.' }
    ],
    'firstname': [
      { type: 'required', message: 'First name is required' },
      { type: 'maxlength', message: 'First name cannot be more than 250 characters long' },
      { type: 'pattern', message: 'Your first name must contain only  alphabets' }
    ],
  
    'lastname': [
      { type: 'required', message: 'Last name is required' },
      { type: 'maxlength', message: 'Last name cannot be more than 250 characters long' },
      { type: 'pattern', message: 'Your last name must contain only numbers and alphabets' }
    ],
    'mobile': [
      { type: 'required', message: 'Mobile is required' },
      { type: 'minlength', message: 'Mobile must be of 10 digits' },
      { type: 'pattern', message: 'Mobile is invalid' }
    ],
    'Amobile': [
      { type: 'required', message: 'Mobile is required' },
      { type: 'minlength', message: 'Mobile must be of 10 digits' },
      { type: 'pattern', message: 'Mobile is invalid' }
    ],
    'email': [
      { type: 'required', message: 'Email is required' },
      { type: 'maxlength', message: 'Email cannot be more than 100 characters long' },
      { type: 'pattern', message: 'Email is invalid' }
    ],
    'Address': [
      { type: 'required', message: 'Address is required' },
      { type: 'maxlength', message: 'Address cannot be more than 100 characters long' },
    ],
    'PIN': [
      { type: 'required', message: 'PIN is required' },
      // { type: 'pattern', message: 'PIN is invalid' }
      { type: 'pattern', message: 'PIN should be numeric' }
    ],
    'State': [
      { type: 'required', message: 'State is required' },
      // { type: 'pattern', message: 'PIN is invalid' }
    ],
    'StaterootLvl': [
      { type: 'required', message: 'State is required' },
      // { type: 'pattern', message: 'PIN is invalid' }
    ],
    'City': [
      { type: 'required', message: 'City is required' },
      // { type: 'pattern', message: 'PIN is invalid' }
    ],
    'Organization':[
      { type: 'required', message: 'Organization is Required'},
    ]

  };
  ngOnInit() {
    this.vendorservice.getStateEdit()
            .pipe(catchError((err) => this.handleError(err)))
            .subscribe((data: any) => {
            this.State = data.results;
            this.vendorservice.getCityEdit('All')
                  .pipe(catchError((err) => this.handleError(err)))
                  .subscribe((data: any) => {
                    this.City = data.results;
                    this.userService.getVS()
                    .subscribe((data: any) => {
                      if (data != null)
                      {
                        // if (data.results[0].checklist != null)
                        // {
                        //   this.citylistSelected = [];
                        //   data.results[0].checklist.forEach(check => {
                        //    this.citylistSelected.push(Number(check));
                        //  });
                        // }

                        this.levelOfVS = data.results[0].level;
                        if (this.levelOfVS !=0)
                        {

                         this.VendorSupervisorEditForm.controls['State'].setValidators([Validators.required]);
                         this.VendorSupervisorEditForm.controls["State"].updateValueAndValidity();
                        }
                        else
                        {
                          this.VendorSupervisorEditForm.controls['State'].clearValidators();
                          this.VendorSupervisorEditForm.controls["State"].updateValueAndValidity();
                        }
                        if (this.levelOfVS >1)
                        {
                          this.VendorSupervisorEditForm.controls['City'].setValidators([Validators.required]);
                          this.VendorSupervisorEditForm.controls["City"].updateValueAndValidity();
                        }
                        else
                        {
                          this.VendorSupervisorEditForm.controls['City'].clearValidators();
                          this.VendorSupervisorEditForm.controls["City"].updateValueAndValidity();
                        }

                        // if (this.levelOfVS == 0)
                        // {
                        //   this.VendorSupervisorEditForm.controls['StaterootLvl'].setValidators([Validators.required]);
                        //   this.VendorSupervisorEditForm.controls["StaterootLvl"].updateValueAndValidity();
                        //   this.VendorSupervisorEditForm.controls['State'].clearValidators();
                        //   this.VendorSupervisorEditForm.controls["State"].updateValueAndValidity();
                        //   // this.VendorSupervisorEditForm.controls['City'].clearValidators();
                        //   // this.VendorSupervisorEditForm.controls["City"].updateValueAndValidity();
                        // }
                        // else
                        // {
                        // //  this.SEService.getVSSStates()
                        // //  .pipe(catchError((err) => this.handleError(err)))
                        // //  .subscribe((data: any) => {
                        // //    this.State = data.results;        
                        // //  });
                        //   this.VendorSupervisorEditForm.controls['State'].setValidators([Validators.required]);
                        //   this.VendorSupervisorEditForm.controls["State"].updateValueAndValidity();
                        //   this.VendorSupervisorEditForm.controls['StaterootLvl'].clearValidators();
                        //   this.VendorSupervisorEditForm.controls["StaterootLvl"].updateValueAndValidity();
                        //   // this.VendorSupervisorEditForm.controls['City'].setValidators([Validators.required]);
                        //   // this.VendorSupervisorEditForm.controls["City"].updateValueAndValidity();
                        // }
                
                         this.SetVSIdDetails(data.results);
                      }
                    },
                    (err: any) => {
                     this.openSnackBar(err.error.message, 'error');
                    }); 
                  });        
            });



   

    // this.vendorservice.getCityEdit('All')
    // .pipe(catchError((err) => this.handleError(err)))
    // .subscribe((data: any) => {
    //   this.City = data.results; 
    //   // this.userService.getVS()
    //   //   .subscribe((data: any) => {
    //   //     if (data != null)
    //   //     {
    //   //       this.levelOfVS = data.results[0].level;
    //   //        this.SetVSIdDetails(data.results);
    //   //     }
    //   //   },
    //   //   (err: any) => {
    //   //    this.openSnackBar(err.error.message, 'error');
    //   //   });       
    // });

  }

  selectedStates(state :any): void {
    if (this.selectedState.length > 0) {
      var index_of_selected_option = this.selectedState.indexOf(state.name);
      if (index_of_selected_option != -1) {
        this.selectedState.splice(index_of_selected_option, 1)
      }
      else {
        this.selectedState = this.selectedState.concat(state.name);
      }
    }
    else {
      this.selectedState = this.selectedState.concat(state.name);
    }
  }

//   FillCityDD(Slectedstate:any,patchValue:boolean)
// {
//   var stateName = "";
//   if (patchValue)
//   {
//     stateName = Slectedstate;
//   }
//   else
//   {
//     stateName = Slectedstate.name;
//   }
//   this.vendorservice.getCity(stateName)
//     .pipe(catchError((err) => this.handleError(err)))
//     .subscribe((data: any) => {
//       this.City = data.results;        
//   });
// }


FillCityDD(Slectedstate:any,patchValue:boolean)
{
  if (this.selectedState.length > 0) {
    var index_of_selected_option = this.selectedState.indexOf(Slectedstate.name);
    if (index_of_selected_option != -1) {
      this.selectedState.splice(index_of_selected_option, 1)
    }
    else {
      this.selectedState = this.selectedState.concat(Slectedstate.name);
    }
  }
  else {
    this.selectedState = this.selectedState.concat(Slectedstate.name);
  }

  if (this.levelOfVS == 0 || this.levelOfVS == 1)
  {
      this.SEService.getVSSCity(this.selectedState)
      .pipe(catchError((err) => this.handleError(err)))
      .subscribe((data: any) => {
        this.City = data.results;        
      });
  }
  else
  {
    this.SEService.getVSSCities()
    .pipe(catchError((err) => this.handleError(err)))
    .subscribe((data: any) => {
      this.City = data.results;        
    });

  }
}

selectedCities(city :any): void {
  if (this.selectedCity.length > 0) {
    var index_of_selected_option = this.selectedCity.indexOf(city.name);
    if (index_of_selected_option != -1) {
      this.selectedCity.splice(index_of_selected_option, 1)
    }
    else {
      this.selectedCity = this.selectedCity.concat(city.name);
    }
  }
  else {
    this.selectedCity = this.selectedCity.concat(city.name);
  }
}




  EditForm() {
    this.VendorSupervisorEditForm = this.fb.group({
      username: new FormControl('', Validators.compose([
        Validators.required,
        Validators.minLength(5),
        Validators.maxLength(100),
        Validators.pattern('^[A-Za-z0-9]+(?:[A-Za-z0-9]+)*$')
      ]),
        //UniqueUsernameValidator(this.VendorSupervisorEditForm) // async validator
      ),
      firstName: new FormControl('', Validators.compose([
        Validators.required,
        Validators.maxLength(250),
        Validators.pattern('^[A-Za-z]+(?:[A-Za-z]+)*$')
        //Validators.pattern('/^[a-zA-Z]+$/')
      ])),
     
      lastName: new FormControl('', Validators.compose([
        Validators.required,
        Validators.maxLength(250),
        Validators.pattern('^[A-Za-z]+(?:[A-Za-z]+)*$')
      ])),
      mobile: new FormControl('', Validators.compose([
        Validators.required,
        Validators.minLength(10),
        Validators.pattern('^[6-9][0-9]{9}$')
      ])),
      Amobile: new FormControl('', Validators.compose([
        Validators.minLength(10),
        Validators.pattern('^[6-9][0-9]{9}$')
      ])),
      email: new FormControl('', Validators.compose([
        Validators.required,
        Validators.maxLength(100),
        Validators.pattern('^([a-zA-Z0-9_\.\-])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})+$')
      ])),

      Aemail: new FormControl('', Validators.compose([
       // Validators.required,
        Validators.maxLength(100),
        Validators.pattern('^([a-zA-Z0-9_\.\-])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})+$')
      ])),
      Organization: new FormControl('', Validators.compose([
        Validators.required,
        Validators.maxLength(100),
        //Validators.pattern('^([a-zA-Z0-9_\.\-])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})+$')
      ])),
      
      Address: new FormControl('', Validators.compose([
        //Validators.required,
        Validators.maxLength(100),
        //Validators.pattern('^([a-zA-Z0-9_\.\-])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})+$')
      ])),
      PIN: new FormControl('', Validators.compose([
        Validators.required,
        Validators.maxLength(6),
        Validators.pattern('^[0-9]*$')
      ])),
      State: new FormControl('', Validators.compose([
       
       ])),
       StaterootLvl: new FormControl('', Validators.compose([
       
       ])),
      City: new FormControl([], Validators.compose([
       
       ])),
      VendorID: new FormControl('', Validators.compose([
        Validators.required
        
      ])),

     // reference: new FormControl({ disabled: true, value: '' },[Validators.required]),
     
    });
  }
  onSubmit(form: any) {
    this.userDetailsSubmitted = true;
  

    // if (!this.VendorSupervisorEditForm.valid) {
    //   return;
    // }

    if(this.mobileExists || this.emailExists || this.alternateemailExists || this.alternatemobileExists)
    {
      return;
    }
    const editUserFormData: any = Object.assign({}, this.VendorSupervisorEditForm.value);

    //let changed = false;
    let changed = true;

   
    //   if (editUserFormData.username !== this.userData.username) {
    //     this.userData.username = editUserFormData.username;
    //     changed = true;
    //   }
    //   if (editUserFormData.firstName !== this.userData.firstName) {
    //     this.userData.firstName = editUserFormData.firstName;
    //     changed = true;
    //   }
    //   if (editUserFormData.lastName !== this.userData.lastName) {
    //     this.userData.lastName = editUserFormData.lastName;
    //     changed = true;
    //   }
    //   if (editUserFormData.mobile !== this.userData.mobile) {
    //     this.userData.mobile = editUserFormData.mobile;
    //     changed = true;
    //   }
    //   if (editUserFormData.email !== this.userData.email) {
    //     this.userData.email = editUserFormData.email;
    //     changed = true;
    //   }
    //   if (editUserFormData.Organization !== this.userData.Organization) {
    //     this.userData.Organization = editUserFormData.userType;
    //     changed = true;
    //   }
    //   if (editUserFormData.Address !== this.userData.Address) {
    //     this.userData.Address = editUserFormData.Address;
    //     changed = true;
    //   }
    //   if (editUserFormData.VendorID !== this.userData.VendorID) {
    //     this.userData.VendorID = editUserFormData.VendorID;
    //     changed = true;
    //   }
    //   if (editUserFormData.PIN !== this.userData.PIN) {
    //     this.userData.PIN = editUserFormData.PIN;
    //     changed = true;
    //   }
    //   if (editUserFormData.Aemail !== this.userData.Aemail) {
    //     this.userData.Aemail = editUserFormData.Aemail;
    //     changed = true;
    //   }
    //   if (editUserFormData.Amobile !== this.userData.Amobile) {
    //     this.userData.Amobile = editUserFormData.Amobile;
    //     changed = true;
    //   }
    //   if (editUserFormData.state !== this.userData.State) {
    //     this.userData.State = editUserFormData.state;
    //     changed = true;
    //   }
    //   if (editUserFormData.city !== this.userData.City) {
    //     this.userData.City = editUserFormData.city;
    //     changed = true;
    //   }
    
    // if (editUserFormData.isactive !== this.userSoftDeleted) {
    //   this.userData.activeFlag = editUserFormData.isactive ? 'A' : 'I';
    //   changed = true;
    // }

       
    // update user data to database
    if (changed) {
      this.userService.updateUser(editUserFormData).subscribe(
        (data: any) => {
          this.openSnackBar(data.message, '');
          if(data.flag=="F"){
            this.showUserDetails=true;
            
          }
          else
          {
          this.showUserDetails = false;
          //this.resetForm();
        }
        },
        (err: any) => this.openSnackBar(err.error.message, ''),
        () => { }
      );
    }
  }

  resetForm() {
    //this.form.reset();
    this.form.control.controls['lastName'].reset();
    this.form.control.controls['username'].reset();
    this.form.control.controls['mobile'].reset();
    this.form.control.controls['firstName'].reset();
    this.form.control.controls['Address'].reset();
    this.form.control.controls['Aemail'].reset();
    this.form.control.controls['email'].reset();
    this.form.control.controls['Amobile'].reset();
    this.form.control.controls['Organization'].reset();
    this.form.control.controls['State'].reset();
    this.form.control.controls['City'].reset();
    this.form.control.controls['PIN'].reset();
    // clear validators and dont remove them
    this.VendorSupervisorEditForm.clearValidators();

    this.VendorSupervisorEditForm.markAsUntouched();
    this.VendorSupervisorEditForm.markAsPristine();
    this.mobileExists = false;
    this.emailExists = false;
    this.userService.getVS()
        .subscribe((data: any) => {
          if (data != null)
          {
             this.SetVSIdDetails(data.results);
          }
        },
        (err: any) => {
         this.openSnackBar(err.error.message, 'error');
        });
    
  }

  checkMobile() {
    if (this.VendorSupervisorEditForm.get('mobile').valid) {
      const mobile = this.VendorSupervisorEditForm.get('mobile').value;

      const searchType = 'mobile';

      this.VSSService.CheckMobileEmail(searchType, mobile).subscribe(
        (data: any) => {
          if (data.flag == applicationconstant.SuccessFlag) {
            this.mobileExists = false;
          } else {
            this.mobileExists = true;
          }
        },
        (err: any) => { },
      );
    }
  }

  
  checkEmail() {
    if (this.VendorSupervisorEditForm.get('email').valid) {
      const email = this.VendorSupervisorEditForm.get('email').value;

      const searchType = 'email';

      this.VSSService.CheckMobileEmail(searchType, email).subscribe(
        (data: any) => {
          if (data.flag == applicationconstant.SuccessFlag) {
            this.emailExists = false;
          } else {
            this.emailExists = true;
          }
        },
        (err: any) => { },
      );
    }
  }
  
  checkAMobile() {
    if (this.VendorSupervisorEditForm.get('Amobile').valid) {
      const mobile = this.VendorSupervisorEditForm.get('Amobile').value;

      const searchType = 'mobile';

      this.VSSService.CheckMobileEmail(searchType, mobile).subscribe(
        (data: any) => {
          if (data.flag == applicationconstant.SuccessFlag) {
            this.alternatemobileExists = false;
          } else {
            this.alternatemobileExists = true;
          }
        },
        (err: any) => { },
      );
    }
  }

  checkAlternateEmail() {
    if (this.VendorSupervisorEditForm.get('Aemail').valid) {
      const email = this.VendorSupervisorEditForm.get('Aemail').value;

      const searchType = 'email';

      this.VSSService.CheckMobileEmail(searchType, email).subscribe(
        (data: any) => {
          if (data.flag == applicationconstant.SuccessFlag) {
            this.alternateemailExists = false;
          } else {
            this.alternateemailExists = true;
          }
        },
        (err: any) => { },
      );
    }
  }



  public SetVSIdDetails(VSID: VendorSupervisor) {

    // if (VSID[0].city != null)
    //   {
    //     var cityarray = VSID[0].city.split(',');
    //     this.citylistSelected = [];
    //     cityarray.forEach(check => {
    //     this.citylistSelected.push(Number(check));
    //     });
    //   }


    if (VSID != null) {
      
      this.VendorSupervisorEditForm.patchValue({
        username: VSID[0].vendorID ,
        VendorID: VSID[0].vendorID ,
        firstName: VSID[0].firstName,
        lastName: VSID[0].lastName,
        mobile: VSID[0].mobile,
        email: VSID[0].email,
        Aemail: VSID[0].aEmail,
        Amobile: VSID[0].aMobile,
        Organization: VSID[0].organization,
        PIN: VSID[0].pin,
        //City:VSID[0].city,
        //State: VSID[0].state,
        Address: VSID[0].address,
       
       
      });
    }
    if (VSID[0].state != null)
    {
      this.statelistSelected  =  VSID[0].state.split(",");
    }
    if (VSID[0].city != null)
    {
      this.citylistSelected = VSID[0].city.split(",");
    }
    this.VendorSupervisorEditForm.controls['State'].setValue(this.statelistSelected);
    this.VendorSupervisorEditForm.controls['City'].setValue(this.citylistSelected);
    
    //var statename = this.VendorSupervisorEditForm.get('State').value;
    //this.FillCityDD(statename,true);
  }
  openSnackBar(message: string, action: string) {
    this.snackBar.open(message, action, {
      duration: 5000,
      verticalPosition: 'top'
    });
  }

  private handleError(error: any) {
    // this.errorReceived = true;
    return Observable.throw(error);
  }

}
