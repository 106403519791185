import { Component, OnInit, ViewChild,Input, ElementRef } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators, NgForm, Form, FormArray } from '@angular/forms';
import { MatDialog, MatSnackBar, MatDialogConfig } from '@angular/material';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { ConfigurationService } from 'src/app/Services/Utilities/configuration.service';
import { Constants } from 'src/app/Constants/constants';
import { efsrService } from 'src/app/Services/EFSR/efsrcreate.service';
import { efsrcreate, hpsmandsedetails,efsrDraft, CheckListSaveDto, patchefsrDraft, EFSRApproveReject } from 'src/app/Models/EFSR/efsrcreate.model';
import { Ivendor, Irokeypersonlist } from 'src/app/Models/common/vendor.model';
import { Iservicing } from 'src/app/Models/common/vendor.model';
import { Idevice } from 'src/app/Models/common/vendor.model';
//import { CheckListSaveDto } from 'src/app/Models/common/vendor.model';
import { TicketsAssginedSE } from 'src/app/Services/ServiceEngineer/TicketsAssignedSE.service';
import { VendorSupervisorService } from 'src/app/Services/vendorsupervisor/vendorsupervisor.service';
import { applicationconstant } from 'src/app/Models/DropdownItemsGroup/applicationconstant.model';
import { ConfirmationDeleteComponent } from 'src/app/Component/EFSR/Confirmation/confirmation-delete/confirmation-delete.component';
import { ConfirmationDialogDeleteComponent } from '../confirmation-dialog-delete/confirmation-dialog-delete.component';
import { Router } from '@angular/router';
import { UserService } from 'src/app/Services/ServiceEngineer/SECreate.service';
//import * as pdfMake from 'pdfmake/build/pdfmake';
//import * as pdfFonts from 'pdfmake/build/vfs_fonts';
//const pdf = pdfMake;
//pdf.vfs = pdfFonts.pdfMake.vfs;
//import 'rxjs/Rx' ;
import { SECreate } from 'src/app/Models/ServiceEngineer/SECreate.model';
// import { createPdf } from 'pdfmake/build/pdfmake';
import { documentList } from 'src/app/Models/ServiceEngineer/documentList';
import { FileDownload } from 'src/app/Models/common/fileDownload.model';
import { Ticket } from 'src/app/Models/vendorsupervisor/ticket.model';
import { SearchRODTO } from 'src/app/Models/common/search.model';
import { Icity } from 'src/app/Models/common/city.model';
import { RemoveFile } from 'src/app/Models/common/removeFile.model';
import { efsrDoc } from 'src/app/Models/EFSR/efsr.model';
//import{ SECreate} from 'src/app/Models/ServiceEngineer/SECreate.model';
@Component({
  selector: 'app-view-efsr',
  templateUrl: './view-efsr.component.html',
  styleUrls: ['./view-efsr.component.scss']
})
export class ViewEfsrComponent implements OnInit {
  //[x: string]: any;

  //public spare=[{name:"Yes"},{name:"No"}];
  public vendorList: Ivendor[];
  public serviceList: Iservicing[];
  public deviceList: Idevice[];
  public checklist: CheckListSaveDto[];
  public ratingcnt: number;
//public remove:RemoveFile;
efsrDoc: efsrDoc[]=[];
  // public imagePath;
  // imgURL: any;
  showRemark: boolean = false;
  showRatingRemark1:boolean = false;
  showQuantity: boolean = false;
  checklistSelected:number[];
  selected:number[];
  docId:number;
  createdBy:number;
////sEEMAIL:string;
  viewEFSRForm: FormGroup;
  //public spareremark: boolean;
  showRating:boolean=false;
  public ratingremark: boolean;
  showRatingRemark:boolean=false;
  attachmentFileName:FormGroup;
  //public ratingcnt: number;
  //startdate : Date;
  incidentId : string
  roId : number
  roCity:string;
  //roCityList:Ticket[];
  //incident:Ticket[]=[];
  ticketId: number
  isEfsrExists: boolean =false;
  //seId:number = 0;
ApproveFlag:number;
  isDocumentSectionVisiable: boolean = false;
  isFormSectionVisible: boolean = true;
  efsrId: number = 0;
  public roKeyPersonName:string;
  isEditForm: boolean = true;
  requestFromSupervisor : boolean;
  requestFromRO : boolean;
  poorratingcomments:string;
  poorratingcommentsbyRO:string;
  validationMessages = {
    'vendor': {
      'required': 'Type Of Vendor is required'
    },
    'rokeypersonmobile': {
      'required': 'RO Key Person Mobile is required.',
      'minlength': 'RO Key Person Mobile must be of 10 digits.',
      //'maxlength': 'Password can contain maximum 16 characters.',
      'pattern': 'RO Key Person Mobile should contain numeric value.',
      //'notSame': 'Passwords do not match.',
      //'Same': 'Old Password and New Password Cannot be same.'
    },
    'service': {
      'required': 'Type Of Servicing is required.'
    },
    'rokeyperson': {
      'required': 'RO Key Person is required.'
    },
    'device': {
      'required': 'Type Of Device is required.'
    },
    'checklistControl': {
      'required': 'Check List is required.'
    },
    'spare': {
      'required': 'Spares Consumed is required.'
    },
    'visitdate': {
      'required': 'Visit Date is required.'
    },
    'quantity': {
      'required': 'Quantity is required.',
      'pattern': 'Quantity is invalid.',
    },
    'servicesummary': {
      'required': 'Service Summary is required.'
    },
    'remark': {
      'required': 'Remark is required.'
    },
    'ratingSlider': {
      'required': 'Rating is required.'
    },
     'poorratingcomments': {
      'required': 'Please Provide Comment.'
     },
    'poorratingcommentsbyRO': {
      'required': 'Please Provide Comment.'
    },
    'ratingSlider1': {
      'required': 'Rating is required.'
    },
  };

  formErrors = {
    'vendor': '',
    //'rokeypersonmobile': '',
    'service': '',
    //'rokeyperson': '',
    'device': '',
    'checklistControl': '',
    'spare': '',
    'visitdate': '',
    'quantity': '',
    'servicesummary': '',
    'remark': '',
    'ratingSlider': '',
    'ratingSlider1': '',
    'poorratingcomments': '',
    'poorratingcommentsbyRO': ''
  };
  //@ViewChild('View') content:ElementRef;

  constructor(private fb: FormBuilder,
    public snackBar: MatSnackBar,
    public dialog: MatDialog,
    private efsrservice: efsrService,
    private router: Router,
    private configurationService: ConfigurationService,private EngineerService: TicketsAssginedSE,private VendorService: VendorSupervisorService, private SEservice:UserService) 
    { 
      this.viewEFSRForm = this.fb.group({
      });
  
      this.viewEFSRForm = this.createFormGroupWithBuilderAndModel();
      // this.vendorDisabledMap = new Map();
      // this.serviceDisabledMap = new Map();
      // this.deviceDisabledMap=new Map();
      // this.checklistDisabledMap=new Map();
      // this.roketpersonlistDisabledMap=new Map();
      //this.spareremark = false;
      //this.seId=0;
      this.ratingremark=false;
      this.ratingcnt=0;

    }

  ngOnInit() {
    //this.isFormSectionVisible =  this.efsrservice.getIsFormSectionVisibleFromROEfsr();
    //this.isEfsrExists =  this.EngineerService.efsrExists;
    this.efsrId = this.EngineerService.getEFSRId();
    //const seDetails= new SECreate();
    //this.seId=Number(this.viewEFSRForm.get('sEId').value);
 // this.seId=this.efsrservice.getEFSRDetails(this.efsrId);
    this.ticketId = this.VendorService.getTicketId();
    this.incidentId =  this.VendorService.getTicketInteractionId();
    this.requestFromSupervisor = this.VendorService.getRequestFromVendor();
    this.requestFromRO = this.VendorService.getRequestFromRO();
   
    this.roId = this.EngineerService.getROId();
    //this.roId = this.EngineerService.getROId();
    
  

    //this.startdate=new Date();
    //this.startdate.setDate(this.startdate.getDate() - 0);


    this.efsrservice.getTypeOfVendor()
    .pipe(catchError((err) => this.handleError(err)))
    .subscribe((data: any) => {
      this.vendorList = data.results;
      this.efsrservice.getTypeOfService()
        .pipe(catchError((err) => this.handleError(err)))
        .subscribe((data: any) => {
            this.serviceList = data.results;
            this.efsrservice.getTypeOfDevice()
              .pipe(catchError((err) => this.handleError(err)))
              .subscribe((data: any) => {
                  this.deviceList = data.results;  
                  this.efsrservice.getTypeOfChcekList()
                    .pipe(catchError((err) => this.handleError(err)))
                    .subscribe((data: any) => {
                        this.checklist = data.results;  
                        this.efsrservice.getEFSRDetails(this.efsrId)
                          .subscribe(
                            (data: any) => {
                              if (data.flag === Constants.SUCCESS_FLAG) 
                              {
                                if (data.results[0].checklist != null)
                                {
                                  this.checklistSelected = [];
                                  data.results[0].checklist.forEach(check => {
                                   this.checklistSelected.push(Number(check));
                                 });
                                }
                                  //this.selected = [11];
                                  this.setFormDraftValues(data.results);
                                  
                                  //data.results=this.PdfDownload("pdf","");
                              } 
                            
                              else 
                              {
                                this.openSnackBar(data.message, '');
                                this.resetForm();
                              }
                            },
                            (err: any) => this.openSnackBar(err.error.message, ''),
                            () => { }
                          );
              });
          });        
      });        
    });

    // this.efsrservice.getROKeyPersonList()
    // .pipe(catchError((err) => this.handleError(err)))
    // .subscribe((data: any) => {
    //   this.rokeyperson = data.results;        
    // });
      this.viewEFSRForm.valueChanges.subscribe((data) => {
          this.logValidationErrors(this.viewEFSRForm);
        });

    
  
  }


  createFormGroupWithBuilderAndModel() {
    return this.fb.group({
      roname: new FormControl('', Validators.compose([
      ]),
      ),
      rocode: new FormControl('', Validators.compose([
      ]),
      ),
      rocity: new FormControl('', Validators.compose([
      ])),
      roaddress: new FormControl('', Validators.compose([
      ])),
      romobile: new FormControl('', Validators.compose([
      ])),
      rokeypersonmobile: new FormControl('', Validators.compose([
      ])),
      reference: new FormControl('', Validators.compose([
      ])),
      hpsmid: new FormControl('', Validators.compose([
      ])),
      callstatus: new FormControl('', Validators.compose([
      ])),
      slainhand: new FormControl('', Validators.compose([
      ])),
      visitdate: new FormControl('', Validators.compose([
      ])),
      vendor: new FormControl('', Validators.compose([
      ])),
      service: new FormControl('', Validators.compose([
      ])),
      rokeyperson: new FormControl('', Validators.compose([
      ])),
      device: new FormControl('', Validators.compose([
      ])),
      checklistControl: new FormControl('', Validators.compose([
      ])),
      spare: new FormControl('', Validators.compose([
      ])),
      remark: new FormControl('', Validators.compose([
      ])),
      servicesummary: new FormControl('', Validators.compose([
      ])),
      poorratingcomments: new FormControl('', Validators.compose([
        Validators.required  
      ])),
      poorratingcommentsbyRO: new FormControl('', Validators.compose([
        Validators.required  
      ])),
      quantity: new FormControl('', Validators.compose([
      ])),
      semobile: new FormControl('', Validators.compose([
      ])),

     sename: new FormControl('', Validators.compose([
      ])),
     // seemail: new FormControl('', Validators.compose([
     // ])),
      ratingSlider: new FormControl('', Validators.compose([
        Validators.required
      ])),
      ratingSlider1: new FormControl('', Validators.compose([
        Validators.required
      ])),
      commentsbyRO: new FormControl('',Validators.compose([

      ])),
      
      RejectFlag:new FormControl('',Validators.compose([

      ])), 

      createdBy:new FormControl('',Validators.compose([

      ])), 

      //sEId: new FormControl('', Validators.compose([
      // Validators.required
      //])),
     
    });
  }

  setFormDraftValues(details: patchefsrDraft) {
    const vendorDisplay = this.vendorList.find(ven => ven.id === Number(details[0].typeOfVendor));
    const serviceDisplay = this.serviceList.find(ven => ven.id === Number(details[0].typeOfServicing));
    const deviceDispaly = this.deviceList.find(ven => ven.id === Number(details[0].typeOfDevice));
    const visitDate = new Date(details[0].visitDate).toLocaleDateString();
    //const seId= details1[0].seId;
    //const sEEMAIL= details1[0].sEEMAIL;
    //const myFormattedDate = this.visitDate.transform(visitDate, 'short');
    this.viewEFSRForm.patchValue({
        roname: details[0].roName,
        rocode:details[0].roCode,
        rocity: details[0].roCity,
        roaddress: details[0].roAddress,
        romobile: details[0].roContactNo,
        rokeypersonmobile: details[0].roKeyPersonMobileNo,
        reference: details[0].reference,
        hpsmid: details[0].hpsmId,
        callstatus: details[0].callStatus,
        slainhand: details[0].slaInHand,
       //sEId: Number(details[0].seId),
        visitdate: visitDate,
        //vendor: Number(details[0].typeOfVendor),
       // array.find(i => i.id === 1);
        vendor: vendorDisplay.name,
        //service: Number(details[0].typeOfServicing),
        service: serviceDisplay.name,
        rokeyperson: details[0].roKeyPersonName,
        device: deviceDispaly.name,
       // checklistControl: this.checklistSelected,
        spare: details[0].sparesConsumedFlag == 1? "Yes" :"No",
        remark: details[0].remarkOfSparesConsumed,
        servicesummary: details[0].serviceSummary,
        poorratingcomments: details[0].ratingComments==null?"NA":details[0].ratingComments,
        poorratingcommentsbyRO: details[0].ratingCommentsByRO,
        quantity: details[0].quantity,
        semobile: details[0].semobile,
        sename: details[0].sename,
        //efsrstatus:details[0].callStatus,
        commentsbyRO:details[0].rejectReason,
        ApprovedFlag: details[0].approvedFlag,
        RejectFlag: details[0].rejectFlag==null?details[0].approvedFlag:details[0].rejectFlag,
        //seemail:details[0].seemail,
        ratingSlider: details[0].rating,
        createdBy:details[0].createdBy,
        ratingSlider1: details[0].ratingByRO,
        
        //reference: details[0].reference,
        //visitdate: details[0].visitDate,
      
        //checklist: this.selectedchecklist
    });
    const currentUser = JSON.parse(localStorage.getItem('currentUser'));
    if (currentUser.Role == "VSROLE")
    {
     
     //this.poorratingcomments = this.viewEFSRForm.get('poorratingcommentsbyRO').value ;
     //this.ratingcnt= this.viewEFSRForm.get('ratingSlider').value;
     this.showRatingRemark=true;
     const efsrDetails = Object.assign({}, this.viewEFSRForm.value);
    var draftEfsr = new patchefsrDraft();
    draftEfsr.rating= efsrDetails.ratingSlider;
    if(efsrDetails.ratingSlider==1){

      this.viewEFSRForm.controls['ratingSlider'].setValue("Very poor");
    }

    else if(efsrDetails.ratingSlider ==2)
    {
      this.viewEFSRForm.controls['ratingSlider'].setValue("Poor");
    }
    else if(efsrDetails.ratingSlider ==3)
    {
      this.viewEFSRForm.controls['ratingSlider'].setValue("Satisfactory");
    }
    else if(efsrDetails.ratingSlider == 4)
    {
      this.viewEFSRForm.controls['ratingSlider'].setValue("Good");
    }
    else if(efsrDetails.ratingSlider ==5)
    {
      this.viewEFSRForm.controls['ratingSlider'].setValue("Excellent");
    }

  //  return slidervalue;
 // }

    draftEfsr.ratingComments =efsrDetails.poorratingcomments;
    if(efsrDetails.poorratingcomments==null){

      this.viewEFSRForm.controls['poorratingcomments'].disable();
    }
    else{
      this.viewEFSRForm.controls['poorratingcomments'].enable();
      this.viewEFSRForm.controls["poorratingcomments"].updateValueAndValidity();
    }
     
     //this.remarksFromVS=String(this.vendorservice.Remark);
    }

    else if(currentUser.Role=="HOROLE"){
      this.showRatingRemark1=true;
      const efsrDetails = Object.assign({}, this.viewEFSRForm.value);
      var draftEfsr = new patchefsrDraft();
      draftEfsr.rating= efsrDetails.ratingSlider;
  draftEfsr.ratingByRO=efsrDetails.ratingSlider1;

      if(efsrDetails.ratingSlider==1){

        this.viewEFSRForm.controls['ratingSlider'].setValue("Very poor");
        //this.viewEFSRForm.controls['ratingSlider1'].setValue("Very poor");
      }
  
      else if(efsrDetails.ratingSlider ==2)
      {
        this.viewEFSRForm.controls['ratingSlider'].setValue("Poor");
       // this.viewEFSRForm.controls['ratingSlider1'].setValue("Poor");
      }
      else if(efsrDetails.ratingSlider ==3)
      {
        this.viewEFSRForm.controls['ratingSlider'].setValue("Satisfactory");
        //this.viewEFSRForm.controls['ratingSlider1'].setValue("Satisfactory");
      }
      else if(efsrDetails.ratingSlider == 4)
      {
        this.viewEFSRForm.controls['ratingSlider'].setValue("Good");
        //this.viewEFSRForm.controls['ratingSlider1'].setValue("Good");
      }
      else if(efsrDetails.ratingSlider ==5)
      //this.viewEFSRForm.controls['ratingSlider1'].setValue("Very poor");
      {
        this.viewEFSRForm.controls['ratingSlider'].setValue("Excellent");
        //this.viewEFSRForm.controls['ratingSlider1'].setValue("Excellent");
      }

   if(efsrDetails.ratingSlider1==1){

        //this.viewEFSRForm.controls['ratingSlider'].setValue("Very poor");
        this.viewEFSRForm.controls['ratingSlider1'].setValue("Very poor");
      }
  
      else if(efsrDetails.ratingSlider1 ==2)
      {
        //this.viewEFSRForm.controls['ratingSlider'].setValue("Poor");
        this.viewEFSRForm.controls['ratingSlider1'].setValue("Poor");
      }
      else if(efsrDetails.ratingSlider1 ==3)
      {
        //this.viewEFSRForm.controls['ratingSlider'].setValue("Satisfactory");
        this.viewEFSRForm.controls['ratingSlider1'].setValue("Satisfactory");
      }
      else if(efsrDetails.ratingSlider1 == 4)
      {
        //this.viewEFSRForm.controls['ratingSlider'].setValue("Good");
        this.viewEFSRForm.controls['ratingSlider1'].setValue("Good");
      }
      else if(efsrDetails.ratingSlider1 ==5)
      //this.viewEFSRForm.controls['ratingSlider1'].setValue("Very poor");
      {
        //this.viewEFSRForm.controls['ratingSlider'].setValue("Excellent");
        this.viewEFSRForm.controls['ratingSlider1'].setValue("Excellent");
      }
      
  
    //  return slidervalue;
   // }
  
      draftEfsr.ratingComments =efsrDetails.poorratingcomments;
      draftEfsr.ratingCommentsByRO= efsrDetails.poorratingcommentsbyRO;
      if(efsrDetails.poorratingcomments==null ){
  
        this.viewEFSRForm.controls['poorratingcomments'].disable();
      }
      else{
        this.viewEFSRForm.controls['poorratingcomments'].enable();
        this.viewEFSRForm.controls["poorratingcomments"].updateValueAndValidity();
      }
      if(efsrDetails.poorratingcommentsbyRO==null ){
  
        this.viewEFSRForm.controls['poorratingcommentsbyRO'].disable();
      }
      else{
        this.viewEFSRForm.controls['poorratingcommentsbyRO'].enable();
        this.viewEFSRForm.controls["poorratingcommentsbyRO"].updateValueAndValidity();
      }
    }  

      

    this.viewEFSRForm.controls['checklistControl'].setValue(this.checklistSelected);
    this.createdBy=details[0].createdBy;
    this.viewEFSRForm.controls['createdBy'].setValue(this.createdBy);
this.roKeyPersonName= details[0].roKeyPersonName;
this.roCity=details[0].roCity;
this.viewEFSRForm.controls['rocity'].setValue(this.roCity);
this.viewEFSRForm.controls['rokeyperson'].setValue(this.roKeyPersonName);
this.ApproveFlag= this.viewEFSRForm.get('RejectFlag').value;
if(details[0].rejectFlag==null){

  this.viewEFSRForm.controls['commentsbyRO'].setValue(details[0].rejectReason);
}

else{

  this.viewEFSRForm.controls['commentsbyRO'].setValue(details[0].rejectReason);
}
this.viewEFSRForm.controls['checklistControl'].setValue(this.checklistSelected);

//this.seId = Number(details[0].seId);
//this.viewEFSRForm.controls['seemail'].setValue(this.seemail);
//this.viewEFSRForm.controls['sEId'].setValue(this.seId);
    if (details[0].sparesConsumedFlag == 1)
    {
      this.showQuantity = true;
      this.showRemark = true;
    }
   // if (details[0].rating != null || details[0].poorratingcomments!=null)
   // {
      //this.showRating = true;
      //this.showRatingRemark=true;
      //this.ratingremark= this.viewEFSRForm.get('poorratingcomments').value;
      //this.ratingcnt=this.viewEFSRForm.get('ratingSlider').value;
   // }
    
    // this.viewEFSRForm.get('ratingSlider').enable();
    // this.ratingcnt = details[0].rating;
    // this.viewEFSRForm.get('ratingSlider').disable();
  //  if (details[0].checklist != null)
  //  {
  //   details[0].checklist.forEach(check => {
  //     this.selectedchecklist.push(Number(check));
  //   });
  //   this.viewEFSRForm.controls['checklistControl'].setValue(this.selectedchecklist);
  //  }
    //this.selectedchecklist = details[0].checklist;
    
  }

  onInputChange(value:number) {
    value=Number(this.ratingcnt);
    //rating1=String(this.ratingcnt);
    //this.ratingcnt=maxvalue;
      if(value<=2)
      {
       
      //  this.formatLabel(slidervalue);
       // this.ticksData01=[];
      this.ratingremark=true;
       // this.createEFSRForm.controls['ratingSlider'].valid;
       // this.createEFSRForm.controls["ratingSlider"].value.selected._multiple=false;
      this.viewEFSRForm.controls['poorratingcommentsbyRO'].setValidators([Validators.required]);
        this.viewEFSRForm.controls['poorratingcommentsbyRO'].enable();
           this.viewEFSRForm.controls["poorratingcommentsbyRO"].updateValueAndValidity();
      }
   
      else {
       this.ratingremark=false;
       // this.createEFSRForm.controls['ratingSlider'].valid;
      //  this.createEFSRForm.controls["ratingSlider"].value.selected._multiple=false;
        this.viewEFSRForm.controls['poorratingcommentsbyRO'].clearValidators();
        this.viewEFSRForm.controls['poorratingcommentsbyRO'].reset();
        this.viewEFSRForm.controls['poorratingcommentsbyRO'].disable();
      }
    }

   onSubmit() {
    
    
    const dialogRef = this.dialog.open(ConfirmationDeleteComponent, {
          width: '500px',
          data: {efsrId : this.efsrId,ratingcnt:this.ratingcnt,ratingremark: this.ratingremark,  roKeyPersonName: this.roKeyPersonName, incidentId:this.incidentId} //sEEMAIL:this.sEEMAIL}
        });
        dialogRef.afterClosed().subscribe(result => {
          if (result) {
            this.router.navigate(['RO/incidentsearch']);
          }
          
        });
        
      
    
      
    //const efsrDetails = this.viewEFSRForm.value;
   // var approveRejectEFSR = new EFSRApproveReject();
    //approveRejectEFSR.efsrId = this.efsrId;
    //approveRejectEFSR.ratingByRO = this.ratingcnt;
    //approveRejectEFSR.ratingCommentsByRO = efsrDetails.poorratingcomments;
    //approveRejectEFSR.rejectReason = null
    //approveRejectEFSR.approvedFlag =1;
    //approveRejectEFSR.rejectFlag = 0;

    //this.efsrservice.approveRejectefsr(approveRejectEFSR).subscribe((data: any) => {
     //   if (data.flag === 'S') {
            //this.router.navigate(['RO/incidentsearch']);
            //this.openSnackBar(data.message, '');
      //  } else {
            // this.openSnackBar(data.message, '');
           
       // }
   // },
       // (err: any) => {
         // this.openSnackBar(err.errormessage, '');
           
       // });
   // }

 
}

 public PdfDownload(){

  this.efsrservice.setRequestFromROEfsr(true);
  this.isFormSectionVisible = true;
  this.isDocumentSectionVisiable=false;
 //const currentUser = JSON.parse(localStorage.getItem('currentUser'));
  this.efsrId= this.EngineerService.getEFSRId();
  //this.createdBy=currentUser.UserId;
  //this.createdBy=317;
  this.efsrservice.getEFSRAllReports(applicationconstant.EFSRForm, this.efsrId)
  .subscribe((data:any)=>{
    if (data.flag == "S") {
    data.results.forEach(x => {     
      if(x.isSuccess==true){       
      this.efsrDoc.filter((val) => val.id == x.fileType)[0].docName = x.fileName;
      this.efsrDoc.filter((val) => val.id == x.fileType)[0].file = x.fileStream;
      this.efsrDoc.filter((val) => val.id == x.fileType)[0].docType = x.fileType;
      //this.efsrDoc.filter((val) => val.documentId == x.fileType)[0].isUploaded = true;
      this.efsrDoc.filter((val) => val.id == x.fileType)[0].docPath = x.filePath;
      }
    });
  }
  if (data.returnId == 0) {
    var removeFile = new RemoveFile();
      removeFile.docId = this.efsrId;
      removeFile.docType = data.results[0].docType;
                //this.isAlldocumentsUploaded = true;
                this.efsrservice.downloadEFSR(removeFile).subscribe(result => {
                  if (window.navigator.msSaveOrOpenBlob) {
                    window.navigator.msSaveOrOpenBlob(result);
                  } else {
                    var url = window.URL.createObjectURL(result);
              
                    let link = document.createElement('a');
                    link.setAttribute('type', 'hidden');
                    link.href = url;
                    link.download = data.results[0].docName;
                    document.body.appendChild(link);
                    link.click();
                    link.remove();
                  }
                  this.openSnackBar('Downloading EFSR...','');
                  //this.router.navigate['/incidentsearch'];
                }
                );
  }
               else {
                this.openSnackBar('No Document Found','');
               }
              })
 }
  
//this.efsrDoc= data.results;


  //this.efsrservice.downloadDoc(this.remove);

  //this.VehicleId = this.vehicleService.getVehicleId();
  
 
//   this.efsrservice.getEFSRDetailsForReport(this.efsrId)
//     .subscribe((data1) => {
//        this.docId= data1.docId;
//        this.attachmentFileName=data1;
//     });

//   var efsr = new FileDownload();
      
      
//       efsr.trackingId=this.efsrId;
//       efsr.fileGroupName = fileGroupName;
//       efsr.docId = 561;
//       efsr.fileName="561_EFSR_REPORT.pdf";
//       efsr.fileType=fileType;
//       var efsrDetails= new patchefsrDraft();
//       efsrDetails.efsrId=this.efsrId;
//       this.efsrservice.getSEAllDocs(fileGroupName,561,this.efsrId).subscribe(result => {
//         if (window.navigator.msSaveOrOpenBlob) {
//           window.navigator.msSaveOrOpenBlob(result, efsr.fileName);
//         } else {
//           var url = window.URL.createObjectURL(result);
  
//           let link = document.createElement('a');
//           link.setAttribute('type', 'hidden');
//           link.href = url;
//           link.download = efsr.fileName;
//           document.body.appendChild(link);
//           link.click();
//           link.remove();
//         }
//         //this.resetform();
//       });   
    

    
//     }

  // const efsr = new FileDownload();
  // efsr.filePath=
  // efsr.fileName=this.attachmentFileName;
  // efsr.fileType=this.attachmentFileName.split('.').pop();
  

//let fileName = this.attachmentFileName;

  //file type extension

//   let checkFileType =  efsr.fileType;

//   var fileType;

//   // //if(checkFileType == ".txt")

//   // {

//   //   fileType = "text/plain";

//   // }

//   if(checkFileType == ".pdf")

//   {

//     fileType = "application/pdf";

//   }

//   // if(checkFileType == ".doc")

//   // {

//   //   fileType = "application/vnd.ms-word";

//   // }

//   // if(checkFileType == ".docx")

//   // {

//   //   fileType = "application/vnd.ms-word";

//   // }

//   // if(checkFileType == ".xls")

//   // {

//   //   fileType = "application/vnd.ms-excel";

//   // }

//   // if(checkFileType == ".png")

//   // {

//   //   fileType = "image/png";

//   // }

//   // if(checkFileType == ".jpg")

//   // {

//   //   fileType = "image/jpeg";

//   // }

//   // if(checkFileType == ".jpeg")

//   // {

//   //   fileType = "image/jpeg";

//   // }

//   // if(checkFileType == ".gif")

//   // {

//   //   fileType = "image/gif";

//   // }

//   // if(checkFileType == ".csv")

//   // {

//   //   fileType = "text/csv";

//   // }

//   this.efsrservice.downloadFile(efsr)

//   .subscribe(

//             success => {

//               saveAs(success, efsr.fileName); 

//             },

//             err => {

//                 alert("Server error while downloading file.");

//             }

//         );

// }

 hasToBeChecked(op: CheckListSaveDto): boolean {
  return this.checklist.indexOf(op) > -1;
}

  openSnackBar(message: string, action: string) {
    this.snackBar.open(message, action, {
      duration: 5000,
      verticalPosition: 'top'
    });
  }
  private handleError(error: any) {
    return Observable.throw(error);
  }

  logValidationErrors(group: FormGroup = this.viewEFSRForm): void {
    Object.keys(group.controls).forEach((key: string) => {
      const abstractControl = group.get(key);
      if (abstractControl instanceof FormGroup) {
        this.logValidationErrors(abstractControl)
      } else {
        this.formErrors[key] = '';
        if (abstractControl && !abstractControl.valid && (abstractControl.touched || abstractControl.dirty)) {
          const messages = this.validationMessages[key];

          for (const errorKey in abstractControl.errors) {
            if (errorKey) {
              this.formErrors[key] += messages[errorKey] + '';
            }
          }
        }
      }
    });
  }

  ValidateAllFields(formGroup: FormGroup) {
    Object.keys(formGroup.controls).forEach(field => {
      const control = formGroup.get(field);
      if (control instanceof FormControl) {
        control.markAsTouched({ onlySelf: true });
      } else if (control instanceof FormGroup) {
        this.ValidateAllFields(control);
      }
    });
  }

 public back() {
    if(this.requestFromSupervisor)
    {
      this.VendorService.setRequestFromVendor(false);
      this.router.navigate(['supervisor/ticketsearch']);
    }
    else if(this.requestFromRO==true)
    {
      this.VendorService.setRequestFromRO(false);
      this.router.navigate(['RO/incidentsearch']);
    }
    else{
      //this.vendorservice.setRequestFromVendor(false);
      this.router.navigate(['serviceengineer/incidentsearch']);
    }


  }
  backToList()
  {
    this.isFormSectionVisible = true;
    this.isDocumentSectionVisiable = false;
  }
  resetForm() {
    this.viewEFSRForm.reset();
    for (const i in this.viewEFSRForm.controls) {
      if (this.viewEFSRForm.controls[i]) {
        this.viewEFSRForm.controls[i].setErrors(null);
      }
    }
    // this.spareremark = false;
    // this.ratingremark=false;
    // this.ratingcnt=0;
    }

    

    ViewDocument() {
      this.efsrservice.setRequestFromROEfsr(true);
      this.isFormSectionVisible = false;
      //this.VehicleId = this.vehicleService.getVehicleId();
      this.isDocumentSectionVisiable = true;
    }
    RejectEFSR() {


      const dialogRef = this.dialog.open(ConfirmationDialogDeleteComponent, {
        width: '350px',
        data:{ efsrId:this.efsrId, roKeyPersonName: this.roKeyPersonName, incidentId:this.incidentId}
      });
      dialogRef.afterClosed().subscribe(result => {
        if (result) {
          this.router.navigate(['RO/incidentsearch']);
        }
      });
   
  }
  ngOnDestroy() {
    this.requestFromSupervisor = false;
  }
}
