export const environment = {
  production: true,
  //siteKeyCaptcha: '6LdG9ZIUAAAAAKRJv-OyyEgO3gKoo7dsK8E4Xfnk',
  siteKeyCaptcha: '6LenhNMUAAAAABb3P17QFJGmlHHxvGOC363ysNha',
  // prod
  // restUrl: 'http://10.131.81.199/RIMAP_SERVICE',
  // socketUrl: 'http://10.131.81.199/RIMAP',

  restUrl: 'https://rimap.ril.com/RIMAP_SERVICE/',
  socketUrl: 'https://rimap.ril.com/RIMAP/',
  // googleMapsApiKey: 'AIzaSyCFFkCxzPZcYaN4AquxdydmR9pHvftHRq4'
  googleMapsApiKey: 'AIzaSyDlmJcgSeJ1SYx5XrxjVdNXg2fKtFKWQEw',
  googleMapsApiClient: 'gme-reliancecorporate2'
};
