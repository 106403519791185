import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { ErrorService } from 'src/app/Services/error/error.service';
import { MenuItemService } from 'src/app/Services/Utilities/menuitems.service'

@Injectable({
    providedIn: 'root'
})
export class AuthGuard implements CanActivate {

    constructor(private router: Router, private errorService: ErrorService,
        private menuItemService: MenuItemService) {
    }

    canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
        if (localStorage.getItem('userToken') != null) {
            if (next && next.routeConfig && next.routeConfig.path && !this.menuItemService.SearchMenuItems(next.routeConfig.path)) {
                // role not authorised so redirect to Access denied page
                var error = {
                    message: 'User is not authorize to access this page',
                    status: 403
                }
                this.errorService.handleError(error);
                return false;
            }
            return true;
        } else {
            //user not authenticated
            this.router.navigate(['/login']);
            return false;
        }
    }
}
