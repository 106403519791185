import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { map, catchError } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class HttpVerbService {

  constructor(private http: HttpClient) {
  }

  /**
   * Http Get Verb Action, other services calls this action to get some data from web api
   *
   * @param {string} url
   * @param {*} [params]
   * @returns {Observable<HttpResponse<any>>}
   * @memberof HttpVerbService
   */
  get(url: string, params?: any): Observable<HttpResponse<any>> {
    const options = {};
    this.setHeaders(options);

    return this.http.get(url, options)
      .pipe(
        map((res: HttpResponse<any>) => {
          return res;
        }),
        catchError(this.handleError)
      );
  }

  /**
   * Post with id of http service
   *
   * @param {string} url
   * @param {*} data
   * @param {*} [params]
   * @returns {Observable<HttpResponse<any>>}
   * @memberof HttpVerbService
   */
  postWithId(url: string, data: any, params?: any): Observable<HttpResponse<any>> {
    return this.doPost(url, data, params);
  }


  /**
   * Post verb of http
   *
   * @param {string} url
   * @param {*} data
   * @param {*} [params]
   * @returns {Observable<HttpResponse<any>>}
   * @memberof HttpVerbService
   */
  post(url: string, data: any, params?: any): Observable<HttpResponse<any>> {
    return this.doPost(url, data, params);
  }


  /**
   * Put verb of http
   *
   * @param {string} url
   * @param {*} data
   * @param {*} [params]
   * @returns {Observable<HttpResponse<any>>}
   * @memberof HttpVerbService
   */
  putWithId(url: string, data: any, params?: any): Observable<HttpResponse<any>> {
    return this.doPut(url, data, params);
  }

  /**
   * Implementation of Post
   *
   * @private
   * @param {string} url
   * @param {*} data
   * @param {*} [params]
   * @returns {Observable<HttpResponse<any>>}
   * @memberof HttpVerbService
   */
  private doPost(url: string, data: any, params?: any): Observable<HttpResponse<any>> {
    const options = {};
    this.setHeaders(options);

    return this.http.post(url, data, options)
      .pipe(
        map((res: HttpResponse<any>) => {
          return res;
        }),
        catchError(this.handleError)
      );
  }

  /**
   * Delete verb of http
   *
   * @param {string} url
   * @param {*} [params]
   * @memberof HttpVerbService
   */
  delete(url: string, params?: any) {
    const options = {};
    this.setHeaders(options);

    console.log('httpverb.service deleting');

    this.http.delete(url, options)
      .subscribe((res) => {
        console.log('deleted');
      });
  }

  /**
   * Doing Put operation
   *
   * @private
   * @param {string} url
   * @param {*} data
   * @param {*} [params]
   * @returns {Observable<HttpResponse<any>>}
   * @memberof HttpVerbService
   */
  private doPut(url: string, data: any, params?: any): Observable<HttpResponse<any>> {
    const options = {};
    this.setHeaders(options);

    return this.http.put(url, data, options)
      .pipe(
        map((res: HttpResponse<any>) => {
          return res;
        }),
        catchError(this.handleError)
      );
  }

  private handleError(error: any) {
    if (error.error instanceof ErrorEvent) {
      // A client-side or network error occurred. Handle it accordingly.
      console.error('Client side network error occurred:', error.error.message);
    } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong,
      console.error('Backend - ' +
        `status: ${error.status}, ` +
        `statusText: ${error.statusText}, ` +
        `message: ${error.error.message}`);
    }

    // return an observable with a user-facing error message
    return throwError(error || 'server error');
  }

  private setHeaders(options: any) {
    options['headers'] = new HttpHeaders()
      .append('Content-Type', 'application/json')
      .append('X-Frame-Options', 'DENY')
      .append('Accept', 'application/json');
  }
}
