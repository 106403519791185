import { Injectable } from "@angular/core";

/**
 * Asset service for asset management
 *
 * @export
 * @class AssetService
 */
@Injectable({
  providedIn: 'root'
})
export class HeaderService {

  visible: boolean;
  userName: string;
  constructor() {
    this.visible = true;
  }

  /**
   * Hide Menu bar
   */
  public hide() {
    this.visible = false;
  }

  /**
   * Show Menu bar
   */
  public show(userName: string) {
    this.visible = true;
    this.userName = userName;
  }

  /**
   * Toggele Menu bar
   */
  public toggle() {
    this.visible = !this.visible;
  }
}
