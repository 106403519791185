import { Component, OnInit,ChangeDetectorRef, ViewChild } from '@angular/core';
import { FormGroup, FormControl, Validators, FormBuilder, ValidatorFn, AbstractControl } from '@angular/forms';
import { MatDialog, MatSnackBar } from '@angular/material';
import { seratingService } from 'src/app/Services/Reports/serating.service';
import { ISearchPara } from 'src/app/Models/Reports/serating.model';
import { serating } from 'src/app/Models/Reports/serating.model';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { UniqueUsernameValidator } from 'src/app/shared/unique-username-validator.directive';
import { ConfigurationService } from 'src/app/Services/Utilities/configuration.service';
import { Constants } from 'src/app/Constants/constants';
import { applicationconstant } from 'src/app/Models/DropdownItemsGroup/applicationconstant.model';


@Component({
  selector: 'app-serating',
  templateUrl: './serating.component.html',
  styleUrls: ['./serating.component.scss']
})
export class SeratingComponent implements OnInit {

  public btnSearch:string= 'Hide';
  public btnExport:string='Hide';
  seratingList: serating[];

  Priority = ['Critical', 'High', 'Medium','Low'];
  
  public SERatingForm: FormGroup;
  isFormSectionVisible: boolean = true;

  constructor(private seratingHistory: seratingService,
    private fb: FormBuilder,
    public snackBar: MatSnackBar,
    private configurationService: ConfigurationService,
    private cdr:ChangeDetectorRef) { 
      this.createForm();
  }

  createForm() {
    this.SERatingForm = this.fb.group({
      Priority: new FormControl('', Validators.compose([
        //Validators.required,
      ])),
      
    });
  }
  ResetSearch()
  {
    this.SERatingForm.reset();
    this.onSearch();
  }
  public account_validation_messages = {
    'Priority': [
      { type: 'required', message: 'status is required' },
    ],
  };

  ngOnInit() {
      this.onSearch();
  }

  onSearch(){
    const RoFormData: any = Object.assign({}, this.SERatingForm.value);
    let changed = true;
    const userData=new ISearchPara();
    userData.priority=RoFormData.Priority;
    if (changed) {
      this.seratingHistory.searchData(userData).subscribe(
        (data: any) => {
          
          if (data.flag == applicationconstant.SuccessFlag) {
            this.seratingList = data.results;
            this.openSnackBar('Data found and displayed','');
            this.btnSearch='Hide';
            this.btnExport='Show';
          }
          else {
            this.openSnackBar('Data not found for selected criteria','');
            this.seratingList = [];
            this.btnSearch='Show';
            this.btnExport='Hide';
          }
        },
        (err: any) => this.openSnackBar('Something Went Wrong, Please Refresh/Relogin.', ''),
        () => { }
      );
    }
  }

  funCategory()
  {
    this.btnSearch='Show';
    this.btnExport='Hide';
  }
  funStatus()
  {
    this.btnSearch='Show';
    this.btnExport='Hide';
  }
  funLocation()
  {
    this.btnSearch='Show';
    this.btnExport='Hide';
  }
  funPriority()
  {
    this.btnSearch='Show';
    this.btnExport='Hide'; 
  }

  ExporttoExcel(){
    const RoFormData: any = Object.assign({}, this.SERatingForm.value);
    let changed = true;
    const userData=new ISearchPara();
    userData.priority=RoFormData.Priority;
    if (changed) {
      this.seratingHistory.searchData(userData).subscribe(
        (data: any) => {
          //this.openSnackBar(data.message, '');
          if (data.flag == applicationconstant.SuccessFlag) {
            this.seratingList = data.results;
            this.seratingHistory.exportExcel(this.seratingList, 'SE_Rating_Report');
            this.openSnackBar('Data Exported Successfully','');
          }
          else {
            this.openSnackBar('No Data Found To Export','');
            this.seratingList = [];
          }
        },
        (err: any) => this.openSnackBar(err.error.message, ''),
        () => { }
      );
    }
  }

  onSubmit(form: any) {
    // const RoFormData: any = Object.assign({}, this.ReportIncidentForm.value);
    // let changed = false;
    // RoFormData.category=this.userData.category;
    // RoFormData.status=this.userData.status;
    // RoFormData.location=this.userData.location;
    // RoFormData.Priority=this.userData.priority;
    // if (changed) {
    //   this.ROIncidentHistory.SearchData(this.userData).subscribe(
    //     (data: any) => {
    //       this.openSnackBar(data.message, '');
    //       if(data.flag=="F"){
                       
    //     }
    //     },
    //     (err: any) => this.openSnackBar(err.error.message, ''),
    //     () => { }
    //   );
    // }
  }
  openSnackBar(message: string, action: string) {
    this.snackBar.open(message, action, {
      duration: 5000,
      verticalPosition: 'top'
    });
  }
  private handleError(error: any) {
    // this.errorReceived = true;
    return Observable.throw(error);
  }

}
