import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, FormBuilder, Validators, FormGroupDirective, NgForm } from '@angular/forms'
import { MatDialog, MatSnackBar } from '@angular/material';
import { ActivatedRoute } from '@angular/router';
import { LoginService } from 'src/app/Services/loginmanagement/login.service';
import { ForgotPassword } from '../../Models/ForgotPassword/forgotPassword.model';
import { Router } from '@angular/router';
import { HeaderService } from '../../Services/header/header.service';
import { applicationconstant } from 'src/app/Models/DropdownItemsGroup/applicationconstant.model';

/**
 * Forgot password form
 *
 * @export
 * @class ForgotpasswordComponent
 * @implements {OnInit}
 */
@Component({
  selector: 'app-forgotpassword',
  templateUrl: './forgotpassword.component.html',
  styleUrls: ['./forgotpassword.component.css']
})
export class ForgotpasswordComponent implements OnInit {

  /**
   * Form group for password reset
   *
   * @type {FormGroup}
   * @memberof ForgotpasswordComponent
   */
  forgotPasswordForm: FormGroup;

  hidePassword = true;
  parameteruserInput = null;
  

   /**
   * Validation messages
   *
   * @memberof ForgotpasswordComponent
   */
  validationMessages = {
    'otp': {
      'required': 'Please enter OTP recieved in your regeistered mobile no.',
      'pattern': 'Your OTP must contain only numbers.'
    },
    'password': {
      'required': 'Enter new password.',
      'minlength': 'Password must contain atleast 8 characters.',
      'maxlength': 'Password must contain maximum 16 characters.',
      'pattern': 'The password must contain atleast one capital letter,one small letter,one number and a special character.',
      'notSame': 'Password do not match.'
    },
    'confirmPassword': {
      'required': 'Confirm new password.',
      'notSame': 'Passwords do not match.'
    },
  };

  formErrors = {
    'otp': '',
    'password': '',
    'confirmPassword': ''
  };

  /**
   * Creates an instance of ForgotpasswordComponent.
   * @param {FormBuilder} fb
   * @memberof ForgotpasswordComponent
   */
  constructor(private fb: FormBuilder, private activatedroute: ActivatedRoute, private router: Router, private loginService: LoginService, public snackBar: MatSnackBar,
    public headerService: HeaderService) { }

  ngOnInit() {

    this.headerService.hide();
     this.parameteruserInput = this.loginService.GetForgotPasswordUserInput();



    this.forgotPasswordForm = this.fb.group({
      otp: ['', [Validators.required,Validators.pattern("^[0-9]*$")]],
      password: ['', [Validators.required, Validators.minLength(8),
        Validators.pattern("^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[@$!%*?&])[A-Za-z0-9@$!%*?&]{8,16}$"),
        Validators.maxLength(16)]],
      confirmPassword: ['', [Validators.required]]
    }, { validator: this.checkPasswords });

    this.forgotPasswordForm.valueChanges.subscribe((data) => {
      this.logValidationErrors(this.forgotPasswordForm);
    });

  }


  checkPasswords(group: FormGroup) { // here we have the 'passwords' group
    let pass = group.get('password').value;
    let confirmPass = group.get('confirmPassword').value;
    pass === confirmPass ? null:
      group.get('confirmPassword').setErrors({ notSame: true });
  }

 
  /**
   * Submit the form
   *
   * @returns
   * @memberof ForgotpasswordComponent
   */
  OnSubmit(): void {
    if (this.forgotPasswordForm.valid) {
   
      const userDetails = this.forgotPasswordForm.value;
      var changepassword = new ForgotPassword();
      changepassword.userInput = this.parameteruserInput;
      changepassword.OTP = userDetails.otp;
    
      changepassword.oldPassword = "";
      changepassword.newPassword = userDetails.password;
      changepassword.confirmPassword = userDetails.confirmPassword;
      this.loginService.ForgotPassword(changepassword).subscribe((data: any) => {
        if (data.flag === applicationconstant.SuccessFlag) {
          this.forgotPasswordForm.reset();
          this.openSnackBar(data.message, '');
          this.router.navigate(['/login']);
        }
        else {
          this.openSnackBar(data.message, '');
        }
      },
        (err: any) => {
          this.openSnackBar(err.error.message, '')
        });
    }
    else {
      this.ValidateAllFields(this.forgotPasswordForm);
      this.logValidationErrors(this.forgotPasswordForm);
    }
  };

  logValidationErrors(group: FormGroup = this.forgotPasswordForm): void {
    Object.keys(group.controls).forEach((key: string) => {
      const abstractControl = group.get(key);
      if (abstractControl instanceof FormGroup) {
        this.logValidationErrors(abstractControl)
      } else {
        this.formErrors[key] = '';
        if (abstractControl && !abstractControl.valid && (abstractControl.touched || abstractControl.dirty)) {
          const messages = this.validationMessages[key];

          for (const errorKey in abstractControl.errors) {
            if (errorKey) {
              this.formErrors[key] += messages[errorKey] + '';
            }
          }
        }
      }
    });
  }

  ValidateAllFields(formGroup: FormGroup) {
    Object.keys(formGroup.controls).forEach(field => {
      const control = formGroup.get(field);
      if (control instanceof FormControl) {
        control.markAsTouched({ onlySelf: true });
      } else if (control instanceof FormGroup) {
        this.ValidateAllFields(control);
      }
    });
  }

    /**
   * Snack bar
   *
   * @param {string} message
   * @param {string} action
   * @memberof ForgotpasswordComponent
   */
  openSnackBar(message: string, action: string) {
    this.snackBar.open(message, action, {
      duration: 5000,
      verticalPosition: 'top'
    });
  }
}

