import { Component, OnInit, NgZone } from '@angular/core';
import { FormGroup, FormControl, FormBuilder, Validators } from '@angular/forms';
import { MatDialog, MatSnackBar } from '@angular/material';
import { Router } from '@angular/router';
import { AssignTicket } from 'src/app/Models/TicketsSE/AssignTicket.model';
import { Observable } from 'rxjs';
import{AssignTickettoSEComponent} from 'src/app/Component/ServiceEngineer/assign-ticketto-se/assign-ticketto-se.component';
import { Ticket } from 'src/app/Models/vendorsupervisor/ticket.model';
import { Incident } from 'src/app/Models/vendorsupervisor/incident.model';
import{ VendorSupervisorService } from 'src/app/Services/vendorsupervisor/vendorsupervisor.service'
import { efsrService } from 'src/app/Services/EFSR/efsrcreate.service';
import { documentList } from 'src/app/Models/ServiceEngineer/documentList';
import { Constants } from 'src/app/Constants/constants';
import { efsrcreate } from 'src/app/Models/EFSR/efsrcreate.model';
import { applicationconstant } from 'src/app/Models/DropdownItemsGroup/applicationconstant.model';

@Component({
  selector: 'app-view-se-details',
  templateUrl: './view-se-details.component.html',
  styleUrls: ['./view-se-details.component.scss']
})
export class ViewSeDetailsComponent implements OnInit {

      SEForm:FormGroup;
      ticketId : number;
      documents: documentList[]=[];
      seid:number;
     // requestFromSupervisor : boolean;
      //requestFromRO : boolean;

  constructor(private fb: FormBuilder,private efsrservice: efsrService,private vendorservice: VendorSupervisorService,public snackBar: MatSnackBar, private dialog: MatDialog, 
    private router: Router) { }

  ngOnInit() {
    this.ticketId = this.vendorservice.getTicketId();
    // this.requestFromSupervisor = this.vendorservice.getRequestFromVendor();
    // this.requestFromRO = this.vendorservice.getRequestFromRO();
    this.SEForm = this.fb.group({
      sename: new FormControl('', ),
      semobile: new FormControl('', ),
      interactionId: new FormControl('',),
    });
    

    // this.requestFromSupervisor = this.vendorservice.getRequestFromVendor();
    // this.requestFromRO = this.vendorservice.getRequestFromRO();
   
    this.efsrservice.getSEandHPSMDetailsforRO(this.ticketId)
    .subscribe(
      (data: any) => {
        if (data.flag === Constants.SUCCESS_FLAG) {
          if (data.result.sename !== undefined) {
            this.seid=data.result.seid;
            this.setHPSMandSEEditFormValues(data.result);
            this.getRequiredDocuments();
          } else {
            this.openSnackBar('SE Deatils not found', '');
          }
        }
      },
      (err: any) => this.openSnackBar(err.error.message, ''),
      () => { }
    );
    
  }

  setHPSMandSEEditFormValues(user: efsrcreate) {
    this.SEForm.patchValue({
        interactionId: user.hpsmid,
        semobile: user.semobile,
        sename: user.sename
    });
  }

  openSnackBar(message: string, action: string) {
    this.snackBar.open(message, action, {
      duration: 5000,
      verticalPosition: 'top'
    });
  }
  getRequiredDocuments() {
    this.vendorservice.getRequiredFiles().subscribe((data: any) => {
      this.documents = data.results;
        this.patchFiles();
      });
  }

  patchFiles() {
    this.vendorservice.getSEAllDocs(applicationconstant.SEDocument, 0, this.seid).subscribe(
        (data: any) => {
          if (data.flag == "S") {
          data.results.forEach(x => {     
            if(x.isSuccess==true){       
            this.documents.filter((val) => val.documentId == x.fileType)[0].documentName = x.fileName;
            this.documents.filter((val) => val.documentId == x.fileType)[0].file = x.fileStream;
            this.documents.filter((val) => val.documentId == x.fileType)[0].isUploaded = true;
            //this.documents.filter((val) => val.documentId == x.fileType)[0].createdBy = x.createdBy;
            }
          });
        }
        }
      )
  }

  preview(documentType: string, documentName: string) {
    
    
      this.vendorservice.downloadDoc(this.seid, documentType).subscribe(result => {
        if (window.navigator.msSaveOrOpenBlob) {
          window.navigator.msSaveOrOpenBlob(result, documentName);
        } else {
          var url = window.URL.createObjectURL(result);
  
          let link = document.createElement('a');
          link.setAttribute('type', 'hidden');
          link.href = url;
          link.download = documentName;
          document.body.appendChild(link);
          link.click();
          link.remove();
        }
      }    
      );
    

  }

 public  backToList()
    {
      const currentUser = JSON.parse(localStorage.getItem('currentUser'));
    if (currentUser.Role == "ROROLE")
      
     // if(this.requestFromSupervisor==true)
      {
        //this.vendorservice.setRequestFromVendor(false);
        this.router.navigate(['RO/incidentsearch']);
       
      }
      
      else 
      {
        //this.vendorservice.setRequestFromRO(false);
        this.router.navigate(['supervisor/ticketsearch']);
      }
        //this.router.navigate(['RO/incidentsearch']);
    }
    // ngOnDestroy() {
    //   this.requestFromRO = false;
    // }
  // ngOnDestroy()
  //   {
  //     this.ticketId = 0;
  //   }

}
