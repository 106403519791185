import { Component, OnInit } from '@angular/core';
import { UserService } from 'src/app/Services/usermanagement/user.service';
import { Router } from '@angular/router';
import { MenuItemService } from '../../../../Services/Utilities/menuitems.service';
import { HeaderService } from '../../../../Services/header/header.service';
import { HeaderComponent } from '../header/header.component';
// import {OrderByPipe} from '../../../../Services/Utilities/orderby.pipe';


@Component({
  selector: 'app-subheader',
  providers: [HeaderComponent],
  templateUrl: './subheader.component.html',
  styleUrls: ['./subheader.component.scss']
})
export class SubheaderComponent implements OnInit {

  public userName: any;
  public menuItems: any;

  hideMenus: boolean;

  constructor(private router: Router, private userService: UserService,
      private menuItemService: MenuItemService, public headerService: HeaderService, public headerComponent: HeaderComponent) {
    this.hideMenus = true;
  }

  ngOnInit() {
    this.getMenuItems();
    const currentUser = JSON.parse(localStorage.getItem('currentUser'));
    if (currentUser !== null) {
      this.hideMenus = false;
    }
    this.userName = currentUser && currentUser.FirstName + ' ' + currentUser.LastName;
    this.headerService.show(this.userName);
    this.headerComponent.getLoggedInUserName();
  }

  /**
   * Get Menu items
   */
  public getMenuItems() {
    this.userService.getMenuAsPerUser().subscribe((data: any) => {
      this.menuItems = data;
      this.menuItemService.setMenuItems(data);
    },
      (err: any) => {
        console.log(err);
      });
  }

  public toggeleMenu() {
    $('.offcanvas-collapse').toggleClass('open');
    $('.backdrops').toggleClass('disBl');
  }

  public ToggleMenuItems(id: any, event: Event) {
    // /event.stopPropagation();
    var collapseMenu = document.getElementsByClassName('addBlock');
    if(collapseMenu && collapseMenu.length > 0) {
      collapseMenu[0].classList.remove('addBlock');
    }
    var expandMenu = document.getElementById('level2-'+id);
    if(expandMenu) {
      expandMenu.classList.add('addBlock');
    }
  }

}
