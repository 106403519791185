import { Component, OnInit, ViewChild, OnDestroy } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog, MatSnackBar, MatDialogConfig } from '@angular/material';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import {VendorService} from 'src/app/Services/master/vendor.service';
import {vendordetails} from 'src/app/Models/master/vendor.model';
import { documentList } from 'src/app/Models/ServiceEngineer/documentList';
//import{SeuserDisableDialogComponent} from '../seuser-disable-dialog/seuser-disable-dialog.component'
import { ConfigurationService } from 'src/app/Services/Utilities/configuration.service';
import { Constants } from 'src/app/Constants/constants';
import { IState } from 'src/app/Models/common/state.model';
import { Icity } from 'src/app/Models/common/city.model';
import { Router, NavigationEnd } from '@angular/router';

/**
 * Edit user page
 *
 * @export
 * @class UserEditComponent
 * @implements {OnInit}
 */

@Component({
  selector: 'app-activateuserlogin',
  templateUrl: './activateuserlogin.component.html',
  styleUrls: ['./activateuserlogin.component.scss']
})
export class ActivateuserloginComponent implements OnInit,OnDestroy {

  /**
   * User Search form
   *
   * @type {FormGroup}
   * @memberof UserEditComponent
   */
  searchSEUserForm: FormGroup;
  navigationSubscription;
  /**
   * Update user form
   *
   * @type {FormGroup}
   * @memberof UserEditComponent
   */
  editSEUserForm: FormGroup;

  /**
   * User types or designation
   *
   * @type {IUserType[]}
   * @memberof UserEditComponent
   */
  //userTypes: IUserType[];

  channelPartners = []; // TODO

  public tempemail:string;

  /**
   * Search input types
   *
   * @memberof UserEditComponent
   */
  searchTypes = ['User ID', 'Email', 'Mobile'];
  activeTypes = ['Active', 'InActive'];

  // default is false
  showSEUserDetails = false;

  public mobileExists: boolean;
  public emailExists: boolean;
  public ShowLoader:boolean=false;
  //public addressExists: boolean;
  public pincodeExists: boolean;
  seId: number = 0;
  isEditForm: boolean = true;
  isDocumentSectionVisiable: boolean = false;
  isFormSectionVisible: boolean = true;

  userTypesDisabledMap: Map<string, boolean>;
  stateDisabledMap: Map<string, boolean>;
  cityDisabledMap: Map<string, boolean>;
  documents: documentList[]=[];

  // public selectedReference: string;
  // isUserTypeSelected:boolean=false;
  // public referenceTypes: IState[];
  // @ViewChild('referenceid',{static: false}) referenceElement: any;

  /**
   * User search validation messages
   *
   * @memberof UserEditComponent
   */
  user_search_validation_messages = {
    'searchtype': [
      { type: 'required', message: 'Search type is required' }
    ],
    'searchterm': [
      { type: 'required', message: 'Search value is required' },
      { type: 'maxlength', message: 'Search value is cannot be more than 100 characters' }
    ]
  };

  /**
   * User details validation messages
   *
   * @memberof UserEditComponent
   */
  public account_validation_messages = {
    'username': [
      { type: 'required', message: 'UserID is required' },
      { type: 'minlength', message: 'UserID must be of 4 digits' },
     // { type: 'pattern', message: 'UserID  must contain only numbers' }
    ],
    'firstname': [
      { type: 'required', message: 'First name is required' },
      { type: 'maxlength', message: 'First name cannot be more than 250 characters long' },
      { type: 'pattern', message: 'Your first name must contain only alphabets' }
    ],
    'middlename': [
      //{ type: 'required', message: 'Middle name is required' },
      { type: 'maxlength', message: 'Middle name cannot be more than 250 characters long' },
      { type: 'pattern', message: 'Your first name must contain only alphabets' }
    ],
    'lastname': [
      { type: 'required', message: 'Last name is required' },
      { type: 'maxlength', message: 'Last name cannot be more than 250 characters long' },
      { type: 'pattern', message: 'Your last name must contain only alphabets' }
    ],
    'mobile': [
      { type: 'required', message: 'Mobile is required' },
      { type: 'minlength', message: 'Mobile must be of 10 digits' },
      { type: 'pattern', message: 'Mobile is invalid' }
    ],
    'email': [
      { type: 'required', message: 'Email is required' },
      { type: 'maxlength', message: 'Email cannot be more than 100 characters long' },
      { type: 'pattern', message: 'Email is invalid' }
    ],
    'DOB': {
      'required': 'DOB is required.',
      'matDatepickerMin': 'DOB should be past date.'
    }, 
    'pincode': [
      { type: 'required', message: 'pincode is required' },
      { type: 'minlength', message: 'pincode must be of 6 digits' },
      { type: 'pattern', message: 'pincode is invalid' }
    ]
  };

  searched = false;
  userData: vendordetails;
  userSoftDeleted: boolean;
  userDetailsSubmitted = false;
  disableSwitchBtn: boolean;
  
  /**
   * Creates an instance of UserEditComponent.
   * @param {FormBuilder} fb
   * @param {MatSnackBar} snackBar
   * @param {UserService} userService
   * @memberof UserEditComponent
   */
  constructor(private fb: FormBuilder,
    public snackBar: MatSnackBar,
    public dialog: MatDialog,
    private SEService: VendorService,
    private router: Router,
    private configurationService: ConfigurationService) {

    this.searchSEUserForm = this.fb.group({
      searchType: new FormControl(null, Validators.required),

      searchTerm: new FormControl(null, Validators.compose([
        Validators.required,
        Validators.maxLength(100),
      ]))
    });

    this.editSEUserForm = this.createFormGroupWithBuilderAndModel();

    this.mobileExists = false;
    this.emailExists = false;
    //this.addressExists = false;
    this.pincodeExists = false;
    this.stateDisabledMap = new Map();
    this.cityDisabledMap = new Map();
    this.disableSwitchBtn = false;

    this.navigationSubscription = this.router.events.subscribe((e: any) => {
      // If it is a NavigationEnd event re-initalise the component
      if (e instanceof NavigationEnd) {
        this.resetForm();
        this.isFormSectionVisible = true;
        this.isDocumentSectionVisiable = false;
         
        this.ResetSearch();
      }
    });

  }

  
  /**
   * Initialize component and subcribe to value to fill in
   * dropdowns of usertypes and roles
   *
   * @memberof UserEditComponent
   */
  ngOnInit() {
      this.ShowLoader=false;
  }
  /**
   * Create a reactive form for user update using form builder
   *
   * @returns
   * @memberof UserEditComponent
   */
  createFormGroupWithBuilderAndModel() {
    return this.fb.group({
      username: new FormControl('', Validators.compose([
        Validators.required,        
      ]),
      ),
      firstName: new FormControl('', Validators.compose([
        Validators.required,
        Validators.maxLength(250),
        Validators.pattern('^[A-Za-z]+(?:[A-Za-z]+)*$') 
      ])),
      lastName: new FormControl('', Validators.compose([
        Validators.required,
        Validators.maxLength(250),
        Validators.pattern('^[A-Za-z]+(?:[A-Za-z]+)*$') 
      ])),
      mobile: new FormControl('', Validators.compose([
        Validators.required,
        Validators.minLength(10),
        Validators.pattern('^[6-9][0-9]{9}$')
      ])),
      email: new FormControl('', Validators.compose([
        Validators.required,
        Validators.maxLength(100),
        Validators.pattern('^([a-zA-Z0-9_\.\-])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})+$')
      ])),
      activeType: new FormControl(null, [Validators.required,Validators.min(1)]),
    });
  }

  /**
   * Fill the form inputs with user details
   *
   * @param {User} user
   * @memberof UserEditComponent
   */
  setEditFormValues(user: vendordetails) {
    this.editSEUserForm.patchValue({
      username : user.username,
      firstName : user.firstName,
      middlename:user.middlename,
      lastName : user.lastName,
      email : user.email,
      mobile : user.mobile,
      DOB:user.DOB,
      address:user.address,
      pincode:user.pincode,
      activeType:user.activeFlag
    });
    this.tempemail=user.email;
   // this.seId =  user.seId;
    //this.FillCityDD(user.state);
  }

  /**
   * Submit the form details and pass data to web api
   *
   * @returns
   * @memberof UserEditComponent
   */
  onSubmit() {
    this.userDetailsSubmitted = true;

    // if (!this.editSEUserForm.valid) {
    //   return;
    // }

    const editSEUserFormData: any = Object.assign({}, this.editSEUserForm.value);

    let changed = false;

      if (editSEUserFormData.firstName !== this.userData.firstName) {
        this.userData.firstName = editSEUserFormData.firstName;
        changed = true;
      }
      if (editSEUserFormData.lastName !== this.userData.lastName) {
        this.userData.lastName = editSEUserFormData.lastName;
        changed = true;
      }
      if (editSEUserFormData.middlename !== this.userData.middlename) {
        this.userData.middlename = editSEUserFormData.middleName;
        changed = true;
      }
      if (editSEUserFormData.mobile !== this.userData.mobile) {
        this.userData.mobile = editSEUserFormData.mobile;
        changed = true;
      }
      if (editSEUserFormData.activeType !== this.userData.activeFlag) {
        this.userData.activeFlag = editSEUserFormData.activeType;
        changed = true;
      }
    //}
   
    // update user data to database
    if (changed) {
      this.SEService.updateUserStatus(this.userData).subscribe(
        (data: any) => {
          this.openSnackBar(data.message, '');
          if(data.flag=="F"){
            this.showSEUserDetails=true;
            
          }
          else
          {
          this.showSEUserDetails = false;
          this.searchSEUserForm.get('searchTerm').patchValue('');
          this.searchSEUserForm.get('searchTerm').setErrors(null);
          this.searchSEUserForm.get('searchType').patchValue('null');
          this.resetForm();
        }
        },
        (err: any) => this.openSnackBar(err.error.message, ''),
        () => { }
      );
    }
  }

  /**
   * Reset edit user from, set all controls errors to null
   *
   * @memberof UserEditComponent
   */
  resetForm() {
    this.editSEUserForm.reset();

    for (const i in this.editSEUserForm.controls) {
      if (this.editSEUserForm.controls[i]) {
        this.editSEUserForm.controls[i].setErrors(null);
      }
    }

    this.showSEUserDetails = false;
    this.userData = null;

    this.mobileExists = false;
    this.emailExists = false;

  }
  cancelEdit() {
    this.resetForm();
  }

  /**
   * Search user based on either of three parameters:
   * 1. username; 2. email; 3. mobile;
   *
   * @returns
   * @memberof UserEditComponent
   */
  onSearch() {
    this.searched = true;

    // stop here if form is invalid
    if (!this.searchSEUserForm.valid) {
      return;
    }

    const searchFormData: any = Object.assign({}, this.searchSEUserForm.value);
    var searchType = searchFormData.searchType;
    const searchTerm = searchFormData.searchTerm;
    const checkVSID = searchTerm.substring(0,2); 
     
    if(searchType=="User ID")
    {
      searchType="Username";
    }

    this.SEService.searchForAllUser(searchType, searchTerm)
      .subscribe(
        (data: any) => {
          if (data.flag === Constants.SUCCESS_FLAG) {
            if (data.result.userId !== undefined) {
              this.openSnackBar(' Details fetched for Selected Records ', '');
              this.showSEUserDetails = true;
              this.userData = data.result;              
              // this.populateReferences({value:this.userData.designation});
              this.setEditFormValues(data.result);
              this.editSEUserForm.get('username').enable();
              this.editSEUserForm.get('firstName').enable();
              this.editSEUserForm.get('lastName').enable();
              this.editSEUserForm.get('mobile').enable();
              this.editSEUserForm.get('email').enable();
              this.disableSwitchBtn = false;
              //this.setUserSoftDeleted(data.result.activeFlag);
            } else {
              this.openSnackBar('User not found', '');
              this.resetForm();
            }
          } else {
            this.openSnackBar(data.message, '');
            this.resetForm();
          }
        },
        (err: any) => this.openSnackBar(err.error.message, ''),
        () => { }
      );
  }

  checkMobile() {
    if (this.editSEUserForm.get('mobile').valid) {
      const mobile = this.editSEUserForm.get('mobile').value;

      const searchType = 'mobile';

      this.SEService.searchUser(searchType, mobile).subscribe(
        (data: any) => {
          if (data.result !== null) {
            this.mobileExists = true;
          } else if (data.result.mobile !== this.userData.mobile) {
            this.mobileExists = true;
          } else {
            this.mobileExists = false;
          }
        },
        (err: any) => { },
      );
    }
  }

  checkEmail() {
    
    if (this.editSEUserForm.get('email').valid) {
      const email = this.editSEUserForm.get('email').value;
      const searchType = 'email';

      this.SEService.searchUser(searchType, email).subscribe(
        (data: any) => {
          if (data.result === null) {
            this.emailExists = false;
          } else if (data.result.email !== this.userData.email) {
            this.emailExists = true;
          } else {
            this.emailExists = false;
          }
        },
        (err: any) => { },
      );
    }
  }

  checkEmailForEdit() {
    
    if (this.editSEUserForm.get('email').valid) {
      const email = String(this.editSEUserForm.get('email').value).toLocaleUpperCase();
      if(this.tempemail==email)
      {
        return;
      }
      const searchType = 'email';

      this.SEService.searchUserForEdit(searchType, email).subscribe(
        (data: any) => {
          if (data.result === null) {
            this.emailExists = true;
          } 
          else {
            this.emailExists = false;
          }
        },
        (err: any) => { },
      );
    }
  }

  /**
   * Dialog is shown, when admin tries to disable a user.
   * This stops admin from to disable user accidentally
   *
   * @returns
   * @memberof UserEditComponent
   */
  openDeactivationDialog() {
    if (this.editSEUserForm.value.isactive !== false) {
      return;
    }

    const dialogConfig = new MatDialogConfig();
    dialogConfig.autoFocus = true;
    dialogConfig.disableClose = true;
    // dialogConfig.height = '30%';
    // dialogConfig.width = '30%';
    dialogConfig.data = {
      username: this.editSEUserForm.value.username,
      isactive: this.editSEUserForm.value.isactive,
      WarningMessage: 'User ' + this.editSEUserForm.value.username + ' will be disabled'
    }

    // const dialogRef = this.dialog.open(SeuserDisableDialogComponent, dialogConfig);
    // dialogRef.afterClosed().subscribe(result => {

    //   if (result !== undefined) {
    //     // console.log(result);
    //   } else {
    //     this.editSEUserForm.patchValue({
    //       isactive: true
    //     });
    //   }
    // });
  }
  
  openSnackBar(message: string, action: string) {
    this.snackBar.open(message, action, {
      duration: 5000,
      verticalPosition: 'top'
    });
  }

  setUserSoftDeleted(activeFlag: string) {
    this.userSoftDeleted = (activeFlag === 'I') ? false : true;
  }

  private handleError(error: any) {
    // this.errorReceived = true;
    return Observable.throw(error);
  }

  ResetSearch(): void {
    this.searchSEUserForm.reset();
    this.searchSEUserForm.clearValidators();
    for (let i in this.searchSEUserForm.controls) {
      this.searchSEUserForm.controls[i].setErrors(null);
    }
    this.showSEUserDetails = false;
  }
  ngOnDestroy() {
    // avoid memory leaks here by cleaning up after ourselves. If we  
    // don't then we will continue to run our initialiseInvites()   
    // method on every navigationEnd event.
    if (this.navigationSubscription) {  
       this.navigationSubscription.unsubscribe();
    }
  }

}


