import { Injectable } from '@angular/core';
import{vendordetails,  Isubcategory,Iareaname,Isubareaname,Ivendor,Iro} from 'src/app/Models/master/vendor.model';
import {romappingdetails} from 'src/app/Models/master/romappingdetails.model';
import { Observable, throwError } from 'rxjs';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { map, catchError, tap } from 'rxjs/operators';
import { HttpVerbService } from 'src/app/Services/httpverb/httpverb.service';

import { IUserType } from 'src/app/Models/usermanagement/usertype.model';
import { IState } from 'src/app/Models/common/state.model';
import { environment } from 'src/environments/environment';
import { Icity } from 'src/app/Models/common/city.model';
import{ IName } from 'src/app/Models/TicketsSE/engineername.model';
import { documentList } from 'src/app/Models/ServiceEngineer/documentList';
import { Icategory } from 'src/app/Models/Reports/roincidenthistory.model';
import {paraDetails } from 'src/app/Models/master/paraDetails.model';
import {rovendorpara} from 'src/app/Models/Reports/rovendorpara.model';
import {rovendorparaedit} from 'src/app/Models/Reports/rovendorparaedit.model';

/**
 * User service for user and role management
 *
 * @export
 * @class UserService
 */
@Injectable({
  providedIn: 'root'
})
export class VendorService {

  /**
   * Base url where the api is hosted
   *
   * @type {string}
   * @memberof UserService
   */
  baseUrl: string;

  /**
   * Creates an instance of UserService.
   * @param {HttpClient} http
   * @param {HttpVerbService} service
   * @memberof UserService
   */
  constructor(private http: HttpClient, private service: HttpVerbService) {
    this.baseUrl = environment.restUrl + '/api/v1/';
  }
 /**
   * Create new user with the details provide in the form
   *
   * @param {User} newUser
   * @returns {Observable<any>}
   * @memberof UserService
   */
  createUser(newUser: vendordetails): Observable<any> {
    return this.http.post<any>(this.baseUrl + 'CreateNewVendor', newUser, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    });
  }

  /**
   * Create new user with the details provide in the form
   *
   * @param {User} newUser
   * @returns {Observable<any>}
   * @memberof UserService
   */
  saveMapping(newUser: romappingdetails): Observable<any> {
    return this.http.post<any>(this.baseUrl + 'CreateVendorMapping', newUser, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    });
  }

  /**
   * Search user the search type and search parameter
   *
   * @param {*} [searchType=null]
   * @param {*} [searchTerm=null]
   * @returns {Observable<User>}
   * @memberof UserService
   */
  searchUser(searchType = null, searchTerm = null): Observable<vendordetails> {
    // encoding the search term into base64 format
    const url = this.baseUrl + 'SearchVendor?searchType=' + searchType + '&parameter=' + btoa(searchTerm);
    return this.http.get<vendordetails>(url);
  }

  CheckMobileEmail(searchType = null, searchTerm = null): Observable<vendordetails> {
    // encoding the search term into base64 format
    const url = this.baseUrl + 'CheckMobileEmail?searchType=' + searchType + '&parameter=' + btoa(searchTerm);
    return this.http.get<vendordetails>(url);
  }

  searchForAllUser(searchType = null, searchTerm = null): Observable<vendordetails> {
    // encoding the search term into base64 format
    const url = this.baseUrl + 'SearchAllUser?searchType=' + searchType + '&parameter=' + btoa(searchTerm);
    return this.http.get<vendordetails>(url);
  }

  searchUserForEdit(searchType = null, searchTerm = null): Observable<vendordetails> {
    // encoding the search term into base64 format
    const url = this.baseUrl + 'seusers/searchemail?searchType=' + searchType + '&parameter=' + btoa(searchTerm);
    return this.http.get<vendordetails>(url);
  }
  /**
   * Update user with changed user details for edit user form
   *
   * @param {User} userData
   * @returns {Observable<any>}
   * @memberof UserService
   */
  updateUser(userData: vendordetails): Observable<any> {
    return this.http.post<any>(this.baseUrl + 'UpdateNewVendor', userData, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    });
  }

  updateUserStatus(userData: vendordetails): Observable<any> {
    return this.http.post<any>(this.baseUrl + 'updateUserStatus', userData, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    });
  }
  /**
   * Get all active States
   *
   * @returns {Observable<IState[]>}
   * @memberof UserService
   */
  getStates(): Observable<IState[]> {
    const url = this.baseUrl + 'GetROStates';
    return this.service.get(url).pipe(map((response: any) => {
      return response;
    }));
  }
   /**
   * Get all active States
   *
   * @returns {Observable<Icategory[]>}
   * @memberof UserService
   */
  getCategoryROMap(): Observable<Icategory[]> {
    const url = this.baseUrl + 'GetCategory';
    return this.service.get(url).pipe(map((response: any) => {
      return response;
    }));
  }
  /**
   * Get all active States
   *
   * @returns {Observable<Iro[]>}
   * @memberof UserService
   */
  getRO(): Observable<Iro[]> {
    const url = this.baseUrl + 'GetPNOCRO';
    return this.service.get(url).pipe(map((response: any) => {
      return response;
    }));
  }

   /**
   * Get all active States
   *
   * @returns {Observable<Isubcategory[]>}
   * @memberof UserService
   */
  getSubCategoryROMap(): Observable<Isubcategory[]> {
    const url = this.baseUrl + 'GetSubCategory';
    return this.service.get(url).pipe(map((response: any) => {
      return response;
    }));
  }

  getSubCategoryROMapEdit(): Observable<Isubcategory[]> {
    const url = this.baseUrl + 'GetSubCategoryToEdit';
    return this.service.get(url).pipe(map((response: any) => {
      return response;
    }));
  }

   /**
   * Get all active States
   *
   * @returns {Observable<Iareaname[]>}
   * @memberof UserService
   */
  getAreaNameforEdit(subcat:string): Observable<Iareaname[]> {
    const url = this.baseUrl + 'GetAreaNameForEdit?subcat=' + subcat;
    return this.service.get(url).pipe(map((response: any) => {
      return response;
    }));
  }

  getSubAreaNameforEdit(subcat:string,area:string): Observable<Iareaname[]> {
    const url = this.baseUrl + 'GetSubAreaNameForEdit?subcat=' + subcat+ '&area=' + (area);
    return this.service.get(url).pipe(map((response: any) => {
      return response;
    }));
  }

  getRODetailsforEdit(subcat:string,area:string,subarea:string): Observable<Iareaname[]> {
    const url = this.baseUrl + 'GetRODetailsForEdit?subcat=' + subcat+ '&area=' + (area)+ '&subarea=' + (subarea);
    return this.service.get(url).pipe(map((response: any) => {
      return response;
    }));
  }

  getRODetailsAreaforEdit(subcat:string,area:string): Observable<Iareaname[]> {
    const url = this.baseUrl + 'GetRODetailsAreaForEdit?subcat=' + subcat+ '&area=' + (area);
    return this.service.get(url).pipe(map((response: any) => {
      return response;
    }));
  }

  getvendorforEdit(subcat:string,area:string,subarea:string,ro:string): Observable<Iareaname[]> {
    const url = this.baseUrl + 'GetVendorDetailsForEdit?subcat=' + subcat + '&area=' + (area)+ '&subarea=' + (subarea)+ '&ro=' + (ro);
    return this.service.get(url).pipe(map((response: any) => {
      return response;
    }));
  }
  /**
   * Get all active States
   *
   * @returns {Observable<Iareaname[]>}
   * @memberof UserService
   */
  getAreaName(): Observable<Iareaname[]> {
    const url = this.baseUrl + 'GetAreaName';
    return this.service.get(url).pipe(map((response: any) => {
      return response;
    }));
  }
   /**
   * Get all active States
   *
   * @returns {Observable<Isubareaname[]>}
   * @memberof UserService
   */
  getSubAreaName(): Observable<Isubareaname[]> {
    const url = this.baseUrl + 'GetSubAreaName';
    return this.service.get(url).pipe(map((response: any) => {
      return response;
    }));
  }

   /**
   * Get all active States
   *
   * @returns {Observable<Ivendor[]>}
   * @memberof UserService
   */
  getVendorForMapping(): Observable<Ivendor[]> {
    const url = this.baseUrl + 'GetVendorForMapping';
    return this.service.get(url).pipe(map((response: any) => {
      return response;
    }));
  }

 updateROVendorMapping(paraDetail:rovendorparaedit): Observable<any> {
    return this.http.post<any>(this.baseUrl + 'updateROVendorMapping', paraDetail, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    });
  }

  getHTSMData(): Observable<IState[]> {
    const url = this.baseUrl + 'PostData';
    return this.service.get(url).pipe(map((response: any) => {
      return response;
    }));
  }

   /**
   * Get all active States
   *
   * @returns {Observable<IState[]>}
   * @memberof UserService
   */
  getCity(state:string): Observable<Icity[]> {
    const url = this.baseUrl + 'GetROCity?state=' + state;
    return this.service.get(url).pipe(map((response: any) => {
      return response;
    }));
  }
  getNames(): Observable<IName[]> {
    const url = this.baseUrl + 'getNames';
    return this.service.get(url).pipe(map((response: any) => {
      return response;
    }));
  }
  getUniqueUserName(): Observable<any> {
     return this.http.get<any>(this.baseUrl + 'segetUniqueUserName')
    .pipe(map((response: any) => {
    return response;
    }
    ));
    } 

   submitSEDoc(seId: number): Observable<any> {
    return this.http.post<any>(this.baseUrl + 'SubmitSEDoc',seId, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
       // 'No-Auth': 'True' //need to remove during deployment
      })
    }).pipe(
      map((response: any) => {
      return response;
    }
    ));
  }

  searchROVendorData(userData: rovendorpara): Observable<any> {
    return this.http.post<any>(this.baseUrl + 'GetROVendorDetailsForEdit', userData, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    }).pipe(
      map((response: any) => {
      return response;
    }
    ));
  }
}
