import { Injectable } from '@angular/core';
import { ISearchPara } from 'src/app/Models/Reports/roincidenthistory.model';
import { Observable, throwError } from 'rxjs';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { map, catchError, tap } from 'rxjs/operators';
import { HttpVerbService } from 'src/app/Services/httpverb/httpverb.service';
import { Istatus, Ilocation } from 'src/app/Models/Reports/roincidenthistory.model';
import { Icategory,IimPara } from 'src/app/Models/Reports/roincidenthistory.model';
import * as FileSaver from 'file-saver'; 
import * as XLSX from 'xlsx'; 
import { environment } from 'src/environments/environment';


@Injectable({
    providedIn: 'root'
  })
  export class roincidenthistoryService {
  
    baseUrl: string;
    constructor(private http: HttpClient, private service: HttpVerbService) {
      this.baseUrl = environment.restUrl + '/api/v1/';
    }
    fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8'; 
    fileExtension = '.xlsx'; 

    public exportExcel(jsonData: any[], fileName: string): void { 
       const ws: XLSX.WorkSheet = XLSX.utils.json_to_sheet(jsonData); 
      const wb: XLSX.WorkBook = { Sheets: { 'data': ws }, SheetNames: ['data'] }; 
      const excelBuffer: any = XLSX.write(wb, { bookType: 'xlsx', type: 'array' }); 
      this.saveExcelFile(excelBuffer, fileName); 
      } 
       
      private saveExcelFile(buffer: any, fileName: string): void { 
      const data: Blob = new Blob([buffer], {type: this.fileType}); 
      FileSaver.saveAs(data, fileName + this.fileExtension); 
      } 
      
    
    getCategory(): Observable<Icategory[]> {
      const url = this.baseUrl + 'reportcategory';
      return this.service.get(url).pipe(map((response: any) => {
        return response;
      }));
    }

    getStatus(): Observable<Istatus[]> {
        const url = this.baseUrl + 'reportstatus';
        return this.service.get(url).pipe(map((response: any) => {
          return response;
        }));
      }

      getLocation(): Observable<Ilocation[]> {
        const url = this.baseUrl + 'reportlocations';
        return this.service.get(url).pipe(map((response: any) => {
          return response;
        }));
      }

      getState(): Observable<Ilocation[]> {
        const url = this.baseUrl + 'GetState';
        return this.service.get(url).pipe(map((response: any) => {
          return response;
        }));
      }

      searchData(userData: ISearchPara): Observable<any> {
        return this.http.post<any>(this.baseUrl + 'SearchROIncidentHistoryData', userData, {
          headers: new HttpHeaders({
            'Content-Type': 'application/json'
          })
        }).pipe(
          map((response: any) => {
          return response;
        }
        ));
      }
      AdditionalExportExcelData(userData: ISearchPara): Observable<any> {
        return this.http.post<any>(this.baseUrl + 'ExportAdditionalData', userData, {
          headers: new HttpHeaders({
            'Content-Type': 'application/json'
          })
        }).pipe(
          map((response: any) => {
          return response;
        }
        ));
      }
      searchDataforNonAssIM(userData: IimPara): Observable<any> {
        return this.http.post<any>(this.baseUrl + 'SearchNonAssignedIM', userData, {
          headers: new HttpHeaders({
            'Content-Type': 'application/json'
          })
        }).pipe(
          map((response: any) => {
          return response;
        }
        ));
      }

      getCategoryNonAssIM(): Observable<Icategory[]> {
        const url = this.baseUrl + 'rptnonassimcategory';
        return this.service.get(url).pipe(map((response: any) => {
          return response;
        }));
      }

     

      getAreaNonAssIM(): Observable<Icategory[]> {
        const url = this.baseUrl + 'rptnonassimarea';
        return this.service.get(url).pipe(map((response: any) => {
          return response;
        }));
      }

      getSubAreaNonAssIM(): Observable<Icategory[]> {
        const url = this.baseUrl + 'rptnonassimsubarea';
        return this.service.get(url).pipe(map((response: any) => {
          return response;
        }));
      }
      getRONonAssIM(): Observable<Icategory[]> {
        const url = this.baseUrl + 'rptnonassimro';
        return this.service.get(url).pipe(map((response: any) => {
          return response;
        }));
      }

  }  