
import { Component, OnInit,ChangeDetectorRef, ViewChild } from '@angular/core';
import { FormGroup, FormControl, Validators, FormBuilder, ValidatorFn, AbstractControl } from '@angular/forms';
import { MatDialog, MatSnackBar } from '@angular/material';
import { roincidenthistoryService } from 'src/app/Services/Reports/roincidenthistory.service';
import { Icategory } from 'src/app/Models/Reports/roincidenthistory.model';
import { Istatus } from 'src/app/Models/Reports/roincidenthistory.model';
import { Ilocation } from 'src/app/Models/Reports/roincidenthistory.model';
import { IimPara } from 'src/app/Models/Reports/roincidenthistory.model';
import { Incident } from 'src/app/Models/Reports/incident.model';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { UniqueUsernameValidator } from 'src/app/shared/unique-username-validator.directive';
import { ConfigurationService } from 'src/app/Services/Utilities/configuration.service';
import { Constants } from 'src/app/Constants/constants';
import { applicationconstant } from 'src/app/Models/DropdownItemsGroup/applicationconstant.model';
import { DropdownItems } from 'src/app/Models/DropdownItemsGroup/dropdownitems.model';


@Component({
  selector: 'app-nonassignedim',
  templateUrl: './nonassignedim.component.html',
  styleUrls: ['./nonassignedim.component.scss']
})
export class NonassignedimComponent implements OnInit {

  public category: Icategory[];
  public ro: Icategory[];
  public status: Icategory[];
  public location: Icategory[];
  public state: Icategory[];
  IncidentList: Incident[];
  public btnSearch:string= 'Hide';
  public btnExport:string='Hide';
  //ticketValues:string;
  //ticketSearchType: DropdownItems[];
    
  public ReportIncidentForm: FormGroup;
  isFormSectionVisible: boolean = true;

  constructor(private ROIncidentHistory: roincidenthistoryService,
    private fb: FormBuilder,
    public snackBar: MatSnackBar,
    private configurationService: ConfigurationService,
    private cdr:ChangeDetectorRef) { 
      this.createForm();
      
  }

  createForm() {
    this.ReportIncidentForm = this.fb.group({
      
      category: new FormControl('', Validators.compose([
        //Validators.required,
      ])),
      status: new FormControl('', Validators.compose([
       // Validators.required,
      ])),
      location: new FormControl('', Validators.compose([
        //Validators.required,
      ])),
      ro: new FormControl('', Validators.compose([
        //Validators.required,
      ])),
      state: new FormControl('', Validators.compose([
        //Validators.required,
      ])),
    });
  }
  ResetSearch()
  {
    this.ReportIncidentForm.reset();
    //this.ticketValues=this.ticketSearchType.map(x=>x.mstValue).join(' / ');
    this.onSearch();
    this.btnSearch='Hide';
    this.btnExport='Show';
  }
  public account_validation_messages = {
   
    'category': [
      { type: 'required', message: 'category is required' },
    ],
    'status': [
      { type: 'required', message: 'status is required' },
    ],
    'location': [
      { type: 'required', message: 'location is required' },
    ],
    'ro': [
      { type: 'required', message: 'status is required' },
    ],
  };

  ngOnInit() {

    this.ROIncidentHistory.getRONonAssIM()
      .pipe(catchError((err) => this.handleError(err)))
      .subscribe((data: any) => {
        this.ro=data.results;
      });

    this.ROIncidentHistory.getCategoryNonAssIM()
      .pipe(catchError((err) => this.handleError(err)))
      .subscribe((data: any) => {
        if (data.flag == applicationconstant.SuccessFlag)
        {
          this.category = data.results; 
          this.ROIncidentHistory.getAreaNonAssIM()
          .pipe(catchError((err) => this.handleError(err)))
          .subscribe((data: any) => {
            if (data.flag == applicationconstant.SuccessFlag)
            {
               this.status = data.results;
               this.ROIncidentHistory.getSubAreaNonAssIM()
                .pipe(catchError((err) => this.handleError(err)))
                .subscribe((data: any) => {
                  if (data.flag == applicationconstant.SuccessFlag)
                  {
                    this.location = data.results; 
                    this.ROIncidentHistory.getState()
                    .pipe(catchError((err) => this.handleError(err)))
                    .subscribe((data: any) => {
                      if(data.flag == applicationconstant.SuccessFlag)
                      {
                        this.state=data.results;
                        this.onSearch(); 
                      }
                    })
                   
                  }  
                  else
                  {
                    this.openSnackBar('Data not found for selected criteria','');
                  }
                },
                (err: any) => this.openSnackBar(err.error.message, ''),
                () => { }
              );
            } 
            else
            {
              this.openSnackBar('Data not found for selected criteria','');
            }
          },
            (err: any) => this.openSnackBar(err.error.message, ''),
            () => { }
          );
        }   
        else
        {
          this.openSnackBar('Data not found for selected criteria','');
        } 
        
      },
      (err: any) => this.openSnackBar(err.error.message, ''),
        () => { }
      );
     
  }

  onSearch(){
    const RoFormData: any = Object.assign({}, this.ReportIncidentForm.value);
    let changed = true;
    const userData=new IimPara();
    if(RoFormData.category!=null)
    {
    userData.category=RoFormData.category.name;
    }else{userData.category=RoFormData.category;}
    userData.area=RoFormData.status;
    userData.subarea=RoFormData.location;
    userData.ro=RoFormData.ro;
    userData.state=RoFormData.state;
    if (changed) {
      this.ROIncidentHistory.searchDataforNonAssIM(userData).subscribe(
        (data: any) => {
          
          if (data.flag == applicationconstant.SuccessFlag) {
            this.IncidentList = data.results;
            this.openSnackBar('Data found and displayed','');
            this.btnSearch='Hide';
            this.btnExport='Show';
          }
          else {
            this.openSnackBar('Data not found for selected criteria','');
            this.IncidentList = [];
            this.btnSearch='Show';
            this.btnExport='Hide';
          }
        },
        (err: any) => this.openSnackBar(err.error.message, ''),
        () => { }
      );
    }
  }

  funCategory()
  {
    this.btnSearch='Show';
    this.btnExport='Hide';
  }
  funStatus()
  {
    this.btnSearch='Show';
    this.btnExport='Hide';
  }
  funLocation()
  {
    this.btnSearch='Show';
    this.btnExport='Hide';
  }
  funro()
  {
    this.btnSearch='Show';
    this.btnExport='Hide'; 
  }

  ExporttoExcel(){
    const RoFormData: any = Object.assign({}, this.ReportIncidentForm.value);
    let changed = true;
    const userData=new IimPara();
    if(RoFormData.category!=null)
    {
    userData.category=RoFormData.category.name;
    }
    else{
    userData.category=RoFormData.category;}
    userData.area=RoFormData.status;
    userData.subarea=RoFormData.location;
    userData.ro=RoFormData.ro;
    if (changed) {
      this.ROIncidentHistory.searchDataforNonAssIM(userData).subscribe(
        (data: any) => {
          //this.openSnackBar(data.message, '');
          if (data.flag == applicationconstant.SuccessFlag) {
            this.IncidentList = data.results;
            this.ROIncidentHistory.exportExcel(this.IncidentList, 'Non_Assigned_IM');
            this.openSnackBar('Data Exported Successfully','');
          }
          else {
            this.openSnackBar('No Data Found To Export','');
            //this.IncidentList = [];
          }
        },
        (err: any) => this.openSnackBar(err.error.message, ''),
        () => { }
      );
    }
  }

  onSubmit(form: any) {
    // const RoFormData: any = Object.assign({}, this.ReportIncidentForm.value);
    // let changed = false;
    // RoFormData.category=this.userData.category;
    // RoFormData.status=this.userData.status;
    // RoFormData.location=this.userData.location;
    // RoFormData.Priority=this.userData.priority;
    // if (changed) {
    //   this.ROIncidentHistory.SearchData(this.userData).subscribe(
    //     (data: any) => {
    //       this.openSnackBar(data.message, '');
    //       if(data.flag=="F"){
                       
    //     }
    //     },
    //     (err: any) => this.openSnackBar(err.error.message, ''),
    //     () => { }
    //   );
    // }
  }
  openSnackBar(message: string, action: string) {
    this.snackBar.open(message, action, {
      duration: 5000,
      verticalPosition: 'top'
    });
  }
  private handleError(error: any) {
    // this.errorReceived = true;
    return Observable.throw(error);
  }

}
