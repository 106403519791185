//import { Component } from '@angular/core';

//@Component({
//  selector: 'app-root',
//  templateUrl: './app.component.html',
//  styleUrls: ['./app.component.scss']
//})
//export class AppComponent {
//  title = 'ClientApp';
//}

import { Component, AfterViewInit } from '@angular/core';
import * as $ from 'jquery';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements AfterViewInit {
  title = 'RelFuel';
  public googleMapsJs = `https://maps.googleapis.com/maps/api/js?key=${environment.googleMapsApiKey}&amp;libraries=places`;

  ngAfterViewInit() {
    $('#awz-preloader').delay(350).fadeOut(function () {
      $('body').delay(350);
    });
  }
}

