import { Component, OnInit,ChangeDetectorRef, ViewChild } from '@angular/core';
import { FormGroup, FormControl, Validators, FormBuilder, ValidatorFn, AbstractControl } from '@angular/forms';
import { MatDialog, MatSnackBar } from '@angular/material';
import { workingincidenthistoryService } from 'src/app/Services/Reports/workingincidenthistory.service';
import { Icategory } from 'src/app/Models/Reports/workingincidenthistory.model';
import { Istatus } from 'src/app/Models/Reports/workingincidenthistory.model';
import { Ilocation } from 'src/app/Models/Reports/workingincidenthistory.model';
import { ISearchPara } from 'src/app/Models/Reports/workingincidenthistory.model';
import { Incident } from 'src/app/Models/Reports/workingincident.model';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { UniqueUsernameValidator } from 'src/app/shared/unique-username-validator.directive';
import { ConfigurationService } from 'src/app/Services/Utilities/configuration.service';
import { Constants } from 'src/app/Constants/constants';
import { applicationconstant } from 'src/app/Models/DropdownItemsGroup/applicationconstant.model';


@Component({
  selector: 'app-workingincidenthistory',
  templateUrl: './workingincidenthistory.component.html',
  styleUrls: ['./workingincidenthistory.component.scss']
})

export class WorkingincidenthistoryComponent implements OnInit {

  public category: Icategory[];
  public status: Istatus[];
  public location: Ilocation[];
  IncidentList: Incident[];
  public btnSearch:string= 'Hide';
  public btnExport:string='Hide';

  Priority = ['Critical', 'High', 'Medium','Low'];
  
  public ReportWorkingIncidentForm: FormGroup;
  isFormSectionVisible: boolean = true;

  constructor(private WorkingIncidentHistory:workingincidenthistoryService,
    private fb: FormBuilder,
    public snackBar: MatSnackBar,
    private configurationService: ConfigurationService,
    private cdr:ChangeDetectorRef) { 
      this.createForm();
  }

  createForm() {
    this.ReportWorkingIncidentForm = this.fb.group({
      
      category: new FormControl('', Validators.compose([
        //Validators.required,
      ])),
      status: new FormControl('', Validators.compose([
       // Validators.required,
      ])),
      location: new FormControl('', Validators.compose([
        //Validators.required,
      ])),
      Priority: new FormControl('', Validators.compose([
        //Validators.required,
      ])),
      
    });
  }
  ResetSearch()
  {
    this.ReportWorkingIncidentForm.reset();
    this.onSearch();
    this.btnSearch='Hide';
    this.btnExport='Show';
  }
  public account_validation_messages = {
   
    'category': [
      { type: 'required', message: 'category is required' },
    ],
    'status': [
      { type: 'required', message: 'status is required' },
    ],
    'location': [
      { type: 'required', message: 'category is required' },
    ],
    'Priority': [
      { type: 'required', message: 'status is required' },
    ],
  };

  ngOnInit() {
    this.WorkingIncidentHistory.getCategory()
      .pipe(catchError((err) => this.handleError(err)))
      .subscribe((data: any) => {
        if (data.flag == applicationconstant.SuccessFlag)
        {
          this.category = data.results; 
          this.WorkingIncidentHistory.getStatus()
          .pipe(catchError((err) => this.handleError(err)))
          .subscribe((data: any) => {
            if (data.flag == applicationconstant.SuccessFlag)
            {
              this.status = data.results;
              this.WorkingIncidentHistory.getLocation()
              .pipe(catchError((err) => this.handleError(err)))
              .subscribe((data: any) => {
                if (data.flag == applicationconstant.SuccessFlag)
                {
                   this.location = data.results; 
                   this.onSearch();    
                }
                else
                {
                  this.openSnackBar('Data not found for selected criteria','');
                } 
              },
              (err: any) => this.openSnackBar(err.error.message, ''),
              () => { }
              );
            }
            else
            {
              this.openSnackBar('Data not found for selected criteria','');
            }
          },
          (err: any) => this.openSnackBar(err.error.message, ''),
          () => { }
        );
        }
        else
        {
          this.openSnackBar('Data not found for selected criteria','');
        }
      },
        (err: any) => this.openSnackBar(err.error.message, ''),
        () => { }
      );
  }

  onSearch(){
    const RoFormData: any = Object.assign({}, this.ReportWorkingIncidentForm.value);
    let changed = true;
    const userData=new ISearchPara();
    userData.category=RoFormData.category;
    userData.status=RoFormData.status;
    userData.location=RoFormData.location;
    userData.priority=RoFormData.Priority;
    if (changed) {
      this.WorkingIncidentHistory.searchData(userData).subscribe(
        (data: any) => {
          
          if (data.flag == applicationconstant.SuccessFlag) {
            this.IncidentList = data.results;
            this.openSnackBar('Data found and displayed','');
            this.btnSearch='Hide';
            this.btnExport='Show';
          }
          else {
            this.openSnackBar('Data not found for selected criteria','');
            this.IncidentList = [];
            this.btnSearch='Show';
            this.btnExport='Hide';
          }
        },
        (err: any) => this.openSnackBar(err.error.message, ''),
        () => { }
      );
    }
  }

  funCategory()
  {
    this.btnSearch='Show';
    this.btnExport='Hide';
  }
  funStatus()
  {
    this.btnSearch='Show';
    this.btnExport='Hide';
  }
  funLocation()
  {
    this.btnSearch='Show';
    this.btnExport='Hide';
  }
  funPriority()
  {
    this.btnSearch='Show';
    this.btnExport='Hide'; 
  }

  ExporttoExcel(){
    const RoFormData: any = Object.assign({}, this.ReportWorkingIncidentForm.value);
    let changed = true;
    const userData=new ISearchPara();
    userData.category=RoFormData.category;
    userData.status=RoFormData.status;
    userData.location=RoFormData.location;
    userData.priority=RoFormData.Priority;
    if (changed) {
      this.WorkingIncidentHistory.searchData(userData).subscribe(
        (data: any) => {
          //this.openSnackBar(data.message, '');
          if (data.flag == applicationconstant.SuccessFlag) {
            this.IncidentList = data.results;
            this.WorkingIncidentHistory.exportExcel(this.IncidentList, 'Working_Incident_History_Report');
            this.openSnackBar('Data Exported Successfully','');
          }
          else {
            this.openSnackBar('No Data Found To Export','');
            //this.IncidentList = [];
          }
        },
        (err: any) => this.openSnackBar(err.error.message, ''),
        () => { }
      );
    }
  }

  IncidentExporttoExcel(){
    const RoFormData: any = Object.assign({}, this.ReportWorkingIncidentForm.value);
    let changed = true;
    const userData=new ISearchPara();
    userData.category=RoFormData.category;
    userData.status=RoFormData.status;
    userData.location=RoFormData.location;
    userData.priority=RoFormData.Priority;
    if (changed) {
      this.WorkingIncidentHistory.IncidentReportExcelData(userData).subscribe(
        (data: any) => {
          //this.openSnackBar(data.message, '');
          if (data.flag == applicationconstant.SuccessFlag) {
            this.IncidentList = data.results;
            this.WorkingIncidentHistory.exportExcel(this.IncidentList, 'Working_Incident_History_Report');
            this.openSnackBar('Data Exported Successfully','');
          }
          else {
            this.openSnackBar('No Data Found To Export','');
            //this.IncidentList = [];
          }
        },
        (err: any) => this.openSnackBar(err.error.message, ''),
        () => { }
      );
    }
  }

  onSubmit(form: any) {
    // const RoFormData: any = Object.assign({}, this.ReportIncidentForm.value);
    // let changed = false;
    // RoFormData.category=this.userData.category;
    // RoFormData.status=this.userData.status;
    // RoFormData.location=this.userData.location;
    // RoFormData.Priority=this.userData.priority;
    // if (changed) {
    //   this.ROIncidentHistory.SearchData(this.userData).subscribe(
    //     (data: any) => {
    //       this.openSnackBar(data.message, '');
    //       if(data.flag=="F"){
                       
    //     }
    //     },
    //     (err: any) => this.openSnackBar(err.error.message, ''),
    //     () => { }
    //   );
    // }
  }
  openSnackBar(message: string, action: string) {
    this.snackBar.open(message, action, {
      duration: 5000,
      verticalPosition: 'top'
    });
  }
  private handleError(error: any) {
    // this.errorReceived = true;
    return Observable.throw(error);
  }

}
