import { NgModule } from '@angular/core';
import { Routes, RouterModule, PreloadAllModules, NoPreloading } from '@angular/router';
import { LoginComponent } from './Component/login/login.component';
import { ForgotpasswordComponent } from './Component/forgotpassword/forgotpassword.component';
import { DashboardComponent } from './Component/dashboard/dashboard.component';
import { AuthGuard } from './Services/auth/auth.guard';
import { UserVerificationComponent } from './Component/users/user-verification/user-verification.component';
import { SECreateComponent } from './Component/ServiceEngineer/SE-Create/se-create.component';
import { FirsttimeloginComponent } from './Component/login/firsttimelogin/firsttimelogin.component';
import {VendorsupervisorComponent} from './Component/VendorSupervisor/vendorsupervisor.component';
import {ViewTicketsComponent} from './Component/VendorSupervisor/view-tickets/view-tickets.component';
import {SEEditComponent} from './Component/ServiceEngineer/SE-Edit/se-edit.component';
import {VendorsupervisorRegistrationComponent} from './Component/VendorSupervisor/vendorsupervisor-registration/vendorsupervisor-registration.component';
import { VSEditComponent } from './Component/VendorSupervisor/vendorsupervisor-registration/vs-edit/vs-edit.component';
import { SeIncidentManagementComponent } from './Component/ServiceEngineer/seincidentmanagement/se-incident-management.component';
import { IncidenthistoryComponent } from './Component/ServiceEngineer/IncidentHistory/incidenthistory/incidenthistory.component';

//import {VendorsupervisorRegistrationComponent} from './Component/vendorsupervisor/vendorsupervisor-registration/vendorsupervisor-registration.component';
import{ AssignTickettoSEComponent } from './Component/ServiceEngineer/assign-ticketto-se/assign-ticketto-se.component';
import{ CreateEfsrComponent } from './Component/EFSR/create-efsr/create-efsr.component';
import { RoIncidentManagementComponent } from './Component/ro/ro-incident-management.component';
import { ViewEfsrComponent } from './Component/EFSR/view-efsr/view-efsr.component';
import { ViewSeDetailsComponent } from './Component/ro/view-se-details/view-se-details.component';
import {VendorsubsupervisorComponent } from './Component/VendorSupervisor/vendorsubsupervisor/vendorsubsupervisor.component';
import{RoincidenthistoryComponent} from './Component/report/roincidenthistory/roincidenthistory.component';
import{WorkingincidenthistoryComponent} from './Component/report/workingincidenthistory/workingincidenthistory.component';
import { SeratingComponent } from './Component/report/serating/serating.component';
import { RoratingComponent } from './Component/report/rorating/rorating.component';
import{AddvendorComponent} from './Component/master/addvendor/addvendor.component';
import{EditvendorComponent} from './Component/master/editvendor/editvendor.component';
import{RocategorymappingComponent} from './Component/master/rocategorymapping/rocategorymapping.component';
import{ActivateuserloginComponent} from './Component/master/activateuserlogin/activateuserlogin.component';
import {NonassignedimComponent} from './Component/report/nonassignedim/nonassignedim.component';
import {EditrocatmappComponent} from './Component/master/editrocatmapp/editrocatmapp.component';
import {UserlistComponent} from './Component/report/userlist/userlist.component';
import {RovendormappinglistComponent} from './Component/report/rovendormappinglist/rovendormappinglist.component';
import {UserActivationbyOTPComponent} from './Component/login/UserActivstion/user-activationby-otp/user-activationby-otp.component'
import {ActivateLoginUserComponent} from './Component/login/UserActivstion/ActivateUser/activate-login-user/activate-login-user.component'
import { UploadUserdocsComponent } from './Component/UserHelp/UploadUserManual/upload-userdocs/upload-userdocs.component';

const routes: Routes = [
  { path: 'login', component: LoginComponent },
  { path: 'firsttimelogin', component: FirsttimeloginComponent },
  { path: 'forgotpassword', component: ForgotpasswordComponent },
  { path: 'users/userverify', component: UserVerificationComponent },
  { path: 'dashboard', component: DashboardComponent, canActivate: [AuthGuard] },
  { path: 'supervisor/registration', component: VendorsupervisorRegistrationComponent },
  { path: 'supervisor/VS-Edit', component: VSEditComponent },
  { path: 'supervisor/Incidenthistory', component: IncidenthistoryComponent },
  
  { path: 'users/create', loadChildren: './Component/users/user-create/usercreate.module#UsercreateModule', canActivate: [AuthGuard] },
  { path: 'users/edit', loadChildren: './Component/users/user-edit/useredit.module#UsereditModule', canActivate: [AuthGuard] },
  { path: 'roles/manage', loadChildren: './Component/roles/role-create/rolecreate.module#RolecreateModule', canActivate: [AuthGuard] },
  { path: 'users/resetpassword', loadChildren: './Component/users/user-password-reset/userpasswordreset.module#UserpasswordresetModule', canActivate: [AuthGuard] },
  { path: 'changepassword', loadChildren: './Component/changepassword/changepassword.module#ChangepasswordModule', canActivate: [AuthGuard] },
  { path: 'ServiceEngineer/se-create', component: SECreateComponent },
  { path: 'ServiceEngineer/assign-ticketto-se', component: AssignTickettoSEComponent },
  { path: 'supervisor/ticketsearch', component: VendorsupervisorComponent },
  { path: 'supervisor/viewticket', component: ViewTicketsComponent },
  { path: 'ServiceEngineer/se-edit', component: SEEditComponent , runGuardsAndResolvers: 'always'},
  { path: 'EFSR/create-efsr', component: CreateEfsrComponent },

  { path: 'serviceengineer/incidentsearch', component: SeIncidentManagementComponent },
  { path: 'RO/incidentsearch', component: RoIncidentManagementComponent },
  { path: 'serviceengineer/view-efsr', component: ViewEfsrComponent },
  { path: 'supervisor/view-efsr', component: ViewEfsrComponent },
  { path: 'RO/view-efsr', component: ViewEfsrComponent },
  { path: 'SE/view-se', component: ViewSeDetailsComponent },
  { path: 'subsupervisor/registration', component:  VendorsubsupervisorComponent},
  { path: 'Reports/incidenthistory', component:  RoincidenthistoryComponent},
  { path: 'Reports/serating', component:  SeratingComponent},
  { path: 'Reports/workingincidenthistory', component:  WorkingincidenthistoryComponent},
  { path: 'Reports/rorating', component:  RoratingComponent},
  { path: 'master/addvendor', component:  AddvendorComponent},
  { path: 'master/editvendor', component:  EditvendorComponent},
  { path: 'master/rocatmapping', component:  RocategorymappingComponent},
  { path: 'master/activateuserlogin', component:  ActivateuserloginComponent},
  { path: 'Reports/nonassignedim', component:  NonassignedimComponent},
  { path: 'master/editrocatmapping', component:  EditrocatmappComponent},
  { path: 'Reports/userlist', component:  UserlistComponent},
  { path: 'Reports/rovendormappinglist', component:  RovendormappinglistComponent},
  {path: 'Useractivation', component:UserActivationbyOTPComponent},
  {path: 'ActivateLoginUser', component:ActivateLoginUserComponent},
  {path: 'help/uploaddocuments', component:UploadUserdocsComponent},
  //{path:'RO/viewRO-efsr',component: RoViewEFSRComponent},
  { path: '**', redirectTo: '/login', pathMatch: 'full' },
  { path: '', redirectTo: '/login', pathMatch: 'full' },

];

@NgModule({
  imports: [RouterModule.forRoot(routes,{onSameUrlNavigation: 'reload'})],
  exports: [RouterModule]
})
export class AppRoutingModule { }
