import { Component, OnInit, OnDestroy, AfterViewInit, AfterViewChecked } from '@angular/core';
import { FormGroup, FormControl, FormBuilder, Validators, FormGroupDirective} from '@angular/forms'
import { Router } from '@angular/router';
import { NgForm } from '@angular/forms';
import { UserService } from 'src/app/Services/usermanagement/user.service';
import { LoginService } from 'src/app/Services/loginmanagement/login.service';
import * as $ from 'jquery';
import { HeaderService } from '../../Services/header/header.service';
import { RecaptchaService } from 'src/app/Services/recaptcha/recaptcha.service';
import { environment } from 'src/environments/environment';
import { ISingleResponse } from 'src/app/Models/common/single-response.model';
declare var grecaptcha: any;

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit, OnDestroy {

  public loginValid = false;
  public errorMessage: string;
  public showPopUp: boolean;
  public numberOfDays: number;
  public alreadyLoggedIn: boolean;
  public currentLoggedInUserDetails: any;
  public accessToken: any;
  public username: string;
  public password: string;
  public hidePassword = true;
  public submitted: boolean = false;
  public isFormvalid: boolean = true;
  public isPNOCUser: string = 'Y';
  public isPNOCUserChecked: boolean = true;
  
  public recaptchaEnabled: boolean;
  private siteKeyCaptcha: string;
  public userVerificationResponse: string;
  public NoofAttempt: number;
  public chkcheck= false;

  loginForm: FormGroup;

  validationMessages = {
    'userid': {
      'required': 'Please enter User ID'
    },
    'password': {
      'required': 'Please enter password.'
    }
  };

  formErrors = {
    'userid': '',
    'password': ''
  };

  constructor(private fb: FormBuilder,private router: Router,
    private userService: UserService,
    private loginService: LoginService,
    public headerService: HeaderService,
    private service: RecaptchaService) {
    this.recaptchaEnabled = false;
    this.siteKeyCaptcha = `${environment.siteKeyCaptcha}`;
    this.NoofAttempt =0;
  }

  ngOnInit(): void {
    this.headerService.hide();
    this.loginForm = this.fb.group({
      userid: ['', [Validators.required]],
      password: ['', [Validators.required]],
      pnocUser:  [false]
      });

    //this.ShowCaptcha();  //Bhumi
    const body = document.getElementsByTagName('body')[0];
    body.classList.add('login-page');
    const menuToggle = $('.menuToggle');
    menuToggle.addClass('d-none');

    if (localStorage.getItem('currentUser') != null) {

      // TODO: not working, sometimes giving exception
      // this.loginService.updateUserLogoutTime().subscribe((data: any) => { },
      //     (err: any) => { console.log(err); });

      localStorage.removeItem('userToken');
      localStorage.removeItem('currentUser');
      localStorage.removeItem('menu');
    }

    // this.service.isRecaptchaEnabled().subscribe(
    //   (response: ISingleResponse<boolean>) => {
    //     this.recaptchaEnabled = response.result;
    //     if (this.recaptchaEnabled) {
    //       grecaptcha.render('captcha_element', {
    //         'sitekey': this.siteKeyCaptcha,
    //         'hl': 'en'
    //       });
    //       window['getResponceCapcha'] = this.getResponceCapcha.bind(this);
    //     }
    //   },
    //   (err: any) => {
    //     console.log(err);
    //   }
    // );

    this.loginForm.valueChanges.subscribe((data) => {
      this.logValidationErrors(this.loginForm);
    });
  }

  logValidationErrors(group: FormGroup = this.loginForm): void {
    Object.keys(group.controls).forEach((key: string) => {
      const abstractControl = group.get(key);
      if (abstractControl instanceof FormGroup) {
        this.logValidationErrors(abstractControl)
      } else {
        this.formErrors[key] = '';
        if (abstractControl && !abstractControl.valid && (abstractControl.touched || abstractControl.dirty)) {
          const messages = this.validationMessages[key];

          for (const errorKey in abstractControl.errors) {
            if (errorKey) {
              this.formErrors[key] += messages[errorKey] + '';
            }
          }
        }
      }
    });
  }

  ValidateAllFields(formGroup: FormGroup) {
    Object.keys(formGroup.controls).forEach(field => {
      const control = formGroup.get(field);
      if (control instanceof FormControl) {
        control.markAsTouched({ onlySelf: true });
      } else if (control instanceof FormGroup) {
        this.ValidateAllFields(control);
      }
    });
  }

  ngOnDestroy(): void {
    const body = document.getElementsByTagName('body')[0];
    const menuToggle = $('.menuToggle');
    body.classList.remove('login-page');
    menuToggle.removeClass('d-none');
    this.isPNOCUser = 'Y';
  }

  /**
   * Handles login button click
   * @param formValues
   */
  // public onClickLogin(formValues: NgForm): void {
    
  //   if (formValues.value.username == undefined)
  //   {
  //     this.submitted = true;
  //     this.isFormvalid = false;
  //   }
  //   if (this.recaptchaEnabled === true && this.userVerificationResponse === undefined) {
  //     return;
  //   }
  //   if (this.NoofAttempt <= 3 && this.NoofAttempt > 0 && this.userVerificationResponse === undefined )
  //   {
  //     this.ShowCaptcha();
  //     return;
  //   }
      
  //    if (this.isFormvalid)
  //    {
  //      this.userService.userAuthentication(formValues.value.username.trim(), formValues.value.password.trim(), this.recaptchaEnabled, this.userVerificationResponse).subscribe((data: any) => {
  //     if (data != null) {
  //       this.currentLoggedInUserDetails = JSON.parse(data.currentUser);
  //       this.accessToken = data.access_token;
  //       if (!this.currentLoggedInUserDetails.AlreadyLoggedIn) {
  //         this.alreadyLoggedIn = false;
  //         this.CheckLoggedInUser();
  //         // if everu thing goes right and no need to show any pop up then redirect to dashboard
  //         if (!(this.showPopUp) && this.currentLoggedInUserDetails.IsRedirectToChangePassword == 'N') {
  //           this.loginService.setDashboardParameter(true);
  //           this.router.navigate(['/dashboard']);
  //         }
  //       } else {
  //         // show pop as user is already logged in
  //         this.alreadyLoggedIn = true;
  //       }
  //     }
  //   },
  //     (err: any) => {
  //       localStorage.removeItem('userToken');
  //       localStorage.removeItem('currentUser');
  //       localStorage.removeItem('menu');
  //       this.loginValid = true;
  //       this.errorMessage = err.error['error'];
  //       var thenum = this.errorMessage.replace(/\D/g,''); 
  //       this.NoofAttempt = +thenum;
  //       console.log(err);
  //       console.log(thenum);
  //     });
  //   }
  // }

  IsPnocUserChecked(checked:boolean): void{
    if (checked)
    {
       this.isPNOCUserChecked = false;
    }
    else
    {
      this.isPNOCUserChecked = true;
    }
  }

  public onClickLogin(): void {
    
    if (this.loginForm.valid)
    {
   
      this.recaptchaEnabled=false; //remove this -- Bhumi

      //Uncomment later -- Bhumi
    //  if (this.userVerificationResponse === undefined) {
    //   this.recaptchaEnabled=true;
    //   return;
    //  }
    
      if (this.loginForm.value.pnocUser)
      {
        this.isPNOCUser = 'Y';
      }
      this.userService.userAuthentication(this.loginForm.value.userid.trim(), this.loginForm.value.password.trim(), this.recaptchaEnabled, this.userVerificationResponse,this.isPNOCUser).subscribe((data: any) => {
      if (data != null) {
        this.currentLoggedInUserDetails = JSON.parse(data.currentUser);
        this.accessToken = data.access_token;
        if (!this.currentLoggedInUserDetails.AlreadyLoggedIn) {
          this.alreadyLoggedIn = false;
          this.CheckLoggedInUser();
          // if everu thing goes right and no need to show any pop up then redirect to dashboard
          if (!(this.showPopUp) && this.currentLoggedInUserDetails.IsRedirectToChangePassword == 'N') {
            this.loginService.setDashboardParameter(true);
            this.router.navigate(['/dashboard']);
          }
        } else {
          // show pop as user is already logged in
          this.alreadyLoggedIn = true;
        }
      }
    },
      (err: any) => {
        localStorage.removeItem('userToken');
        localStorage.removeItem('currentUser');
        localStorage.removeItem('menu');
        this.loginValid = true;
        this.errorMessage = err.error['error'];
        var thenum = this.errorMessage.replace(/\D/g,''); 
        this.NoofAttempt = +thenum;
        console.log(err);
        console.log(thenum);
      });
    
  }
  else
   {
    this.ValidateAllFields(this.loginForm);
    this.logValidationErrors(this.loginForm);
   }
  }

  private CheckLoggedInUser() {
    localStorage.setItem('userToken', this.accessToken);
    localStorage.setItem('currentUser', JSON.stringify(this.currentLoggedInUserDetails));

    if (this.currentLoggedInUserDetails.IsRedirectToChangePassword == 'Y') {
      // check if user need to change password or not
      this.loginService.setNotification(this.currentLoggedInUserDetails.OutputMsg);
      this.router.navigate(['/changepassword']);
    } else {
      if (parseInt(this.currentLoggedInUserDetails.NumberOfDaysPendingToPasswordExpire, 10) <= 7) {
        this.numberOfDays = parseInt(this.currentLoggedInUserDetails.NumberOfDaysPendingToPasswordExpire, 10);
        this.showPopUp = true;
      } else {
        this.showPopUp = false;
        // this.router.navigate(['/dashboard']);
      }
    }
  }

  private ShowCaptcha()
  {
    grecaptcha.render('captcha_element', {
        'sitekey': this.siteKeyCaptcha,
        'hl': 'en'
      });
      window['getResponceCapcha'] = this.getResponceCapcha.bind(this);
  }
  /**
   * close notification message
   */
  public close() {
    this.loginValid = false;
  }

  public ClosePopUp(): void {
    this.alreadyLoggedIn = false;
  }

  /**
   * this will force previously logged in user with same userid to
   * forcefully logout and set new user details
   */
  public ForceLogoutPrevoiusUser(): void {
    this.CheckLoggedInUser();
    this.loginService.updateUserDetails().subscribe((data: any) => {
     if (!(this.showPopUp) && this.currentLoggedInUserDetails.IsRedirectToChangePassword == 'N') {
        this.loginService.setDashboardParameter(true);
        this.router.navigate(['/dashboard']);
     }
    },
      (err: any) => {
        localStorage.removeItem('userToken');
        localStorage.removeItem('currentUser');
        localStorage.removeItem('menu');
        this.loginValid = true;
        this.errorMessage = err.statusText;
        console.log(err);
      });

  }
  // ngAfterViewInit(){
  //   this.ShowCaptcha();//uncomment this for captcha
  // }

  getResponceCapcha(captchaResponse: string) {
    this.userVerificationResponse = captchaResponse;
  }
}

