export class Incident {
    id: number;
    incidentId: string;
    interactionId: string;
    openDate: Date;
    openDTAgeing: string;
    resolvedDTAgeing: string;
    location: string;
    serviceRecepient: string;
    storeType: string;
    status: string;
    subCategory: string;
    area: string;
    subArea: string;
    source: string;
    assignmentGroup: string;
    openedBy: string;
    assignee: string;
    technicianName: string;
    title: string;
    description: string;
    resolution: string;
    resolvedTime: Date;
    resolutionCode: string;
    reopenCount: number;
    updateTime: Date;
    priorityCode: string;
    category: string;
    alternateContact: string;
    emailId: string;
    city: string;
    state: string;
    affectedItem: string;
    ownerName: string;
    callbackType: string;
    handleTime: string;
    closeTime: Date;
    closedBy: string;
    criticalUser: string;
    department: string;
    callbackContact: string;
    remarks: string;
    sla: number;
    roId: number;
    isOTPSent:boolean =false;
    resendOTP:boolean =false;
  }

  export class IncidentReject {
    id: number;
    incidentId: string;
    interactionId: string;
      remarksByVS:string;
      rejectFlag:number;
      //rejectedBy:string;
      //modifiedDate:Date


  }