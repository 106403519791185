//import { CheckListSaveDto } from '../common/vendor.model';

export class efsrcreate {
  roname: string;
  rocode: string;
  city:string;
    roaddress1: string;
    rophone1: string;
    reference: string;
    visitdate: Date;
    hpsmid: string;
    sla: string;
    sename: string;
    semobile: string;
    callstatus: string;
    vendor:string;
    service:string;
    device:string;
    spare:string;
    remark:string;
    quantity:string;
    servicesummary:string;
    //rokeyperson:string;
    //rokeypersonmobile:string;
    dealername:string;
    dealerphone:string;
    rating:number;
    //sEEmail:string;
    poorratingcomments:string;
    checklist:CheckListSaveDto[];
  }

  export class CheckListSaveDto {
    id: number;
    name: string;
  }

  export class CheckListPullDto {
    id: number;
  }

  export class hpsmandsedetails {
    hpsmid: string;
    sla: string;
    sename: string;
    semobile: string;
    }

    export class EFSRApproveReject {
      efsrId: number;
      seId:number;
      ratingByRO: number;
      incidentId:string;
      ratingCommentsByRO: string;
      rejectReason: string;
      roKeyPersonName:string;
      approveReason:string;
      approvedFlag: number;
      rejectFlag: number;
      vSEmail:string;
      }
  

    export class efsrDraft {
      efsrId: number
      roName: string
      roCode:string
      roCity: string
      roAddress: string
      roContactNo: string
      visitDate: Date
      reference: string
      hPSMId: string
      typeOfDevice: string
      typeOfVendor:string
      typeOfServicing: string
      callStatus: string
      slaInHand: number
      sparesConsumedFlag: number
      remarkOfSparesConsumed: string
      quantity: number
      serviceSummary: string
      seId:number
      roKeyPersonName: string
      roKeyPersonMobileNo: string
      rating: number
      ratingComments: string
      approvedFlag: number
      approvedBy: string
      approvedDate: Date
      createdDate: Date
      createdBy: string
      modifiedDate: Date
      modifiedBy: string
      incidentId: string
      checklist:Number[]
      isSubmitted: number
      //seemail:string;
    }

    export class patchefsrDraft {
      efsrId: number
      roName: string
      roCode: string
      roCity:string
      roAddress: string
      roContactNo: string
      visitDate: Date
      reference: string
      hPSMId: string
      //seId:number
      typeOfDevice: string
      typeOfVendor:string
      typeOfServicing: string
      callStatus: string
      slaInHand: number
      sparesConsumedFlag: number
      remarkOfSparesConsumed: string
      quantity: number
      serviceSummary: string
      
      roKeyPersonName: string
      roKeyPersonMobileNo: string
      rating: number
      ratingByRO:number
      ratingCommentsByRO:string
      ratingComments: string
      approvedFlag: number
      rejectFlag:number
      approvedBy: string
      approvedDate: Date
      createdDate: Date
      createdBy: string
      modifiedDate: Date
      modifiedBy: string
      incidentId: string
      checklist:number[]
      isSubmitted: number
      sename: string;
      approveReason:string;
      //seemail:string;
      semobile: string;
      rejectReason: string;
    }
    