import { Component, OnInit,ChangeDetectorRef, ViewChild } from '@angular/core';
import { FormGroup, FormControl, Validators, FormBuilder, ValidatorFn, AbstractControl } from '@angular/forms';
import { MatDialog, MatSnackBar } from '@angular/material';
import {userlistService} from 'src/app/Services/Reports/userlist.service'
import { ISearchPara } from 'src/app/Models/Reports/workingincidenthistory.model';
import {rovendorpara} from 'src/app/Models/Reports/rovendorpara.model';
import { Observable } from 'rxjs';
import { ConfigurationService } from 'src/app/Services/Utilities/configuration.service';
import {ISearchUser} from 'src/app/Models/Reports/workingincidenthistory.model';
import { Constants } from 'src/app/Constants/constants';
import { applicationconstant } from 'src/app/Models/DropdownItemsGroup/applicationconstant.model';


@Component({
  selector: 'app-rovendormappinglist',
  templateUrl: './rovendormappinglist.component.html',
  styleUrls: ['./rovendormappinglist.component.scss']
})

export class RovendormappinglistComponent implements OnInit {

  IncidentList: any=[];
  public btnSearch:string= 'Hide';
  public btnExport:string='Hide';
  userpara:ISearchUser;
  
  public SearchUserListForm: FormGroup;
  isFormSectionVisible: boolean = true;

  searchTypes = ['SUBCATEGORY','AREANAME','SUBAREANAME','VENDOR ID','ROCODE'];
  searched = false;

  constructor(private userlistService: userlistService,
    private fb: FormBuilder,
    public snackBar: MatSnackBar,
    private configurationService: ConfigurationService,
    private cdr:ChangeDetectorRef) { 
      this.SearchUserListForm = this.fb.group({
        searchType: new FormControl(null, Validators.required),
        searchTerm: new FormControl(null, Validators.compose([
          Validators.required,
          Validators.maxLength(100),
        ]))
      });
  }

   /**
   * User search validation messages
   *
   * @memberof UserEditComponent
   */
  user_search_validation_messages = {
    'searchtype': [
      { type: 'required', message: 'Search type is required' }
    ],
    'searchterm': [
      { type: 'required', message: 'Search value is required' },
      { type: 'maxlength', message: 'Search value is cannot be more than 100 characters' }
    ]
  };
  
  ResetSearch()
  {
    this.SearchUserListForm.reset();
    this.onSearch();
    this.btnSearch='Hide';
    this.btnExport='Show';
  }
  
  ngOnInit() {
    this.onSearch(); 
    this.IncidentList=[];
  }

  onSearch() {
    //this.searched = true;

    const searchFormData: any = Object.assign({}, this.SearchUserListForm.value);
    var searchType = searchFormData.searchType;
    const searchTerm = searchFormData.searchTerm;
     
    if(searchType=="User ID")
    {
      searchType="Username";
    }
    if(searchType=="VENDOR ID")
    {
      searchType="VENDOR";
    }
    const userData=new ISearchUser()  ;
    userData.searchTerm=searchTerm;
    userData.searchType=searchType;
      this.userlistService.searchROVendorData(userData).subscribe(
        (data: any) => {
          
          if (data.flag == applicationconstant.SuccessFlag) {
            this.IncidentList = data.results;
            this.openSnackBar('Data found and displayed','');
            this.btnSearch='Hide';
            this.btnExport='Show';
          }
          else {
            this.openSnackBar('Data not found for selected criteria','');
            this.IncidentList = [];
            this.btnSearch='Show';
            this.btnExport='Hide';
          }
        },
        (err: any) => this.openSnackBar(err.error.message, ''),
        () => { }
      );
    

  }

  ExporttoExcel(){
    let changed = true;
    const searchFormData: any = Object.assign({}, this.SearchUserListForm.value);
    var searchType = searchFormData.searchType;
    const searchTerm = searchFormData.searchTerm;
     
    if(searchType=="User ID")
    {
      searchType="Username";
    }
    if(searchType=="VENDOR ID")
    {
      searchType="VENDOR";
    }
    const userData=new ISearchUser();
    userData.searchTerm=searchTerm;
    userData.searchType=searchType;

    if (changed) {
      this.userlistService.searchROVendorData(userData).subscribe(
        (data: any) => {
          //this.openSnackBar(data.message, '');
          if (data.flag == applicationconstant.SuccessFlag) {
            this.IncidentList = data.results;
            this.userlistService.exportExcel(this.IncidentList, 'Working_Incident_History_Report');
            this.openSnackBar('Data Exported Successfully','');
          }
          else {
            this.openSnackBar('No Data Found To Export','');
            //this.IncidentList = [];
          }
        },
        (err: any) => this.openSnackBar(err.error.message, ''),
        () => { }
      );
    }
  }

  onSubmit(form: any) {
  }

  openSnackBar(message: string, action: string) {
    this.snackBar.open(message, action, {
      duration: 5000,
      verticalPosition: 'top'
    });
  }
  private handleError(error: any) {
    return Observable.throw(error);
  }

}

