import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, FormBuilder, Validators, FormGroupDirective, NgForm } from '@angular/forms'
import { MatDialog, MatSnackBar } from '@angular/material';
import { Router } from '@angular/router';
import { applicationconstant } from 'src/app/Models/DropdownItemsGroup/applicationconstant.model';
import { Ticket } from 'src/app/Models/vendorsupervisor/ticket.model';
import { Incident } from 'src/app/Models/vendorsupervisor/incident.model';
import { DropdownItems } from '../../Models/DropdownItemsGroup/dropdownitems.model';
import { DropdownItemsGroup } from '../../Models/DropdownItemsGroup/dropdownitemsgroup.model';
import { VendorSupervisorService } from 'src/app/Services/vendorsupervisor/vendorsupervisor.service';
import {MatSortModule} from '@angular/material/sort';
import {Sort} from '@angular/material/sort';
import{EFSR} from 'src/app/Models/EFSR/efsr.model';
import { TicketsAssginedSE } from 'src/app/Services/ServiceEngineer/TicketsAssignedSE.service';
import { SearchDTO, SearchRODTO } from 'src/app/Models/common/search.model';
import  {CloseIncidentDialogComponent} from 'src/app/Component/ro/RejectSmmSMEDialog/close-incident-dialog/close-incident-dialog.component';
import { efsrService } from 'src/app/Services/EFSR/efsrcreate.service';
@Component({
  selector: 'app-ro-incident-management',
  templateUrl: './ro-incident-management.component.html',
  styleUrls: ['./ro-incident-management.component.scss']
})
export class RoIncidentManagementComponent implements OnInit {

  roSearchForm: FormGroup;
  incidentList: Incident[];
  searched = false;
  ticketSearchType: DropdownItems[];
  ticketValues:string;
  assignedIncidentStatus: string;
  openIncidentStatus: string;
  workinprogressIncidentStatus: string;
  efsrWIPIncidentStatus: string;
  roapprovalIncidentStatus: string;
  workapprovedIncidentStatus: string;
  closedIncidentStatus: string;
  searchTermStatus: string;
  showValidateSE: boolean =false;
  showResendOTP: boolean = false;

  validationMessages = {
    'searchType': {
      'required': 'Search type is required'
    },
    'searchTerm': {
      'required': 'Search Term is required'
    },
  };
  formErrors = {
    'searchType': '',
    'searchTerm': ''
  };

  constructor(private fb: FormBuilder, public snackBar: MatSnackBar, private router: Router, private VendorService: VendorSupervisorService,private dialog:MatDialog,private EngineerService: TicketsAssginedSE, private efsrService:efsrService ) 
  { 
    this.getStatusList();
    this.getDropdownItems();


    this.roSearchForm = this.fb.group({
    searchType: new FormControl(null, Validators.required),
    searchTerm: new FormControl(null, Validators.required),
   });
  }

  ngOnInit() {
    this.roSearchForm.valueChanges.subscribe((data) => {
      this.logValidationErrors(this.roSearchForm);
    });
    this.showValidateSE = true;
    this.getTicketList('','');
    //.showLoader=false;
  }

  public getStatusList() {
    this.assignedIncidentStatus = applicationconstant.Incident_Assigned_Status;
    this.openIncidentStatus = applicationconstant.Incident_Open_Status;
    this.workinprogressIncidentStatus = applicationconstant.Incident_WIP_Status;
    this.efsrWIPIncidentStatus = applicationconstant.Incident_EFSRWIP_Status;
    this.roapprovalIncidentStatus = applicationconstant.Incident_ROApproval_Status;
    this.workapprovedIncidentStatus = applicationconstant.Incident_WorkApproved_Status;
    this.closedIncidentStatus = applicationconstant.Incident_Closed_Status;
  }

  onSearchTicket() {
    if (this.roSearchForm.valid) {
      const searchFormData: any = Object.assign({}, this.roSearchForm.value);
      const searchType = searchFormData.searchType;
      const searchTerm = searchFormData.searchTerm;
      if (searchType == applicationconstant.Ticket_Search_Status && searchTerm.trim().toUpperCase() == applicationconstant.Incident_Assigned_Status.toUpperCase()) {

        this.searchTermStatus = this.assignedIncidentStatus;
      }
      if (searchType == applicationconstant.Ticket_Search_Status && searchTerm.trim().toUpperCase() == applicationconstant.Incident_Open_Status.toUpperCase()) {

        this.searchTermStatus = this.openIncidentStatus;
      }
      if (searchType == applicationconstant.Ticket_Search_Status && searchTerm.trim().toUpperCase() == applicationconstant.Incident_WIP_Status.toUpperCase()) {

        this.searchTermStatus = this.workinprogressIncidentStatus;
      }
      if (searchType == applicationconstant.Ticket_Search_Status && searchTerm.trim().toUpperCase() == applicationconstant.Incident_EFSRWIP_Status.toUpperCase()) {

        this.searchTermStatus = this.efsrWIPIncidentStatus;
      }
      if (searchType == applicationconstant.Ticket_Search_Status && searchTerm.trim().toUpperCase() == applicationconstant.Incident_ROApproval_Status.toUpperCase()) {

        this.searchTermStatus = this.roapprovalIncidentStatus;
      }
      if (searchType == applicationconstant.Ticket_Search_Status && searchTerm.trim().toUpperCase() == applicationconstant.Incident_WorkApproved_Status.toUpperCase()) {

        this.searchTermStatus = this.workapprovedIncidentStatus;
      }
      if (searchType == applicationconstant.Ticket_Search_Status && searchTerm.trim().toUpperCase() == applicationconstant.Incident_Closed_Status.toUpperCase()) {

        this.searchTermStatus = this.closedIncidentStatus;
      }

      if (searchType == applicationconstant.Ticket_Search_Status && (
        searchTerm.trim().toUpperCase() == applicationconstant.Incident_Assigned_Status.toUpperCase() ||
        searchTerm.trim().toUpperCase() == applicationconstant.Incident_Open_Status.toUpperCase() ||
        searchTerm.trim().toUpperCase() == applicationconstant.Incident_WIP_Status.toUpperCase() ||
        searchTerm.trim().toUpperCase() == applicationconstant.Incident_ROApproval_Status.toUpperCase() ||
        searchTerm.trim().toUpperCase() == applicationconstant.Incident_WorkApproved_Status.toUpperCase() ||
        searchTerm.trim().toUpperCase() == applicationconstant.Incident_Closed_Status.toUpperCase() ||
        searchTerm.replace(/\s/g, "").toUpperCase() == applicationconstant.Incident_EFSRWIP_Status.toUpperCase()
      )) {
        this.getTicketList(searchType, this.searchTermStatus.toLocaleString());
      }
      else {
        this.getTicketList(searchType, searchTerm);
      }
    }
    else {
      this.ValidateAllFields(this.roSearchForm);
      this.logValidationErrors(this.roSearchForm);
    }
    
  }

  ResetSearch()
  {
    this.roSearchForm.reset();
    this.ticketValues=this.ticketSearchType.map(x=>x.mstValue).join(' / ');
    this.getTicketList('','');
  }

  onSearchChange(value: any) {
    this.ticketValues=this.ticketSearchType.filter(x=>x.mstKey==value).map(x=>x.mstValue).toString();
  }

  public getTicketList(searchParam: string, searchValue: string) {
    const currentUser = JSON.parse(localStorage.getItem('currentUser'));
    //7128
    var searchValues =  new SearchRODTO();
    searchValues.searchParam = searchParam;
    searchValues.searchValue = searchValue;
    searchValues.roId = parseInt(currentUser.ROID);
    this.VendorService.getROIncidentList(searchValues)
      .subscribe((data) => {
        if (data.flag == applicationconstant.SuccessFlag) {
          this.incidentList = data.results;
        }
        else {
          this.openSnackBar(data.message, '');
          this.incidentList = [];
        }
      }),
      (err: any) => {
        this.openSnackBar(err.error.message, 'error');
      }
  }
  

  public ViewDetails(ticketId: number) {
    const currentUser = JSON.parse(localStorage.getItem('currentUser'));
    this.VendorService.setTicketId(ticketId);
    this.VendorService.setROId(parseInt(currentUser.ROID));
    this.VendorService.setRequestFromRO(true);

    this.router.navigate(['supervisor/viewticket']);
  }

  public RejectTicket(ticketId:number, interactionId:string){
    this.VendorService.setTicketId(ticketId);
    this.EngineerService.setIncidentId(interactionId);
    if(ticketId!=null){

      const dialogRef = this.dialog.open(CloseIncidentDialogComponent, {
            width: '500px',
            data: {ticketId : ticketId,interactionId:interactionId} //sEEMAIL:this.sEEMAIL}
          });
          dialogRef.afterClosed().subscribe(result => {
            if (result) {
              this.router.navigate(['supervisor/ticketsearch']);
              this.getTicketList('','');
            } 
          });
  }
}


  public ApproveEFSR(ticketId: number,ticketInteractionId: string) {
 
    this.EngineerService.checkEFSRForRO(ticketInteractionId)
    .subscribe((data) => {
      if (data.flag == applicationconstant.SuccessFlag) {
        this.EngineerService.setEFSRId(data.returnId);
        //this.EngineerService.setEFSRExists(true);
        this.VendorService.setTicketId(ticketId);
        this.VendorService.setTicketInteractionId(ticketInteractionId);
        this.router.navigate(['RO/view-efsr']);
       
      }
      else {
        this.openSnackBar(data.message, '');
     
      }
    }),
    (err: any) => {
      this.openSnackBar(err.error.message, 'error');
     
    }
  }

  

  public ViewEFSR( ticketId:number, ticketInteractionId: string) {
    this.VendorService.setRequestFromRO(true);
    this.VendorService.setTicketId(ticketId);
    this.VendorService.setTicketInteractionId(ticketInteractionId);
    this.EngineerService.checkEFSRForRO(ticketInteractionId)
    .subscribe((data) => {
      if (data.flag == applicationconstant.SuccessFlag) {
    this.EngineerService.setEFSRId(data.returnId);
    this.EngineerService.setIncidentId(ticketInteractionId);
      
         // this.VendorService.setTicketId(ticketId);
     // this.EngineerService.setIncidentId(ticketInteractionId);
       this.router.navigate(['RO/view-efsr']);
      
        
     }
      else{
       // this.EngineerService.setEFSRExists(false);
        this.openSnackBar(data.message, 'error');
      }
    }),
   (err: any) => {
     this.openSnackBar(err.error.message, 'error');
   }

    }
      
    SendSMSOTP(ticketId: number) {
    this.EngineerService.SendOTPToValidateSE(ticketId).subscribe((data: any) => {
      if (data.flag == applicationconstant.SuccessFlag) {
        this.openSnackBar(data.message, '');
        this.showValidateSE = false;

        let otpSentIndex = this.incidentList.findIndex(item => item.id == ticketId);
        this.incidentList[otpSentIndex].isOTPSent = true;

        let resendOTPIndex = this.incidentList.findIndex(item => item.id == ticketId);
        this.incidentList[resendOTPIndex].resendOTP = true;
      }
      else {
        this.openSnackBar(data.message, '');
        let otpSentIndex = this.incidentList.findIndex(item => item.id == ticketId);
        this.incidentList[otpSentIndex].isOTPSent = true;

        let resendOTPIndex = this.incidentList.findIndex(item => item.id == ticketId);
        this.incidentList[resendOTPIndex].resendOTP = true;
      }
    }),
    (err: any) => {
      this.openSnackBar(err.error.message, 'error');
      let otpSentIndex = this.incidentList.findIndex(item => item.id == ticketId);
      this.incidentList[otpSentIndex].isOTPSent = true;

       let resendOTPIndex = this.incidentList.findIndex(item => item.id == ticketId);
        this.incidentList[resendOTPIndex].resendOTP = true;
    }
  }

  ViewSEDetails(ticketId: number) {
    this.VendorService.setTicketId(ticketId);
    this.router.navigate(['SE/view-se']);
  }

  public getDropdownItems() {
    const params =
      `${DropdownItemsGroup.TICKET_SEARCH_PARAM}`;
    this.VendorService.getDropdownItems(JSON.stringify(params))
      .subscribe(items => {
        if (items.flag == applicationconstant.SuccessFlag) {
          this.setDropdownItemsAsPerGroup(items.results);
        }
        else {
          this.openSnackBar(items.message, '')
        }
      },
        (err: any) => this.openSnackBar(err.error.message, '')
      );
   }

  private setDropdownItemsAsPerGroup(itemsGrop: any) {
    if (itemsGrop.length > 0) {
      this.ticketSearchType = [];
      for (let item of itemsGrop) {
        if (item.mstGroup == DropdownItemsGroup.TICKET_SEARCH_PARAM) {
          this.ticketSearchType.push(item);
        }
      }
    }
    this.ticketValues=this.ticketSearchType.map(x=>x.mstValue).join(' / '); 
  }

  logValidationErrors(group: FormGroup = this.roSearchForm): void {
    Object.keys(group.controls).forEach((key: string) => {
      const abstractControl = group.get(key);
      if (abstractControl instanceof FormGroup) {
        this.logValidationErrors(abstractControl)
      } else {
        this.formErrors[key] = '';
        if (abstractControl && !abstractControl.valid && (abstractControl.touched || abstractControl.dirty)) {
          const messages = this.validationMessages[key];

          for (const errorKey in abstractControl.errors) {
            if (errorKey) {
              this.formErrors[key] += messages[errorKey] + '';
            }
          }
        }
      }
    });
  }

  ValidateAllFields(formGroup: FormGroup) {
    Object.keys(formGroup.controls).forEach(field => {
      const control = formGroup.get(field);
      if (control instanceof FormControl) {
        control.markAsTouched({ onlySelf: true });
      } else if (control instanceof FormGroup) {
        this.ValidateAllFields(control);
      }
    });
  }

  openSnackBar(message: string, action: string) {
    this.snackBar.open(message, action, {
      duration: 5000,
      verticalPosition: 'top'
    });
  }

}
