import { Component, OnInit, NgZone } from '@angular/core';
import { FormGroup, FormControl, FormBuilder, Validators } from '@angular/forms';
import { MatDialog, MatSnackBar } from '@angular/material';
import { Router } from '@angular/router';
import { AssignTicket } from 'src/app/Models/TicketsSE/AssignTicket.model';
import { Observable } from 'rxjs';
//import{ TicketsAssginedSE} from 'src/app/Services/ServiceEngineer/TicketsAssginedSE.service';
import{AssignTickettoSEComponent} from 'src/app/Component/ServiceEngineer/assign-ticketto-se/assign-ticketto-se.component';
import { Ticket } from 'src/app/Models/vendorsupervisor/ticket.model';
import { Incident } from 'src/app/Models/vendorsupervisor/incident.model';
import{ VendorSupervisorService } from 'src/app/Services/vendorsupervisor/vendorsupervisor.service'
@Component({
  selector: 'app-view-tickets',
  templateUrl: './view-tickets.component.html',
  styleUrls: ['./view-tickets.component.scss']
})
export class ViewTicketsComponent implements OnInit {
      TicketForm:FormGroup;
      ticketId : number;
      incidentIdToken : any;
      requestFromSupervisor : boolean = false;
      requestFromRO : boolean = false;
      showRemarksDetails = false;
      remarksFromVS: string;
  constructor(private fb: FormBuilder,private vendorservice: VendorSupervisorService,public snackBar: MatSnackBar, private dialog: MatDialog, 
    private router: Router)   { 
    this.TicketForm = this.ValidateTicketDetails();
  
   }
  ngOnInit() {
    const currentUser = JSON.parse(localStorage.getItem('currentUser'));
    this.ticketId = this.vendorservice.getTicketId();
    this.requestFromSupervisor = this.vendorservice.getRequestFromVendor();
    this.requestFromRO = this.vendorservice.getRequestFromRO();
     if (currentUser.Role == "SEROLE")
     {
      this.showRemarksDetails = true;
      //this.remarksFromVS=String(this.vendorservice.Remark);
     }
    
    if (this.ticketId != null || this.ticketId != undefined)
    {
      this.incidentIdToken = this.ticketId;
    }
    if (localStorage.getItem('incidentIdToken') == null ||localStorage.getItem('incidentIdToken') == undefined) 
    {
      localStorage.setItem('incidentIdToken', this.incidentIdToken);
    }
    if (this.ticketId == undefined)
    {
      this.ticketId = Number(localStorage.getItem('incidentIdToken'));
    }
    
   this.vendorservice.getticket(this.ticketId)
       .subscribe((data: any) => {
         if (data != null)
         {
            this.SetticketIdDetails(data.results);
         }
       },
       (err: any) => {
        this.openSnackBar(err.error.message, 'error');
       });
   }
       public SetticketIdDetails(incident: Incident) {
        if (incident != null) {
          var priority = ""; 
          if (incident[0].priorityCode == "1")
          {
            priority = "CRITICAL";
          }
          if (incident[0].priorityCode == "2")
          {
            priority = "HIGH";
          }
          if (incident[0].priorityCode == "3")
          {
            priority = "MEDIUM";
          }
          if (incident[0].priorityCode == "4")
          {
            priority = "LOW";
          }
          this.TicketForm.patchValue({
            id : this.ticketId ,
            incidentId : incident[0].incidentId,
            interactionId : incident[0].interactionId,
            openDate : incident[0].openDate,
            openDTAgeing : incident[0].openDTAgeing,
            resolvedDTAgeing : incident[0].resolvedDTAgeing,
            location : incident[0].location,
            serviceRecepient : incident[0].serviceRecepient,
            storeType: incident[0].storeType,
            status : incident[0].status,
            subCategory : incident[0].subCategory,
            area : incident[0].area,
            subArea : incident[0].subArea,
            source : incident[0].source,
            assignmentGroup : incident[0].assignmentGroup,
            openedBy : incident[0].openedBy,
            assignee : incident[0].assignee,
            technicianName : incident[0].technicianName,
            title : incident[0].title,
            description : incident[0].description,
            resolution : incident[0].resolution,
            resolvedTime : incident[0].resolvedTime,
            resolutionCode : incident[0].resolutionCode,
            reopenCount : incident[0].reopenCount,
            updateTime : incident[0].updateTime,
            priorityCode : priority,
            emailId : incident[0].emailId,
            city : incident[0].city,
            state : incident[0].state,
            affectedItem : incident[0].affectedItem,
            ownerName : incident[0].ownerName,
            callbackType : incident[0].callbackType,
            handleTime : incident[0].handleTime,
            closeTime : incident[0].closeTime,
            closedBy : incident[0].closedBy,
            criticalUser : incident[0].criticalUser,
            department : incident[0].department,
            callbackContact : incident[0].callbackContact,
            sla :incident[0].sla,
            remarks :incident[0].remarks,
          });
        }
      }

      public ValidateTicketDetails() {
        return this.TicketForm = this.fb.group({
           id : new FormControl(),
           incidentId :new FormControl(),
           interactionId : new FormControl(),
           openDate :new FormControl(),
           openDTAgeing :new FormControl(),
           resolvedDTAgeing :new FormControl(),
           location :new FormControl(),
           serviceRecepient :new FormControl(),
           storeType:new FormControl(),
           status :new FormControl(),
           subCategory :new FormControl(),
           area :new FormControl(),
           subArea :new FormControl(),
           source :new FormControl(),
           assignmentGroup :new FormControl(),
           openedBy :new FormControl(),
           assignee :new FormControl(),
           technicianName :new FormControl(),
           title :new FormControl(),
           description :new FormControl(),
           resolution :new FormControl(),
           resolvedTime :new FormControl(),
           resolutionCode :new FormControl(),
           reopenCount :new FormControl(),
           updateTime :new FormControl(),
           priorityCode :new FormControl(),
           emailId :new FormControl(),
           city :new FormControl(),
           state :new FormControl(),
           affectedItem :new FormControl(),
           ownerName :new FormControl(),
           callbackType :new FormControl(),
           handleTime :new FormControl(),
           closeTime :new FormControl(),
           closedBy :new FormControl(),
           criticalUser :new FormControl(),
           department :new FormControl(),
           callbackContact :new FormControl(),
           sla:new FormControl(),
           remarks:new FormControl()
        });
      }
      
      public openSnackBar(message: string, action: string) {
        this.snackBar.open(message, action, {
          duration: 5000,
          verticalPosition: 'top'
        });
      }
      private handleError(error: any) {
        return Observable.throw(error);
      }
      public backToList()
        {
          if(this.requestFromSupervisor)
          {
            this.vendorservice.setRequestFromVendor(false);
            this.router.navigate(['supervisor/ticketsearch']);
          }
          else if(this.requestFromRO)
          {
            this.vendorservice.setRequestFromRO(false);
            this.router.navigate(['RO/incidentsearch']);
          }
          else{
            //this.vendorservice.setRequestFromVendor(false);
            this.router.navigate(['serviceengineer/incidentsearch']);
          }
         
        }
        ngOnDestroy() {
          this.requestFromSupervisor = false;
        }
}
