import { Injectable } from '@angular/core';
//import { SECreate } from 'src/app/Models/ServiceEngineer/SECreate.model';
import{VSSCreate} from 'src/app/Models/vendorsupervisor/VSS-Create.model';
import { Observable, throwError } from 'rxjs';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { map, catchError, tap } from 'rxjs/operators';
import { HttpVerbService } from 'src/app/Services/httpverb/httpverb.service';

import { IUserType } from 'src/app/Models/usermanagement/usertype.model';
import { IState } from 'src/app/Models/common/state.model';
import { environment } from 'src/environments/environment';
import { Icity } from 'src/app/Models/common/city.model';
import{ IName } from 'src/app/Models/TicketsSE/engineername.model'
import{ AssignTicket } from 'src/app/Models/TicketsSE/AssignTicket.model'


/**
 * User service for user and role management
 *
 * @export
 * @class UserService
 */
@Injectable({
  providedIn: 'root'
})
export class VSSCreateService {

  /**
   * Base url where the api is hosted
   *
   * @type {string}
   * @memberof UserService
   */
  baseUrl: string;

  /**
   * Creates an instance of UserService.
   * @param {HttpClient} http
   * @param {HttpVerbService} service
   * @memberof UserService
   */
  constructor(private http: HttpClient, private service: HttpVerbService) {
    this.baseUrl = environment.restUrl + '/api/v1/';
  }
 /**
   * Create new user with the details provide in the form
   *
   * @param {User} newUser
   * @returns {Observable<any>}
   * @memberof UserService
   */
  createUser(newUser: VSSCreate): Observable<any> {
    return this.http.post<any>(this.baseUrl + 'createSEuser', newUser, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    });
  }

  createVSSUser(newUser: any): Observable<any> {
    return this.http.post<any>(this.baseUrl + 'createVssuser', newUser, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    });
  }
  /**
   * Search user the search type and search parameter
   *
   * @param {*} [searchType=null]
   * @param {*} [searchTerm=null]
   * @returns {Observable<User>}
   * @memberof UserService
   */
  searchUser(searchType = null, searchTerm = null): Observable<VSSCreate> {
    // encoding the search term into base64 format
    const url = this.baseUrl + 'seusers/search?searchType=' + searchType + '&parameter=' + btoa(searchTerm);
    return this.http.get<VSSCreate>(url);
  }

 

  // searchUserVS(searchType = null, searchTerm = null): Observable<any> {
  //   // encoding the search term into base64 format
  //   const url = this.baseUrl + 'vsusers/search?searchType=' + searchType + '&parameter=' + btoa(searchTerm);
  //   return this.http.get<any>(url);
  // }

  searchUserVS(searchType = null, searchTerm = null): Observable<any> {
    return this.http.get<any>(this.baseUrl+'vsusers/search?searchType=' + searchType + '&parameter=' + searchTerm, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'No-Auth': 'True'
      })
    });
  }

  searchUserForEdit(searchType = null, searchTerm = null): Observable<VSSCreate> {
    // encoding the search term into base64 format
    const url = this.baseUrl + 'seusers/searchemail?searchType=' + searchType + '&parameter=' + btoa(searchTerm);
    return this.http.get<VSSCreate>(url);
  }
  /**
   * Update user with changed user details for edit user form
   *
   * @param {User} userData
   * @returns {Observable<any>}
   * @memberof UserService
   */
  updateUser(userData: VSSCreate): Observable<any> {
    return this.http.post<any>(this.baseUrl + 'seupdate', userData, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    });
  }

  /**
   * Get all active States
   *
   * @returns {Observable<IState[]>}
   * @memberof UserService
   */
  getStates(): Observable<IState[]> {
    const url = this.baseUrl + 'states';
    return this.service.get(url).pipe(map((response: any) => {
      return response;
    }));
  }

  getVSSStates(): Observable<IState[]> {
    const url = this.baseUrl + 'vssstates';
    return this.service.get(url).pipe(map((response: any) => {
      return response;
    }));
  }

  getVSSCities(): Observable<IState[]> {
    const url = this.baseUrl + 'vsscities';
    return this.service.get(url).pipe(map((response: any) => {
      return response;
    }));
  }


  getHTSMData(): Observable<IState[]> {
    const url = this.baseUrl + 'PostData';
    return this.service.get(url).pipe(map((response: any) => {
      return response;
    }));
  }

   /**
   * Get all active States
   *
   * @returns {Observable<IState[]>}
   * @memberof UserService
   */
  getCity(state:string): Observable<Icity[]> {
    const url = this.baseUrl + 'city?state=' + state;
    return this.service.get(url).pipe(map((response: any) => {
      return response;
    }));
  }

  

  getNames(): Observable<IName[]> {
    const url = this.baseUrl + 'getNames';
    return this.service.get(url).pipe(map((response: any) => {
      return response;
    }));
  }
  getUniqueUserName(): Observable<any> {
     return this.http.get<any>(this.baseUrl + 'vssgetUniqueUserName')
    //, {
    // headers: new HttpHeaders({
    // 'Content-Type': 'application/json'
    
    // })
    // })
    .pipe(map((response: any) => {
    return response;
    }
    ));
    } 
  AssignTicekToSE(newUser: AssignTicket): Observable<any> {
    return this.http.post<any>(this.baseUrl + 'AssignTicektToSE', newUser, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    });
  }

  getVSSCity(state:string[]): Observable<Icity[]> {
    return this.http.post<any>(this.baseUrl + 'vsscity', state, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    });
  }

}
