import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, FormBuilder, Validators, FormGroupDirective, NgForm } from '@angular/forms'
import { MatDialog, MatSnackBar } from '@angular/material';
import { Router } from '@angular/router';
import { applicationconstant } from 'src/app/Models/DropdownItemsGroup/applicationconstant.model';
import { Ticket } from 'src/app/Models/vendorsupervisor/ticket.model';
import { efsrService } from 'src/app/Services/EFSR/efsrcreate.service';
import { Incident } from 'src/app/Models/vendorsupervisor/incident.model';
import { DropdownItems } from 'src/app/Models/DropdownItemsGroup/dropdownitems.model';
import { DropdownItemsGroup } from 'src/app/Models/DropdownItemsGroup/dropdownitemsgroup.model';
import { VendorSupervisorService } from 'src/app/Services/vendorsupervisor/vendorsupervisor.service';
import { TicketsAssginedSE } from 'src/app/Services/ServiceEngineer/TicketsAssignedSE.service';
import { VerifyincidentComponent } from 'src/app/Component/EFSR/verifyincident/verifyincident.component';
import { SearchDTO } from 'src/app/Models/common/search.model';
import { ConfirmationDeleteComponent } from 'src/app/Component/EFSR/Confirmation/confirmation-delete/confirmation-delete.component';
import { ConfirmationDialogDeleteComponent } from 'src/app/Component/EFSR/confirmation-dialog-delete/confirmation-dialog-delete.component';
import { SERemarkDialogComponent } from '../SeRemarkDialog/seremark-dialog/seremark-dialog.component';

@Component({
  selector: 'app-se-incident-management',
  templateUrl: './se-incident-management.component.html',
  styleUrls: ['./se-incident-management.component.scss']
})
export class SeIncidentManagementComponent implements OnInit {

  engineerSearchForm: FormGroup;
  //ticketList: Ticket[];
  incidentList: Incident[];
  searched = false;
  ticketSearchType: DropdownItems[];
  ticketValues:string;

  assignedIncidentStatus: string;
  openIncidentStatus: string;
  workinprogressIncidentStatus: string;
  efsrWIPIncidentStatus: string;
  roapprovalIncidentStatus: string;
  workapprovedIncidentStatus: string;
  closedIncidentStatus: string;

  closedTicketStatus: string;
  openTicketStatus: string;
  onholdTicketStatus: string;
  inprogressTicketStatus: string;


  searchTermStatus: string;

  validationMessages = {
    'searchType': {
      'required': 'Search type is required'
    },
    'searchTerm': {
      'required': 'Search Term is required'
    },
  };
  formErrors = {
    'searchType': '',
    'searchTerm': ''
  };

  constructor(private fb: FormBuilder,private EngineerService: TicketsAssginedSE, private VendorService: VendorSupervisorService,public snackBar: MatSnackBar, private router: Router,private dialog:MatDialog,
    private efsrservice: efsrService)
   { 
    this.getStatusList();
    this.getDropdownItems();

    this.engineerSearchForm = this.fb.group({
    searchType: new FormControl(null, Validators.required),
    searchTerm: new FormControl(null, Validators.required),
     });
   }

  ngOnInit() {

    this.engineerSearchForm.valueChanges.subscribe((data) => {
      this.logValidationErrors(this.engineerSearchForm);
    });

    this.getTicketList('','');
    
  }

  public getStatusList() {
    // this.closedTicketStatus = applicationconstant.Ticket_Search_Closed;
    // this.openTicketStatus = applicationconstant.Ticket_Search_Open;
    // this.onholdTicketStatus = applicationconstant.Ticket_Search_OnHold;
    // this.inprogressTicketStatus = applicationconstant.Ticket_Search_InProgress;

    this.assignedIncidentStatus = applicationconstant.Incident_Assigned_Status;
    this.openIncidentStatus = applicationconstant.Incident_Open_Status;
    this.workinprogressIncidentStatus = applicationconstant.Incident_WIP_Status;
    this.efsrWIPIncidentStatus = applicationconstant.Incident_EFSRWIP_Status;
    this.roapprovalIncidentStatus = applicationconstant.Incident_ROApproval_Status;
    this.workapprovedIncidentStatus = applicationconstant.Incident_WorkApproved_Status;
    this.closedIncidentStatus = applicationconstant.Incident_Closed_Status;
  }

  onSearchTicket() {
    if (this.engineerSearchForm.valid) {
      const searchFormData: any = Object.assign({}, this.engineerSearchForm.value);
      const searchType = searchFormData.searchType;
      const searchTerm = searchFormData.searchTerm;

      if (searchType == applicationconstant.Ticket_Search_Status && searchTerm.trim().toUpperCase() == applicationconstant.Incident_Assigned_Status.toUpperCase()) {

        this.searchTermStatus = this.assignedIncidentStatus;
      }
      if (searchType == applicationconstant.Ticket_Search_Status && searchTerm.trim().toUpperCase() == applicationconstant.Incident_Open_Status.toUpperCase()) {

        this.searchTermStatus = this.openIncidentStatus;
      }
      if (searchType == applicationconstant.Ticket_Search_Status && searchTerm.trim().toUpperCase() == applicationconstant.Incident_WIP_Status.toUpperCase()) {

        this.searchTermStatus = this.workinprogressIncidentStatus;
      }
      if (searchType == applicationconstant.Ticket_Search_Status && searchTerm.trim().toUpperCase() == applicationconstant.Incident_EFSRWIP_Status.toUpperCase()) {

        this.searchTermStatus = this.efsrWIPIncidentStatus;
      }
      if (searchType == applicationconstant.Ticket_Search_Status && searchTerm.trim().toUpperCase() == applicationconstant.Incident_ROApproval_Status.toUpperCase()) {

        this.searchTermStatus = this.roapprovalIncidentStatus;
      }
      if (searchType == applicationconstant.Ticket_Search_Status && searchTerm.trim().toUpperCase() == applicationconstant.Incident_WorkApproved_Status.toUpperCase()) {

        this.searchTermStatus = this.workapprovedIncidentStatus;
      }
      if (searchType == applicationconstant.Ticket_Search_Status && searchTerm.trim().toUpperCase() == applicationconstant.Incident_Closed_Status.toUpperCase()) {

        this.searchTermStatus = this.closedIncidentStatus;
      }
      if (searchType == applicationconstant.Ticket_Search_Status && (
        searchTerm.trim().toUpperCase() == applicationconstant.Incident_Assigned_Status.toUpperCase() ||
        searchTerm.trim().toUpperCase() == applicationconstant.Incident_Open_Status.toUpperCase() ||
        searchTerm.trim().toUpperCase() == applicationconstant.Incident_WIP_Status.toUpperCase() ||
        searchTerm.trim().toUpperCase() == applicationconstant.Incident_ROApproval_Status.toUpperCase() ||
        searchTerm.trim().toUpperCase() == applicationconstant.Incident_WorkApproved_Status.toUpperCase() ||
        searchTerm.trim().toUpperCase() == applicationconstant.Incident_Closed_Status.toUpperCase() ||
        searchTerm.replace(/\s/g, "").toUpperCase() == applicationconstant.Incident_EFSRWIP_Status.toUpperCase()
      )) {
        this.getTicketList(searchType, this.searchTermStatus.toLocaleString());
      }
      else {
        this.getTicketList(searchType, searchTerm);
      }

    }
    else {
      this.ValidateAllFields(this.engineerSearchForm);
      this.logValidationErrors(this.engineerSearchForm);
    }
    
  }

  ResetSearch()
  {
    this.engineerSearchForm.reset();
    this.ticketValues=this.ticketSearchType.map(x=>x.mstValue).join(' / ');
    this.getTicketList('','');
  }

  onSearchChange(value: any) {
    this.ticketValues=this.ticketSearchType.filter(x=>x.mstKey==value).map(x=>x.mstValue).toString();
  }

  public getTicketList(searchParam: string, searchValue: string) {
    var search = new SearchDTO();
    search.searchParam =searchParam;
    search.searchValue = searchValue;
    this.EngineerService.getIncidentList(search)
      .subscribe((data) => {
        if (data.flag == applicationconstant.SuccessFlag) {
          this.incidentList = data.results;
        }
        else {
          this.openSnackBar(data.message, '');
          this.incidentList = [];
        }
      }),
      (err: any) => {
        this.openSnackBar(err.error.message, 'error');
      }
  }

  public ViewDetails(ticketId: number) {
    this.VendorService.setTicketId(ticketId);
    this.VendorService.setRequestFromSE(true);
    this.router.navigate(['supervisor/viewticket']);
  }

  public FillEFSR(ticketId: number,incidentId: string,roId:number, status:string) {
    if (status == "ASSIGNED")
    {
      this.VendorService.setTicketId(ticketId);
      this.EngineerService.setROId(roId);
      this.EngineerService.setIncidentId(incidentId);
      this.EngineerService.checkEFSRExists(incidentId)
      .subscribe((data) => {
        if (data.flag == applicationconstant.SuccessFlag) {
          this.EngineerService.setEFSRId(data.returnId);
          this.EngineerService.setEFSRExists(true);
          //this.router.navigate(['EFSR/edit-efsr']);
          this.router.navigate(['EFSR/create-efsr']);
        }
        else {
          this.EngineerService.setEFSRExists(false);
          this.router.navigate(['EFSR/create-efsr']);
        }
      }),
      (err: any) => {
        this.openSnackBar(err.error.message, 'error');
      }
    }
    else
    {
      this.VendorService.setTicketId(ticketId);
      this.EngineerService.setROId(roId);
      this.EngineerService.setIncidentId(incidentId);
      this.EngineerService.checkEFSRExists(incidentId)
      .subscribe((data) => {
        if (data.flag == applicationconstant.SuccessFlag) {
          this.EngineerService.setEFSRId(data.returnId);
          this.EngineerService.setEFSRExists(true);
          //this.router.navigate(['EFSR/edit-efsr']);
          this.router.navigate(['EFSR/create-efsr']);
        }
        else {
          this.EngineerService.setEFSRExists(false);
          this.router.navigate(['EFSR/create-efsr']);
        }
      }),
      (err: any) => {
        this.openSnackBar(err.error.message, 'error');
      }
    }
    
  }

  public EFSRNotRequired(ticketId: number,incidentId: string,roId:number, status:string) {
      this.VendorService.setTicketId(ticketId);
      this.EngineerService.setROId(roId);
      this.EngineerService.setIncidentId(incidentId);
      const dialogRef = this.dialog.open(SERemarkDialogComponent, {
        width: '500px',
        data:{ incidentid:ticketId, roKeyPersonName: roId, incidentId:incidentId}
      });
      dialogRef.afterClosed().subscribe(result => {
        if (result) {
          this.router.navigate(['RO/incidentsearch']);
        }
        
      });

     
    

  }


  // public FillEFSR(ticketId: number,incidentId: string,roId:number, status:string) {
  //   if (status == "ASSIGNED")
  //   {
  //     const dialogRef = this.dialog.open(VerifyincidentComponent, {
  //       disableClose: true,
  //       width: '350px',
  //       data: ticketId
  //     });
  //     dialogRef.afterClosed().subscribe(result => {
  //       if (result) {
  //         this.VendorService.setTicketId(ticketId);
  //         this.EngineerService.setROId(roId);
  //         this.EngineerService.setIncidentId(incidentId);
  //         this.EngineerService.checkEFSRExists(incidentId)
  //         .subscribe((data) => {
  //           if (data.flag == applicationconstant.SuccessFlag) {
  //             this.EngineerService.setEFSRId(data.returnId);
  //             this.EngineerService.setEFSRExists(true);
  //             //this.router.navigate(['EFSR/edit-efsr']);
  //             this.router.navigate(['EFSR/create-efsr']);
  //           }
  //           else {
  //             this.EngineerService.setEFSRExists(false);
  //             this.router.navigate(['EFSR/create-efsr']);
  //           }
  //         }),
  //         (err: any) => {
  //           this.openSnackBar(err.error.message, 'error');
  //         }
  //       }
  //     });
  //   }
  //   else
  //   {
  //     this.VendorService.setTicketId(ticketId);
  //     this.EngineerService.setROId(roId);
  //     this.EngineerService.setIncidentId(incidentId);
  //     this.EngineerService.checkEFSRExists(incidentId)
  //     .subscribe((data) => {
  //       if (data.flag == applicationconstant.SuccessFlag) {
  //         this.EngineerService.setEFSRId(data.returnId);
  //         this.EngineerService.setEFSRExists(true);
  //         //this.router.navigate(['EFSR/edit-efsr']);
  //         this.router.navigate(['EFSR/create-efsr']);
  //       }
  //       else {
  //         this.EngineerService.setEFSRExists(false);
  //         this.router.navigate(['EFSR/create-efsr']);
  //       }
  //     }),
  //     (err: any) => {
  //       this.openSnackBar(err.error.message, 'error');
  //     }
  //   }
    

  //   // if (this.checkValiditiy(ticketId))
  //   // {
  //   //   this.VendorService.setTicketId(ticketId);
  //   //   this.EngineerService.setROId(roId);
  //   //   this.EngineerService.setIncidentId(incidentId);
  //   //   this.EngineerService.checkEFSRExists(incidentId)
  //   //   .subscribe((data) => {
  //   //     if (data.flag == applicationconstant.SuccessFlag) {
  //   //       this.EngineerService.setEFSRId(data.returnId);
  //   //       this.EngineerService.setEFSRExists(true);
  //   //       //this.router.navigate(['EFSR/edit-efsr']);
  //   //       this.router.navigate(['EFSR/create-efsr']);
  //   //     }
  //   //     else {
  //   //       this.EngineerService.setEFSRExists(false);
  //   //       this.router.navigate(['EFSR/create-efsr']);
  //   //     }
  //   //   }),
  //   //   (err: any) => {
  //   //     this.openSnackBar(err.error.message, 'error');
  //   //   }
  //   // }
  //   // else
  //   // {
  //   //   this.openSnackBar("Invalid OTP", 'error');
  //   // }
  // }

  public VIEWEFSR(ticketId: number,ticketInteractionId: string){

    
  this.VendorService.setTicketId(ticketId);
    this.VendorService.setTicketInteractionId(ticketInteractionId);
    this.EngineerService.checkEFSRForRO(ticketInteractionId)
    .subscribe((data) => {
      if (data.flag == applicationconstant.SuccessFlag) {
    this.EngineerService.setEFSRId(data.returnId);
    this.EngineerService.setIncidentId(ticketInteractionId);
      
         // this.VendorService.setTicketId(ticketId);
     // this.EngineerService.setIncidentId(ticketInteractionId);
       this.router.navigate(['serviceengineer/view-efsr']);
        
     }
      else{
       // this.EngineerService.setEFSRExists(false);
        this.openSnackBar(data.message, 'error');
      }
    }),
   (err: any) => {
     this.openSnackBar(err.error.message, 'error');
   }

    }
  

  public ViewHistoryDetails(ticketId: number) {
    this.VendorService.setTicketId(ticketId);
    this.router.navigate(['supervisor/Incidenthistory']);
  }
  public AssignTicket(ticketId: number,ticketInteractionId: string) {
    this.VendorService.setTicketId(ticketId);
    this.VendorService.setTicketInteractionId(ticketInteractionId);
    this.router.navigate(['ServiceEngineer/assign-ticketto-se']);
  }

  public getDropdownItems() {
    const params =
      `${DropdownItemsGroup.TICKET_SEARCH_PARAM}`;
    this.VendorService.getDropdownItems(JSON.stringify(params))
      .subscribe(items => {
        if (items.flag == applicationconstant.SuccessFlag) {
          this.setDropdownItemsAsPerGroup(items.results);
        }
        else {
          this.openSnackBar(items.message, '')
        }
      },
        (err: any) => this.openSnackBar(err.error.message, '')
      );
   }

  private setDropdownItemsAsPerGroup(itemsGrop: any) {
    if (itemsGrop.length > 0) {
      this.ticketSearchType = [];
      for (let item of itemsGrop) {
        if (item.mstGroup == DropdownItemsGroup.TICKET_SEARCH_PARAM) {
          this.ticketSearchType.push(item);
        }
      }
    }
    this.ticketValues=this.ticketSearchType.map(x=>x.mstValue).join(' / '); 
  }


  logValidationErrors(group: FormGroup = this.engineerSearchForm): void {
    Object.keys(group.controls).forEach((key: string) => {
      const abstractControl = group.get(key);
      if (abstractControl instanceof FormGroup) {
        this.logValidationErrors(abstractControl)
      } else {
        this.formErrors[key] = '';
        if (abstractControl && !abstractControl.valid && (abstractControl.touched || abstractControl.dirty)) {
          const messages = this.validationMessages[key];

          for (const errorKey in abstractControl.errors) {
            if (errorKey) {
              this.formErrors[key] += messages[errorKey] + '';
            }
          }
        }
      }
    });
  }

  ValidateAllFields(formGroup: FormGroup) {
    Object.keys(formGroup.controls).forEach(field => {
      const control = formGroup.get(field);
      if (control instanceof FormControl) {
        control.markAsTouched({ onlySelf: true });
      } else if (control instanceof FormGroup) {
        this.ValidateAllFields(control);
      }
    });
  }

  openSnackBar(message: string, action: string) {
    this.snackBar.open(message, action, {
      duration: 5000,
      verticalPosition: 'top'
    });
  }
  
}
