import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { ChangePassword } from 'src/app/Models/usermanagement/changepassword.model';
import { debounceTime } from 'rxjs/internal/operators/debounceTime';
import { map, tap, catchError } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { ForgotPassword } from 'src/app/Models/usermanagement/forgotPassword.model';
import { FirstTimeLogin } from 'src/app/Models/firsttimelogin/firsttimelogin.model';


@Injectable({
  providedIn: 'root'
})
export class LoginService {

  baseUrl: string;
  public notification: string;

  recaptchaEnabled: boolean;
  forgotPwdUserInput: string;
  firsttimeloginUserInput: string;
  firsttimeloginUserMobile: string;
  IsRequestThroughLogin: boolean;

  constructor(private http: HttpClient) {
    this.baseUrl = environment.restUrl + '/api/v1/';

    this.recaptchaEnabled = false;
  }

  /**
   * Change existing/new user password
   * @param newUser 
   */
  public ChangePassword(changePassword: ChangePassword): Observable<any> {
    return this.http.post<any>(this.baseUrl + 'changepassword', changePassword, {
      headers: new HttpHeaders({
        // 'Content-Type': 'application/json'
      })
    });
  }

  /** 
   * update user logout time
   */
  public updateUserLogoutTime(): any {
    return this.http.post<any>(this.baseUrl + 'updateuserlog', 'update logout time', {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    });
  }
  /** 
   * update user logout time
   */
  public updateUserDetails(): any {
    return this.http.get<any>(this.baseUrl + 'deleteandinsertuserlog', {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    });
  }

  public setNotification(outputMsg: string): void {
    this.notification = outputMsg;
  }
  public getNotification(): string {
    return this.notification;
  }


  VerifyUserForgotPwd(forgotpwdsendotp: ForgotPassword): Observable<any[]> {
    return this.http.post<any[]>(this.baseUrl + 'verifyuser', forgotpwdsendotp, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'No-Auth': 'True'
      })
    });
  }
  VerifyUserforOtpActivation(forgotpwdsendotp: ForgotPassword): Observable<any[]> {
    return this.http.post<any[]>(this.baseUrl + 'VerifyUserforActivation', forgotpwdsendotp, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'No-Auth': 'True'
      })
    });
  }
  
  SendOTPForFirstTImeLogin(forgotpwdsendotp: ForgotPassword): Observable<any[]> {
    return this.http.post<any[]>(this.baseUrl + 'sendOTPFirstTimeLogin', forgotpwdsendotp, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'No-Auth': 'True'
      })
    });
  }

  public ForgotPassword(changePassword: ChangePassword): Observable<any> {
    return this.http.post<any>(this.baseUrl + 'forgotpassword', changePassword, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'No-Auth': 'True'
      })
    });
  }

  public ActivateUserbyOtp(changePassword: ChangePassword): Observable<any> {
    return this.http.post<any>(this.baseUrl + 'ActivateUserByOtp', changePassword, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'No-Auth': 'True'
      })
    });
  }
    
    


  public FirstTimeLogin(firstlogin: FirstTimeLogin): Observable<any> {
    return this.http.post<any>(this.baseUrl + 'firsttimelogin', firstlogin, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'No-Auth': 'True'
      })
    });
  }

  public SetForgotPasswordUserInput(userInput: string) {
    this.forgotPwdUserInput = userInput;
  }

  public SetFirstTimeLoginUserInput(userInput: string) {
    this.firsttimeloginUserInput= userInput;
  }

  public SetFirstTimeLoginUserMobile(userInput: string) {
    this.firsttimeloginUserMobile= userInput;
  }


  public GetForgotPasswordUserInput() {
    return this.forgotPwdUserInput;
  }

  public GetFirstTimeLoginUserInput() {
    return this.firsttimeloginUserInput;
  }

  public GetFirstTimeLoginUserMobile() {
    return this.firsttimeloginUserMobile;
  }

  public getMobileandEmail(): any {
    return this.http.get<any>(this.baseUrl + 'getMobileEmail', {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    });
  }

  public getMobileandEmailForVerification(): any {
    return this.http.get<any>(this.baseUrl + 'getMobileEmailForVerification', {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    });
  }

  SendMobileOTPForMobileVerify(): Observable<any> {
    var responseMessage = this.http.get<any>(this.baseUrl + 'SendMobileOTPForVerification', {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    })
      .pipe(
        debounceTime(500),
        map(
          (data: any) => {
            return (
            /*data.length != 0 || */data !== null
                ? data as any : [{ "Error": "Error while sending Mobile OTP." } as any]
            );
          }
        ));
    return responseMessage;
  }

  SendEmailOTPForEmailVerify(): Observable<any> {
    var responseMessage = this.http.get<any>(this.baseUrl + 'SendEmailOTPForVerification', {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    })
      .pipe(
        debounceTime(500),
        map(
          (data: any) => {
            return (
            /*data.length != 0 || */data !== null
                ? data as any : [{ "Error": "Error while sending Mobile OTP." } as any]
            );
          }
        ));
    return responseMessage;
  }

  public VerifyMobile(OTP: string): Observable<any> {
    return this.http.post<any>(this.baseUrl + 'verifymobile', OTP, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    });
  }

  public VerifyEmail(OTP: string): Observable<any> {
    return this.http.post<any>(this.baseUrl + 'verifyemail', OTP, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    });
  }

  public setDashboardParameter(parameter: boolean) {
    this.IsRequestThroughLogin = parameter;
  }

  public getDashboardParameter(): boolean {
    return this.IsRequestThroughLogin;
  }

}
