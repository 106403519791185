export class SearchDTO {
    searchParam: string;
    searchValue: string;
}

export class SearchRODTO {
    searchParam: string;
    searchValue: string;
    roId: number;
}

export class ViewIncidentRODTO {
    ticketId:number
    roId: number;
}
