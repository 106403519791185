export class VendorSupervisor{
    userId: number;
    VendorID :string;
    username: string;
    firstName: string;
    lastName: string;
    mobile: string;
    email: string;
    Aemail: string;
    Amobile: string;
    Organization: string;
    activeFlag: string;
    referenceId: string;
    PIN: number;
    State: string;
    City: string;
    Address: string;
    level: number;
    
}

export class VendorSubSupervisor{
    userId: number;
    VendorID :string;
    username: string;
    firstName: string;
    lastName: string;
    mobile: string;
    email: string;
    Aemail: string;
    Amobile: string;
    Organization: string;
    activeFlag: string;
    referenceId: string;
    PIN: number;
    State: string[];
    City: string[];
    Address: string;
    level: number;
    
}
