import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, FormBuilder, Validators, FormGroupDirective, NgForm, AbstractControl } from '@angular/forms'
import { MatDialog, MatSnackBar } from '@angular/material';
import { ActivatedRoute } from '@angular/router';
import { LoginService } from 'src/app/Services/loginmanagement/login.service';
import { FirstTimeLogin } from 'src/app/Models/firsttimelogin/firsttimelogin.model';
import { Router } from '@angular/router';
import { HeaderService } from 'src/app/Services/header/header.service';
import { applicationconstant } from 'src/app/Models/DropdownItemsGroup/applicationconstant.model';
//import { MobileNOpromptComponent } from 'src/app/Component/login/firsttimelogin/mobile-noprompt/mobile-noprompt.component';
import { ForgotPassword } from 'src/app/Models/usermanagement/forgotPassword.model';



@Component({
  selector: 'app-firsttimelogin',
  templateUrl: './firsttimelogin.component.html',
  styleUrls: ['./firsttimelogin.component.scss']
})
export class FirsttimeloginComponent implements OnInit {
   /**
   * Form group for firsttimelogin 
   *
   * @type {FormGroup}
   * @memberof FirsttimeloginComponent
   */
  firsttimeloginForm: FormGroup;

  hidePassword = true;
  parameteruserInput = null;
  mobileNo:string;
  showResendOTP: boolean = false;

   /**
   * Validation messages
   *
   * @memberof FirsttimeloginComponent
   */
  validationMessages = {
    'userid': {
      'required': 'Please enter user id.'
    },
    'otp': {
      'required': 'Please enter OTP recieved on mobile number.'
    },
    'mobile': {
      'required': 'Please enter mobile number.',
      'maxlength': 'Mobile can contain maximum 10 characters.',
      'pattern': 'Mobile must start with 6,7,8 or 9 and should contain numbers only.',
      'ageRange': 'Age range validator.'
      //'increaseSequence' : 'increaseSequence'
    },
  };

  formErrors = {
    'userid': '',
    'otp': '',
    'mobile':''
  };


  constructor(private fb: FormBuilder, private activatedroute: ActivatedRoute, private router: Router, private loginService: LoginService,  public snackBar: MatSnackBar,
    public dialog: MatDialog,
    public headerService: HeaderService) { }

  ngOnInit() {
    this.headerService.hide();

    this.firsttimeloginForm = this.fb.group({
      userid: ['', [Validators.required]],
      otp: ['', [Validators.required]],
      mobile: ['',[Validators.required,Validators.maxLength(10),
        Validators.pattern(/^[6-9]\d{9}$/),
        ]]
      });

      this.firsttimeloginForm.valueChanges.subscribe((data) => {
        this.logValidationErrors(this.firsttimeloginForm);
      });
  }

  OnSubmit(): void {
    if (this.firsttimeloginForm.valid) {
      const userDetails = this.firsttimeloginForm.value;
      var firstlogin = new FirstTimeLogin();
      firstlogin.userInput = userDetails.userid;
      firstlogin.OTP = userDetails.otp;
       firstlogin.mobileNo = userDetails.mobile;
      
      this.loginService.FirstTimeLogin(firstlogin).subscribe((data: any) => {
        if (data.flag === applicationconstant.SuccessFlag) {
          this.loginService.SetFirstTimeLoginUserInput(userDetails.userid);
          this.loginService.SetFirstTimeLoginUserMobile(userDetails.mobile);
          this.firsttimeloginForm.reset();
          this.router.navigate(['supervisor/registration']);
          this.openSnackBar(data.message, '');
        }
        else {
          this.openSnackBar(data.message, '');
        }
      },
        (err: any) => {
          this.openSnackBar(err.error.message, '')
        });
    }
    else {
      this.ValidateAllFields(this.firsttimeloginForm);
      this.logValidationErrors(this.firsttimeloginForm);
    }
  }

  

//   SendSMSOTP(): void {

//     // const dialogRef = this.dialog.open(MobileNOpromptComponent, {
//     //   width: '350px',
//     //   data: this.mobileNo
//     // });
//     // dialogRef.afterClosed().subscribe(result => {
//     //   if (result) {
//     //     this.router.navigate(['/firsttimelogin']);
//     //   }
//     // });
//     var EmailNo = this.firsttimeloginForm.get('newemail').value;
//     if (EmailNo != '' && this.firsttimeloginForm.get('newemail').valid) {
//         this.loginService.SendEmailOTPForEmailChange(EmailNo).subscribe(
//       (data: any) => {
//         this.openSnackBar(data.message, '');
//       },
//       (err: any) => this.openSnackBar(err.error.message, 'error')
//     );
//   }
// }

SendSMSOTP() {
  const userDetails = this.firsttimeloginForm.value;
  var forgotpwdsendotp = new ForgotPassword();
  forgotpwdsendotp.userInput = userDetails.userid;
  forgotpwdsendotp.mobileNo = userDetails.mobile;
  this.loginService.SendOTPForFirstTImeLogin(forgotpwdsendotp).subscribe((data: any) => {
    this.openSnackBar(data.message, '');
    this.showResendOTP = true;
  },
    (err: any) => {
      this.openSnackBar(err.error.message, 'error')
      this.showResendOTP = true;
    });
}

Back(): void
{
  this.router.navigate(['/login']);
}


 ageRangeValidator(control: AbstractControl): { [key: string]: boolean } | null {
  if (control.value !== undefined && (isNaN(control.value) || control.value < 18 || control.value > 45)) {
      return { 'ageRange': true };
  }
  return null;
}

isIncreasingSequence(control: AbstractControl): { [key: string]: boolean } | null {
  let numArr = Array.prototype.slice.call(control.value);
  for (var num = 0; num < numArr.length - 1; num++) {
    if (!(numArr[num] < numArr[num + 1])) {
      return null;
    }
  }
  return { 'increaseSequence': true };
}



  logValidationErrors(group: FormGroup = this.firsttimeloginForm): void {
    Object.keys(group.controls).forEach((key: string) => {
      const abstractControl = group.get(key);
      if (abstractControl instanceof FormGroup) {
        this.logValidationErrors(abstractControl)
      } else {
        this.formErrors[key] = '';
        if (abstractControl && !abstractControl.valid && (abstractControl.touched || abstractControl.dirty)) {
          const messages = this.validationMessages[key];

          for (const errorKey in abstractControl.errors) {
            if (errorKey) {
              this.formErrors[key] += messages[errorKey] + '';
            }
          }
        }
      }
    });
  }

  ValidateAllFields(formGroup: FormGroup) {
    Object.keys(formGroup.controls).forEach(field => {
      const control = formGroup.get(field);
      if (control instanceof FormControl) {
        control.markAsTouched({ onlySelf: true });
      } else if (control instanceof FormGroup) {
        this.ValidateAllFields(control);
      }
    });
  }

    /**
   * Snack bar
   *
   * @param {string} message
   * @param {string} action
   * @memberof ForgotpasswordComponent
   */
  openSnackBar(message: string, action: string) {
    this.snackBar.open(message, action, {
      duration: 5000,
      verticalPosition: 'top'
    });
  }

}
