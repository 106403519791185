export interface Istatus {
    id: number;
    name: string;
  }
  export interface Icategory {
    id: number;
    name: string;
  }
  export interface Ilocation {
    id: number;
    name: string;
  }
  export interface IPriority {
    id: number;
    name: string;
  }
  
  export class ISearchPara {
    category: string;
    status: string;
    location: string;
    priority: string;
  }

  export class ISearchUser {
    searchType: string;
    searchTerm: string;
  }