import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, FormBuilder, Validators, FormGroupDirective, NgForm } from '@angular/forms'
import { MatDialog, MatSnackBar } from '@angular/material';
import { Router } from '@angular/router';
import { applicationconstant } from 'src/app/Models/DropdownItemsGroup/applicationconstant.model';
import { Ticket } from 'src/app/Models/vendorsupervisor/ticket.model';
import { DropdownItems } from 'src/app/Models/DropdownItemsGroup/dropdownitems.model';
import { DropdownItemsGroup } from 'src/app/Models/DropdownItemsGroup/dropdownitemsgroup.model';
import { VendorSupervisorService } from 'src/app/Services/vendorsupervisor/vendorsupervisor.service';
import { TicketsAssginedSE } from 'src/app/Services/ServiceEngineer/TicketsAssignedSE.service';
import { Incident } from 'src/app/Models/vendorsupervisor/incident.model';

@Component({
  selector: 'app-incidenthistory',
  templateUrl: './incidenthistory.component.html',
  styleUrls: ['./incidenthistory.component.scss']
})
export class IncidenthistoryComponent implements OnInit {
 
  IncidenHistoryForm: FormGroup;
  ticketList: Incident[];
  searched = false;
  ticketSearchType: DropdownItems[];
  ticketValues:string;
  closedTicketStatus: string;
  openTicketStatus: string;
  onholdTicketStatus: string;
  inprogressTicketStatus: string;
  searchTermStatus: string;
  ticketId : number;
  incidentIdToken : any;
  requestFromSupervisor : boolean;
  documetListValidation: boolean = false;
  documentFileValidation: boolean = false;

  validationMessages = {
    'searchType': {
      'required': 'Search type is required'
    },
    'searchTerm': {
      'required': 'Search Term is required'
    },
  };
  formErrors = {
    'searchType': '',
    'searchTerm': ''
  };


  constructor(private fb: FormBuilder, public snackBar: MatSnackBar, 
    private router: Router,
    private vendorservice:  VendorSupervisorService,
     private TicketAssService:
     TicketsAssginedSE,private dialog:MatDialog)
  {
     this.getStatusList();
    

     this.IncidenHistoryForm = this.fb.group({
     searchType: new FormControl(null, Validators.required),
     searchTerm: new FormControl(null, Validators.required),
    });
  }
  public getStatusList() {
  
    this.closedTicketStatus = applicationconstant.Ticket_Search_Closed;
    this.openTicketStatus = applicationconstant.Ticket_Search_Open;
    this.onholdTicketStatus = applicationconstant.Ticket_Search_OnHold;
    this.inprogressTicketStatus = applicationconstant.Ticket_Search_InProgress;

  }
  public backToList()
        {
          if(this.requestFromSupervisor)
          {
            this.router.navigate(['supervisor/ticketsearch']);
          }
          else{
            this.router.navigate(['serviceengineer/incidentsearch']);
          }
         
        }

        ngOnDestroy() {
          this.requestFromSupervisor = false;
        }
  ngOnInit() {
    this.ticketId = this.vendorservice.getTicketId();
    this.requestFromSupervisor = this.vendorservice.getRequestFromVendor();
    if (this.ticketId != null || this.ticketId != undefined)
    {
      this.incidentIdToken = this.ticketId;
    }
    if (localStorage.getItem('incidentIdToken') == null ||localStorage.getItem('incidentIdToken') == undefined) 
    {
      localStorage.setItem('incidentIdToken', this.incidentIdToken);
    }
    if (this.ticketId == undefined)
    {
      this.ticketId = Number(localStorage.getItem('incidentIdToken'));
    }
    

    this.getTicketList(this.ticketId);
    
  }
  
  preview(documentType: string, documentName: string) {
    this.vendorservice.downloadDoc(this.ticketId, documentType).subscribe(result => {
      if (window.navigator.msSaveOrOpenBlob) {
        window.navigator.msSaveOrOpenBlob(result, documentName);
      } else {
        var url = window.URL.createObjectURL(result);

        let link = document.createElement('a');
        link.setAttribute('type', 'hidden');
        link.href = url;
        link.download = documentName;
        document.body.appendChild(link);
        link.click();
        link.remove();
      }
      this.resetform();
    }    
    );
  }

  resetform(){
    this.IncidenHistoryForm.get('documentfile').reset();
    this.IncidenHistoryForm.get('documetList').patchValue('0');
    this.documentFileValidation=false;
    this.documetListValidation=false;
  }
  ValidateAllFields(formGroup: FormGroup) {
    Object.keys(formGroup.controls).forEach(field => {
      const control = formGroup.get(field);
      if (control instanceof FormControl) {
        control.markAsTouched({ onlySelf: true });
      } else if (control instanceof FormGroup) {
        this.ValidateAllFields(control);
      }
    });
  }

  
  logValidationErrors(group: FormGroup = this.IncidenHistoryForm): void {
    Object.keys(group.controls).forEach((key: string) => {
      const abstractControl = group.get(key);
      if (abstractControl instanceof FormGroup) {
        this.logValidationErrors(abstractControl)
      } else {
        this.formErrors[key] = '';
        if (abstractControl && !abstractControl.valid && (abstractControl.touched || abstractControl.dirty)) {
          const messages = this.validationMessages[key];

          for (const errorKey in abstractControl.errors) {
            if (errorKey) {
              this.formErrors[key] += messages[errorKey] + '';
            }
          }
        }
      }
    });
  }
  onSearchTicket() {
    if (this.IncidenHistoryForm.valid) {
      const searchFormData: any = Object.assign({}, this.IncidenHistoryForm.value);
      const searchType = searchFormData.searchType;
      const searchTerm = searchFormData.searchTerm;
      if (searchType == applicationconstant.Ticket_Search_Status && searchTerm.trim().toUpperCase() == applicationconstant.Ticket_Search_Open.toUpperCase()) {

        this.searchTermStatus = this.openTicketStatus;
      }
      if (searchType == applicationconstant.Ticket_Search_Status && searchTerm.replace(/\s/g, "").toUpperCase() == applicationconstant.Ticket_Search_Closed.toUpperCase()) {

        this.searchTermStatus = this.closedTicketStatus;
      }
      if (searchType == applicationconstant.Ticket_Search_Status && searchTerm.replace(/\s/g, "").toUpperCase() == applicationconstant.Ticket_Search_OnHold.toUpperCase()) {

        this.searchTermStatus = this.onholdTicketStatus;
      }
      if (searchType == applicationconstant.Ticket_Search_Status && searchTerm.replace(/\s/g, "").toUpperCase() == applicationconstant.Ticket_Search_InProgress.toUpperCase()) {

        this.searchTermStatus = this.inprogressTicketStatus;
      }
      // if (searchType == applicationconstant.Ticket_Search_Status && (
      //   searchTerm.trim().toUpperCase() == applicationconstant.Ticket_Search_Open.toUpperCase() ||
      //   searchTerm.trim().toUpperCase() == applicationconstant.Ticket_Search_Closed.toUpperCase() ||
      //   searchTerm.replace(/\s/g, "").toUpperCase() == applicationconstant.Ticket_Search_OnHold.toUpperCase() ||
      //   searchTerm.replace(/\s/g, "").toUpperCase() == applicationconstant.Ticket_Search_InProgress.toUpperCase()
      // )) {
      //  // this.getTicketList(searchType, this.searchTermStatus.toLocaleString());
      // }
      // else {
      //  // this.getTicketList(searchType, searchTerm);
      // }
    }
    else {
      this.ValidateAllFields(this.IncidenHistoryForm);
      this.logValidationErrors(this.IncidenHistoryForm);
    }
    
  }
  
  public getTicketList(IncidentID: number) {
    this.TicketAssService.gethistoryticket(IncidentID)
      .subscribe((data) => {
        if (data.flag == applicationconstant.SuccessFlag) {
          this.ticketList = data.results;
        }
        else {
          this.openSnackBar(data.message, '');
          this.ticketList = [];
        }
      }),
      (err: any) => {
        this.openSnackBar(err.error.message, 'error');
      }
  }


  openSnackBar(message: string, action: string) {
    this.snackBar.open(message, action, {
      duration: 5000,
      verticalPosition: 'top'
    });
  }
}
