export class SECreate {
 seId: number;
  userId: number;
  username: string;
  firstName: string;
  lastName: string;
  location: null;
  roleId: number;
  email: string;
  mobile: string;
  designation: string;
  activeFlag: string;
  referenceId: string;
  middleName:string;
  DOB:Date;
  //address:string;
  state:number;
  city:number;
  pincode:number;
  }
  