import { Component, OnInit, ViewChild, OnDestroy } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog, MatSnackBar, MatDialogConfig } from '@angular/material';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import {VendorService} from 'src/app/Services/master/vendor.service';
import {vendordetails, Isubcategory,Iareaname,Isubareaname,Ivendor,Iro} from 'src/app/Models/master/vendor.model';
import { documentList } from 'src/app/Models/ServiceEngineer/documentList';
import { ConfigurationService } from 'src/app/Services/Utilities/configuration.service';
import { Constants } from 'src/app/Constants/constants';
import { IState } from 'src/app/Models/common/state.model';
import { Icity } from 'src/app/Models/common/city.model';
import {romappingdetails} from 'src/app/Models/master/romappingdetails.model';
import { Router, NavigationEnd } from '@angular/router';

/**
 * Edit user page
 *
 * @export
 * @class UserEditComponent
 * @implements {OnInit}
 */

@Component({
  selector: 'app-rocategorymapping',
  templateUrl: './rocategorymapping.component.html',
  styleUrls: ['./rocategorymapping.component.scss']
})
export class RocategorymappingComponent implements OnInit,OnDestroy {

  /**
   * User Search form
   *
   * @type {FormGroup}
   * @memberof ROComponent
   */
  rocategorymappingForm: FormGroup;
  navigationSubscription;

  //public category: Icategory[];
  public subcategory: Isubcategory[];
  public areaname: Iareaname[];
  public subareaname: Isubareaname[];
  public vendor: Ivendor[];
  public ro: Iro[];
  isFormSectionVisible: boolean = true;
  //userData: romappingdetails;
  public submitted: boolean;

  /**
   * User details validation messages
   *
   * @memberof UserEditComponent
   */
  public account_validation_messages = {
    // 'category': [
    //   { type: 'required', message: 'Category is required' },
    // ],
    'subcategory': [
      { type: 'required', message: 'SubCategory is required' },
    ],
    'ro': [
      { type: 'required', message: 'RO is required' },
    ],
    'areaname': [
      { type: 'required', message: 'Area Name is required' },
    ],
    'subareaname': [
    { type: 'required', message: 'Sub Area Name is required' },
    ],
    'vendor': [
      { type: 'required', message: 'Vendor is required' },
    ],
  };
 
  /**
   * Creates an instance of UserEditComponent.
   * @param {FormBuilder} fb
   * @param {MatSnackBar} snackBar
   * @param {UserService} userService
   * @memberof UserEditComponent
   */
  constructor(private fb: FormBuilder,
    public snackBar: MatSnackBar,
    public dialog: MatDialog,
    private vendService: VendorService,
    private router: Router,
    private configurationService: ConfigurationService) {
      this.submitted = false;
    this.rocategorymappingForm = this.fb.group({
      //category: new FormControl(null, Validators.required),
      ro: new FormControl(null, Validators.required),
      areaname: new FormControl(null, Validators.required),
      subareaname: new FormControl(null),
      vendor: new FormControl(null, Validators.required),
      subcategory: new FormControl(null, Validators.compose([
        Validators.required,
        Validators.maxLength(100),
      ]))
    });

  }

  
  /**
   * Initialize component and subcribe to value to fill in
   * dropdowns of usertypes and roles
   *
   * @memberof UserEditComponent
   */
  ngOnInit() {
    // this.vendService.getCategoryROMap()
    //   .pipe(catchError((err) => this.handleError(err)))
    //   .subscribe((data: any) => {
    //     this.category = data.results;        
    //   });
      this.vendService.getSubCategoryROMap()
      .pipe(catchError((err) => this.handleError(err)))
      .subscribe((data: any) => {
        this.subcategory = data.results;        
      });

      this.vendService.getRO()
      .pipe(catchError((err) => this.handleError(err)))
      .subscribe((data: any) => {
        this.ro = data.results;        
      });

      this.vendService.getAreaName()
      .pipe(catchError((err) => this.handleError(err)))
      .subscribe((data: any) => {
        this.areaname = data.results;        
      });

      this.vendService.getSubAreaName()
      .pipe(catchError((err) => this.handleError(err)))
      .subscribe((data: any) => {
        this.subareaname = data.results;        
      });

      this.vendService.getVendorForMapping()
      .pipe(catchError((err) => this.handleError(err)))
      .subscribe((data: any) => {
        this.vendor = data.results;        
      });
  }
  // FillSubCatDD(Slectedstate:any)
  // {
  //   this.vendService.getSubCategoryROMap(Slectedstate.name)
  //     .pipe(catchError((err) => this.handleError(err)))
  //     .subscribe((data: any) => {
  //       this.subcategory = data.results;        
  //     });
  // }
  

  /**
   * Submit the form details and pass data to web api
   *
   * @returns
   * @memberof UserEditComponent
   */
  onSubmit() {
    
    const userDetails = Object.assign({}, this.rocategorymappingForm.value);
    const userData = new romappingdetails();
    userData.subcategory = userDetails.subcategory.name;
    userData.areaname = userDetails.areaname.name;
    userData.subareaname = userDetails.subareaname ?userDetails.subareaname.name:"";
    userData.roname = userDetails.ro.name;
    userData.vendorname = userDetails.vendor.name;

    
      this.vendService.saveMapping(userData)
      .subscribe(
        (data: any) => {
          this.submitted = false;
          this.openSnackBar(data.message, '');
          if (data.flag === Constants.SUCCESS_FLAG) {
            this.openSnackBar(data.message, '');
            this.resetForm();
            this.isFormSectionVisible = true;
          }
        },
        (err: any) => {
          this.submitted = false;
          this.openSnackBar(err.error.message, '');
        },
        () => { }
      );
  }

  /**
   * Reset edit user from, set all controls errors to null
   *
   * @memberof UserEditComponent
   */
  resetForm() {
    this.rocategorymappingForm.reset();

    for (const i in this.rocategorymappingForm.controls) {
      if (this.rocategorymappingForm.controls[i]) {
        this.rocategorymappingForm.controls[i].setErrors(null);
      }
    }
  }

  cancelEdit() {
    this.resetForm();
  }
  
  openSnackBar(message: string, action: string) {
    this.snackBar.open(message, action, {
      duration: 5000,
      verticalPosition: 'top'
    });
  }

  private handleError(error: any) {
    // this.errorReceived = true;
    return Observable.throw(error);
  }

  ngOnDestroy() {
    // avoid memory leaks here by cleaning up after ourselves. If we  
    // don't then we will continue to run our initialiseInvites()   
    // method on every navigationEnd event.
    if (this.navigationSubscription) {  
       this.navigationSubscription.unsubscribe();
    }
  }

}


