import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { UserService } from 'src/app/Services/usermanagement/user.service';
import { LoginService } from 'src/app/Services/loginmanagement/login.service';
import { MenuItemService } from '../../Services/Utilities/menuitems.service';
import { MatDialog, MatSnackBar } from '@angular/material';
//import { VerifymobileComponent } from 'src/app/Component/verifymobile/verifymobile.component';
import { applicationconstant } from 'src/app/Models/DropdownItemsGroup/applicationconstant.model';

export interface Link {
  text: string;
  url: string;
}

export interface Tile {
  color: string;
  cols: number;
  rows: number;
  text: string;
  links: Link[];
}

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit {

  public userName: any;
  public menuItems: any;
  requestafterLogin: boolean ;


  tiles: Tile[] = [
    {
      text: 'My Profile', cols: 2, rows: 2, color: '#ffffff',
      links: [
        { 'text': 'Change Password', 'url': 'changepassword/create' },
      ]
    },
    {
      text: 'Place an Order', cols: 2, rows: 2, color: '#ffffff',
      links: [{ 'text': 'Place Order', 'url': 'orders' }]
    },
    {
      text: 'My last 5 orders', cols: 2, rows: 2, color: '#ffffff',
      links: [{ 'text': 'Recent Orders', 'url': 'orders/delivered' }]
    },
    {
      text: 'My Assets', cols: 2, rows: 2, color: '#ffffff',
      links: [{ 'text': 'View Assets', 'url': 'asset' }]
    },
    {
      text: 'Track an Order', cols: 2, rows: 2, color: '#ffffff',
      links: [{ 'text': 'Track your order', 'url': 'orders/track' }]
    },
    {
      text: 'Offers', cols: 2, rows: 2, color: '#ffffff',
      links: [{ 'text': 'Avaiable Offers', 'url': 'offers' }]
    }
  ];

  constructor(private router: Router, private userService: UserService,
    private loginService: LoginService, private menuItemService: MenuItemService, public snackBar: MatSnackBar, public dialog: MatDialog) { }

  ngOnInit() {
    // get menu items as per logged in user
    this.getMenuItems();
    const currentUser = JSON.parse(localStorage.getItem('currentUser'));
    this.userName = currentUser && currentUser.FirstName + " " + currentUser.LastName;
   // this.requestafterLogin =  this.loginService.getDashboardParameter()
   // if (this.requestafterLogin) {
     // this.verifyMobile();
    //}
    
  }

  /**
   * Get Menu items 
   */
  public getMenuItems() {
    this.userService.getMenuAsPerUser().subscribe((data: any) => {
      this.menuItems = data;
      this.menuItemService.setMenuItems(data);
      //this.requestafterLogin =  this.loginService.getDashboardParameter()
      //if (this.requestafterLogin) {
       //  this.verifyMobile();
      // }
    },
      (err: any) => {
        console.log(err);
      });
  }

  /**
   * Logout user
   * Clear localstorage after logout
   */
  public logout() {
    //update user logout time  
    this.loginService.updateUserLogoutTime().subscribe((data: any) => { },
      (err: any) => { console.log(err); });

    localStorage.removeItem('userToken');
    localStorage.removeItem('currentUser');
    localStorage.removeItem('menu');
    //redirect to login page
    this.router.navigate(['/login']);
  }

  public getUserMobileDetails() {
    this.userService.getMenuAsPerUser().subscribe((data: any) => {
      this.menuItems = data;
      this.menuItemService.setMenuItems(data);
    },
      (err: any) => {
        console.log(err);
      });
  }

  //public verifyMobile() {
  //  this.loginService.getMobileandEmailForVerification().subscribe((data: any) => {
  //    if (data && data.flag === 'S') {
  //      if (data.result.isMobileVerified === 'N' && data.result.isMobileOTPLimitExceeded === 'N') {
  //        const dialogRef = this.dialog.open(VerifymobileComponent, {
  //          disableClose: true,
  //          width: '350px',
  //          data: applicationconstant.Request_For_Mobile_Verification
  //        });
  //        dialogRef.afterClosed().subscribe(result => {
  //          if (result) {
  //         //close
  //            if (data.result.isEmailVerified === 'N' && data.result.isEmailOTPLimitExceeded === 'N') {
  //              const dialogRef = this.dialog.open(VerifymobileComponent, {
  //                disableClose: true,
  //                width: '350px',
  //                data: applicationconstant.Request_For_Email_Verification
  //              });
  //              dialogRef.afterClosed().subscribe(result => {
  //                if (result) {
  //                  // close;
  //                }
  //              });
  //            }
  //           //close
  //          }
  //        });
  //      }
  //      if (data.result.isMobileVerified === 'Y' && data.result.isEmailVerified === 'N' && data.result.isEmailOTPLimitExceeded === 'N') {
  //        const dialogRef = this.dialog.open(VerifymobileComponent, {
  //          disableClose: true,
  //          width: '350px',
  //          data: applicationconstant.Request_For_Email_Verification
  //        });
  //        dialogRef.afterClosed().subscribe(result => {
  //          if (result) {
  //            // close;
  //          }
  //        });
  //      }
  //      if (data.result.isMobileVerified === 'N'  && data.result.isMobileOTPLimitExceeded === 'Y' && data.result.isEmailVerified === 'N' && data.result.isEmailOTPLimitExceeded === 'N') {
  //        const dialogRef = this.dialog.open(VerifymobileComponent, {
  //          disableClose: true,
  //          width: '350px',
  //          data: applicationconstant.Request_For_Email_Verification
  //        });
  //        dialogRef.afterClosed().subscribe(result => {
  //          if (result) {
  //            // close;
  //          }
  //        });
  //      }
  //    }
  //    else {
  //      this.openSnackBar(data.message, '');
  //    }
  //  },
  //    (err: any) => {
  //      this.openSnackBar(err.error.message, '');
  //    });
  //    this.loginService.setDashboardParameter(false);
  //}

  openSnackBar(message: string, action: string) {
    this.snackBar.open(message, action, {
      duration: 5000,
      verticalPosition: 'top'
    });
  }

}
