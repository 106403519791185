import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class RoleBasedAccessService {

  isAccessible:boolean=false;
  baseUrl:string;
  constructor(private http:HttpClient) { 
    this.baseUrl = environment.restUrl  + '/api/v1/';
  }
/*
 * @param elementName = controllerName,ServiceName
 */
  public isVisible(elementName:string){    
    return this.http.get(this.baseUrl+'element/visibility?ElementName='+elementName);
  }
}
