import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, FormBuilder, Validators } from '@angular/forms'
import { MatDialog, MatSnackBar } from '@angular/material';
import { Router } from '@angular/router';
import { LoginService } from 'src/app/Services/loginmanagement/login.service';
import { HeaderService } from 'src/app/Services/header/header.service';
import { ForgotPassword } from 'src/app/Models/ForgotPassword/forgotPassword.model';
import { environment } from 'src/environments/environment';
import { applicationconstant } from 'src/app/Models/DropdownItemsGroup/applicationconstant.model';

@Component({
  selector: 'app-activate-login-user',
  templateUrl: './activate-login-user.component.html',
  styleUrls: ['./activate-login-user.component.scss']
})
export class ActivateLoginUserComponent implements OnInit {
  userDetailsForm: FormGroup;
  parameteruserInput = null;
  userInput: string;
  Username: string;
  mobileNo: string;
  private siteKeyCaptcha: string;
  public userVerificationResponse: string;
  public recaptchaEnabled: boolean;
  resendOTP:boolean = false;

  validationMessages = {
    'userInput': {
      'required': 'User Input is required.'
    },


  };

  formErrors = {
    'userInput': ''
  };
  constructor(private fb: FormBuilder, private router: Router, private loginService: LoginService, public snackBar: MatSnackBar,
    public headerService: HeaderService) { 
      this.siteKeyCaptcha = `${environment.siteKeyCaptcha}`;
      this.recaptchaEnabled = false;
    }

  ngOnInit() {
    this.headerService.hide();
    this.parameteruserInput = this.loginService.GetForgotPasswordUserInput();
    this.userDetailsForm = this.fb.group({
      userInput: ['', [Validators.required]]
    });
    //this.ShowCaptcha();

    this.userDetailsForm.valueChanges.subscribe((data) => {
      this.logValidationErrors(this.userDetailsForm);
    });
  }


  getResponceCapcha(captchaResponse: string) {
    this.userVerificationResponse = captchaResponse;
  }

  OnSubmit(): void {
    if (this.userDetailsForm.valid) {
      // if (this.userVerificationResponse === undefined) {
      //   //this.recaptchaEnabled=true;
      //   return;
      //  }
      // save data
      const userDetails = this.userDetailsForm.value;
      this.userInput = userDetails.userInput
      this.Username=this.parameteruserInput;
      var changepassword = new ForgotPassword();
      changepassword.userInput = this.parameteruserInput;
      changepassword.OTP = userDetails.userInput;
      this.loginService.ActivateUserbyOtp(changepassword).subscribe((data: any) => {
        if (data.flag === applicationconstant.SuccessFlag) {
          this.userDetailsForm.reset();
          this.openSnackBar(data.message, '');
          this.router.navigate(['/login']);
        }
        else {
          this.openSnackBar(data.message, '');
        }
      },
        (err: any) => {
          this.openSnackBar(err.error.message, '')
        }); 

    }
    else {
      this.ValidateAllFields(this.userDetailsForm);
      this.logValidationErrors(this.userDetailsForm);
    }
  }

  openSnackBar(message: string, action: string) {
    this.snackBar.open(message, action, {
      duration: 5000,
      verticalPosition: 'top'
    });
  }

  logValidationErrors(group: FormGroup = this.userDetailsForm): void {
    Object.keys(group.controls).forEach((key: string) => {
      const abstractControl = group.get(key);
      if (abstractControl instanceof FormGroup) {
        this.logValidationErrors(abstractControl)
      } else {
        this.formErrors[key] = '';
        if (abstractControl && !abstractControl.valid && (abstractControl.touched || abstractControl.dirty)) {
          const messages = this.validationMessages[key];

          for (const errorKey in abstractControl.errors) {
            if (errorKey) {
              this.formErrors[key] += messages[errorKey] + '';
            }
          }
        }
      }
    });
  }

  ValidateAllFields(formGroup: FormGroup) {
    Object.keys(formGroup.controls).forEach(field => {
      const control = formGroup.get(field);
      if (control instanceof FormControl) {
        control.markAsTouched({ onlySelf: true });
      } else if (control instanceof FormGroup) {
        this.ValidateAllFields(control);
      }
    });
  }

  ResetForm(): void {
    this.userDetailsForm.reset();
    this.userDetailsForm.clearValidators();
    for (let i in this.userDetailsForm.controls) {
      this.userDetailsForm.controls[i].setErrors(null);
    }
  }

}
