import { ErrorHandler, Injectable, Injector } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { ErrorService } from 'src/app/Services/error/error.service'
import { PathLocationStrategy, LocationStrategy } from '@angular/common';
import * as StackTrace from 'stacktrace-js';
import { MatSnackBar } from '@angular/material';

@Injectable({
  providedIn: 'root'
})
export class GlobalErrorHandlerService implements ErrorHandler {

  constructor(private injector: Injector) {
 
  }

  errorType: string = '';
  stackTrace: string = '';  
  handleError(error: Error | HttpErrorResponse) {    
    if (error instanceof HttpErrorResponse) {
      this.errorType = 'Client side error occured while communicating to the server.';
    } else {
      this.errorType = 'Angular side error occured';
    }

    StackTrace.fromError(error).then(stackframes => {
      this.stackTrace = stackframes
        .splice(0, 3)
        .map(function (sf) {
          return JSON.stringify(sf);
        }).join('\n');

      const location = this.injector.get(LocationStrategy);
      const locationData = JSON.stringify(location._platformLocation.location.href).replace('#', '');

      const loggingService = this.injector.get(ErrorService);
      loggingService.logError(error, locationData, this.errorType, this.stackTrace).subscribe(
        (data: any) => {
          if (data.result = 'S') {
          }
        });
    });
    throw error;
  }
}
