import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpRequest, HttpEventType, HttpResponse, HttpEvent, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from '../../../environments/environment';
//import { Ticket } from 'src/app/Models/vendorsupervisor/ticket.model';
import { ViewTicket } from 'src/app/Models/ViewTicket/ViewTicket.model';
//import { flattenStyles } from '@angular/platform-browser/src/dom/dom_renderer';
import { HttpVerbService } from 'src/app/Services/httpverb/httpverb.service';
import { DropdownItems } from '../../Models/DropdownItemsGroup/dropdownitems.model';
import { debounceTime } from 'rxjs/internal/operators/debounceTime';
import { SearchDTO, SearchRODTO } from 'src/app/Models/common/search.model';
import { IncidentReject } from 'src/app/Models/vendorsupervisor/incident.model';


@Injectable({
  providedIn: 'root'
})

export class VendorSupervisorService {

  baseUrl: string;
  ticketId: number;
  ROId: number;
  ticketInteractionId: string;
  requestFromSupervisor: boolean = false;
  requestFromRO: boolean = false;
  requestFromSE : boolean = false;

  constructor(private http: HttpClient, private service: HttpVerbService) {
    this.baseUrl = environment.restUrl + '/api/v1/';
  }

 /**
 * Submit new vehicle
 *
 * 
 * @param {Vehicle} vehicle
 * @returns {Observable<any>}
 * @memberof vehicleService
 */
//   saveVehicle(vehicle: Vehicle): Observable<any> {
//     return this.http.post<any>(this.baseUrl + 'saveNewVehicle', vehicle, {
//       headers: new HttpHeaders({
//         'Content-Type': 'application/json'
//       })
//     });
//   }

   /**
   * Get search result for asset
   *
   * @returns {Observable<any>}
   * @memberof VehicleService
   */
  public getTicketList(searchValues: SearchDTO): Observable<any> {
    return this.http.post<any>(this.baseUrl + 'TicketList/searchticket',searchValues, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'//,
        //'No-Auth':  'True' //need to remove during deployment
      })
    }).pipe(map((response: any) => {
      return response;
    }
    ));
  }

  public getROIncidentList(SearchDTO: SearchRODTO): Observable<any> {
    return this.http.post<any>(this.baseUrl + 'ROIncidentList/searchincident',SearchDTO, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    }).pipe(map((response: any) => {
      return response;
    }
    ));
  }

  /**
   * Get all active dropdown items
   *
   * @returns {Observable<any>}
   * @memberof VehicleService
   */
  public getDropdownItems(group: string): Observable<any> {
    return this.http.get<any>(this.baseUrl + 'TicketDropdownItems/getdropdownitems' + '?group=' + group, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
        //'No-Auth': 'True' //need to remove durinf deployment
      })
    }).pipe(map((response: any) => {
      return response;
    }
    ));
  }

  getDriverList(): Observable<any> {
    return this.http.get<any>(this.baseUrl + "VehicleDriverList/getvehicledriverlist", {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
       // 'No-Auth': 'True' //need to remove durinf deployment
      })
    }).pipe(map((response: any) => {
      return response;
    }
    ));
  }

  getDriverListForUpdate(vehicleId: number): Observable<any> {
    return this.http.get<any>(this.baseUrl + "VehicleDriverList/getvehicledriverlistforupdate" + '?vehicleid=' + vehicleId, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
       // 'No-Auth': 'True' //need to remove durinf deployment
      })
    }).pipe(map((response: any) => {
      return response;
    }
    ));
  }

  getStatusList(): Observable<any> {
    return this.http.get<any>(this.baseUrl + "VehicleStatusList/getvehiclestatuslist", {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
       // 'No-Auth': 'True' //need to remove durinf deployment
      })
    }).pipe(map((response: any) => {
      return response;
    }
    ));
  }



  public setTicketId(TicketId: number) {
    this.ticketId = TicketId;
  }

  public setRequestFromVendor(request: boolean) {
    this.requestFromSupervisor = request;
  }

  public setRequestFromRO(request: boolean) {
    this.requestFromRO = request;
  }

  public setROId(ROId: number) {
    this.ROId = ROId;
  }

  public getROId() {
    return this.ROId;
  }

  public setTicketInteractionId(TicketInteractionId: string) {
    this.ticketInteractionId = TicketInteractionId;
  }

  public getTicketId() {
    return this.ticketId;
  }

  public getTicketInteractionId() {
    return this.ticketInteractionId;
  }

  public getRequestFromVendor() {
    return this.requestFromSupervisor;
  }

  public getRequestFromRO() {
    return this.requestFromRO;
  }

  public getRequestFromSE() {
    return this.requestFromSE;
  }

  public setRequestFromSE(request: boolean) {
    this.requestFromSE = request;
  }
  

  public DeleteVehicle(VehicleId: number): Observable<any> {
    return this.http.post(this.baseUrl + "deletevehicle?id=" + VehicleId, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    }).pipe(map((res: any) => {
      return res;
    }))
  }

  public getticket(ticketId: number): Observable<any> {
    return this.http.post<any>(this.baseUrl + 'TicketList/getticket',ticketId, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
       // 'No-Auth': 'True' //need to remove during deployment
      })
    }).pipe(
      map((response: any) => {
      return response;
    }
    ));
  }

//   public TicketDetails(tktId): Observable<any> {
//     return this.http.get<any>(this.baseUrl + 'viewticket/' + tktId, {
//         headers: new HttpHeaders({
//           'Content-Type': 'application/json',
//         })
//       });
//     }


  getAllProductPromoter(): Observable<any> {
    return this.http.get<any>(this.baseUrl + "VehiclePPList/getproductpromoterlist", {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    }).pipe(map((response: any) => {
      return response;
    }
    ));
  }

//   public updateVehicle(vehicleData: Vehicle): Observable<any> {
//     return this.http.post<any>(this.baseUrl + 'updatevehicle', vehicleData, {
//       headers: new HttpHeaders({
//         'Content-Type': 'application/json'
//       })
//     });
//   }

  public getRequiredFiles(): Observable<any> {
    return this.http.get(this.baseUrl + "getSEDocumentsList", {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    }).pipe(map((res: any) => {
      return res;
    }))
  }

  public getRequiredFilest(trackingId: number): Observable<any> {
    return this.http.get(this.baseUrl + "getSEDocumentsList?TRACKING_ID=" + trackingId, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    }).pipe(map((res: any) => {
      return res;
    }))
  }

  getSEAllDocs(docGroup: string, docid: number, trackingId: number): Observable<any> {
    return this.http.get(this.baseUrl + "getSEDocs?doc_Group=" + docGroup + "&docId=" + docid + "&TRACKING_ID=" + trackingId, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    }).pipe(map((res: any) => {
      return res;
    }))
  }

  getEFSRAllDocs(docGroup: string, docid: number, trackingId: number): Observable<any> {
    return this.http.get(this.baseUrl + "getEFSRDocs?doc_Group=" + docGroup + "&docId=" + docid + "&TRACKING_ID=" + trackingId, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    }).pipe(map((res: any) => {
      return res;
    }))
  }

  // getEFSRReportsList(docGroup: string, docid: number, trackingId: number): Observable<any> {
  //   return this.http.get(this.baseUrl + "getEFSRDocs?doc_Group=" + docGroup + "&docId=" + docid + "&TRACKING_ID=" + trackingId, {
  //     headers: new HttpHeaders({
  //       'Content-Type': 'application/json'
  //     })
  //   }).pipe(map((res: any) => {
  //     return res;
  //   }))
  // }

  removeFile(fileType: string, docId: number): Observable<any> {
    return this.http.post(this.baseUrl + "RemoveSEDoc?trackingId=" + docId + "&docType=" + fileType, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    }).pipe(map((res: any) => {
      return res;
    }))
  }

  rejectIncident(details: IncidentReject): Observable<any> {
    return this.http.post<any>(this.baseUrl + 'rejectIncident', details, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    });
  }


  uploadFile(file: File, fileType: string, docNum: string, driverId: number): Observable<HttpEvent<any>> {
    let formData = new FormData();
    formData.append('upload', file);
    formData.append('TrackingId', String(driverId));
    formData.append('FileType', fileType);
    formData.append('UniqueFileNumber', docNum);
    let params = new HttpParams();
    const options = {
      params: params,
      reportProgress: true,
    };
    const req = new HttpRequest('POST', this.baseUrl + "UploadSEDoc", formData, options);
    return this.http.request(req);
  }

  public downloadDoc(trackingId: number, docType: string): Observable<any> {

    let url = this.baseUrl + "DownloadSEDoc?trackingId=" + trackingId + "&docType=" + docType;
    return this.http.get(url, { responseType: "blob" });
  }

}
