import { Directive,ElementRef, Input, Renderer } from '@angular/core';
import { RoleBasedAccessService } from '../Services/RoleBasedAccess/role-based-access.service';
import { MatSnackBar } from '@angular/material';

@Directive({
  selector: '[appElementVisibility]'
})


export class ElementVisibilityDirective {

  @Input('appElementVisibility') elementName: string;

  constructor(private el: ElementRef, private renderer: Renderer, private roleBasedAccessService:RoleBasedAccessService,private snackbar:MatSnackBar) {    
}

public ngOnInit()
{
     //this.renderer.setElementStyle(this.el.nativeElement,'backgroundColor',this.elementName);
     this.roleBasedAccessService.isVisible(this.elementName).subscribe((data:any)=>{
      if(data.flag=='S'){
        if(data.result){
        this.renderer.setElementStyle(this.el.nativeElement,'display',null);
        }
        else
        {
          this.renderer.setElementStyle(this.el.nativeElement,'display','none');
        }
    }
    else{
      this.openSnackBar(data.message,'error');
    }
  });
}

public openSnackBar(message: string, action: string) {
  this.snackbar.open(message, action, {
    duration: 5000,
    verticalPosition: 'top'
  });
}

}
