import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';

@Component({
  selector: 'app-user-disable-dialog',
  templateUrl: './user-disable-dialog.component.html',
  styleUrls: ['./user-disable-dialog.component.scss']
})
export class UserDisableDialogComponent implements OnInit {

  error: string;
  username: string;
  WarningMessage:string;

  constructor(
    public _dialogRef: MatDialogRef<UserDisableDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    // this.status = data.defaultStatus;
  }

  ngOnInit() { }

  cancel() {
    this._dialogRef.close();
  }

  deactivateUser() {
    if (this.username === this.data.username) {
      //console.log('username entered correctly');
      this._dialogRef.close(this.data);
    } else {
      this.error = 'Please enter the username.';
    }
  }
}
