
import { BrowserModule } from '@angular/platform-browser';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import * as $ from 'jquery';
import { NgModule, ErrorHandler, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { LoginComponent } from './Component/login/login.component';
import { environment } from '../environments/environment';
import{MatRadioModule} from '@angular/material';
import{MatCheckboxModule} from '@angular/material';
import { AlertpopupComponent } from './Component/alertpopup/alertpopup.component';
import { MatDialogModule } from '@angular/material/dialog';
import { MatButtonModule } from '@angular/material/button';
import {MatAutocompleteModule,MatInputModule} from '@angular/material';
import { MatFormFieldModule } from '@angular/material';
import { AuthInterceptor } from './Services/auth/auth.interceptor';
import { GlobalErrorHandlerService } from './Services/globalerrorhandler/global-error-handler.service';
import { HashLocationStrategy, LocationStrategy } from '@angular/common';
import { DashboardComponent } from './Component/dashboard/dashboard.component';
import { SharedModule } from './shared/shared.module';
import { ForgotpasswordComponent } from './Component/forgotpassword/forgotpassword.component';
import { UserVerificationComponent } from './Component/users/user-verification/user-verification.component';
//import { VendorsupervisorComponent } from './Component/vendorsupervisor/vendorsupervisor.component';
//import { VendorsupervisorRegistrationComponent } from './Component/vendorsupervisor/vendorsupervisor-registration/vendorsupervisor-registration.component';
import { FirsttimeloginComponent } from './Component/login/firsttimelogin/firsttimelogin.component';
import {UserDisableDialogComponent} from './Component/users/user-disable-dialog/user-disable-dialog.component';
import { OrderByPipe } from './Services/Utilities/orderby.pipe';
import {SECreateComponent} from './Component/ServiceEngineer/SE-Create/se-create.component';
import { SEEditComponent } from './Component/ServiceEngineer/SE-Edit/se-edit.component';
// import { ViewTicketsComponent } from './Component/VendorSupervisor/view-tickets/view-tickets.component';
import { VendorsupervisorRegistrationComponent } from './Component/VendorSupervisor/vendorsupervisor-registration/vendorsupervisor-registration.component';
import { ViewTicketsComponent } from './Component/VendorSupervisor/view-tickets/view-tickets.component';
import { VSEditComponent } from './Component/VendorSupervisor/vendorsupervisor-registration/vs-edit/vs-edit.component';
import { UploadDocsComponent } from './Component/ServiceEngineer/upload-docs/upload-docs.component';
import { AssignTickettoSEComponent } from './Component/ServiceEngineer/assign-ticketto-se/assign-ticketto-se.component';
import { SeuserDisableDialogComponent } from './Component/ServiceEngineer/seuser-disable-dialog/seuser-disable-dialog.component';
import { SeIncidentManagementComponent } from './Component/ServiceEngineer/seincidentmanagement/se-incident-management.component';
import { CreateEfsrComponent } from './Component/EFSR/create-efsr/create-efsr.component';
//import{ TicketsAssginedSE} from 'src/app/Services/ServiceEngineer/TicketsAssginedSE.service';
import{ VendorSupervisorService } from 'src/app/Services/vendorsupervisor/vendorsupervisor.service';

// import { BarRatingModule } from "ngx-bar-rating";

import { IncidenthistoryComponent } from './Component/ServiceEngineer/IncidentHistory/incidenthistory/incidenthistory.component';
import { ViewEfsrComponent } from './Component/EFSR/view-efsr/view-efsr.component';
import { RoIncidentManagementComponent } from './Component/ro/ro-incident-management.component';
import { ConfirmationDialogDeleteComponent } from './Component/EFSR/confirmation-dialog-delete/confirmation-dialog-delete.component';
import { ConfirmationDeleteComponent } from './Component/EFSR/Confirmation/confirmation-delete/confirmation-delete.component';
import { SERemarkDialogComponent } from './Component/ServiceEngineer/SeRemarkDialog/seremark-dialog/seremark-dialog.component';
//import { MobileNOpromptComponent } from './Component/login/firsttimelogin/mobile-noprompt/mobile-noprompt.component';

//import { RoViewEFSRComponent } from './Component/ro/ro-view-efsr/ro-view-efsr.component';
// import * as pdfMake from 'pdfmake/build/pdfmake';
// import * as pdfFonts from 'pdfmake/build/vfs_fonts';
// const pdf = pdfMake;
// pdf.vfs = pdfFonts.pdfMake.vfs;
import { VerifyincidentComponent } from './Component/EFSR/verifyincident/verifyincident.component';
import { BackspaceDirective } from './backspace.directive';
import { ViewSeDetailsComponent } from './Component/ro/view-se-details/view-se-details.component';
import { VendorsubsupervisorComponent } from './Component/VendorSupervisor/vendorsubsupervisor/vendorsubsupervisor.component';
//import { RejectComponent } from './Component/vendorsupervisor/reject/reject.component';
import { RoincidenthistoryComponent } from './Component/report/roincidenthistory/roincidenthistory.component';
import {WorkingincidenthistoryComponent} from './Component/report/workingincidenthistory/workingincidenthistory.component';
import { SeratingComponent } from './Component/report/serating/serating.component';
import { RoratingComponent } from './Component/report/rorating/rorating.component';
import {AddvendorComponent} from './Component/master/addvendor/addvendor.component';
import { ServiceWorkerModule } from '@angular/service-worker';
import { EditvendorComponent } from './Component/master/editvendor/editvendor.component';
import { RocategorymappingComponent } from './Component/master/rocategorymapping/rocategorymapping.component';
import { ActivateuserloginComponent } from './Component/master/activateuserlogin/activateuserlogin.component';
import { NonassignedimComponent } from './Component/report/nonassignedim/nonassignedim.component';
import { EditrocatmappComponent } from './Component/master/editrocatmapp/editrocatmapp.component';
import { UserlistComponent } from './Component/report/userlist/userlist.component';
import { RovendormappinglistComponent } from './Component/report/rovendormappinglist/rovendormappinglist.component';
import { UploadefsrdocsComponent } from './Component/EFSR/upload-EFSR-docs/uploadefsrdocs/uploadefsrdocs.component';
import { CloseIncidentDialogComponent } from './Component/ro/RejectSmmSMEDialog/close-incident-dialog/close-incident-dialog.component';
import { UserActivationbyOTPComponent } from './Component/login/UserActivstion/user-activationby-otp/user-activationby-otp.component';
import { ActivateLoginUserComponent } from './Component/login/UserActivstion/ActivateUser/activate-login-user/activate-login-user.component';
import { UploadUserdocsComponent } from './Component/UserHelp/UploadUserManual/upload-userdocs/upload-userdocs.component';
import {VendorsupervisorComponent} from './Component/VendorSupervisor/vendorsupervisor.component';
import { RejectComponent } from './Component/VendorSupervisor/reject/reject.component';


//import { UploadDocsROComponent } from './Component/ro/upload-docs/upload-docs.component';
// import { RejectComponent } from './Component/VendorSupervisor/reject/reject.component';
//import { saveAs } from 'file-saver';
//import { ServiceWorkerModule } from '@angular/service-worker';


@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    
    AlertpopupComponent,
    DashboardComponent,
    ForgotpasswordComponent,
    UserVerificationComponent,
    VendorsupervisorComponent,
    FirsttimeloginComponent,
    UserDisableDialogComponent,
    OrderByPipe,
    SECreateComponent,
    ViewTicketsComponent,
    VendorsupervisorRegistrationComponent,
    SECreateComponent,
    //SeEditComponent,
    SEEditComponent,
    SeuserDisableDialogComponent,
    VSEditComponent,
    UploadDocsComponent,
    AssignTickettoSEComponent,
    CreateEfsrComponent,
    SeIncidentManagementComponent,
    SeIncidentManagementComponent,
    IncidenthistoryComponent,
    ViewEfsrComponent,
    RoIncidentManagementComponent,
    ConfirmationDialogDeleteComponent,
    ConfirmationDeleteComponent,
    //MobileNOpromptComponent,
    //ViewSEEfsrComponentComponent,
    //RoViewEFSRComponent,
    VerifyincidentComponent,
    BackspaceDirective,
    ViewSeDetailsComponent,
    VendorsubsupervisorComponent,
    RejectComponent,
    RoincidenthistoryComponent,
    WorkingincidenthistoryComponent,
    SeratingComponent,
    RoratingComponent,
    AddvendorComponent,
    EditvendorComponent,
    RocategorymappingComponent,
    ActivateuserloginComponent,
    NonassignedimComponent,
    EditrocatmappComponent,
    UserlistComponent,
    RovendormappinglistComponent,
    UploadefsrdocsComponent,
    SERemarkDialogComponent,
    CloseIncidentDialogComponent,
    UserActivationbyOTPComponent,
    ActivateLoginUserComponent,
    UploadUserdocsComponent
    //UploadDocsROComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    FormsModule,
    BrowserAnimationsModule,
    ReactiveFormsModule,
    HttpClientModule,
    MatAutocompleteModule,
    MatInputModule,
    MatDialogModule,
    MatButtonModule,
    MatFormFieldModule,
    MatRadioModule,
   // MDCSlider,
   MatCheckboxModule,
    SharedModule,
    ServiceWorkerModule.register('ngsw-worker.js', { enabled: environment.production })
    //ServiceWorkerModule.register('ngsw-worker.js', { enabled: environment.production })
    // BarRatingModule
  ],
  entryComponents: [LoginComponent,ConfirmationDialogDeleteComponent,SERemarkDialogComponent,ConfirmationDeleteComponent,VerifyincidentComponent,RejectComponent,CloseIncidentDialogComponent],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
    { provide: LocationStrategy, useClass: HashLocationStrategy },
    { provide: ErrorHandler, useClass: GlobalErrorHandlerService }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }