export class Constants {
    public static readonly SUCCESS_FLAG = 'S';

    public static readonly FAILURE_FLAG = 'F';

    public static readonly DENOMINATIONS = [500, 1000, 2000];

    public static readonly MAP_ZOOM_CONTROLS = ['+', '-'];

    public static readonly ASSET_SERVICE_MODE_PFC = 'PFC';

    public static readonly ASSET_SERVICE_MODE_MDU = 'MDU';

    public static readonly VEHICHLE_TYPE_MDU = 'Mdu';

    public static readonly VEHICHLE_TYPE_TRUCK = 'Truck';

    public static readonly VEHICHLE_TYPE_TEMPO = 'Tempo';

    public static readonly DRIVER_IMAGE_TYPE = 'Self Photograph';
}
