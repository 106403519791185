import { Injectable } from '@angular/core';
import { SECreate } from 'src/app/Models/ServiceEngineer/SECreate.model';
import { Observable, throwError } from 'rxjs';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { map, catchError, tap } from 'rxjs/operators';
import { HttpVerbService } from 'src/app/Services/httpverb/httpverb.service';

import { IUserType } from 'src/app/Models/usermanagement/usertype.model';
import { IState } from 'src/app/Models/common/state.model';
import { environment } from 'src/environments/environment';
import { Icity } from 'src/app/Models/common/city.model';
import{ IName } from 'src/app/Models/TicketsSE/engineername.model'
import{ AssignTicket } from 'src/app/Models/TicketsSE/AssignTicket.model'
import { documentList } from 'src/app/Models/ServiceEngineer/documentList';


/**
 * User service for user and role management
 *
 * @export
 * @class UserService
 */
@Injectable({
  providedIn: 'root'
})
export class UserService {

  /**
   * Base url where the api is hosted
   *
   * @type {string}
   * @memberof UserService
   */
  baseUrl: string;

  /**
   * Creates an instance of UserService.
   * @param {HttpClient} http
   * @param {HttpVerbService} service
   * @memberof UserService
   */
  constructor(private http: HttpClient, private service: HttpVerbService) {
    this.baseUrl = environment.restUrl + '/api/v1/';
  }
 /**
   * Create new user with the details provide in the form
   *
   * @param {User} newUser
   * @returns {Observable<any>}
   * @memberof UserService
   */
  createUser(newUser: SECreate): Observable<any> {
    return this.http.post<any>(this.baseUrl + 'createSEuser', newUser, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    });
  }
  /**
   * Search user the search type and search parameter
   *
   * @param {*} [searchType=null]
   * @param {*} [searchTerm=null]
   * @returns {Observable<User>}
   * @memberof UserService
   */
  searchUser(searchType = null, searchTerm = null): Observable<SECreate> {
    // encoding the search term into base64 format
    const url = this.baseUrl + 'seusers/search?searchType=' + searchType + '&parameter=' + btoa(searchTerm);
    return this.http.get<SECreate>(url);
  }

  searchSEUserForEdit(searchType = null, searchTerm = null): Observable<SECreate> {
    // encoding the search term into base64 format
    const url = this.baseUrl + 'seusersforedit/search?searchType=' + searchType + '&parameter=' + btoa(searchTerm);
    return this.http.get<SECreate>(url);
  }

  searchUserForEdit(searchType = null, searchTerm = null): Observable<SECreate> {
    // encoding the search term into base64 format
    const url = this.baseUrl + 'seusers/searchemail?searchType=' + searchType + '&parameter=' + btoa(searchTerm);
    return this.http.get<SECreate>(url);
  }
  /**
   * Update user with changed user details for edit user form
   *
   * @param {User} userData
   * @returns {Observable<any>}
   * @memberof UserService
   */
  updateUser(userData: SECreate): Observable<any> {
    return this.http.post<any>(this.baseUrl + 'seupdate', userData, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    });
  }

  /**
   * Get all active States
   *
   * @returns {Observable<IState[]>}
   * @memberof UserService
   */
  getStates(): Observable<IState[]> {
    const url = this.baseUrl + 'states';
    return this.service.get(url).pipe(map((response: any) => {
      return response;
    }));
  }

  getHTSMData(): Observable<IState[]> {
    const url = this.baseUrl + 'PostData';
    return this.service.get(url).pipe(map((response: any) => {
      return response;
    }));
  }

   /**
   * Get all active States
   *
   * @returns {Observable<IState[]>}
   * @memberof UserService
   */
  getCity(state:string): Observable<Icity[]> {
    const url = this.baseUrl + 'city?state=' + state;
    return this.service.get(url).pipe(map((response: any) => {
      return response;
    }));
  }
  getNames(): Observable<IName[]> {
    const url = this.baseUrl + 'getNames';
    return this.service.get(url).pipe(map((response: any) => {
      return response;
    }));
  }
  getUniqueUserName(): Observable<any> {
     return this.http.get<any>(this.baseUrl + 'segetUniqueUserName')
    //, {
    // headers: new HttpHeaders({
    // 'Content-Type': 'application/json'
    
    // })
    // })
    .pipe(map((response: any) => {
    return response;
    }
    ));
    } 
  AssignTicekToSE(newUser: AssignTicket): Observable<any> {
    return this.http.post<any>(this.baseUrl + 'AssignTicektToSE', newUser, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    });
  }


  // submitSEDoc(seId:number): Observable<any> {
  //   return this.http.post<any>(this.baseUrl + 'SubmitSEDoc',seId, {
  //     headers: new HttpHeaders({
  //       'Content-Type': 'application/json'
  //     })
  //   });
  // }
   submitSEDoc(seId: number): Observable<any> {
    return this.http.post<any>(this.baseUrl + 'SubmitSEDoc',seId, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
       // 'No-Auth': 'True' //need to remove during deployment
      })
    }).pipe(
      map((response: any) => {
      return response;
    }
    ));
  }

}
