export interface Istatus {
    id: number;
    name: string;
  }
  export interface Icategory {
    id: number;
    name: string;
  }
  export interface Ilocation {
    id: number;
    name: string;
  }
  export interface IPriority {
    id: number;
    name: string;
  }
  
  export class ISearchPara {
    category: string;
    status: string;
    location: string;
    priority: string;
  }
  export class IimPara {
    category: string;
    area: string;
    subarea: string;
    ro: string;
    state: string;
  }

  