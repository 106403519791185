//import { Component, OnInit } from '@angular/core';

//@Component({
//  selector: 'app-alertpopup',
//  templateUrl: './alertpopup.component.html',
//  styleUrls: ['./alertpopup.component.scss']
//})
//export class AlertpopupComponent implements OnInit {

//  constructor() { }

//  ngOnInit() {//test delete i afterwards
//  }

//}

import { Component, OnInit, Input } from '@angular/core';
import { Router } from '@angular/router';
import { LoginService } from 'src/app/Services/loginmanagement/login.service';

@Component({
  selector: 'app-alertpopup',
  templateUrl: './alertpopup.component.html',
  styleUrls: ['./alertpopup.component.scss']
})
export class AlertpopupComponent implements OnInit {

  @Input() public displayPopUp: boolean;
  @Input() public numberOfDays: number;
  public displayPopUpFlag: boolean;
  public numberOfDaysPending: number;
  constructor(private router: Router, private loginService: LoginService) { }

  ngOnInit() {
  }

  ngOnChanges(): void {
    this.numberOfDaysPending = this.numberOfDays;
    this.displayPopUpFlag = this.displayPopUp;
  }
  public ClosePopUp(): void {
    this.displayPopUpFlag = false;
    //if user close password expiry warning then redirect to dashboard
    this.router.navigate(['/dashboard']);
  }

  public ChangePassword(): void {
    this.loginService.setNotification('Change Existing Password!');
    this.router.navigate(['/changepassword']);
  }
}

