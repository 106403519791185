import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent } from '@angular/common/http';
import { Router } from '@angular/router';
import { Observable, throwError } from 'rxjs';
import { tap } from 'rxjs/operators';
import { ErrorService } from 'src/app/Services/error/error.service';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
    constructor(private router: Router, private errorService: ErrorService) { }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        if (request.headers.get('No-Auth') == 'True') {
            return next.handle(request.clone())
            // .pipe(
            //     tap(succ => { },
            //         error => {
            //             this.errorService.handleError(error);
            //         }));
        }
   
        if (localStorage.getItem('userToken') != null) {
            const clonedreq = request.clone({
                headers: request.headers.set('Authorization', 'Bearer ' + localStorage.getItem('userToken'))
            });
            return next.handle(clonedreq).pipe(
                tap(succ => { },
                    error => {                        
                        this.errorService.handleError(error);
                        if (error.status === 401) {
                            alert('Access Denied');
                            // <Log the user out of your application code>
                            this.router.navigate([ 'login' ]);
                            return throwError(error);
                          }
                    }));
        } else {
            this.router.navigateByUrl('/login');
            //return throwError('Access is Denied');
        }
    }
}
