import { Component, OnInit } from '@angular/core';
import { Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { FormGroup, FormBuilder, FormControl, Validators } from '@angular/forms';
import { applicationconstant } from 'src/app/Models/DropdownItemsGroup/applicationconstant.model';
import { MatDialog, MatSnackBar, MatAutocompleteModule, MatInputModule } from '@angular/material';
import { efsrcreate, hpsmandsedetails,efsrDraft, CheckListSaveDto, patchefsrDraft, EFSRApproveReject } from 'src/app/Models/EFSR/efsrcreate.model';
//import{ SECreate} from 'src/app/Models/ServiceEngineer/SECreate.model';
import { Observable } from 'rxjs';
import { efsrService } from 'src/app/Services/EFSR/efsrcreate.service';
import { TicketsAssginedSE } from 'src/app/Services/ServiceEngineer/TicketsAssignedSE.service';

@Component({
  selector: 'app-seremark-dialog',
  templateUrl: './seremark-dialog.component.html',
  styleUrls: ['./seremark-dialog.component.scss']
})
export class SERemarkDialogComponent implements OnInit {

  form: FormGroup;
  description: string;
  submitted: boolean = false;
  close: boolean = false;

  validationMessages = {
    'remarks': {
      'required': 'EFSR remarks is required.',
    },
  };

  formErrors = {
    'remarks': ''
  };

  constructor(private fb: FormBuilder, public snackBar: MatSnackBar,private EngineerService: TicketsAssginedSE,public dialogRef: MatDialogRef<SERemarkDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data:any) {
      this.form = this.fb.group({
        remarks: new FormControl(null, Validators.required)
      });
     }

  onNoClick(): void {
    this.dialogRef.close();
  }

  onYesClick(): void{
    if (this.form.valid) {
      const remarks = this.form.get('remarks').value;
      const rejectData = new EFSRApproveReject();
      rejectData.efsrId = this.data.efsrId;
      rejectData.incidentId=this.data.incidentId;
      rejectData.roKeyPersonName= this.data.roKeyPersonName;
      rejectData.rejectReason = remarks;
      rejectData.approveReason=null;
      rejectData.ratingByRO = null;
      rejectData.ratingCommentsByRO = null;
      rejectData.approvedFlag = 0;
      rejectData.rejectFlag = 1;
      this.EngineerService.updateefsrnotrequired(rejectData.incidentId,rejectData.rejectReason, this.data.roKeyPersonName).subscribe(
        data => {
          if (data.flag == applicationconstant.SuccessFlag) {
            this.openSnackBar(data.message, '');
            this.dialogRef.close(true);
          }
          else {
            this.openSnackBar(data.message, '');
          }
        },
        (err: any) => this.openSnackBar(err.error.message, '')
      );
    }
}

  ngOnInit() {
   
    this.form.valueChanges.subscribe((data) => {
      this.logValidationErrors(this.form);
    });
  }

  logValidationErrors(group: FormGroup = this.form): void {
    Object.keys(group.controls).forEach((key: string) => {
      const abstractControl = group.get(key);
      if (abstractControl instanceof FormGroup) {
        this.logValidationErrors(abstractControl)
      } else {
        this.formErrors[key] = '';
        if (abstractControl && !abstractControl.valid && (abstractControl.touched || abstractControl.dirty)) {
          const messages = this.validationMessages[key];

          for (const errorKey in abstractControl.errors) {
            if (errorKey) {
              this.formErrors[key] += messages[errorKey] + '';
            }
          }
        }
      }
    });
  }

  ValidateAllFields(formGroup: FormGroup) {
    Object.keys(formGroup.controls).forEach(field => {
      const control = formGroup.get(field);
      if (control instanceof FormControl) {
        control.markAsTouched({ onlySelf: true });
      } else if (control instanceof FormGroup) {
        this.ValidateAllFields(control);
      }
    });
  }

  public openSnackBar(message: string, action: string) {
    this.snackBar.open(message, action, {
      duration: 5000,
      verticalPosition: 'top'
    });
  }

  private handleError(error: any) {
    return Observable.throw(error);
  }
}
