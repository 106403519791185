import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, FormBuilder, Validators, FormGroupDirective, NgForm } from '@angular/forms'
import { MatDialog, MatSnackBar } from '@angular/material';
import { ActivatedRoute } from '@angular/router';
import { Router } from '@angular/router';
import { applicationconstant } from 'src/app/Models/DropdownItemsGroup/applicationconstant.model';
import { Observable } from 'rxjs';
import { TicketsAssginedSE } from 'src/app/Services/ServiceEngineer/TicketsAssignedSE.service';
//import { CustomerService } from 'src/app/Services/customer.service';
//import { ChangeMobile } from 'src/app/Models/ChangeMobile/changemobile.model';
//import { LoginService } from 'src/app/Services/login.service';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { Inject } from '@angular/core';
import { SETicketOTP } from 'src/app/Models/ServiceEngineer/SETicketOTP.model';

@Component({
  selector: 'app-verifyincident',
  templateUrl: './verifyincident.component.html',
  styleUrls: ['./verifyincident.component.scss']
})
export class VerifyincidentComponent implements OnInit {
  verifyIncidentForm: FormGroup;
  currentMobileNumber: number;
  redirectToDashboard: boolean;
  showverify: boolean = true;
  userMobile: number;
  userEmail: string;

  validationMessages = {
    'currentmobileNo': {
      'required': 'Mobile No. is required.',
      'minlength': 'Mobile No. must be 10 digits.',
      'maxlength': 'Mobile No. must be 10 digits.',
      'pattern': 'Your Mobile number must contain only numbers.'
    },
    'newmobileNo': {
      'required': 'Mobile No. is required.',
      'minlength': 'Mobile No. must be 10 digits.',
      'maxlength': 'Mobile No. must be 10 digits.',
      'pattern': 'Your Mobile number must contain only numbers.'
    },
    'mobileOTP': {
      'required': 'Mobile OTP is required.',
      'maxlength': 'Mobile OTP cannot be more than 10 digits.',
      'pattern': 'Your OTP must contain only numbers.'
    }
  };

  formErrors = {
    'currentmobileNo': '',
    'newmobileNo': '',
    'mobileOTP': ''
  };
  constructor( private fb: FormBuilder, public snackBar: MatSnackBar,private EngineerService: TicketsAssginedSE,
    public dialogRef: MatDialogRef<VerifyincidentComponent>, @Inject(MAT_DIALOG_DATA) public ticketId: number ,private router: Router) 
    { 
      this.verifyIncidentForm = this.fb.group({
        mobileOTP: ['', [Validators.required, Validators.maxLength(10), Validators.pattern("^[0-9]*$")]]
      });
    }

  ngOnInit() {

      this.verifyIncidentForm = this.fb.group({
        mobileOTP: ['', [Validators.required, Validators.maxLength(10), Validators.pattern("^[0-9]*$")]]
      });
    this.verifyIncidentForm.valueChanges.subscribe((data) => {
      this.logValidationErrors(this.verifyIncidentForm);
    });
  }

  ngOnDestroy() {
    // this.isMobileVerification = false;
    // this.isEmailVerification = false;
  }

  OnSubmit(): void {
    if (this.verifyIncidentForm.valid) {
      const details = this.verifyIncidentForm.value;
      var OtpDetails = new SETicketOTP();
      OtpDetails.TicketId = this.ticketId;
      OtpDetails.OTP = details.mobileOTP;
        this.EngineerService.VerifyOTPForEFSR(OtpDetails)
          .subscribe(
            data => {
              if (data.flag == applicationconstant.SuccessFlag) {
                this.openSnackBar(data.message, '');
                this.dialogRef.close(true);
              }
              else {
                this.openSnackBar(data.message, '');
              }
            },
            (err: any) => this.openSnackBar(err.error.message, '')
          );
    }
    else {
      this.ValidateAllFields(this.verifyIncidentForm);
      this.logValidationErrors(this.verifyIncidentForm);
    }
  }

  Skip(): void {
    this.dialogRef.close(false);
  }

  openSnackBar(message: string, action: string) {
    this.snackBar.open(message, action, {
      duration: 5000,
      verticalPosition: 'top'
    });
  }

  logValidationErrors(group: FormGroup = this.verifyIncidentForm): void {
    Object.keys(group.controls).forEach((key: string) => {
      const abstractControl = group.get(key);
      if (abstractControl instanceof FormGroup) {
        this.logValidationErrors(abstractControl)
      } else {
        this.formErrors[key] = '';
        if (abstractControl && !abstractControl.valid && (abstractControl.touched || abstractControl.dirty)) {
          const messages = this.validationMessages[key];

          for (const errorKey in abstractControl.errors) {
            if (errorKey) {
              this.formErrors[key] += messages[errorKey] + '';
            }
          }
        }
      }
    });
  }

  ValidateAllFields(formGroup: FormGroup) {
    Object.keys(formGroup.controls).forEach(field => {
      const control = formGroup.get(field);
      if (control instanceof FormControl) {
        control.markAsTouched({ onlySelf: true });
      } else if (control instanceof FormGroup) {
        this.ValidateAllFields(control);
      }
    });
  }

  private handleError(error: any) {
    return Observable.throw(error);
  }

  ResetForm(): void {
    this.verifyIncidentForm.reset();
    this.verifyIncidentForm.clearValidators();
    for (let i in this.verifyIncidentForm.controls) {
      this.verifyIncidentForm.controls[i].setErrors(null);
    }
  }    
}
