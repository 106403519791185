export class vendordetails {
     userId: number;
     username: string;
     firstName: string;
     lastName: string;
     location: null;
     roleId: number;
     email: string;
     mobile: string;
     designation: string;
     activeFlag: string;
     referenceId: string;
     middlename:string;     
     organization: string;
     DOB:Date;
     address:string;
     state:number;
     city:number;
     pincode:number;
     //activeflag:string;
     }
     
     export interface Icategory {
          id: number;
          name: string;
        }
        export interface Isubcategory {
          id: number;
          name: string;
        }
        export interface Iareaname {
          id: number;
          name: string;
        }
        export interface Isubareaname {
          id: number;
          name: string;
        }
        export interface Iro {
          id: number;
          name: string;
        }
        export interface Ivendor {
          id: number;
          name: string;
        }