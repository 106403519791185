import { Injectable } from '@angular/core';
//import { forEach } from '@angular/router/src/utils/collection';
//import { isEmpty } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class MenuItemService {

    public menuItems: any;
    constructor() { }

    /**
     * Check whether logged in user has permission to access given url
     * @param url 
     */
    public SearchMenuItems(url: string): boolean {      
      this.menuItems = this.getMenuItems();
      let breakFlag = false;
        if (url.includes('dashboard') || url.includes('changepassword') || url.includes('changemobile') || url.includes('changeemail') ) {
          return true;
        } else if (this.menuItems != null) {
            for (let menu of this.menuItems) {
                for (let subMenu of menu.subMenuDtos) {
                    if(subMenu.urlLink.includes(url)) {
                        breakFlag = true;
                        break;
                    }
                    else if(!this.isObjectEmpty(subMenu.superSubMenuDtos))
                    {                        
                        for(let supersubmenu of subMenu.superSubMenuDtos)
                        {
                            if(supersubmenu.urlLink.includes(url)){
                                breakFlag=true;
                                break;
                            }
                        }
                    }
                }
                if(breakFlag) {
                    return true;
                }
            }
        } else {
            return false;
        }
    }

    /**
     * Set Menu itmes for futher reference
     * @param menuItems 
     */
    public setMenuItems(menuItems: any): void {
        localStorage.setItem('menu', JSON.stringify(menuItems));
    }
    
    /**
     * Get Menu items to display on dashboard
     * @param menuItems 
     */
    public getMenuItems(): any {
      return JSON.parse(localStorage.getItem('menu'));
  }
    public isObjectEmpty(Obj) {
    for(var key in Obj) {
    if(Obj.hasOwnProperty(key))
    return false;
    }
    return true;
    }
}
