import { Component, OnInit,ChangeDetectorRef, ViewChild } from '@angular/core';
import { FormGroup, FormControl, Validators, FormBuilder, ValidatorFn, AbstractControl } from '@angular/forms';
import { MatDialog, MatSnackBar } from '@angular/material';
import { UserService } from 'src/app/Services/ServiceEngineer/SECreate.service';
import { SECreate } from 'src/app/Models/ServiceEngineer/SECreate.model';
import { AssignTicket } from 'src/app/Models/TicketsSE/AssignTicket.model';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { UniqueUsernameValidator } from 'src/app/shared/unique-username-validator.directive';
import { ConfigurationService } from 'src/app/Services/Utilities/configuration.service';
import { Constants } from 'src/app/Constants/constants';
import{ VendorSupervisorService } from 'src/app/Services/vendorsupervisor/vendorsupervisor.service'
import { Iserviceengineer } from 'src/app/Models/common/ServiceEngineer.model';

@Component({
    selector: 'app-assign-ticketto-se',
    templateUrl: './assign-ticketto-se.component.html',
    styleUrls: ['./assign-ticketto-se.component.scss']
  })
export class AssignTickettoSEComponent  implements OnInit {

  /**
   * Create User From Group
   *
   * @type {FormGroup}
   * @memberof SECreateComponent
   */
  public assigntickettoSEForm: FormGroup;
  ticketId : number;
  ticketInteractionId : string;
  

  /**
   * submitted variable to track if the form values
   * are being submitted
   *
   * @memberof SECreateComponent
   */
  public submitted: boolean;
  isFormSectionVisible: boolean = true;

  /**
   * Collection of user types to be filled in dropdown
   *
   * @type {IUserType[]}
   * @memberof SECreateComponent
   */
   /**
   * Collection of roles to be filled in roles dropdown
   *
   * @type {IRole[]}
   * @memberof SECreateComponent
   */
   public serviceengineer: Iserviceengineer[];
  /**
   * it will help reset both the form data and submit status
   * https://stackoverflow.com/questions/49788215/angular-material-reseting-reactiveform-shows-validation-error
   * 
   * @memberof SECreateComponent
   */
  @ViewChild('form', {static: false}) form;

  ServiceEngineerDisabledMap: Map<string, boolean>;
  /**
   * Validation messages for user creation form
   *
   * @memberof SECreateComponent
   */
  public account_validation_messages = {
   
    'serviceengineer': [
      { type: 'required', message: 'Service Engineer selection is required' }
    ],
    'remark': [
      { type: 'pattern', message: 'Avoid special character' }
      

    ]
  };

  /**
   * Creates an instance of UserCreateComponent.
   * @param {UserService} userService
   * @param {FormBuilder} fb
   * @param {MatSnackBar} snackBar
   * @memberof SECreateComponent
   */
  constructor(private vendorservice:  VendorSupervisorService,private SEService: UserService,
    private fb: FormBuilder,
    public snackBar: MatSnackBar,
    private configurationService: ConfigurationService,
    private cdr:ChangeDetectorRef) {
    this.createForm();

    this.submitted = false;
     this.ServiceEngineerDisabledMap = new Map();
  }

  
  /**
   * Initializes the component and subscribes to the dropdown services
   * for usertypes and roles
   *
   * @memberof SECreateComponent
   */
   ngOnInit() {
    this.ticketId = this.vendorservice.getTicketId();
    this.ticketInteractionId = this.vendorservice.getTicketInteractionId();
    this.assigntickettoSEForm.get('ticket').setValue(this.ticketInteractionId);
    this.SEService.getNames()
      .pipe(catchError((err) => this.handleError(err)))
      .subscribe((data: any) => {
        this.serviceengineer = data.results;        
      });
  }

  /**
   * Build user creation form using form builder
   *
   * @memberof SECreateComponent
   */
  createForm() {
    this.assigntickettoSEForm = this.fb.group({
    
      serviceengineer: new FormControl('', Validators.compose([
        Validators.required,
        Validators.maxLength(250),
        Validators.pattern('^[A-Za-z0-9]+(?:[A-Za-z0-9]+)*$')
      ]))
    ,
    ticket: new FormControl('', Validators.compose([
      Validators.required,
      Validators.maxLength(250),
      Validators.pattern('^[A-Za-z0-9]+(?:[A-Za-z0-9]+)*$')
    ])),
    remark: new FormControl('', Validators.compose([
      Validators.required,
      Validators.maxLength(250),
      Validators.pattern('^[A-Za-z0-9\ , .]+$')
    ]))
  });
    
  }

  onSubmit(form: any) {
    this.submitted = true;

    // stop here if form is invalid
    if (!this.assigntickettoSEForm.valid) {
      this.submitted=false;
      return;
    }

    const userDetails = Object.assign({}, this.assigntickettoSEForm.value);
    const newUser = new AssignTicket();
    newUser.SEID = userDetails.serviceengineer;
    newUser.TckID = this.ticketId;
    newUser.Remark = userDetails.remark;
    this.SEService.AssignTicekToSE(newUser)
      .subscribe(
        (data: any) => {
          this.submitted = false;
          this.openSnackBar(data.message, '');
          if (data.flag === Constants.SUCCESS_FLAG) {
            this.openSnackBar(data.message, '');
            this.form.resetForm();
          }
        },
        (err: any) => {
          this.submitted = false;
          this.openSnackBar(err.error.message, '');
        },
        () => { }
      );
  }

  /**
   * Reset form on clicking reset button.
   * Only resetting the does not reset form group validators
   * https://stackoverflow.com/questions/48216330/angular-5-formgroup-reset-doesnt-reset-validators
   *
   * @memberof SECreateComponent
   */
  resetForm() {
    this.form.reset();
    // clear validators and dont remove them
    this.assigntickettoSEForm.clearValidators();

    this.assigntickettoSEForm.markAsUntouched();
    this.assigntickettoSEForm.markAsPristine();
  }

  openSnackBar(message: string, action: string) {
    this.snackBar.open(message, action, {
      duration: 5000,
      verticalPosition: 'top'
    });
  }

  private handleError(error: any) {
    // this.errorReceived = true;
    return Observable.throw(error);
  }

}
