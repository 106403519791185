import { Component, OnInit,ChangeDetectorRef, ViewChild } from '@angular/core';
import { FormGroup, FormControl, Validators, FormBuilder, ValidatorFn, AbstractControl } from '@angular/forms';
import { MatDialog, MatSnackBar } from '@angular/material';
import { roratingService } from 'src/app/Services/Reports/rorating.service';
import { ISearchPara } from 'src/app/Models/Reports/rorating.model';
import { rorating } from 'src/app/Models/Reports/rorating.model';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { UniqueUsernameValidator } from 'src/app/shared/unique-username-validator.directive';
import { ConfigurationService } from 'src/app/Services/Utilities/configuration.service';
import { Constants } from 'src/app/Constants/constants';
import { applicationconstant } from 'src/app/Models/DropdownItemsGroup/applicationconstant.model';


@Component({
  selector: 'app-rorating',
  templateUrl: './rorating.component.html',
  styleUrls: ['./rorating.component.scss']
})
export class RoratingComponent implements OnInit {

  public btnSearch:string= 'Hide';
  public btnExport:string='Hide';
  roratingList: rorating[];

  Priority = ['Critical', 'High', 'Medium','Low'];
  
  public RORatingForm: FormGroup;
  isFormSectionVisible: boolean = true;

  constructor(private roratingHistory: roratingService,
    private fb: FormBuilder,
    public snackBar: MatSnackBar,
    private configurationService: ConfigurationService,
    private cdr:ChangeDetectorRef) { 
      this.createForm();
  }

  createForm() {
    this.RORatingForm = this.fb.group({
      Priority: new FormControl('', Validators.compose([
        //Validators.required,
      ])),
      searchKey: new FormControl('', Validators.compose([
      ])),
    });
  }
  ResetSearch()
  {
    this.RORatingForm.reset();
    this.onSearch();
  }
  public account_validation_messages = {
    'Priority': [
      { type: 'required', message: 'status is required' },
    ],
  };

  ngOnInit() {
      this.onSearch();
  }

  onSearch(){
    const RoFormData: any = Object.assign({}, this.RORatingForm.value);
    let changed = true;
    const userData=new ISearchPara();
    userData.priority=RoFormData.Priority;
    if (changed) {
      this.roratingHistory.searchData(userData).subscribe(
        (data: any) => {
          
          if (data.flag == applicationconstant.SuccessFlag) {
            this.roratingList = data.results;
            this.openSnackBar('Data found and displayed','');
            this.btnSearch='Hide';
            this.btnExport='Show';
          }
          else {
            this.openSnackBar('Data not found for selected criteria','');
            this.roratingList = [];
            this.btnSearch='Show';
            this.btnExport='Hide';
          }
        },
        (err: any) => this.openSnackBar('Something went wrong. Please Refresh/Relogin', ''),
        () => { }
      );
    }
  }

  funCategory()
  {
    this.btnSearch='Show';
    this.btnExport='Hide';
  }
  funStatus()
  {
    this.btnSearch='Show';
    this.btnExport='Hide';
  }
  funLocation()
  {
    this.btnSearch='Show';
    this.btnExport='Hide';
  }
  funPriority()
  {
    this.btnSearch='Show';
    this.btnExport='Hide'; 
  }

  // onSearchClear()
  // {
  //   this.searchKey="";
  // }

  applyFilter(skey:string)
  {
    return this.roratingList.filter(item => {
      return item.rocode.toUpperCase().indexOf(skey) > -1;
    });
  }

  ExporttoExcel(){
    const RoFormData: any = Object.assign({}, this.RORatingForm.value);
    let changed = true;
    const userData=new ISearchPara();
    userData.priority=RoFormData.Priority;
    if (changed) {
      this.roratingHistory.searchData(userData).subscribe(
        (data: any) => {
          //this.openSnackBar(data.message, '');
          if (data.flag == applicationconstant.SuccessFlag) {
            this.roratingList = data.results;
            this.roratingHistory.exportExcel(this.roratingList, 'RO_Rating_Report');
            this.openSnackBar('Data Exported Successfully','');
          }
          else {
            this.openSnackBar('No Data Found To Export','');
            this.roratingList = [];
          }
        },
        (err: any) => this.openSnackBar(err.error.message, ''),
        () => { }
      );
    }
  }

  onSubmit(form: any) {
    // const RoFormData: any = Object.assign({}, this.ReportIncidentForm.value);
    // let changed = false;
    // RoFormData.category=this.userData.category;
    // RoFormData.status=this.userData.status;
    // RoFormData.location=this.userData.location;
    // RoFormData.Priority=this.userData.priority;
    // if (changed) {
    //   this.ROIncidentHistory.SearchData(this.userData).subscribe(
    //     (data: any) => {
    //       this.openSnackBar(data.message, '');
    //       if(data.flag=="F"){
                       
    //     }
    //     },
    //     (err: any) => this.openSnackBar(err.error.message, ''),
    //     () => { }
    //   );
    // }
  }
  openSnackBar(message: string, action: string) {
    this.snackBar.open(message, action, {
      duration: 5000,
      verticalPosition: 'top'
    });
  }
  private handleError(error: any) {
    // this.errorReceived = true;
    return Observable.throw(error);
  }

}

