import { Component, OnInit } from '@angular/core';
import { Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { FormGroup, FormBuilder, FormControl, Validators } from '@angular/forms';
import { applicationconstant } from 'src/app/Models/DropdownItemsGroup/applicationconstant.model';
import { MatDialog, MatSnackBar, MatAutocompleteModule, MatInputModule } from '@angular/material';
import { Observable } from 'rxjs';
import { VendorSupervisorService } from 'src/app/Services/vendorsupervisor/vendorsupervisor.service';
import{Incident,IncidentReject} from 'src/app/Models/vendorsupervisor/incident.model';
@Component({
  selector: 'app-close-incident-dialog',
  templateUrl: './close-incident-dialog.component.html',
  styleUrls: ['./close-incident-dialog.component.scss']
})
export class CloseIncidentDialogComponent implements OnInit {
  form: FormGroup;
  description: string;
  submitted: boolean = false;
  close: boolean = false;

  validationMessages = {
    'remarks': {
      'required': 'Remarks for Rejection Incident is required.',
    },
  };

  formErrors = {
    'remarks': ''
  };
  constructor(private fb: FormBuilder, public snackBar: MatSnackBar,private vendorservice: VendorSupervisorService,public dialogRef: MatDialogRef<CloseIncidentDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data:any) {
      this.form = this.fb.group({
        remarks: new FormControl(null, Validators.required)
      });
     }

     onNoClick(): void {
      this.dialogRef.close();
    }
  
    onYesClick(): void{
      if (this.form.valid) {
        const remarks = this.form.get('remarks').value;
        const rejectData = new IncidentReject();
        rejectData.id = this.data.ticketId;
        //rejectData.seId = this.data.seId;
        rejectData.incidentId=this.data.incidentId;
        //rejectData.sEEmail=null;
        rejectData.interactionId= this.data.interactionId;
       // rejectData.seemail= this.data.seemail;
        rejectData.remarksByVS = remarks;
        //rejectData.approveReason=null;
        //rejectData.sEEmail = this.data.sEEmail;
        //rejectData.ratingByRO = null;
        // rejectData.ratingCommentsByRO = null;
        // rejectData.approvedFlag = 0;
        rejectData.rejectFlag = 1;
  
        //const seData= new SECreate();
  
  
        this.vendorservice.rejectIncident(rejectData)
          .subscribe(
            data => {
              if (data.flag == applicationconstant.SuccessFlag) {
                this.openSnackBar(data.message, '');
                this.dialogRef.close(true);
              }
              else {
                this.openSnackBar(data.message, '');
              }
            },
            (err: any) => this.openSnackBar(err.error.message, '')
          );
      }
      else {
        this.ValidateAllFields(this.form);
        this.logValidationErrors(this.form);
      }
  }
  
    ngOnInit() {
     
      this.form.valueChanges.subscribe((data) => {
        this.logValidationErrors(this.form);
      });
    }
  
    logValidationErrors(group: FormGroup = this.form): void {
      Object.keys(group.controls).forEach((key: string) => {
        const abstractControl = group.get(key);
        if (abstractControl instanceof FormGroup) {
          this.logValidationErrors(abstractControl)
        } else {
          this.formErrors[key] = '';
          if (abstractControl && !abstractControl.valid && (abstractControl.touched || abstractControl.dirty)) {
            const messages = this.validationMessages[key];
  
            for (const errorKey in abstractControl.errors) {
              if (errorKey) {
                this.formErrors[key] += messages[errorKey] + '';
              }
            }
          }
        }
      });
    }
  
    ValidateAllFields(formGroup: FormGroup) {
      Object.keys(formGroup.controls).forEach(field => {
        const control = formGroup.get(field);
        if (control instanceof FormControl) {
          control.markAsTouched({ onlySelf: true });
        } else if (control instanceof FormGroup) {
          this.ValidateAllFields(control);
        }
      });
    }
  
    public openSnackBar(message: string, action: string) {
      this.snackBar.open(message, action, {
        duration: 5000,
        verticalPosition: 'top'
      });
    }
  
    private handleError(error: any) {
      return Observable.throw(error);
    }
  }
  
  