import { Injectable } from '@angular/core';

import { VendorSupervisor } from 'src/app/Models/vendorsupervisor/vendor-supervisor.model';
import { Observable, throwError } from 'rxjs';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { map, catchError, tap } from 'rxjs/operators';
import { HttpVerbService } from 'src/app/Services/httpverb/httpverb.service';
import { IRole } from 'src/app/Models/usermanagement/role.model';
import { IMenu } from 'src/app/Models/common/menu.model';
import { environment } from 'src/environments/environment';
import { IState } from 'src/app/Models/common/state.model';
import { Icity } from 'src/app/Models/common/city.model';

@Injectable({
  providedIn: 'root'
})
export class VendorsupervisorregisterService {
  baseUrl: string;
  constructor(private http: HttpClient, private service: HttpVerbService) {
    this.baseUrl = environment.restUrl + '/api/v1/';
  }

  CreateVS(newUser: VendorSupervisor): Observable<any> {
      return this.http.post<any>(this.baseUrl + 'createVSuser', newUser, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'No-Auth':'True'
      })
    });
  }
  /**
   * Get all active States
   *
   * @returns {Observable<IState[]>}
   * @memberof UserService
   */
  // getState(): Observable<IState[]> {
  //   debugger;
  //   const url = this.baseUrl + 'VSstates';
  //   return this.service.get(url,
  //     {
  //       headers:  new  HttpHeaders({
  //       'Content-Type':  'application/json',
  //       'No-Auth':'True'
  //       })
  //       } 
  //     )    
  //   .pipe(map((response: any) => {
  //     return response;
  //   }));
  // }

  getState(): Observable<any> {
    return this.http.get<any>(this.baseUrl + 'VSstates', {
    headers: new HttpHeaders({
    'Content-Type': 'application/json',
    'No-Auth':'True'
    })
    }).pipe(map((response: any) => {
    return response;
    }
    ));
    } 

    getStateEdit(): Observable<any> {
      return this.http.get<any>(this.baseUrl + 'VSstatesEdit', {
      headers: new HttpHeaders({
      'Content-Type': 'application/json',
      
      })
      }).pipe(map((response: any) => {
      return response;
      }
      ));
      } 

   /**
   * Get all active States
   *
   * @returns {Observable<Icity[]>}
   * @memberof UserService
   */
  //  getCity(): Observable<Icity[]> {
  //   const url = this.baseUrl + 'VScity';
  //   return this.service.get(url,
  //     {
  //       headers:  new  HttpHeaders({
  //       'Content-Type':  'application/json',
  //       'No-Auth':'True'
  //       })
  //       }
  //     ).pipe(map((response: any) => {
  //     return response;
  //   }));
  // }
  
  getCity(state:string): Observable<any> {
    return this.http.get<any>(this.baseUrl + 'VScity?state=' +state, {
    headers: new HttpHeaders({
    'Content-Type': 'application/json',
    'No-Auth':'True'
    })
    }).pipe(map((response: any) => {
    return response;
    }
    ));
    } 

    getCityEdit(state:string): Observable<any> {
      return this.http.get<any>(this.baseUrl + 'VScityEdit?state=' +state, {
      headers: new HttpHeaders({
      'Content-Type': 'application/json',
      
      })
      }).pipe(map((response: any) => {
      return response;
      }
      ));
      }
}
