import { Injectable } from '@angular/core';
import { HttpVerbService } from 'src/app/Services/httpverb/httpverb.service';
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class ConfigurationService {

  baseUrl: string;

  constructor(private service: HttpVerbService) {
    this.baseUrl = environment.restUrl + '/api/v1/';
  }

  getUserCreationRolesDisabled(): Observable<any> {
    const url = this.baseUrl + 'roles/disabled';
    return this.service.get(url).pipe(map((response: any) => {
      return response;
    }));
  }

  getUserCreationUserTypesDisabled(): Observable<any> {
    const url = this.baseUrl + 'usertypes/disabled';
    return this.service.get(url).pipe(map((response: any) => {
      return response;
    }));
  }
}
