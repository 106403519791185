import { Component, OnInit } from '@angular/core';
import * as $ from 'jquery';
import { HeaderService } from '../../../../Services/header/header.service';
import { LoginService } from 'src/app/Services/loginmanagement/login.service';
import { Router } from '@angular/router';
//import { OrderService } from '../../../../Services/order/order.service';
import { MatSnackBar } from '@angular/material';
//import { CustomerService } from 'src/app/Services/customer.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {

  currentUser: string;
  currentUser1: string;
  userMobile: number;
  userEmail: string;

  constructor(private loginService: LoginService, public headerService: HeaderService,private router: Router,
    public snackBar: MatSnackBar) { }
  selected = 'Platform';

  notificationsCount = 0;
  cartItemsCount = 0;
  showCartItem: boolean = true;
  sidenavToggle() {
    console.log('clicked');
    $('body').toggleClass('nav-close');
  }

  ngOnInit() {
    const currentUser = JSON.parse(localStorage.getItem('currentUser'));
    this.currentUser = currentUser && currentUser.FirstName + " " + currentUser.LastName;

    (function (window, document, undefined) {
      'use strict';

      // Initialize the media query
      const mediaQuery = window.matchMedia('(max-width: 768px)');

      // Add a listen event
      mediaQuery.addListener(doSomething);

      // Function to do something with the media query
      function doSomething(mediaQuery: any) {
        if (mediaQuery.matches) {
          $('body').addClass('nav-close');
        } else {
          //
          $('body').removeClass('nav-close');
        }
      }

      // On load
      doSomething(mediaQuery);

      // Modernizr
      // window.addEventListener('resize', function() {
      //  if (Modernizr.mq('(min-width: 560px)')) {
      //    document.body.style.backgroundColor = 'CornflowerBlue';
      //  } else {
      //    document.body.style.backgroundColor = 'FireBrick';
      //  }
      // }, false);

    })(window, document);

    // if (localStorage.getItem('currentUser') != null) {
    //   this.currentUser = JSON.parse(localStorage.getItem('currentUser'))['FirstName'] + ' '
    //     + JSON.parse(localStorage.getItem('currentUser'))['LastName'];
    // }
    
    // if(currentUser && currentUser.Role.toUpperCase() == 'CUSTOMER') {
    //   this.orderService.getInCartItemsCount(currentUser.UserId).subscribe((data: any) => {
    //     if (data && data.flag === 'S') {
    //       this.cartItemsCount = data.result;
    //     }
    //     else {
    //       this.openSnackBar(data.message, '');          
    //     }},
    //     (err: any) => {
    //         this.openSnackBar(err.error.message, '');
    //     });
    // }

    //calculate my cart counter
      //this.CalculateNumberOfItemsInCart();
  }

    /**
     * Get logged in user name
     */
    public getLoggedInUserName() {
        // const currentUser = JSON.parse(localStorage.getItem('currentUser'));
        // this.currentUser = currentUser && currentUser.FirstName + " " + currentUser.LastName;
    }

  /**
   * Logout user
   * Clear localstorage after logout
   */
  public logout() {
    this.loginService.updateUserLogoutTime().subscribe((data: any) => { },
      (err: any) => { console.log(err); });

    localStorage.removeItem('userToken');
    localStorage.removeItem('currentUser');
    localStorage.removeItem('menu');
  }

  public changePassword() {
    this.router.navigate(['/changepassword']);
  }

  public changeMobile() {
        this.loginService.getMobileandEmail().subscribe((data: any) => {
            if (data && data.flag === 'S') {
                this.userMobile = data.result.mobile;
                //this.customerService.SetMobileToChange(this.userMobile);
                //this.customerService.SetDashboardReturnFlag(true);
                //this.router.navigate(['changemobile']);
            }
            else {
                this.openSnackBar(data.message, '');
            }
        },
            (err: any) => {
                this.openSnackBar(err.error.message, '');
            });
    }

  public changeEmail() {
      this.loginService.getMobileandEmail().subscribe((data: any) => {
          if (data && data.flag === 'S') {
              this.userEmail = data.result.email;
              //this.customerService.SetEmailToChange(this.userEmail);
              //this.customerService.SetDashboardReturnFlag(true);
              //this.router.navigate(['changeemail']);
          }
          else {
              this.openSnackBar(data.message, '');
          }
      },
          (err: any) => {
              this.openSnackBar(err.error.message, '');
          });
      
    }

  public openSnackBar(message: string, action: string) {
    this.snackBar.open(message, action, {
      duration: 5000,
      verticalPosition: 'top'
    });
  }

  //public CalculateNumberOfItemsInCart() {
  //  const user = JSON.parse(localStorage.getItem('currentUser'));
  //  //hide cart item icon for users other than customer
  //  this.showCartItem = false;
  //  if(user && user.Role && user.Role.toUpperCase() == 'CUSTOMER') {
  //    this.showCartItem = true;
  //    this.orderService.getInCartItemsCount(user.UserId).subscribe((data: any) => {
  //      if (data && data.flag === 'S') {
  //        this.cartItemsCount = data.result;
  //      }
  //      else {
  //        this.openSnackBar(data.message, '');          
  //      }},
  //      (err: any) => {
  //          this.openSnackBar(err.error.message, '');
  //      });
  //  }
  //}

}


