import { Injectable } from '@angular/core';
import { HttpErrorResponse,HttpClient, HttpHeaders } from '@angular/common/http';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ErrorService {

  errorStatusCode: number;
  baseUrl:string;
  constructor(private router: Router,private http:HttpClient) {
    this.baseUrl = environment.restUrl + '/api/v1/';
   }

  public handleError(error: any): void {
    if (error) {
      // set error status code
      this.setErrorCode(error.status);      
      // dont naviate to error page if 400 - BadRquest happens
      if (error.status === 500
        || error.status === 401
        || error.status === 403
        || error.status === 405
        ) {

        this.router.navigate(['/error']);
      }
    }
  }

  public setErrorCode(errorCode: number): void {
    this.errorStatusCode = errorCode;
  }

  public getErrorCode(): number {
    return this.errorStatusCode;
  }

  public logError(error:any,errorLocation:any,errorMessage,stackTrace): Observable<any>{
   return  this.http.post(this.baseUrl+'logError?ErrorMessage='+error+'&errorLocation='+errorLocation+'&errorType='+errorMessage+'&stackTrace='+stackTrace, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
  });
}

}
