import { Component, OnInit, ViewChild,Input } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators, NgForm } from '@angular/forms';
import { MatDialog, MatSnackBar, MatDialogConfig, MatCheckboxChange } from '@angular/material';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { ConfigurationService } from 'src/app/Services/Utilities/configuration.service';
import { Constants } from 'src/app/Constants/constants';
import { efsrService } from 'src/app/Services/EFSR/efsrcreate.service';
import { efsrcreate, hpsmandsedetails,efsrDraft, CheckListSaveDto, patchefsrDraft } from 'src/app/Models/EFSR/efsrcreate.model';
import { Ivendor, Irokeypersonlist } from 'src/app/Models/common/vendor.model';
import { Iservicing } from 'src/app/Models/common/vendor.model';
import { Idevice } from 'src/app/Models/common/vendor.model';
//import { CheckListSaveDto } from 'src/app/Models/common/vendor.model';
import { TicketsAssginedSE } from 'src/app/Services/ServiceEngineer/TicketsAssignedSE.service';
import { VendorSupervisorService } from 'src/app/Services/vendorsupervisor/vendorsupervisor.service';
import { ValueConverter } from '@angular/compiler/src/render3/view/template';
import { SearchRODTO } from 'src/app/Models/common/search.model';





@Component({
  selector: 'app-create-efsr',
  templateUrl: './create-efsr.component.html',
  styleUrls: ['./create-efsr.component.scss']
})

export class CreateEfsrComponent implements OnInit {
  tmp : boolean = false;

  //public users = [{name : " Alan"} , {name : "john"}] ;
  public spare=[{name:"Yes"},{name:"No"}];
  public vendorList: Ivendor[];
  public serviceList: Iservicing[];
  public deviceList: Idevice[];
  public checklist: CheckListSaveDto[];
  //public ticksData01= [{name: "No Rating"}, {name:"Very Poor"}, {name:"Poor"}, {name:"Satisfactory"}, {name:"Good"}, {name:"Excellent"}];
  //public rokeyperson: Irokeypersonlist[];
  vendorDisabledMap: Map<string, boolean>;
  serviceDisabledMap: Map<string, boolean>;
  deviceDisabledMap: Map<string, boolean>;
  checklistDisabledMap: Map<string, boolean>;
  roketpersonlistDisabledMap: Map<string, boolean>;
  createEFSRForm: FormGroup;
  public spareremark: boolean;
  public ratingremark: boolean;
 public ratingcnt: number=0;
 //rating:string;
  startdate : Date;
  incidentId : string
  roId : number
  ticketId: number
  isEfsrExists: boolean =false;
  isDocumentSectionVisiable: boolean = false;
  isFormSectionVisible: boolean = true;
  efsrId: number = 0;
  isEditForm: boolean = false;
  selectedchecklist: number[];
 
  //ratingcheck=[{name:'Very Poor', id:1},{name:'Poor', id:2},{name:'Satisfactory', id:3},{name:'Good', id:4},{name:'Excellent', id:5}];
 
  labelPosition="after";



  
  validationMessages = {
    'vendor': {
      'required': 'Type Of Vendor is required'
    },
    'service': {
      'required': 'TypeOfServicing is required.'
    },
    'rokeyperson': {
      'required': 'RO Key Person is required.'
    },
    'device': {
      'required': 'Type Of Device is required.'
    },
    'checklistControl': {
      'required': 'Check List is required.'
    },
    'spare': {
      'required': 'Spares Consumed is required.'
    },
    'visitdate': {
      'required': 'Visit Date is required.'
    },
    'quantity': {
      'required': 'Quantity is required.',
      'pattern': 'Quantity should be numeric.',
    },
    'servicesummary': {
      'required': 'Service Summary is required.'
    },
    'remark': {
      'required': 'Remark is required.'
    },
    'poorratingcomments': {
      'required': 'Please Provide Comment.'
    },
    
    'ratingSlider': {
      'required': 'Rating is required'
    },

  };

  formErrors = {
    'vendor': '',
    //'rokeypersonmobile': '',
    'service': '',
    //'rokeyperson': '',
    'device': '',
    'checklistControl': '',
    'spare': '',
    //'visitdate': '',
    'quantity': '',
    'servicesummary': '',
    'remark': '',
    'poorratingcomments': '',
    'ratingSlider': '',
    
  };

  public roaccount_validation_messages1 = {
    'vendor': [
      { type: 'required', message: 'Type Of Vendor is required' }
    ],    
    'service': [
      { type: 'required', message: 'Type Of Servicing is required' }
    ],
    'rokeyperson': [
      { type: 'required', message: 'RO Key Person is required' }
    ],
    'device': [
      { type: 'required', message: 'Type Of Device is required' }
    ],
    'checklist': [
      { type: 'required', message: 'CheckList is required' }
    ],
    'spare': [
      { type: 'required', message: 'Spares Consumed is required' }
    ],
    'visitdate': {
      'required': 'Visit Date is required.',
      'matDatepickerMin': 'Visit Date should be past date.'
    }, 
    'quantity': [
      { type: 'required', message: 'Quantity is required' },
      // { type: 'minlength', message: 'quantity must be of 6 digits' },
     // { type: 'pattern', message: 'Quantity is invalid' }
    ],
    'servicesummary': [
      { type: 'required', message: 'Service Summary is required' }
    ],
    'ratingSlider': [
      { type: 'required', message: 'Rating is required' }
    ],

    'poorratingcomments': [
      { type: 'required', message: 'Please Provide Comment' }
    ], 
  };

  constructor(private fb: FormBuilder,
    public snackBar: MatSnackBar,
    public dialog: MatDialog,
    private efsrservice: efsrService,
    private configurationService: ConfigurationService,private EngineerService: TicketsAssginedSE,private VendorService: VendorSupervisorService) {

    this.createEFSRForm = this.fb.group({
    });

    this.createEFSRForm = this.createFormGroupWithBuilderAndModel();
    this.vendorDisabledMap = new Map();
    this.serviceDisabledMap = new Map();
    this.deviceDisabledMap=new Map();
    this.checklistDisabledMap=new Map();
    this.roketpersonlistDisabledMap=new Map();
    this.spareremark = false;
    this.ratingremark=false;
    this.ratingcnt=0;
//this.IsDisabled =false;
  }

 

  ngOnInit() {
    this.isEfsrExists =  this.EngineerService.efsrExists;
    this.efsrId = this.EngineerService.getEFSRId();
    this.ticketId = this.VendorService.getTicketId();
    this.incidentId =  this.EngineerService.geIncidentId();
    this.roId = this.EngineerService.getROId();
    this.startdate=new Date();
    this.startdate.setDate(this.startdate.getDate() - 0);

    this.efsrservice.getRODetails(this.roId)
      .subscribe(
        (data: any) => {
          if (data.flag === Constants.SUCCESS_FLAG) {
            if (data.result.rocode !== undefined) {
              //this.openSnackBar(data.result.rocode + ' details fetched', '');
              this.setEditFormValues(data.result);
              this.createEFSRForm.get('roname').enable();
              this.createEFSRForm.get('rocity').enable();
              this.createEFSRForm.get('rocode').enable();
                this.createEFSRForm.get('roaddress').enable();
                this.createEFSRForm.get('romobile').enable();
                
            } else {
              this.openSnackBar('RO City not found','');
              this.resetForm();
            }
          } else {
            this.openSnackBar(data.message, '');
            this.resetForm();
          }
        },
        (err: any) => this.openSnackBar(err.error.message, ''),
        () => { }
      );

      this.efsrservice.getSEandHPSMDetails(this.ticketId)
      .subscribe(
        (data: any) => {
          if (data.flag === Constants.SUCCESS_FLAG) {
            if (data.result.sename !== undefined) {
              this.setHPSMandSEEditFormValues(data.result);
             // this.createEFSRForm.get('rocity').enable();
              this.createEFSRForm.get('hpsmid').enable();
                this.createEFSRForm.get('semobile').enable();
                this.createEFSRForm.get('slainhand').enable();
                this.createEFSRForm.get('sename').enable();
                
            } else {
              this.openSnackBar('HPSM and SE not found', '');
              this.resetForm();
            }
          }
        },
        (err: any) => this.openSnackBar(err.error.message, ''),
        () => { }
      );

      //       this.VendorService.getticket(this.ticketId)
      //       .subscribe(
      //         (data: any) => {
      //           if (data.flag === Constants.SUCCESS_FLAG) {
      //             if (data.result.incidentId !== undefined) {
      //               this.incidentId=data.results;
      //              this.createEFSRForm.get('rocity').enable();
      //               //this.createEFSRForm.get('hpsmid').enable();
      //                // this.createEFSRForm.get('semobile').enable();
      //                // this.createEFSRForm.get('slainhand').enable();
      //                // this.createEFSRForm.get('sename').enable();
                      
      //             } else {
      //               this.openSnackBar('RO City not found', '');
      //               this.resetForm();
      //             }
      //           }
          
      //   },
      //   (err: any) => this.openSnackBar(err.error.message, ''),
      //   () => { }
      // );

      this.efsrservice.getTypeOfVendor()
      .pipe(catchError((err) => this.handleError(err)))
      .subscribe((data: any) => {
        this.vendorList = data.results;        
      });

      // this.efsrservice.getROKeyPersonList()
      // .pipe(catchError((err) => this.handleError(err)))
      // .subscribe((data: any) => {
      //   this.rokeyperson = data.results;        
      // });

      this.efsrservice.getTypeOfService()
      .pipe(catchError((err) => this.handleError(err)))
      .subscribe((data: any) => {
        this.serviceList = data.results;        
      });
      this.efsrservice.getTypeOfDevice()
      .pipe(catchError((err) => this.handleError(err)))
      .subscribe((data: any) => {
        this.deviceList = data.results;        
      });
      this.efsrservice.getTypeOfChcekList()
      .pipe(catchError((err) => this.handleError(err)))
      .subscribe((data: any) => {
        this.checklist = data.results;        
      });
   
      if (this.isEfsrExists)
      {
        this.efsrservice.getEFSRDetails(this.efsrId)
        .subscribe(
          (data: any) => {
            if (data.flag === Constants.SUCCESS_FLAG) 
            {
              if (data.results[0].checklist != null)
              {
                this.selectedchecklist = [];
                data.results[0].checklist.forEach(check => {
                 this.selectedchecklist.push(Number(check));
               });
              }
                this.setFormDraftValues(data.results);
                //this.createEFSRForm.get('roname').enable();
                // this.createEFSRForm.get('rocode').enable();
                // this.createEFSRForm.get('roaddress').enable();
                  //this.createEFSRForm.get('mobile').enable();
            } 
            else 
            {
              this.openSnackBar(data.message, '');
              this.resetForm();
            }
          },
          (err: any) => this.openSnackBar(err.error.message, ''),
          () => { }
        );
      }

      this.createEFSRForm.valueChanges.subscribe((data) => {
        this.logValidationErrors(this.createEFSRForm);
      });
  }
  
  createFormGroupWithBuilderAndModel() {
    return this.fb.group({
      roname: new FormControl('', Validators.compose([
      ]),
      ),
      rocode: new FormControl('', Validators.compose([
      ]),
      ),
      rocity: new FormControl('', Validators.compose([
      ])),
      roaddress: new FormControl('', Validators.compose([
      ])),
      romobile: new FormControl('', Validators.compose([
      ])),
      rokeypersonmobile: new FormControl('', Validators.compose([
        //Validators.required,
       // Validators.minLength(10),
       // Validators.pattern('^[6-9][0-9]{9}$')
      ])),
      reference: new FormControl('', Validators.compose([
        //Validators.required,
      ])),
      hpsmid: new FormControl('', Validators.compose([
      ])),
      callstatus: new FormControl('', Validators.compose([
        Validators.required,
      ])),
      slainhand: new FormControl('', Validators.compose([
        //Validators.required,
      ])),
      
      visitdate: new FormControl('', Validators.compose([
        //Validators.required,
      ])),
      vendor: new FormControl('', Validators.compose([
        Validators.required
      ])),
      service: new FormControl('', Validators.compose([
        Validators.required
      ])),
      rokeyperson: new FormControl('', Validators.compose([
        //Validators.required
      ])),
      device: new FormControl('', Validators.compose([
        Validators.required
      ])),
      checklistControl: new FormControl('', Validators.compose([
        Validators.required
      ])),
      spare: new FormControl('', Validators.compose([
        Validators.required
      ])),
      remark: new FormControl({disabled:true,value:''},Validators.compose([
      ])),
      servicesummary: new FormControl('', Validators.compose([
        Validators.required
      ])),
      poorratingcomments: new FormControl('', Validators.compose([
        Validators.required
      ])),
      quantity: new FormControl({disabled:true,value:''}, Validators.compose([
        Validators.required,
        //Validators.minLength(10),
        //Validators.pattern('^[0-9]*$')
      ])),
      semobile: new FormControl('', Validators.compose([
      ])),
      sename: new FormControl('', Validators.compose([
      ])),
      ratingSlider: new FormControl('', Validators.compose([
        //Validators.required
      ])),
    });
  }

  setEditFormValues(user: efsrcreate) {
    this.createEFSRForm.patchValue({
        roname: user.roname,
        rocode: user.rocode,
        rocity:user.city,
        roaddress: user.roaddress1,
        romobile: user.rophone1,
        rokeyperson : user.dealername,
        rokeypersonmobile: user.dealerphone
        //reference: user.reference,
        //visitdate: user.visitdate,
    });
  }

  // setHPSMandSEEditFormValues(user: hpsmandsedetails) {
    setHPSMandSEEditFormValues(user: efsrcreate) {
    this.createEFSRForm.patchValue({
      //rocity:user.rocity,
        hpsmid: user.hpsmid,
        semobile: user.semobile,
        slainhand: user.sla,
        sename: user.sename,
        callstatus: user.callstatus,
        reference: user.reference
    });
  }

  setFormDraftValues(details: patchefsrDraft) {
    this.createEFSRForm.patchValue({
        //rokeypersonmobile: details[0].roKeyPersonMobileNo,
        //callstatus: details[0].callStatus,
        vendor: Number(details[0].typeOfVendor),
        service: Number(details[0].typeOfServicing),
        //: Number(details[0].roKeyPersonName),
        device: Number(details[0].typeOfDevice),
        spare: details[0].sparesConsumedFlag == 1? "Yes" :"No",
        remark: details[0].remarkOfSparesConsumed,
        servicesummary: details[0].serviceSummary,
        quantity: details[0].quantity,
        //reference: details[0].reference,
        visitdate: details[0].visitDate,
        poorratingcomments: details[0].ratingComments,
       // ratingSlider:details[0].rating,
        //checklist: this.selectedchecklist
    });
    if (details[0].sparesConsumedFlag == 1)
    {
      this.spareremark = true;
      this.createEFSRForm.controls['remark'].setValidators([Validators.required]);
      this.createEFSRForm.controls['remark'].enable();
      this.createEFSRForm.controls["remark"].updateValueAndValidity();
      this.createEFSRForm.controls['quantity'].setValidators([Validators.required, Validators.pattern('^[0-9]*$')]);
      this.createEFSRForm.controls['quantity'].enable();
      this.createEFSRForm.controls["quantity"].updateValueAndValidity();
    }
    else
    {
      this.spareremark = false;
      this.createEFSRForm.controls['remark'].clearValidators();
      this.createEFSRForm.controls['remark'].disable();
      this.createEFSRForm.controls['remark'].reset();
      this.createEFSRForm.controls['quantity'].clearValidators();
      this.createEFSRForm.controls['quantity'].reset();
      this.createEFSRForm.controls['quantity'].disable();
    }
    this.ratingcnt = Number(details[0].rating);
    
    //this.ratingremark= Boolean(details[0].ratingComments);
    //(details[0].ratingComments)= String(this.ratingremark);
    this.createEFSRForm.controls['ratingSlider'].setValue(this.ratingcnt);
  this.createEFSRForm.controls['poorratingcomments'].setValue(details[0].ratingComments);
  if(details[0].ratingComments==null && details[0].rating>2){
    this.createEFSRForm.controls['poorratingcomments'].disable();
    this.createEFSRForm.controls['poorratingcomments'].patchValue(details[0].ratingComments);
  }
  else{
    this.createEFSRForm.controls['poorratingcomments'].enable();
    this.createEFSRForm.controls['poorratingcomments'].patchValue(details[0].ratingComments);

  }

 this.createEFSRForm.controls['checklistControl'].setValue(this.selectedchecklist);
 this.onInputChange(this.ratingcnt);
  }

SaveDraft() {
  const efsrDetails = Object.assign({}, this.createEFSRForm.value);
    var draftEfsr = new efsrDraft();
    draftEfsr.roCode = efsrDetails.rocode;
    draftEfsr.roName = efsrDetails.roname;
    draftEfsr.roCity = efsrDetails.rocity;
    draftEfsr.roContactNo = (efsrDetails.romobile);
    draftEfsr.roAddress = efsrDetails.roaddress;
    draftEfsr.reference = efsrDetails.reference==null?"No Record":efsrDetails.reference;
    if(typeof efsrDetails.visitdate == 'undefined' || efsrDetails.visitdate == "")
    {
     // draftEfsr.visitDate = null;
      const d = new Date()
      //this.startdate.getDate()
      draftEfsr.visitDate = new Date(Date.UTC((d.getFullYear()),d.getMonth(),d.getDate()));
    }
    else{
      const d = new Date(efsrDetails.visitdate)
      draftEfsr.visitDate = new Date(Date.UTC((d.getFullYear()),d.getMonth(),d.getDate()));
    }
    draftEfsr.hPSMId = efsrDetails.hpsmid;
    draftEfsr.callStatus = efsrDetails.callstatus;
    draftEfsr.slaInHand = Number(efsrDetails.slainhand)==0?0:Number(efsrDetails.slainhand);
    draftEfsr.typeOfVendor = efsrDetails.vendor;
    draftEfsr.typeOfServicing = efsrDetails.service;
    draftEfsr.typeOfDevice = efsrDetails.device;
    if (efsrDetails.spare == 'Yes')
    {
      draftEfsr.sparesConsumedFlag = 1;
     // this.createEFSRForm.get('efsrDetails.quantity').enable();
      draftEfsr.quantity = Number(efsrDetails.quantity);
      draftEfsr.remarkOfSparesConsumed = efsrDetails.remark;
    }
    else
    {
      draftEfsr.sparesConsumedFlag = 0;
      draftEfsr.quantity=0;
      draftEfsr.remarkOfSparesConsumed=null;
     // draftEfsr.quantity = 0;
      //this.createEFSRForm.get('efsrDetails.quantity').disable();
    }
    
  
    draftEfsr.serviceSummary = efsrDetails.servicesummary;
    draftEfsr.roKeyPersonName = efsrDetails.rokeyperson;
    //draftEfsr.roKeyPersonMobileNo = Number(efsrDetails.rokeypersonmobile);
    draftEfsr.roKeyPersonMobileNo = efsrDetails.rokeypersonmobile;
    draftEfsr.rating = Number(this.ratingcnt);
    draftEfsr.ratingComments = efsrDetails.poorratingcomments;
    if(efsrDetails.poorratingcomments != null || this.ratingcnt<=2 ){
      this.createEFSRForm.controls['poorratingcomments'].setValue(efsrDetails.poorratingcomments);
    }
    else{
      this.createEFSRForm.controls['poorratingcomments'].disable();
    }
    draftEfsr.incidentId = this.incidentId;
    //Checklist
    if(typeof efsrDetails.checklistControl == 'undefined' || efsrDetails.checklistControl == "" ||efsrDetails.checklistControl == null)
    {
       //do nothing
    }
    else
    {
      draftEfsr.checklist =  [];
      efsrDetails.checklistControl.forEach(check => {
        draftEfsr.checklist.push(Number(check));
      });
    }
    draftEfsr.isSubmitted = 0;

    this.efsrservice.createefsr(draftEfsr)
      .subscribe(
        (data: any) => {
          //this.openSnackBar(data.message, '');
          if (data.flag === Constants.SUCCESS_FLAG) {
            this.efsrservice.setRequestFromEfsr(true);
            this.openSnackBar(data.message, '');
            //this.resetForm();
            //this.isFormSectionVisible = false;
            this.efsrId = data.returnId;
            //this.isDocumentSectionVisiable = true;
          }
          else
          {
            this.openSnackBar(data.message, '');
          }
        },
        (err: any) => {
          this.openSnackBar(err.error.message, '');
        },
        () => { }
      );
}

backToList()
{
  //this.isFormSectionVisible = false;
  //this.efsrId = data.returnId;
  //this.isDocumentSectionVisiable = true;
}

movetoUploadDocument()
{
   //this.isFormSectionVisible = false;
  //this.efsrId = data.returnId;
  //this.isDocumentSectionVisiable = true;
}

  onSubmit() {
        
  if (true)
  {    
    const efsrDetails = Object.assign({}, this.createEFSRForm.value);
    var draftEfsr = new efsrDraft();
    //draftEfsr.roCode = efsrDetails.rocode;
    draftEfsr.roCode=efsrDetails.rocode;
    draftEfsr.roName = efsrDetails.roname;
    draftEfsr.roCity = efsrDetails.rocity;
    draftEfsr.roContactNo = efsrDetails.romobile;
    draftEfsr.roAddress = efsrDetails.roaddress;
    draftEfsr.reference = efsrDetails.reference==null?"No Record":efsrDetails.reference;
    if(typeof efsrDetails.visitdate == 'undefined' || efsrDetails.visitdate == "")
    {
      const d = new Date()
      //this.startdate.getDate()
      draftEfsr.visitDate = new Date(Date.UTC((d.getFullYear()),d.getMonth(),d.getDate()));
      //draftEfsr.visitDate = null;
    }
    else{
      const d = new Date(efsrDetails.visitdate)
      draftEfsr.visitDate = new Date(Date.UTC((d.getFullYear()),d.getMonth(),d.getDate()));
      //draftEfsr.visitDate = new Date(Date.UTC((efsrDetails.visitdate.getFullYear()),efsrDetails.visitdate.getMonth(),efsrDetails.visitdate.getDate()));
    }
    draftEfsr.hPSMId = efsrDetails.hpsmid;
    draftEfsr.callStatus = efsrDetails.callstatus;
    draftEfsr.slaInHand = Number(efsrDetails.slainhand)==0?0:Number(efsrDetails.slainhand);
    draftEfsr.typeOfVendor = efsrDetails.vendor;
    draftEfsr.typeOfServicing = efsrDetails.service;
    draftEfsr.typeOfDevice = efsrDetails.device;
    if (efsrDetails.spare == 'Yes')
    {
      draftEfsr.sparesConsumedFlag = 1;
     // this.createEFSRForm.get('quantity').enable();
     draftEfsr.quantity = Number(efsrDetails.quantity);
     draftEfsr.remarkOfSparesConsumed = efsrDetails.remark;
    }
    else
    {
      draftEfsr.sparesConsumedFlag = 0;
     // this.createEFSRForm.get('quantity').reset();
     // this.createEFSRForm.get('quantity').disable();
      //draftEfsr.quantity = 0;
    }
    
  
   // draftEfsr.quantity = Number(efsrDetails.quantity);
    draftEfsr.serviceSummary = efsrDetails.servicesummary;
    draftEfsr.roKeyPersonName = efsrDetails.rokeyperson;
    //draftEfsr.roKeyPersonMobileNo = Number(efsrDetails.rokeypersonmobile);
    draftEfsr.roKeyPersonMobileNo = efsrDetails.rokeypersonmobile;
    draftEfsr.rating = Number(this.ratingcnt);
   
    draftEfsr.ratingComments = efsrDetails.poorratingcomments;

   // if(this.ratingcnt==0 && efsrDetails.poorratingcomments==''){
   //   draftEfsr.rating = 0;
   
    //  draftEfsr.ratingComments = null;
     // this.createEFSRForm.controls['ratingSlider'].setValidators([Validators.required]);
     // this.createEFSRForm.controls['poorratingcomments'].setValidators([Validators.required]);
      //this.createEFSRForm.disable();
      //this.openSnackBar('Please Provide Rating and Comments for RO','');

  //  }
   // else if(this.ratingcnt<=2 && efsrDetails.poorratingcomments=='' ){

     // draftEfsr.rating = Number(this.ratingcnt);
   
      //draftEfsr.ratingComments = null;
      //this.createEFSRForm.controls['ratingSlider'].setValidators([Validators.required]);
      //this.createEFSRForm.controls['poorratingcomments'].setValidators([Validators.required]);
    //  this.createEFSRForm.disable();
      //this.openSnackBar('Please Provide Rating and Comments for RO','');
    //}
    //else if(this.ratingcnt>=2 && efsrDetails.poorratingcomments==String){
      //draftEfsr.rating = Number(this.ratingcnt);
   
      //draftEfsr.ratingComments = efsrDetails.poorratingcomments;

      //this.createEFSRForm.controls["ratingSlider"].updateValueAndValidity();
      //this.createEFSRForm.controls["poorratingcomments"].updateValueAndValidity();
      //this.createEFSRForm.enable();

    //}

    draftEfsr.incidentId = this.incidentId;
    //Checklist
    if(typeof efsrDetails.checklistControl == 'undefined' || efsrDetails.checklistControl == "" ||efsrDetails.checklistControl == null)
    {
       //do nothing
    }
    else
    {
      draftEfsr.checklist =  [];
      efsrDetails.checklistControl.forEach(check => {
        draftEfsr.checklist.push(Number(check));
      });
    }
    draftEfsr.isSubmitted = 1;

    //draftEfsr.seId = 
    //draftEfsr.incidentId = 

    this.efsrservice.createefsr(draftEfsr)
      .subscribe(
        (data: any) => {
          //this.openSnackBar(data.message, '');
          if (data.flag === Constants.SUCCESS_FLAG) {
            this.efsrservice.setRequestFromEfsr(true);
            this.openSnackBar(data.message, '');
            this.resetForm();
            this.isFormSectionVisible = false;
            this.efsrId = data.returnId;
            this.isDocumentSectionVisiable = true;
          }
          else
          {
            this.openSnackBar(data.message, '');
          }
        },
        (err: any) => {
          this.openSnackBar(err.error.message, '');
        },
        () => { }
      );
    }
    else
      {
        this.ValidateAllFields(this.createEFSRForm);
        this.logValidationErrors(this.createEFSRForm);
      }

  }


  resetForm() {
    this.createEFSRForm.reset();
    for (const i in this.createEFSRForm.controls) {
      if (this.createEFSRForm.controls[i]) {
        this.createEFSRForm.controls[i].setErrors(null);
      }
    }
    this.spareremark = false;
    this.ratingremark=false;
    this.ratingcnt=0;
   
    }

    selectInput(Slectedstate:any) {

      //var selected = this.checklist.filter(classification => classification.name === Slectedstate);

      if (Slectedstate.name == "Yes") {

          this.spareremark = true;
          this.createEFSRForm.controls['remark'].setValidators([Validators.required]);
          this.createEFSRForm.controls['remark'].enable();
          this.createEFSRForm.controls["remark"].updateValueAndValidity();
          this.createEFSRForm.controls['quantity'].setValidators([Validators.required,Validators.pattern('^[0-9]*$')]);
          this.createEFSRForm.controls['quantity'].enable();
          this.createEFSRForm.controls["quantity"].updateValueAndValidity();
      } else {
          this.spareremark = false;
          this.createEFSRForm.controls['remark'].clearValidators();
          this.createEFSRForm.controls['remark'].disable();
          this.createEFSRForm.controls['remark'].reset();
          this.createEFSRForm.controls['quantity'].clearValidators();
          this.createEFSRForm.controls['quantity'].reset();
          this.createEFSRForm.controls['quantity'].disable();
         // this.createEFSRForm.controls["quantity"].updateValueAndValidity();
        }


  }
  onInputChange(value:number) {
  value=Number(this.ratingcnt);
  //rating1=String(this.ratingcnt);
  //this.ratingcnt=maxvalue;
    if(value<=2)
    {
     
    //  this.formatLabel(slidervalue);
     // this.ticksData01=[];
    this.ratingremark=true;
     // this.createEFSRForm.controls['ratingSlider'].valid;
     // this.createEFSRForm.controls["ratingSlider"].value.selected._multiple=false;
    this.createEFSRForm.controls['poorratingcomments'].setValidators([Validators.required]);
      this.createEFSRForm.controls['poorratingcomments'].enable();
         this.createEFSRForm.controls["poorratingcomments"].updateValueAndValidity();
        
    }
 
    else {
     this.ratingremark=false;
     // this.createEFSRForm.controls['ratingSlider'].valid;
    //  this.createEFSRForm.controls["ratingSlider"].value.selected._multiple=false;
      this.createEFSRForm.controls['poorratingcomments'].clearValidators();
      this.createEFSRForm.controls['poorratingcomments'].reset();
      this.createEFSRForm.controls['poorratingcomments'].disable();
    }
  }
  //onRatingchange(){
    
    //if(this.createEFSRForm.controls['ratingSlider'].untouched==true){
    //  this.openSnackBar('Please Provide Rating','');
   // }
  //}

  cancelEdit() {
    this.resetForm();
  }
  /**
   * Dialog is shown, when admin tries to disable a user.
   * This stops admin from to disable user accidentally
   *
   * @returns
   * @memberof UserEditComponent
   */
  openDeactivationDialog() {
    if (this.createEFSRForm.value.isactive !== false) {
      return;
    }

    const dialogConfig = new MatDialogConfig();
    dialogConfig.autoFocus = true;
    dialogConfig.disableClose = true;
    // dialogConfig.height = '30%';
    // dialogConfig.width = '30%';
    // dialogConfig.data = {
    //   username: this.editSEUserForm.value.username,
    //   isactive: this.editSEUserForm.value.isactive,
    //   WarningMessage: 'User ' + this.editSEUserForm.value.username + ' will be disabled'
    // }

    //const dialogRef = this.dialog.open(SeuserDisableDialogComponent, dialogConfig);
   // dialogRef.afterClosed().subscribe(result => {

    //   if (result !== undefined) {
    //     // console.log(result);
    //   } else {
    //     this.editSEUserForm.patchValue({
    //       isactive: true
    //     });
    //   }
    // });
  }
  
  openSnackBar(message: string, action: string) {
    this.snackBar.open(message, action, {
      duration: 5000,
      verticalPosition: 'top'
    });
  }
  private handleError(error: any) {
    // this.errorReceived = true;
    return Observable.throw(error);
  }

  logValidationErrors(group: FormGroup = this.createEFSRForm): void {
    Object.keys(group.controls).forEach((key: string) => {
      const abstractControl = group.get(key);
      if (abstractControl instanceof FormGroup) {
        this.logValidationErrors(abstractControl)
      } else {
        this.formErrors[key] = '';
        if (abstractControl && !abstractControl.valid && (abstractControl.touched || abstractControl.dirty)) {
          const messages = this.validationMessages[key];

          for (const errorKey in abstractControl.errors) {
            if (errorKey) {
              this.formErrors[key] += messages[errorKey] + '';
            }
          }
        }
      }
    });
  }

  ValidateAllFields(formGroup: FormGroup) {
    Object.keys(formGroup.controls).forEach(field => {
      const control = formGroup.get(field);
      if (control instanceof FormControl) {
        control.markAsTouched({ onlySelf: true });
      } else if (control instanceof FormGroup) {
        this.ValidateAllFields(control);
      }
    });
  }
  
 //ormatLabel(slidervalue: number | null) {
    //if (!slidervalue) {
    //  return 0;
    //}
    //if(slidervalue == 0)
   // {
    //  return 'No Rating';
    
      //return this.ticks01= "No Rating";
    //}

   // if(slidervalue == 1)
    //{
     //   return 'Very Poor';
  //  }
   // else if(slidervalue ==2)
   // {
    //   return 'Poor';
   // }
   // else if(slidervalue ==3)
   // {
     //  return 'Satisfactory';
   // }
   // else if(slidervalue == 4)
   // {
    //   return 'Good';
   // }
   // else if(slidervalue ==5)
    //{
    //   return 'Excellent';
   // }

  //  return slidervalue;
 // }
  
}



