import { Injectable } from '@angular/core';
import { User } from 'src/app/Models/usermanagement/user.model';
import { Observable, throwError } from 'rxjs';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { map, catchError, tap } from 'rxjs/operators';
import { HttpVerbService } from 'src/app/Services/httpverb/httpverb.service';
import { IRole } from 'src/app/Models/usermanagement/role.model';

import { IUserType } from 'src/app/Models/usermanagement/usertype.model';
import { IMenu } from 'src/app/Models/common/menu.model';
import { environment } from 'src/environments/environment';


/**
 * User service for user and role management
 *
 * @export
 * @class UserService
 */
@Injectable({
  providedIn: 'root'
})
export class UserService {

  /**
   * Base url where the api is hosted
   *
   * @type {string}
   * @memberof UserService
   */
  baseUrl: string;

  /**
   * Creates an instance of UserService.
   * @param {HttpClient} http
   * @param {HttpVerbService} service
   * @memberof UserService
   */
  constructor(private http: HttpClient, private service: HttpVerbService) {
    this.baseUrl = environment.restUrl + '/api/v1/';
  }

  /**
   * Create new user with the details provide in the form
   *
   * @param {User} newUser
   * @returns {Observable<any>}
   * @memberof UserService
   */
  createUser(newUser: User): Observable<any> {
    return this.http.post<any>(this.baseUrl + 'createuser', newUser, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    });
  }

  /**
   * Search user the search type and search parameter
   *
   * @param {*} [searchType=null]
   * @param {*} [searchTerm=null]
   * @returns {Observable<User>}
   * @memberof UserService
   */
  searchUser(searchType = null, searchTerm = null): Observable<User> {
    // encoding the search term into base64 format
    const url = this.baseUrl + 'users/search?searchType=' + searchType + '&parameter=' + btoa(searchTerm);
    return this.http.get<User>(url);
  }

  /**
   * Update user with changed user details for edit user form
   *
   * @param {User} userData
   * @returns {Observable<any>}
   * @memberof UserService
   */
  updateUser(userData: User): Observable<any> {
    return this.http.post<any>(this.baseUrl + 'users', userData, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    });
  }

  /**
   * Fetch user types or designations
   *
   * @returns {Observable<IUserType[]>}
   * @memberof UserService
   */
  getUserTypes(): Observable<IUserType[]> {
    const url = this.baseUrl + 'usertypes';
    return this.service.get(url).pipe(map((response: any) => {
      return response;
    }));
  }

  getReferencesDetails(item: string): Observable<IUserType[]> {
    const url = this.baseUrl + 'getSelectedListItems?selectedItem=' + item;
    return this.service.get(url).pipe(map((res: any) => {
      return res;
    }))
  }
  /**
   * Get all active roles
   *
   * @returns {Observable<IRole[]>}
   * @memberof UserService
   */
  getRoles(): Observable<IRole[]> {
    const url = this.baseUrl + 'roles';
    return this.service.get(url).pipe(map((response: any) => {
      return response;
    }));
  }

  /**
   * Create role
   *
   * @param {IRole} newRole
   * @returns {Observable<any>}
   * @memberof UserService
   */
  createRole(newRole: IRole): Observable<any> {
    const url = this.baseUrl + 'roles';
    return this.service.post(url, newRole).pipe(map((response: any) => {
      return response;
    }));
  }

  /**
   * Get menus by role id
   *
   * @param {number} roleId
   * @returns {Observable<any>}
   * @memberof UserService
   */
  getRoleMenusByRoleId(roleId: number): Observable<any> {
    const url = this.baseUrl + 'rolemenus?roleId=' + roleId;

    return this.service.get(url).pipe(map((response: any) => {
      return response;
    }));

  }

  public getAllMenuItems(): Observable<any> {
    return this.http.get<any>(this.baseUrl + 'menuitems');
  }

  /**
   * Update role menus
   *
   * @param {number} roleId
   * @param {IMenu[]} menus
   * @returns {Observable<any>}
   * @memberof UserService
   */
  updateRoleMenus(roleId: number, subMenuIds: string): Observable<any> {
    const url = this.baseUrl + 'rolemenus/' + roleId + '?subMenuIds=' + subMenuIds;

    const params = new HttpParams().set('subMenuIds', subMenuIds);

    return this.service.postWithId(url, null).pipe(map((response: any) => {
      return response;
    }));
  }


  /**
   * Reset password for a user from admin's login
   *
   * @param {string} userInput
   * @returns {Observable<any>}
   * @memberof UserService
   */
  passwordReset(userInput: string): Observable<any> {
    // before sending request the user input is encoded to base64
    const url = this.baseUrl + 'resetpassword?userInput=' + btoa(userInput);

    return this.service.postWithId(url, null).pipe(map((response: any) => {
      return response;
    }));
  }

  isUsernameTaken(username: string) {
    const url = this.baseUrl + 'username/availability?username=' + username;

    // HttpParams params = new HttpParams().set('username', username);

    return this.http.get<any>(url);
  }

  /**
   * Check user authentciation
   * @param userName
   * @param password
   */
  public userAuthentication(userName, password, isRecaptchaEnabled: boolean, userverificationResponse,isPnocUser: string) {
    //let data = 'username=' + userName + '&password=' + password + '&grant_type=password';
    let data = 'username=' + userName + '&password=' + password + '&grant_type=password' + '&pnocuser=' + isPnocUser;
    if (isRecaptchaEnabled) {
      data = 'username=' + userName + '&password=' + password + '&grant_type=password' + '&userverificationResponse=' + userverificationResponse;
    }
    const reqHeader = new HttpHeaders({ 'Content-Type': 'application/json', 'No-Auth': 'True' });
    return this.http.post('' + environment.restUrl + '/token', data, { headers: reqHeader });
  }

  /**
   * Get logged in user claims
   */
  // public getCurrentUserClaims(): Observable<any> {
  //   return this.http.get<any>(this.baseUrl + 'getCurrentUserClaims');
  // }

  /**
   * Edit user
   */
  public editUser(): Observable<any> {
    return this.http.get<any>(this.baseUrl + 'getLogin');
  }

  /**
   * Get logged in user claims
   */
  public getMenuAsPerUser(): Observable<any> {
    return this.http.get<any>(this.baseUrl + 'menu');
  }

  // /**
  //  * update user logout time
  //  */
  // public updateUserLogoutTime(): any {
  //   return this.http.put<any>(this.baseUrl + 'updateuserlog', 'update logout time', {
  //     headers: new HttpHeaders({
  //       'Content-Type': 'application/json'
  //     })
  //   });
  // }
}
