import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BootstrapModule } from './bootstrap.module';
import { MaterialModule } from './material.module';
import { HeaderComponent } from './components/layout/header/header.component';
import { FooterComponent } from './components/layout/footer/footer.component';
import { SubheaderComponent } from './components/layout/subheader/subheader.component';
import { RouterModule } from '@angular/router';
import { UniqueUsernameValidatorDirective } from './unique-username-validator.directive';
import { CommonModule } from '@angular/common';
//import { UploadDocsVehicleComponent } from '../Component/vehicle/upload-docs-vehicle/upload-docs-vehicle.component';
//import { OrderPaymentConfirmationPopupComponent } from '../Component/orders/order-payment-confirmation-popup/order-payment-confirmation-popup.component';
import { ElementVisibilityDirective } from './element-visibility.directive';
import { ImagelazyloadDirective } from './directive/imagelazyload.directive';


@NgModule({
  declarations: [
    HeaderComponent,
    FooterComponent,
    SubheaderComponent,
    //UploadDocsVehicleComponent,
    //OrderPaymentConfirmationPopupComponent,
    UniqueUsernameValidatorDirective,
    ElementVisibilityDirective,    
    ImagelazyloadDirective
  ],
  imports: [
    MaterialModule,
    RouterModule,
    CommonModule,
    ReactiveFormsModule
  ],
  exports: [
    // Modules
    MaterialModule,
    BootstrapModule,
    FormsModule,
    ReactiveFormsModule,
    

    // components
    HeaderComponent,
    FooterComponent,
    SubheaderComponent,   
    //UploadDocsVehicleComponent,
    //OrderPaymentConfirmationPopupComponent,
    
    // Directives
    UniqueUsernameValidatorDirective,
    ElementVisibilityDirective,    
    ImagelazyloadDirective
  ]
})
export class SharedModule { }
