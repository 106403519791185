export interface IPriority {
    id: number;
    name: string;
  }
  
  export class ISearchPara {
    //category: string;
    //status: string;
    //location: string;
    priority: string;
  }

  export class serating {
    incidentId: string;
    interactionId: string;
    status: string;
  }
