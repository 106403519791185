import { Component, OnInit } from '@angular/core';
import { Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { FormGroup, FormBuilder, FormControl, Validators } from '@angular/forms';
import { applicationconstant } from 'src/app/Models/DropdownItemsGroup/applicationconstant.model';
import { MatDialog, MatSnackBar, MatAutocompleteModule, MatInputModule } from '@angular/material';
import { efsrcreate, hpsmandsedetails,efsrDraft, CheckListSaveDto, patchefsrDraft, EFSRApproveReject } from 'src/app/Models/EFSR/efsrcreate.model';
import { Observable } from 'rxjs';
import { efsrService } from 'src/app/Services/EFSR/efsrcreate.service';
import{ViewEfsrComponent} from 'src/app/Component/EFSR/view-efsr/view-efsr.component';

@Component({
  selector: 'app-confirmation-delete',
  templateUrl: './confirmation-delete.component.html',
  styleUrls: ['./confirmation-delete.component.scss']
})
export class ConfirmationDeleteComponent implements OnInit {
  Approveform: FormGroup;
  description: string;
  submitted: boolean = false;
  close: boolean = false;

  validationMessages = {
    'remarks': {
      'required': 'Remarks for approval is required.',
    },
   
  };

  formErrors = {
    'remarks': ''
  };

  constructor(private fb: FormBuilder, public snackBar: MatSnackBar,private efsrservice: efsrService,public dialogRef: MatDialogRef<ConfirmationDeleteComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
    ) {
      this.Approveform = this.fb.group({
        remarks: new FormControl(null, Validators.required)
      });
     }

  onNoClick(): void {
    this.dialogRef.close();
  }

  onYesClick(): void{
    if (this.Approveform.valid) {
      const remarks = this.Approveform.get('remarks').value;
      
      const approveRejectEFSR = new EFSRApproveReject();
      approveRejectEFSR.efsrId = this.data.efsrId;
      approveRejectEFSR.incidentId=this.data.incidentId;
      //approveRejectEFSR.seId = this.data.seId;
     //approveRejectEFSR.sEEmail=null;
      approveRejectEFSR.roKeyPersonName = this.data.roKeyPersonName;
      //approveRejectEFSR.seemail = this.data.seemail;
      approveRejectEFSR.approveReason = remarks;
      approveRejectEFSR.ratingByRO = Number(this.data.ratingcnt);
      approveRejectEFSR.ratingCommentsByRO = String(this.data.ratingremark);
      approveRejectEFSR.approvedFlag = 1;
      approveRejectEFSR.rejectFlag = 0;
      //approveRejectEFSR.rejectReason = null;

      this.efsrservice.approveRejectefsr(approveRejectEFSR)
        .subscribe(
          data => {
            if (data.flag === 'S') {
              this.openSnackBar(data.message, '');
              this.dialogRef.close(true);
              
              
            }
            else {
              this.openSnackBar(data.message, '');
              
             
            }
          },
          (err: any) => this.openSnackBar(err.error.message, '')
        );
    }
    else {
      this.ValidateAllFields(this.Approveform);
    
      this.logValidationErrors(this.Approveform);
    }
}

  ngOnInit() {
   
    this.Approveform.valueChanges.subscribe((data) => {
      this.logValidationErrors(this.Approveform);
    });
  }

  logValidationErrors(group: FormGroup = this.Approveform): void {
    Object.keys(group.controls).forEach((key: string) => {
      const abstractControl = group.get(key);
      if (abstractControl instanceof FormGroup) {
        this.logValidationErrors(abstractControl)
      } else {
        this.formErrors[key] = '';
        if (abstractControl && !abstractControl.valid && (abstractControl.touched || abstractControl.dirty)) {
          const messages = this.validationMessages[key];

          for (const errorKey in abstractControl.errors) {
            if (errorKey) {
              this.formErrors[key] += messages[errorKey] + '';
            }
          }
        }
      }
    });
  }

  ValidateAllFields(formGroup: FormGroup) {
    Object.keys(formGroup.controls).forEach(field => {
      const control = formGroup.get(field);
      if (control instanceof FormControl) {
        control.markAsTouched({ onlySelf: true });
      } else if (control instanceof FormGroup) {
        this.ValidateAllFields(control);
      }
    });
  }

  public openSnackBar(message: string, action: string) {
    this.snackBar.open(message, action, {
      duration: 5000,
      verticalPosition: 'top'
    });
  }

  private handleError(error: any) {
    return Observable.throw(error);
  }
}


